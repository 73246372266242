import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

import { locale } from '../../../helpers/locale';
import { formatData } from '../../../helpers/format-multischool-excel-export-data';
import { withNationHOC } from '../../../hocs/withNation';
const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

import { ExcelStatisticsWrapper } from 'containers';

const ExcelMultiSchoolStatisticsWorkbook = withNationHOC(
  ({ data, hasPrograms, nation, resumeBuilder }) => {
    // prettier-ignore
    const introduction = [
      {
        text: `The Career Coach Analytics workbook is comprised of up to 6 worksheets (not including the introduction worksheet). The number of worksheets varies depending on site configurations. The worksheets and descriptions of each are as follows: "Top Viewed Careers" lists the number of visits to that career\'s page by school. "Top Viewed ${locale.edTrack[nation]}s" lists the number of visits to each ${locale.edTrack[nation].toLowerCase()} page by school. "Top Career Searches" and "Top ${locale.edTrack[nation]} Searches" list each search term and the number of times it has been searched during the selected time period by school. "Visits" shows the total number of visits to Career Coach during the selected period by school. "Visitors" shows the total number of visitors to Career Coach during the selected period by school. "Assessments By School" shows the number of assessments started and completed per user-selected school.`, 
        value: null
      }
    ];
    // prettier-ignore

    const TopCareerSearchesData = formatData(
      {
        data: data['top-career-searches'],
        label: 'Top Career Searches'
      },
      nation
    );
    const TopProgramSearchesData = formatData(
      {
        data: data['top-program-searches'],
        label: `Top ${locale.edTrack[nation]} Searches`
      },
      nation
    );
    const TopCareersData = formatData(
      {
        data: data['top-careers'],
        label: 'Top Viewed Careers'
      },
      nation
    );
    const TopProgramsData = formatData(
      {
        data: data['top-programs'],
        label: `Top Viewed ${locale.edTrack[nation]}s`
      },
      nation
    );

    const VisitsData = formatData(
      {
        data: data['visits'],
        label: 'Visits'
      },
      nation
    );

    const VisitorsData = formatData(
      {
        data: data['visitors'],
        label: 'Visitors'
      },
      nation
    );

    return (
      <ExcelStatisticsWrapper
        filename="Career_Coach_Multischool_Analytics"
        hasPrograms={hasPrograms}
        resumeBuilder={resumeBuilder}
      >
        <ExcelSheet dataSet={TopCareersData} name="Top Viewed Careers" />
        <ExcelSheet dataSet={TopProgramsData} name={`Top Viewed ${locale.edTrack[nation]}s`} />
        <ExcelSheet dataSet={TopCareerSearchesData} name="Top Career Searches" />
        <ExcelSheet
          dataSet={TopProgramSearchesData}
          name={`Top ${locale.edTrack[nation]} Searches`}
        />
        <ExcelSheet dataSet={VisitsData} name="Visits" />
        <ExcelSheet dataSet={VisitorsData} name="Visitors" />
        <ExcelSheet data={data['assessment-by-school']} name="Assessments">
          <ExcelColumn label="School" value="schoolName" />
          <ExcelColumn label="Starts" value="starts" />
          <ExcelColumn label="Completions" value="completions" />
        </ExcelSheet>

        <ExcelSheet data={introduction} name="Introduction">
          <ExcelColumn label={`${introduction[0].text}`} value="value" />
        </ExcelSheet>
      </ExcelStatisticsWrapper>
    );
  }
);

ExcelMultiSchoolStatisticsWorkbook.propTypes = {
  data: PropTypes.object,
  hasJobPostings: PropTypes.bool,
  hasPrograms: PropTypes.bool,
  requestEmail: PropTypes.string,
  resumeBuilder: PropTypes.bool
};

export default ExcelMultiSchoolStatisticsWorkbook;
