import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../../../config';
import { color } from '../../../styles';
import { uploadLogo } from '../../../services/uploads';
import { Card, Upload } from 'components';

const { cloudfrontUrl } = config;

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1.5rem 1rem;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0.5rem 0 1rem;
  }
`;

const H2 = styled.h2`
  margin-bottom: 2rem;
  font-size: 2.1rem;
`;

const UploadWrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LogoAndTextWrapper = styled(Wrapper)`
  align-items: center;
`;

const H3 = styled.h3`
  margin: 0.6rem 0;
  font-weight: 600;
`;

const ImgWrapper = styled.div`
  align-items: center;
  border: 2px dashed ${color.mediumGray};
  background: ${props => props.backgroundColor};

  img {
    max-height: 10rem;
  }
`;

const LogoUpload = props => {
  const { styles, updateLogo } = props;

  return (
    <Card>
      <H2>Header Logo</H2>
      <UploadWrapper>
        <LogoAndTextWrapper>
          <H3>Current Logo</H3>
          <ImgWrapper backgroundColor={styles.menuBackgroundColor}>
            {/*
             * The image source includes a timestamp on it so that the browser doesn't cache an old image
             * This is useful for updating to the correct logo when we add/change logos
             */}
            <img
              alt="current logo image"
              src={`${cloudfrontUrl}/logos/${styles.logoFilename}?${Date.now()}`}
            />
          </ImgWrapper>
        </LogoAndTextWrapper>
        <Wrapper>
          <H3>Replace Header Logo</H3>
          <Upload
            ariaLabel="Upload header logo"
            {...props}
            handleUpload={({ acceptedFile, uploadFilename, setUploading }) => {
              uploadLogo(acceptedFile, uploadFilename).then(() => {
                updateLogo(uploadFilename);
                setUploading(false);
              });
            }}
            caveat="Please limit images to 100px in height and less than 1MB in size. Only .png is accepted :)"
          />
        </Wrapper>
      </UploadWrapper>
    </Card>
  );
};

LogoUpload.propTypes = {
  styles: PropTypes.shape({ logoFilename: PropTypes.string.isRequired }).isRequired,
  updateLogo: PropTypes.func.isRequired
};

export default LogoUpload;
