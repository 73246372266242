import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import {
  keenWidgetClicks,
  keenWidgetLoads,
  keenWidgetVisitButtonClicks
} from '../../../services/keen/queries';
import { withSiteHOC } from '../../../hocs/withSite';
import { ProgramWidgetCareerCoachVisitsTable, ProgramWidgetTopActivityTable } from 'containers';
import { AnalyticsMiniChart, Hint } from 'components';

const { darkBlue, darkGreen, purple } = color;

const Card = styled.div`
  padding: 4rem 2rem 2.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:last-child {
    margin-right: 0;
  }
`;

const StyledAnalyticsMiniChart = styled(AnalyticsMiniChart)`
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
`;

const ProgramWidgetStatistics = withSiteHOC()(({ nation, enabled }) => {
  return (
    <Card>
      <Row>
        <StyledAnalyticsMiniChart
          dataLabel="views"
          heading="Total Views"
          color={darkGreen}
          nation={nation}
          tooltip={
            <Hint id="widget-views-mini-chart">
              Total Views: this is the total number of widget loads that can be interpreted as
              widget views
            </Hint>
          }
          keenQuery={keenWidgetLoads}
        />
        <StyledAnalyticsMiniChart
          dataLabel="clicks"
          heading="Clicks within Widgets"
          color={darkBlue}
          nation={nation}
          tooltip={
            <Hint id="widget-clicks-mini-chart">
              This includes clicks on career titles, carousel arrows, and interactions with the
              region selector.
            </Hint>
          }
          keenQuery={keenWidgetClicks}
        />
        {enabled && (
          <StyledAnalyticsMiniChart
            dataLabel="clicks"
            heading="Clicks to Career Coach"
            color={purple}
            nation={nation}
            keenQuery={keenWidgetVisitButtonClicks}
          />
        )}
      </Row>
      <ProgramWidgetTopActivityTable />
      {enabled && <ProgramWidgetCareerCoachVisitsTable />}
    </Card>
  );
});

ProgramWidgetStatistics.propTypes = {
  enabled: PropTypes.bool,
  nation: PropTypes.string
};

export default ProgramWidgetStatistics;
