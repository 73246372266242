import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FilterIcon from '../../../images/table-filter-button.svg';
import ProgramCategoryFilterModal from './ProgramCategoryFilterModal';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const Button = styled.button`
  background: none;
  border: none;
`;

const ProgramCategoryFilter = ({ categoryIds, categoryOptions, setCategoryIds }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleFilterModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Wrapper>
      <Button onClick={toggleFilterModal} aria-label="open program category filter">
        <FilterIcon />
      </Button>
      {isModalOpen && (
        <ProgramCategoryFilterModal
          closeModal={toggleFilterModal}
          categoryIds={categoryIds}
          categoryOptions={categoryOptions}
          setCategoryIds={setCategoryIds}
        />
      )}
    </Wrapper>
  );
};

ProgramCategoryFilter.propTypes = {
  categoryIds: PropTypes.array,
  categoryOptions: PropTypes.array.isRequired,
  setCategoryIds: PropTypes.func
};

export default ProgramCategoryFilter;
