import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Header, Truncate, ExternalLink, Margin, Strong } from 'components';
import HeartFilled from '../../../images/heart-filled.svg';
import { Modal } from 'containers';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  @media (min-width: 1042px) {
    border-radius: 3px;
    width: 90rem;
  }
`;

const Text = styled.div`
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 700;
`;

const StyledList = styled.ul`
  > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.8rem 0;
  }
`;

const FavoritesModal = withNationHOC(({ favorites, modalName, name, nation, subdomain }) => {
  const favoritesList = (faves, type) => {
    return faves.map((fave, i) => (
      <li key={`fave-${i}`}>
        <ExternalLink url={`https://${subdomain}.lightcastcc.com/${type}/${fave.titleSlug}`}>
          <Truncate>
            {fave.singularTitle}
            {fave.credential && <Fragment> &mdash; {fave.credential}</Fragment>}
            {fave.institutionName && (
              <Fragment>
                , <Strong>{fave.institutionName}</Strong>
              </Fragment>
            )}
          </Truncate>
        </ExternalLink>
      </li>
    ));
  };

  return (
    <Modal name={modalName}>
      <Wrapper>
        <Header type="headline" component="h2">
          {name}
        </Header>
        {favorites.careers && (
          <Fragment>
            <Margin value="2rem 0 0 0">
              <Text>
                <HeartFilled aria-hidden="true" /> &nbsp; {locale.spellings.favorite[nation]}d
                Careers
              </Text>
            </Margin>
            <StyledList>{favoritesList(favorites.careers, 'careers')}</StyledList>
          </Fragment>
        )}
        {favorites.programs && favorites.programs.length > 0 && (
          <Fragment>
            <Margin value="4rem 0 0 0">
              <Text>
                <HeartFilled aria-hidden="true" /> &nbsp; {locale.spellings.favorite[nation]}d{' '}
                {locale.edTrack[nation]}s
              </Text>
            </Margin>
            <StyledList>{favoritesList(favorites.programs, 'programs')}</StyledList>
          </Fragment>
        )}
      </Wrapper>
    </Modal>
  );
});

FavoritesModal.propTypes = {
  favorites: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default FavoritesModal;
