/**
 * used to format the data coming from the form into an array that the api can read
 * @param {array} areas,
 * @param {object} level
 */
export function formatAreas(areas) {
  return areas.map(i => {
    const center = i.center.label
      ? {
          name: i.center.label,
          centroid: i.center.centroid
        }
      : { centroid: {} };

    const radiusRegions = i.center.label
      ? i.radiusRegions.map(region => {
          const geoids = region.geoids.map(geo => {
            // If the geoids were loaded from the sites api,
            // they're already in the right format.
            return geo.id
              ? geo
              : {
                  name: geo.label,
                  id: geo.value
                };
          });
          return {
            geoids: geoids,
            radius: region.radius
          };
        })
      : [
          {
            geoids: i.geoids.map(geo => {
              return {
                name: geo.label,
                id: geo.value
              };
            })
          }
        ];

    const formattedArea = {
      level: i.level.label,
      name: i.name,
      radiusRegions: radiusRegions,
      center: center
    };
    formattedArea.distanceUnit = i.distanceUnit;
    return formattedArea;
  });
}
