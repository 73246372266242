import { connect } from 'react-redux';
import { selectSubdomain } from '../../selectors/subdomain';
import { AssessmentBySchoolStatCard } from 'components';

export const mapStateToProps = ({ profile, sites, stats }) => ({
  subdomain: selectSubdomain(sites, profile),
  timeframe: stats
});

export default connect(mapStateToProps)(AssessmentBySchoolStatCard);
