import { get } from '../utils';
import { getCognitoToken } from '../';

/**
 * fetch the institutions
 * @method fetchInstitutions
 * @param  {string}  subdomain
 * @return {function}
 */

export const fetchInstitutions = async subdomain =>
  get(
    `/api/cc-api/institutions/${subdomain}?limit=1000`,
    null,
    new Headers({ Authorization: `Bearer ${await getCognitoToken()}` })
  );
