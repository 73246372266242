import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-media';
import styled from 'styled-components';
import { Message } from 'components';

const StyledMessage = styled(props => <Message {...props} />)`
  text-align: center;
  margin: 2.5rem;
`;

const HideContentOnMobile = ({ breakpoint = '1000px', message, children }) => {
  return (
    <MediaQuery query={`(min-width:${breakpoint})`}>
      {matches => {
        if (matches) {
          return children;
        }
        if (message) {
          return <StyledMessage>{message}</StyledMessage>;
        }
        return null;
      }}
    </MediaQuery>
  );
};

HideContentOnMobile.propTypes = {
  breakpoint: PropTypes.string,
  message: PropTypes.string
};

export default HideContentOnMobile;
