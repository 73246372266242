import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { EmployerInfoDownload } from 'components';

class EmployerInfoDownloadContainer extends React.Component {
  state = {
    shouldDownload: false
  };

  render() {
    const { shouldDownload } = this.state;
    const { employers, postings } = this.props;
    return (
      <EmployerInfoDownload
        shouldDownload={shouldDownload}
        employers={employers}
        postings={postings}
        triggerDownload={() => this.triggerDownload()}
      />
    );
  }

  triggerDownload() {
    this.setState(
      {
        shouldDownload: false
      },
      () => {
        this.setState({
          shouldDownload: true
        });
      }
    );
  }
}

export const mapStateToProps = ({ employers, postings }) => {
  return {
    employers: employers.items,
    postings: postings.items
  };
};

EmployerInfoDownloadContainer.propTypes = {
  employers: PropTypes.object.isRequired,
  postings: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(EmployerInfoDownloadContainer);
