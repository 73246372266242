let COOKIE_CONSENT_CONFIG, previousCookieValue;

export const initCookieConsent = function({
  buttonTextColor,
  buttonBackgroundColor,
  backgroundColor,
  textColor
}) {
  // CookieConsent declaration and initialization
  // Cookie consent config options
  COOKIE_CONSENT_CONFIG = {
    elements: {
      allow:
        '<button aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">{{allow}}</button>',
      deny:
        '<button aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">{{deny}}</button>'
    },
    palette: {
      popup: {
        background: backgroundColor || '#edeff5',
        text: textColor || '#838391'
      },
      button: {
        background: buttonBackgroundColor || '#4b81e8',
        text: buttonTextColor
      }
    },
    type: 'opt-out',
    content: {
      deny: 'Decline',
      link: "Lightcast's Privacy Policy",
      href: 'https://www.lightcast.io/privacy-policy/'
    },
    onPopupOpen: () => {
      previousCookieValue = document.cookie.replace(
        /(?:(?:^|.*;\s*)cookieconsent_status\s*\=\s*([^;]*).*$)|^.*$/,
        '$1'
      );
      // Set the focus to the dismiss button.
      document.getElementsByClassName('cc-allow')[0].focus();
    },
    onStatusChange: status => {
      if (
        (previousCookieValue && previousCookieValue !== status) ||
        (!previousCookieValue && status === 'deny')
      ) {
        window.location.reload(true);
      }
    }
  };
  // Load cookie consent dialog.
  window.cookieconsent.initialise(COOKIE_CONSENT_CONFIG);
};

export function openCookieConsent() {
  let cookieCheck = new window.cookieconsent.Popup(COOKIE_CONSENT_CONFIG);
  cookieCheck.open();
}
