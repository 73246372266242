import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Employers } from 'components';
import { modalShow, modalHide } from '../../reducers/modal';
import {
  getApprovedEmployersWithPostings,
  selectEmployersByStatus
} from '../../selectors/employers';
import { selectPostingsByStatusAndDate, selectEndedPostings } from '../../selectors/postings';
import { selectSubdomain } from '../../selectors/subdomain';
import {
  createPostingRequest,
  fetchEmployersRequest,
  fetchPostingsRequest,
  updateEmployerRequest,
  updatePostingRequest
} from '../../actions/actionCreators';

class EmployersContainer extends React.Component {
  componentDidMount() {
    const { fetchEmployers, fetchPostings, subdomain } = this.props;
    fetchEmployers(subdomain);
    fetchPostings(subdomain);
  }

  render() {
    return <Employers {...this.props} />;
  }
}

EmployersContainer.propTypes = {
  fetchEmployers: PropTypes.func.isRequired,
  fetchPostings: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired
};

export const mapStateToProps = state => {
  let enabledJobTypes = state.sites.items[`${state.sites.currentSite}`].employers.jobTypes;
  enabledJobTypes =
    enabledJobTypes.length > 0
      ? enabledJobTypes.map(jobType => jobType.name)
      : ['Full-Time', 'Part-Time', 'Internship', 'Apprenticeship', 'Work-Study'];
  return {
    approvedEmployers: getApprovedEmployersWithPostings(state, 'approved'),
    created: state.postings.created,
    employers: state.employers.items,
    enabledJobTypes,
    // custom filtering for ended postings. Denied postings won't show up
    endedPostings: selectEndedPostings(state),
    hasLot: state.sites.items[`${state.sites.currentSite}`].hasLot || false,
    livePostings: selectPostingsByStatusAndDate(state, 'approved', 'isSameOrBefore'),
    loaded: state.employers.loaded && state.postings.loaded,
    modals: state.modal,
    nation: state.sites.items[state.sites.currentSite].nation,
    pendingEmployers: selectEmployersByStatus(state, 'pending'),
    pendingPostings: selectPostingsByStatusAndDate(state, 'pending', 'isSameOrBefore'),
    subdomain: selectSubdomain(state.sites, state.profile),
    updated: state.postings.updated
  };
};

export default connect(mapStateToProps, {
  createPosting: createPostingRequest,
  fetchEmployers: fetchEmployersRequest,
  fetchPostings: fetchPostingsRequest,
  modalShow,
  modalHide,
  updateEmployer: updateEmployerRequest,
  updatePosting: updatePostingRequest
})(withRouter(EmployersContainer));
