import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../Select';

class YearSelect extends React.Component {
  static defaultProps = {
    allowFuture: false
  };

  render() {
    const { allowFuture, numOfFutureYears, startYear, ...props } = this.props;
    const date = moment();
    const years = [];
    const endYear = allowFuture ? date.year() + numOfFutureYears : date.year();
    for (let i = startYear; i <= endYear; ++i) {
      years.push({
        value: i,
        label: i
      });
    }
    return <Select options={years} {...props} />;
  }

  static get dateType() {
    return 'year';
  }
}

YearSelect.propTypes = {
  allowFuture: PropTypes.bool,
  numOfFutureYears: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired
};

export default YearSelect;
