import moment from 'moment';
export const sortDates = (a, b, order, field) => {
  if (!a[field] || a[field].includes('---')) {
    return 1;
  } else if (!b[field] || b[field].includes('---')) {
    return -1;
  } else {
    // Profile dates, e.g. createdAt, are in the ISO-8601 format
    // Posting dates, e.g. endOn, are in the MM-DD-YYYY format
    const compareResult = moment(a[field], ['MM-DD-YYYY', moment.ISO_8601]).isBefore(
      moment(b[field], ['MM-DD-YYYY', moment.ISO_8601])
    )
      ? -1
      : 1;
    if (order === 'asc') {
      return compareResult;
    }
    return compareResult * -1;
  }
};

export const sortStrings = (a, b, order, field) => {
  if (!a[field] || a[field].includes('---')) {
    return 1;
  } else if (!b[field] || b[field].includes('---')) {
    return -1;
  } else if (order === 'asc') {
    return a[field].localeCompare(b[field]);
  } else if (order === 'desc') {
    return b[field].localeCompare(a[field]);
  }
};

// determineIfDate, sortByDate, createDateObjects are "subfunctions" of sortDatesAndNotDates
// which is used for the multischool Excel report export
const determineIfDate = date => {
  if (
    date.day &&
    date.month &&
    date.year &&
    !isNaN(date.day) &&
    !isNaN(date.month) &&
    !isNaN(date.year) &&
    date.day.length === 2 &&
    date.month.length === 2 &&
    date.year.length === 4
  ) {
    return true;
  }
  return false;
};

const sortByDate = (aDate, bDate) => {
  if (aDate.year < bDate.year) {
    return -1;
  } else if (aDate.year > bDate.year) {
    return 1;
  } else {
    if (aDate.month < bDate.month) {
      return -1;
    } else if (aDate.month > bDate.month) {
      return 1;
    } else {
      if (aDate.day < bDate.day) {
        return -1;
      } else if (aDate.day > bDate.day) {
        return 1;
      } else {
        return 0;
      }
    }
  }
};

const createDateObjects = ({ a, b, nation }) => {
  const splitDateA = a.split('-');
  const splitDateB = b.split('-');

  // adjusting for nation
  const indexOfYear = {
    us: 2,
    ca: 0,
    uk: 2
  };

  const indexOfMonth = {
    us: 0,
    ca: 1,
    uk: 1
  };

  const indexOfDay = {
    us: 1,
    ca: 2,
    uk: 0
  };
  const aDate = {
    day: splitDateA[indexOfDay[nation]],
    month: splitDateA[indexOfMonth[nation]],
    year: splitDateA[indexOfYear[nation]]
  };
  const bDate = {
    day: splitDateB[indexOfDay[nation]],
    month: splitDateB[indexOfMonth[nation]],
    year: splitDateB[indexOfYear[nation]]
  };
  return { aDate, bDate };
};

export const sortDatesAndNotDates = (a, b, nation) => {
  const { aDate, bDate } = createDateObjects({ a, b, nation });
  const isADate = determineIfDate(aDate, nation);
  const isBDate = determineIfDate(bDate, nation);
  // We'll sort non-dates above dates,
  // because we want the "Total" row in
  // the Visits and Visitors worksheet
  // to be above the date rows.
  if (isADate && !isBDate) {
    return 1;
  } else if (!isADate && isBDate) {
    return -1;
  } else if (!isADate && !isBDate) {
    return a.localeCompare(b, 'en', { sensitivity: 'base' });
  }
  return sortByDate(aDate, bDate);
};
