import { connect } from 'react-redux';
import { SkillsSettings } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

export const mapStateToProps = ({ form, sites }) => ({
  currentSite: sites.currentSite,
  initialValues: sites.items[sites.currentSite].skillsMatchSettings,
  currentValues: form && form.SkillsSettingsForm && form.SkillsSettingsForm.values
});

export const mapDispatchToProps = dispatch => ({
  saveSkillsSettings: ({ currentSite, settings: { hasSkillsAssessment } }) =>
    dispatch(
      patchSiteRequest({
        subdomain: currentSite,
        skillsMatchSettings: {
          hasSkillsAssessment
        }
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillsSettings);
