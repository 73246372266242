import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { TextButton } from 'components';
import { color } from '../../../styles';

import LoadingImage from '../../../images/loading-gray.gif';

const StyledDropzone = styled.div`
  border: 2px dashed ${color.mediumGray};
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${({ isDragAccept, isDragActive, isDragReject }) => {
    if (isDragActive && isDragAccept) {
      return '#ddffdd';
    } else if (isDragActive && isDragReject) {
      return '#ffdddd';
    }
  }};
`;

const PlusIcon = styled.span`
  color: ${color.mediumGray};
  font-size: 6rem;
  font-weight: 300;
`;

const ErrorMessage = styled.span`
  color: ${color.red};
  margin-top: 2rem;
  padding: 0 2rem;
`;

const Loading = styled.img`
  width: 5rem;
  margin-bottom: 1rem;
`;

const LinkButton = styled(TextButton)`
  width: 8.1rem;
`;

const Instructions = styled.span`
  margin: 1rem 0;
`;

const Caveat = styled.span`
  margin: 0 3rem;
  color: ${color.mediumGray};
  text-align: center;
`;

const Upload = props => {
  const { caveat, maxSize = 1000000, mimeType = 'image/png', subdomain, handleUpload } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setUploading] = useState(false);

  const onDrop = async (accepted, rejected) => {
    const [acceptedFile] = accepted;
    const [rejectedFile] = rejected;
    if (acceptedFile) {
      const extension = acceptedFile.name.split('.').pop();
      const uploadFilename = `${subdomain}.${extension}`;
      // Clear out any previous error messages
      setErrorMessage('');
      setUploading(true);
      const uploadError = await handleUpload({
        acceptedFile,
        uploadFilename,
        setUploading
      });

      if (uploadError) {
        setErrorMessage(uploadError.errorMessage);
        setUploading(false);
      }
    } else if (rejectedFile) {
      // Note: Browsers are incapable of detecting a file's size during the drag operation
      // This means that files of the correct type that are too large
      // will show a green background when dragging but will still fail. There isn't a
      // way around this, it's a security feature so browsers can't see your file system.'
      const extensionsByMimeType = {
        'application/pdf': '.pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
        'image/png': '.png'
      };

      if (rejectedFile.size > maxSize) {
        setErrorMessage(
          `Files must be less than ${maxSize / 1000000}MB. Please try again with a smaller file.`
        );
      } else if (rejectedFile.type !== mimeType) {
        setErrorMessage(
          `Incorrect file type. Please try again with a ${extensionsByMimeType[mimeType]}.`
        );
      }
    }
  };

  // Clear any error messages when we drag another file over the dropzone
  const onDragEnter = () => {
    setErrorMessage('');
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
    isDragActive
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: mimeType,
    maxSize,
    onDragEnter
  });

  let rootProps = getRootProps({ isDragAccept, isDragReject, isDragActive });
  // Styled Components < v4.0 requires innerRef.  This should be removed if we update to v4.0+
  rootProps.innerRef = rootProps.ref;
  delete rootProps.ref;

  return (
    <Fragment>
      {isUploading && (
        <StyledDropzone>
          <Loading src={LoadingImage} aria-label="Uploading your file" />
          <p>Uploading...</p>
        </StyledDropzone>
      )}
      {!isUploading && (
        <StyledDropzone {...rootProps}>
          <input aria-label={props.ariaLabel} {...getInputProps()} />
          <PlusIcon>+</PlusIcon>
          <Instructions>
            Drag/drop a file here, or{' '}
            <LinkButton onClick={open} aria-label="Click to upload your file">
              select a file
            </LinkButton>
          </Instructions>
          <Caveat>{caveat}</Caveat>
          {errorMessage && <ErrorMessage aria-live="polite">{errorMessage}</ErrorMessage>}
        </StyledDropzone>
      )}
    </Fragment>
  );
};

Upload.propTypes = {
  ariaLabel: PropTypes.string,
  caveat: PropTypes.string,
  handleUpload: PropTypes.func.isRequired,
  maxSize: PropTypes.number, // in bytes
  mimeType: PropTypes.string,
  subdomain: PropTypes.string.isRequired
};

export default Upload;
