import React from 'react';
import PropTypes from 'prop-types';
/**
 * Returns a custom html element
 * @method Element
 * @param  {string} component html element
 * @param  {node} children  child components
 * @param  {object} rest     more props
 */
const Element = ({ component: Component, children, ...rest }) => {
  return <Component {...rest}>{children}</Component>;
};

Element.defaultProps = {
  component: 'div'
};

Element.propTypes = {
  component: PropTypes.string.isRequired,
  children: PropTypes.node,
  rest: PropTypes.object
};

export default Element;
