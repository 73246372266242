import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormRadio, SliderToggle } from 'components';

const Wrapper = styled.div`
  display: flex;
`;

const WidgetBuilderWageToggle = ({ configurations, setConfigurations }) => {
  const { hasWages, isHourlyWage } = configurations;
  return (
    <Wrapper>
      <SliderToggle
        ariaLabel={`${hasWages ? 'disable' : 'enable'} Wages`}
        handleChange={() => setConfigurations({ ...configurations, hasWages: !hasWages })}
        isChecked={hasWages}
      />
      <FormRadio
        label="Wages"
        disabled={!hasWages}
        options={[
          {
            ariaLabel: 'select hourly wages',
            label: 'Hourly',
            value: true
          },
          {
            ariaLabel: 'select salary wages',
            label: 'Salary',
            value: false
          }
        ]}
        input={{
          onChange: () => setConfigurations({ ...configurations, isHourlyWage: !isHourlyWage }),
          value: isHourlyWage
        }}
        color="black"
      />
    </Wrapper>
  );
};

WidgetBuilderWageToggle.propTypes = {
  configurations: PropTypes.shape({
    isHourlyWage: PropTypes.bool.isRequired,
    hasWages: PropTypes.bool.isRequired
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default WidgetBuilderWageToggle;
