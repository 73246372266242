import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Copy from '../../../images/copy.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
`;

const CopyButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  box-shadow: none !important;
  color: #3e6dcc;
  cursor: pointer;
  margin-bottom: 0.5rem;
  text-decoration: none;

  :hover {
    box-shadow: none !important;
    text-decoration: underline;
  }

  :focus {
    outline: 5px !important;
    outline-color: rgb(77, 144, 254) !important;
    outline-offset: 4px !important;
    outline-style: auto !important;
    box-shadow: none !important;
  }
`;

const CopyIcon = styled(Copy)`
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  margin-right: 0.5rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 18rem;
  border: none;
  margin-bottom: 1rem;
  padding: 2rem;
  word-wrap: break-word;
  resize: none;
`;

const copyToClipboard = id => {
  const copyText = document.getElementById(id);
  navigator.clipboard.writeText(copyText.value);
};

const WidgetBuilderEmbedCode = ({ embedCode, id }) => {
  return (
    <Wrapper>
      <CopyButton onClick={() => copyToClipboard(id)}>
        <CopyIcon />
        Copy to Clipboard
      </CopyButton>
      <TextArea aria-label="widget-embed-code" id={id} value={embedCode} readOnly />
    </Wrapper>
  );
};

WidgetBuilderEmbedCode.propTypes = {
  embedCode: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default WidgetBuilderEmbedCode;
