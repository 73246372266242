let config;

if (process.env.NODE_ENV === 'test') {
  config = require('./config/base.js').default;
} else if (process.env.NODE_ENV === 'development') {
  config = require('./config/dev.js').default;
} else if (process.env.NODE_ENV === 'qa') {
  config = require('./config/qa.js').default;
} else {
  config = require('./config/prod.js').default;
}

export default config;
