import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GeneralSettingsForm } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

class GeneralSettingsFormContainer extends React.Component {
  render() {
    return <GeneralSettingsForm {...this.props} saveGeneralSettings={this.saveGeneralSettings} />;
  }

  /**
   * patch the site with general settings updates
   * @method saveGeneralSettings
   * @param  {object}            settings the new settings getting Updated
   * @return {function}                   function to patch the sites API
   */
  saveGeneralSettings = ({
    id,
    organization,
    requiresAuth,
    selectableProfileSchools = [],
    ...settings
  }) => {
    this.props.patchSite({
      ...settings,
      employerNotificationEmail: settings.employerPortal ? settings.employerNotificationEmail : '',
      requiresAuth: requiresAuth === null ? false : requiresAuth,
      selectableProfileSchools: selectableProfileSchools
        .map(school => school.trim())
        .filter(Boolean)
    });
  };
}

GeneralSettingsFormContainer.propTypes = {
  id: PropTypes.string,
  organization: PropTypes.object,
  patchSite: PropTypes.func,
  requiresAuth: PropTypes.bool
};

export const mapStateToProps = ({ sites, organizations }) => {
  // only pass to the component the props it modifies
  const {
    aboutHeadline,
    aboutText,
    areas,
    authUrl,
    baseMapArea,
    collegeUrl,
    id,
    headlineText,
    introText,
    programSettings,
    styles,
    socialMedia,
    videoUrl,
    ...rest
  } = sites.items[sites.currentSite];

  return {
    organizations,
    currentSite: sites.currentSite,
    initialValues: {
      ...rest,
      organization: {
        value: rest.organization.id,
        label: rest.organization.name
      }
    }
  };
};

export const mapDispatchToProps = dispatch => ({
  patchSite: data => dispatch(patchSiteRequest(data))
});

export { GeneralSettingsFormContainer as PureGeneralSettingsFormContainer }; // used in tests

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsFormContainer);
