import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { Form, Field } from 'react-final-form';

import { isRequired, isPassword } from '../../../helpers/validation';
import { FormInput, Toggle, ExternalLink } from 'components';
import { color } from '../../../styles';

const { sapphireD20 } = color;

const FieldsWrapper = styled.div`
  width: 48%;
  margin-bottom: 1.5rem;

  @media (max-width: 549px) {
    width: 100%;
  }
`;

const Submit = styled.button`
  display: block;
  margin-top: 2rem;
  background: ${sapphireD20};
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  padding: 1.2rem 2rem;
  border-radius: 3px;

  @media (max-width: 549px) {
    margin-top: 3rem;
    width: 100%;
  }

  :hover {
    background: ${darken(0.05, sapphireD20)};
  }
  :disabled {
    cursor: not-allowed;
    color: white;
    background: #ccd0d0;
  }
`;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 549px) {
    flex-direction: column;
  }
`;

const ToggleField = styled.span`
  display: flex;
  flex-direction: column;
`;

const ToggleLabel = styled.span`
  vertical-align: middle;
  margin: 0 1.2rem 0 0.5rem;
  text-transform: capitalize;
`;

const SignUpForm = ({ nation, createAdmin }) => {
  const [hasReadPrivacy, setPrivacy] = useState(false);

  return (
    <div>
      <Form
        onSubmit={values => {
          createAdmin(values);
        }}
        validate={values => {
          const errors = {};
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Passwords must match';
          }

          if (hasReadPrivacy === false) {
            errors.hasReadPrivacyPolicy = 'Required';
          }
          return errors;
        }}
      >
        {({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            {nation === 'us' && (
              <StyledFlexRow>
                <FieldsWrapper>
                  <Field
                    component={FormInput}
                    name="firstName"
                    label="First Name"
                    validate={isRequired}
                    aria-label="First Name"
                    type="text"
                  />
                </FieldsWrapper>
                <FieldsWrapper>
                  <Field
                    component={FormInput}
                    name="lastName"
                    label="Last Name"
                    validate={isRequired}
                    aria-label="Last Name"
                    type="text"
                  />
                </FieldsWrapper>
              </StyledFlexRow>
            )}
            <StyledFlexRow>
              <FieldsWrapper>
                <Field
                  name="password"
                  label="Password"
                  aria-label="Confirm Password"
                  type="password"
                  component={FormInput}
                  validate={(isRequired, isPassword)}
                />
              </FieldsWrapper>
              <FieldsWrapper>
                <Field
                  name="confirmPassword"
                  label="Confirm Password"
                  aria-label="Confirm Password"
                  type="password"
                  component={FormInput}
                  validate={(isRequired, isPassword)}
                />
              </FieldsWrapper>
            </StyledFlexRow>
            <StyledFlexRow>
              <Field
                name="hasReadPrivacyPolicy"
                label="Has Read Privacy Policy"
                type="checkbox"
                validate={isRequired}
              >
                {({ input, meta }) => (
                  <ToggleField>
                    <Toggle
                      type="checkbox"
                      value={input.value}
                      label={
                        <ToggleLabel>
                          I acknowledge that I have read the{' '}
                          <a
                            target="_blank"
                            href="https://www.lightcast.io/privacy-policy/"
                          >
                            Privacy Policy
                          </a>
                        </ToggleLabel>
                      }
                      checked={hasReadPrivacy}
                      onChange={() => {
                        setPrivacy(!hasReadPrivacy);
                        input.onChange(hasReadPrivacy);
                      }}
                      ariaLabel="I acknowledge that I have read the privacy policy"
                    />
                  </ToggleField>
                )}
              </Field>
            </StyledFlexRow>
            <Submit type="submit" data-cy="setup-account-button" disabled={pristine || invalid}>
              Set Up Account
            </Submit>
          </form>
        )}
      </Form>
    </div>
  );
};

SignUpForm.propTypes = {
  createAdmin: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired
};

export default SignUpForm;
