import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { BoldLabel } from 'components';
import Message from '../../atoms/Message';

const DateError = Message.extend.attrs({
  type: 'error'
})`
  width: 52%;
  text-align: right;
  font-size: 1.3rem;
`;

const Container = styled.div`
  @media (min-width: 540px) {
    flex-grow: 1;
  }
  @media (max-width: 539px) {
    width: 100%;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
`;

class DateSelect extends React.Component {
  constructor(props) {
    super(props);

    // N.B. In JavaScript, "day" is "date" :/

    // Controls date types as well as order of output
    this.allowedDateTypes = [
      { name: 'year', format: 'YYYY' },
      { name: 'month', format: 'MM' },
      { name: 'date', format: 'DD' }
    ];

    // Moment ordering
    this.momentOrdering = ['year', 'month', 'date', 'hour', 'minute', 'second', 'millisecond'];

    const allowedNames = this.allowedDateTypes.reduce((allowed, type) => {
      allowed[type.name] = true;
      return allowed;
    }, {});

    // Loop through the children and figure out what date formats there are.

    const children = this.props.children.length ? this.props.children : [this.props.children];

    this.dateTypes = children.reduce((types, child) => {
      const type = child.type.dateType;
      if (allowedNames[type]) {
        types[type] = true;
      }
      return types;
    }, {});

    // Setup the initial date
    this.state = this.createDateState(this.props.initialDate);

    // For Aria
    this.labelId = `${this.props.field}-label`;
  }

  componentDidMount() {
    this.handleDate();
  }

  render() {
    const { children, field, label, presentLabel, error, width = null } = this.props;
    const hasError = !!error;
    const date = this.convertDate(this.state);
    return (
      <Container>
        <BoldLabel label={label} />
        <input type="hidden" name={field} id={field} value={date} />
        <SelectWrapper width={width}>{this.createChildElements(children, hasError)}</SelectWrapper>
        {error && <DateError>{error}</DateError>}
      </Container>
    );
  }

  createChildElements(children, hasError) {
    return React.Children.map(children, child => {
      // N.B. "date" refers to day of month. "day" would refer to day of week. JS thing. >.<
      const days =
        child.type.dateType === 'date'
          ? moment([this.state.year, this.state.month]).daysInMonth()
          : undefined;

      return React.cloneElement(child, {
        onChange: e => this.handleChange(child.type.dateType, e),
        selection: this.state[child.type.dateType],
        ariaLabelledby: this.labelId,
        days: days,
        disabled: this.state.disabled || this.props.disabled,
        error: hasError
      });
    });
  }

  handleKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault();
      this.handleCheckbox(e);
    }
  };

  handleCheckbox = e => {
    this.setState(
      state => ({
        disabled: !state.disabled
      }),
      state => {
        this.handleDate();
      }
    );
  };

  handleDate() {
    const date = this.convertDate(this.state);
    this.props.handleDate && this.props.handleDate(date);
  }

  handleChange(type, e) {
    const year = type === 'year' ? e.target.value : this.state.year;
    const month = type === 'month' ? e.target.value : this.state.month;

    let state = {
      [type]: e.target.value
    };

    // Check if days ("date") is still accurate
    if (
      (type === 'month' || type === 'year') &&
      typeof this.state.date !== 'undefined' &&
      typeof this.state.year !== 'undefined' &&
      typeof this.state.month !== 'undefined' &&
      this.state.date > moment([year, month]).daysInMonth()
    ) {
      state.date = 1;
    }

    this.setState(state, this.handleDate);
  }

  isPresent = date => {
    return !!date && date.toLowerCase() === 'present';
  };

  convertDate(date) {
    if (this.state.disabled) {
      return 'Present';
    }
    // Output (converted date) is in the order of this.allowedDateTypes
    const format = this.allowedDateTypes
      .filter(type => !!this.dateTypes[type.name])
      .map(type => type.format)
      .join('-');

    // The dateArray always needs to be in YYYY, MM, DD, etc, as defined by moment
    const dateArray = this.momentOrdering
      .filter(type => !!this.dateTypes[type])
      .map(type => date[type]);

    const converted = moment(dateArray).format(format);

    return converted;
  }

  // Returning state instead of setting state directly, since this might be used
  //  both inside and outside the constructor
  createDateState(value) {
    let state = {};
    const newDate = moment(value);
    const date = newDate.isValid() ? newDate : moment();

    Object.keys(this.dateTypes).forEach(type => {
      state[type] = date[type]();
    });
    state.disabled = this.isPresent(value);
    return state;
  }
}

DateSelect.defaultProps = {
  disabled: false,
  pristine: false
};

DateSelect.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  initialDate: PropTypes.string,
  handleDate: PropTypes.func,
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  pristine: PropTypes.bool,
  width: PropTypes.string
};

export default DateSelect;
