import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Icon } from 'components';
import { borderRadius } from '../../../styles';

const CloseIcon = styled(Icon)`
  color: #323232;
`;

const CloseButton = styled.button`
  z-index: 1;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: none;
  border: none;
`;

const Wrapper = styled(ReactModal)`
  .ReactModal__Body--open {
    overflow-y: scroll;
  }

  margin-bottom: 4rem;
  border-radius: 0;
  background: #fff;

  @media (min-width: 1042px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10rem;

    /*
      Content will get pushed 50% to the left and we need to figure out how to center the content,
      taking into account both sides of the child component
      the width is coming from the child components wrapper.
      Subtract an extra 3rem for modal card padding.
    */
    left: calc(50% - ${props => `${props.width / 2}rem`} - 3rem);
    right: auto;
    bottom: auto;
    outline: 0;
    border-radius: ${borderRadius};
    width: ${({ width }) => `${width}rem`};
  }
`;

const StyledReactModal = styled(({ className, ...props }) => (
  // Wrapper around ReactModal to position the children of the modal
  <Wrapper overlayClassName={className} closeTimeoutMS={250} {...props} />
))`
  /* overlay class properties */
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  z-index: 9999;
  &[class*='after-open'] {
    opacity: 1;
  }
  &[class*='before-close'] {
    opacity: 0;
  }
`;

// The app element allows you to specify the portion of your app
// that should be hidden (via aria-hidden) to prevent assistive technologies
// such as screenreaders from reading content outside of the content of your modal.
ReactModal.setAppElement('#root');
const Modal = ({
  isOpen,
  close,
  children,
  hasCloseButton = true,
  shouldCloseOnOverlayClick = true,
  ...props
}) => {
  return (
    <div>
      <StyledReactModal
        isOpen={isOpen}
        onRequestClose={close}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        {...props}
      >
        {hasCloseButton && (
          <CloseButton onClick={close} aria-label="close modal">
            <CloseIcon type="close" />
          </CloseButton>
        )}
        {children}
      </StyledReactModal>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.any.isRequired,
  hasCloseButton: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  // Expected width of modal window in rem. Used for its absolute positioning
  width: PropTypes.number
};

Modal.defaultProps = {
  width: 90
};

export default Modal;
