import fetch from 'isomorphic-fetch';
import config from '../../config';

const { NODE_ENV = 'qa' } = process.env;

const sendMandrillEmail = data => {
  return fetch(config.mandrillUrl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  });
};

const getMetaData = (template, subject, email, content, whiteLabel) => ({
  key: config.mandrillKey,
  template_name: template,
  template_content: [],
  message: {
    from_name: `${whiteLabel || 'Career Coach'} Team (No Reply)`,
    subject,
    from_email: 'no-reply@lightcastcc.com',
    to: [
      {
        email: email,
        type: 'to'
      }
    ],
    merge: true,
    merge_language: 'handlebars',
    global_merge_vars: content
  },
  track_opens: true,
  track_clicks: true,
  async: true
});

/**
 * send emails to the profile's email address, after its resume review request has been approved
 */
export function sendApprovedResumes(email, nation, subdomain, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      nation === 'uk' ? 'approved-resumes-uk' : 'approved-resumes',
      `${nation === 'uk' ? 'CV' : 'Résumé'}s Approved!`,
      email,
      [
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendDeniedResumes(adminEmail, email, feedback, nation, subdomain, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      nation === 'uk' ? 'denied-resumes-uk' : 'denied-resumes',
      `${nation === 'uk' ? 'CV' : 'Résumé'} Changes Requested`,
      email,
      [
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'adminEmail',
          content: adminEmail
        },
        {
          name: 'feedback',
          content: feedback
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

/**
 * send emails to employer
 * @payload JSON object to pass variables to the email template, e.g. {name:'John', location:'Moscow', ...}
 */
export function sendApprovedEmployer(email, institution, subdomain, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      'approved-employer',
      "You've Been Approved to Post Jobs!",
      email,
      [
        {
          name: 'institution',
          content: institution
        },
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendDeniedEmployer(email, institution, subdomain, adminEmail, message, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      'denied-employer',
      'Job Posting Access Denied',
      email,
      [
        {
          name: 'institution',
          content: institution
        },
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'adminEmail',
          content: adminEmail
        },
        {
          name: 'message',
          content: message
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendWhitelistedEmployer(email, institution, subdomain, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      'whitelisted-employer',
      "You've Been Whitelisted to Post Jobs!",
      email,
      [
        {
          name: 'institution',
          content: institution
        },
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendDeniedPosting({ employer, title }, adminEmail, subdomain, message, whiteLabel) {
  return sendMandrillEmail(
    getMetaData(
      'denied-posting',
      'Job Posting Denied',
      employer.email,
      [
        {
          name: 'adminEmail',
          content: adminEmail
        },
        {
          name: 'name',
          content: employer.name
        },
        {
          name: 'jobTitle',
          content: title
        },
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'message',
          content: message
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendApprovedPosting({ employer, endOn, title }, adminEmail, subdomain) {
  return sendMandrillEmail(
    getMetaData('approved-posting', 'Job Posting Approved', employer.email, [
      {
        name: 'adminEmail',
        content: adminEmail
      },
      {
        name: 'name',
        content: employer.name
      },
      {
        name: 'jobTitle',
        content: title
      },
      {
        name: 'endOn',
        content: endOn
      },
      {
        name: 'subdomain',
        content: subdomain
      }
    ])
  );
}

export function sendEndedPosting(
  { employer, endOn, title },
  adminEmail,
  subdomain,
  message,
  whiteLabel
) {
  return sendMandrillEmail(
    getMetaData(
      'posting-ended-by-admin',
      'Your Posting Has Been Removed',
      employer.email,
      [
        {
          name: 'adminEmail',
          content: adminEmail
        },
        {
          name: 'name',
          content: employer.name
        },
        {
          name: 'jobTitle',
          content: title
        },
        {
          name: 'endOn',
          content: endOn
        },
        {
          name: 'subdomain',
          content: subdomain
        },
        {
          name: 'message',
          content: message
        },
        {
          name: 'whiteLabel',
          content: whiteLabel
        }
      ],
      whiteLabel
    )
  );
}

export function sendAdminInvitation({ institutionName, token, email }) {
  return sendMandrillEmail(
    getMetaData(
      'admin-invitation',
      'Complete registration for your Career Coach &/or Widget Builder account',
      email,
      [
        {
          name: 'institutionName',
          content: institutionName
        },
        {
          name: 'isQA',
          content: NODE_ENV === 'qa' || NODE_ENV === 'development'
        },
        {
          name: 'token',
          content: token
        }
      ]
    )
  );
}
