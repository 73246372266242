import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Margin, Message, CheckboxGroup } from 'components';

const Text = styled.div`
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-weight: 700;
`;

const FormCheckboxGroup = ({
  options,
  input: { onChange, value },
  meta: { error },
  label,
  helpText
}) => {
  return (
    <Margin value="0 0 3rem 0">
      {label && (
        <Text>
          {label}
        </Text>
      )}
      {helpText}
      <CheckboxGroup
        checkedOptions={value}
        options={options}
        onChange={onChange}
      />
      {error && (
        <Message type="error">Please select at least one option</Message>
      )}
    </Margin>
  );
};

FormCheckboxGroup.defaultProps = {
  label: '',
  helpText: null,
  meta: {
    error: ''
  }
};

FormCheckboxGroup.propTypes = {
  helpText: PropTypes.any,
  // redux form props
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
  }).isRequired,
  label: PropTypes.string,
  // redux form props
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  options: PropTypes.array.isRequired
};

export default FormCheckboxGroup;
