import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { Button, Line, Message, SelectInput, Strong } from 'components';
import TrashIcon from '../../../images/trash-icon.svg';
import is from 'is_js';
import theme from '../../../styles/theme';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  height: 3.9rem;
  width: 3.9rem;
  position: absolute;
  right: 0;
  top: 2.5rem;
  padding: 0 1.2rem;
  font-size: 3rem;
  line-height: 4.1rem;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-top: 2.9px;
  margin-right: 1.3rem;
  fill: ${theme.linkColor};
`;

const InputWrapper = styled.div`
  /* modify the wrapper for the SelectInput to adjust the margin bottom */
  > div {
    margin-bottom: 0;
  }
`;

class EmployerWhitelist extends React.Component {
  static propTypes = {
    employers: PropTypes.array.isRequired,
    // redux form prop
    fields: PropTypes.object.isRequired
  };

  state = {
    error: '',
    suggestedEmployers: [],
    value: ''
  };

  render() {
    const { employers, fields } = this.props;
    const { error, suggestedEmployers, value } = this.state;
    const filteredEmployers = employers.filter(
      ({ status }) => !['denied', 'starter'].includes(status)
    );

    const addEmployer = (e, email) => {
      e.preventDefault();
      if (is.email(email)) {
        const whitelist = fields.getAll().map(item => item.toLowerCase());
        // if the entered email doesn't already exist in the whitelist, insert at top
        if (!whitelist.includes(email.toLowerCase())) {
          fields.insert(0, email);
        }
        this.setState({
          value: '',
          error: ''
        });
      } else {
        this.setState({ error: 'Invalid Email Address' });
      }
    };

    return (
      <AutosuggestStylings>
        <Autosuggest
          id="select-employer"
          inputProps={{
            id: 'select employer',
            label: 'Search by Employer Name or Email',
            name: 'select employer',
            value,
            onChange: (e, { newValue }) =>
              // this condition is necessary, otherwise using arrow key to navigate autosuggest will break
              newValue !== undefined && this.setState({ value: newValue }),
            // to please SelectInput
            meta: {}
          }}
          focusInputOnSuggestionClick={false}
          onSuggestionSelected={(e, { suggestion }) => addEmployer(e, suggestion.email)}
          getSuggestionValue={({ email }) => email}
          onSuggestionsClearRequested={() => this.setState({ suggestedEmployers: [] })}
          onSuggestionsFetchRequested={search => this.filterResults(search, filteredEmployers)}
          renderSuggestion={this.showResults}
          renderInputComponent={inputProps => (
            <InputWrapper>
              <SelectInput {...inputProps} isSearch />
            </InputWrapper>
          )}
          suggestions={suggestedEmployers}
        />
        <StyledButton
          aria-label="Add employer email to whitelist"
          onClick={e => addEmployer(e, value)}
        >
          +
        </StyledButton>
        <Message type="error">{error}</Message>

        {fields.getAll().map((item, index) => (
          <div key={item}>
            <Flex>
              {item}
              <StyledTrashIcon
                aria-label={`Remove ${item} from whitelist`}
                tabIndex="0"
                onKeyDown={e => e.keyCode === 13 && fields.remove(index)}
                onClick={() => fields.remove(index)}
              />
            </Flex>
            <Line />
          </div>
        ))}
      </AutosuggestStylings>
    );
  }

  showResults = ({ email, name }) => {
    return (
      <Flex>
        <Strong>{name}</Strong>
        {email}
      </Flex>
    );
  };

  filterResults = ({ value }, employers) => {
    if (value.length > 2) {
      this.setState({
        suggestedEmployers: employers.filter(
          item =>
            item.email.toLowerCase().includes(value.toLowerCase()) ||
            item.name.toLowerCase().includes(value.toLowerCase())
        )
      });
    }
  };
}

// put this styled component down here because it's super long and handles the child autosuggest styles
const AutosuggestStylings = styled.div`
  margin-top: -2rem;
  margin-bottom: 3rem;
  position: relative;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    background-color: #fff;
    max-height: 20rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 2;
    overflow-y: scroll;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.5rem 2rem;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #efefef;
  }
`;

export default EmployerWhitelist;
