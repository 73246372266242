import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Header, Table, Truncate } from 'components';
import { ResumeDownloadButton, Modal } from 'containers';
import { withNationHOC } from '../../../hocs/withNation';
import { locale } from '../../../helpers/locale';

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;

  @media (min-width: 1042px) {
    border-radius: 3px;
    width: 90rem;
  }
`;

const Title = styled(Header)`
  margin-bottom: 5rem !important;
`;

const ResumeDownloadModal = withNationHOC(({ userName, modalName, resumes, nation }) => {
  return (
    <Modal name={modalName}>
      <Wrapper>
        <Title type="headline" component="h2">
          {userName}
        </Title>
        <Table
          data={resumes}
          columns={[
            {
              dataField: 'title',
              dataSort: false,
              text: 'Title',
              dataFormat: cell => <Truncate>{cell}</Truncate>
            },
            {
              isKey: true,
              dataField: 'resumeId',
              dataAlign: 'right',
              dataSort: false,
              text: locale.resume[nation],
              dataFormat: (cell, row) => (
                <ResumeDownloadButton id={cell} type={row.title} name={userName} />
              )
            }
          ]}
        />
      </Wrapper>
    </Modal>
  );
});

ResumeDownloadModal.propTypes = {
  userName: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  resumes: PropTypes.array.isRequired
};

export default ResumeDownloadModal;
