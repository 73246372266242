import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Header, HideContentOnMobile, StatItems } from 'components';
import { combineAssessmentBySchoolData } from '../../../helpers/process-keen-result';
import {
  keenAssessmentStartsBySchool,
  keenAssessmentCompletionsBySchool
} from '../../../services/keen/queries';

import { fetchKeenData } from '../../../services';

const StyledCard = styled(Card)`
  min-height: 33rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: 0 !important;
`;

const StyledHeader = styled(Header)`
  flex-grow: 1;
  margin-bottom: 1rem !important;
  text-align: center;
  text-align: left;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
`;

const ColumnHeaders = styled.div`
  display: flex;
  flex-direction: row;
`;

const SchoolHeader = styled.div`
  width: 100%;
  font-weight: 600;
`;

const ResultHeader = styled.div`
  margin-left: 3rem;
  width: 12rem;
  display: inline-block;
  text-align: right;
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssessmentBySchoolStatCard = ({ subdomain, timeframe }) => {
  const [bySchoolData, setBySchoolData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const startsBySchool = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentStartsBySchool
      });

      const completionsBySchool = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentCompletionsBySchool
      });

      const data = combineAssessmentBySchoolData(startsBySchool, completionsBySchool);
      setBySchoolData(data);
    };

    fetchData();
  }, [subdomain, timeframe]);

  const props = { ...keenAssessmentStartsBySchool, ...keenAssessmentCompletionsBySchool };
  return (
    <StyledCard>
      <Content>
        <StyledHeader  component="h2" type="subtitle" bold>
          Assessments By Institution
        </StyledHeader>
        <HideContentOnMobile message="Please view Assessments By Institution on desktop!">
          {!!bySchoolData.length && (
            <Fragment>
              <ColumnHeaders>
                <SchoolHeader>School</SchoolHeader>
                <ResultHeader>Started</ResultHeader>
                <ResultHeader>Completed</ResultHeader>
              </ColumnHeaders>
              <StatItems data={bySchoolData} subdomain={subdomain} {...props} />
            </Fragment>
          )}
          {!bySchoolData.length && <div>No Data Available</div>}
        </HideContentOnMobile>
      </Content>
    </StyledCard>
  );
};

AssessmentBySchoolStatCard.propTypes = {
  subdomain: PropTypes.string,
  timeframe: PropTypes.object.isRequired
};

export default AssessmentBySchoolStatCard;
