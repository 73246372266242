import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Toggle } from 'components';

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-weight: 600;
`;

const ToggleWrapper = styled.div`
  display: inline-block;
  margin-right: 1.2rem;
  font-weight: 400;
`;

const JobTypeRadioGroup = ({ enabledJobTypes, input: { onChange, value } }) => (
  <Label>
    Job Type
    <div>
      {enabledJobTypes.map(jobType => {
        return (
          <ToggleWrapper key={jobType}>
            <Toggle
              type="radio"
              value={jobType}
              label={jobType}
              onChange={() => onChange(jobType)}
              checked={value === jobType}
            />
          </ToggleWrapper>
        );
      })}
      ;
    </div>
  </Label>
);

JobTypeRadioGroup.propTypes = {
  enabledJobTypes: PropTypes.array.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default JobTypeRadioGroup;
