import { flashSuccess, flashError } from '../../helpers/flashmessage';
import { get, patch, formatRequest } from '../utils';
import { getCognitoToken } from '../';

/**
 * Attempt to fetch list of employers.
 * @method fetchEmployers
 * @param  {subdomain}   the subdomain we want employers for
 * @return {function}    return an array of employers
 */
export const fetchEmployers = async subdomain => {
  const token = await getCognitoToken();
  return get(`/api/cc-api/employers/${subdomain}`, null, {
    Authorization: `Bearer ${token}`
  }).catch(() => {
    flashError(`Sorry, we are unable to load ${subdomain}'s employers at this point.`);
  });
};

export const updateEmployer = async (subdomain, id, data) => {
  const token = await getCognitoToken();
  return patch(`/api/cc-api/employers/${subdomain}/${id}`, formatRequest(data, 'employer'), {
    Authorization: `Bearer ${token}`
  })
    .then(res => {
      flashSuccess('Employer Status Updated!');
      return res;
    })
    .catch(() => {
      flashError('Sorry, we are unable to update an employer at this point.');
    });
};
