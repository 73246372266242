import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { color } from '../../../styles';
import {
  keenAssessmentWidgetCompletions,
  keenAssessmentWidgetStarts
} from '../../../services/keen/queries';
import { fetchKeenData } from '../../../services';
import { PercentageChart } from 'components';

const { darkBlue, goldYellow, darkGreen } = color;

const AssessmentStartsCompletionsGraph = ({ className, nation, subdomain, timeframe }) => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(false);
      const startsData = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentWidgetStarts
      });

      const completionsData = await fetchKeenData({
        subdomain,
        timeframe,
        ...keenAssessmentWidgetCompletions
      });

      const newData = [
        {
          label: 'Started',
          color: darkGreen,
          results: startsData
        },
        {
          label: 'Completed',
          color: darkBlue,
          results: completionsData
        }
      ];
      setData(newData);
      setIsLoaded(true);
    };

    getData();
  }, [timeframe, subdomain]);

  return (
    <PercentageChart
      a11yHeader="Assessment Widget Starts and Completions"
      className={className}
      data={data}
      chartId="assessment-widget-completions"
      color={goldYellow}
      nation={nation}
      loaded={isLoaded}
      percentageLabel="Completed"
    />
  );
};

AssessmentStartsCompletionsGraph.propTypes = {
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired
};

export default AssessmentStartsCompletionsGraph;
