import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FetchKeen, StatTabCard, WorkOpportunityViewsChart } from 'containers';
import { keenWorkOpportunityViewsByPosting } from '../../../services/keen/queries';

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const WorkOpportunities = ({
  employerAnalytics,
  postingAnalytics,
  isPostingsDataLoaded,
  subdomain,
  timeframe
}) => {
  const getActivePostings = keenPostings => {
    let filteredPostings = [];
    keenPostings.forEach(posting => {
      const [month, day, year] = posting['posting.createdOn'].split('-');
      const createdOn = [year, month, day].join('');
      const startDate = timeframe.start.replaceAll('-', '');

      const [endMonth, endDay, endYear] = posting['posting.endOn'].split('-');
      const endedOn = [endYear, endMonth, endDay].join('');
      const endDate = timeframe.end.replaceAll('-', '');

      const isActiveInTimeRange = createdOn < endDate && endedOn > startDate;

      if (isActiveInTimeRange) {
        filteredPostings.push(posting);
      }
    });
    return filteredPostings;
  };

  return (
    <Fragment>
      <WorkOpportunityViewsChart />
      <CardRow>
        <StatTabCard
          subdomain={subdomain}
          isLoaded={isPostingsDataLoaded}
          title="Top Employers"
          firstTab={{
            buttonLabel: 'APPLICANTS',
            data: employerAnalytics && employerAnalytics.byApplicants,
            resultKey: 'employerName'
          }}
          secondTab={{
            buttonLabel: 'POSTINGS',
            data: employerAnalytics && employerAnalytics.byPostings,
            resultKey: 'employerName'
          }}
        />
        <FetchKeen {...keenWorkOpportunityViewsByPosting}>
          {props => (
            <StatTabCard
              subdomain={subdomain}
              isLoaded={props.loaded && isPostingsDataLoaded}
              title="Top Postings"
              firstTab={{
                buttonLabel: 'VIEWS',
                data: getActivePostings(props.data),
                resultKey: props.resultKey
              }}
              secondTab={{
                buttonLabel: 'APPLICANTS',
                data: postingAnalytics,
                resultKey: 'name'
              }}
            />
          )}
        </FetchKeen>
      </CardRow>
    </Fragment>
  );
};

WorkOpportunities.propTypes = {
  isPostingsDataLoaded: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired,
  employerAnalytics: PropTypes.shape({
    byApplicants: PropTypes.arrayOf(
      PropTypes.shape({
        employerName: PropTypes.string.isRequired,
        result: PropTypes.number.isRequired
      })
    ),
    byPostings: PropTypes.arrayOf(
      PropTypes.shape({
        employerName: PropTypes.string.isRequired,
        result: PropTypes.number.isRequired
      })
    )
  }),
  postingAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      result: PropTypes.number.isRequired
    })
  )
};

export default WorkOpportunities;
