import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import sites from './sites';
import profile from './profile';
import stats from './stats';
import employers from './employers';
import postings from './postings';
import modal from './modal';
const rootReducer = combineReducers({
  sites,
  profile,
  stats,
  employers,
  postings,
  form: formReducer,
  toastr: toastrReducer,
  modal
});

export default rootReducer;
