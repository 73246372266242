import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Badge,
  Button,
  ConfirmationModal,
  Icon,
  PostingModal,
  PostingsTable,
  Strong,
  TextButton,
  ApplicantsModal
} from 'components';
import { sortDates } from '../../../helpers/sort';
import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';

const Table = styled(PostingsTable)`
  /* last (edit and end icons) columns heading and data */
  th:last-child,
  td:last-child,
  th:nth-last-child(2),
  td:nth-last-child(2) {
    text-align: center !important;
  }
  td:last-child {
    padding-left: 3px;
  }
`;

const LivePostings = ({
  data,
  employers,
  enabledJobTypes,
  hasLot,
  modalHide,
  modals,
  modalShow,
  nation,
  subdomain,
  theme,
  updatePosting,
  updated
}) => {
  return (
    <Table
      data={data}
      employers={employers}
      enabledJobTypes={enabledJobTypes}
      postingType="live"
      modals={modals}
      modalShow={modalShow}
      options={{
        defaultSortName: 'publishedOn',
        defaultSortOrder: 'desc'
      }}
      tableColumns={[
        {
          dataField: 'applicants',
          text: 'Applicants',
          dataFormat: (cell, row) =>
            cell.length > 0 && nation === 'us' ? (
              <Fragment>
                <TextButton onClick={() => modalShow(`applicant${row.postingId}`)}>
                  {cell.length}
                </TextButton>
                {modals[`applicant${row.postingId}`] && (
                  <ApplicantsModal
                    applicants={cell}
                    selectedApplicant={row.selectedApplicant}
                    name={`applicant${row.postingId}`}
                  />
                )}
              </Fragment>
            ) : (
              cell.length
            )
        },
        {
          dataField: 'publishedOn',
          text: 'Posted Date',
          sortFunc: sortDates,
          dataFormat: cell =>
            cell ? moment(cell, 'MM-DD-YYYY').format(locale.date[nation]) : '---'
        },
        {
          dataField: 'endOn',
          text: 'End Date',
          sortFunc: sortDates,
          dataFormat: cell =>
            cell ? moment(cell, 'MM-DD-YYYY').format(locale.date[nation]) : '---'
        },
        {
          dataField: 'jobType',
          text: 'Type',
          dataFormat: cell => <Badge type={cell}>{cell}</Badge>
        },
        {
          dataField: 'editPosting',
          text: 'Edit',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="pencil"
                tabIndex="0"
                size="2"
                role="button"
                aria-label={`Edit Posting: ${row.title}`}
                color={theme.linkColor}
                onKeyDown={e => e.keyCode === 13 && modalShow(`editPosting${row.postingId}`)}
                onClick={() => modalShow(`editPosting${row.postingId}`)}
              />
              {modals[`editPosting${row.postingId}`] && (
                <PostingModal
                  employers={Object.values(employers)}
                  enabledJobTypes={enabledJobTypes}
                  initialValues={{
                    ...row,
                    endOn: moment(row.endOn, 'MM-DD-YYYY').format('YYYY-MM-DD')
                  }}
                  hasLot={hasLot}
                  isCompleted={updated}
                  modalHide={modalHide}
                  modalShow={modalShow}
                  name={`editPosting${row.postingId}`}
                  postingId={row.postingId}
                  subdomain={subdomain}
                  submitText="Update"
                  title="Edit Posting"
                  updatePosting={updatePosting}
                  nation={nation}
                />
              )}
            </Fragment>
          )
        },
        {
          dataField: 'endPosting',
          text: 'End',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="close"
                tabIndex="0"
                size="2"
                role="button"
                aria-label={`End Posting: ${row.title}`}
                color={color.red}
                onKeyDown={e => e.keyCode === 13 && modalShow(`end${row.postingId}`)}
                onClick={() => modalShow(`end${row.postingId}`)}
              />
              {modals[`end${row.postingId}`] && (
                <ConfirmationModal
                  name={`end${row.postingId}`}
                  heading="End Posting?"
                  modalHide={modalHide}
                  deny
                  text={
                    <Fragment>
                      This posting, <Strong>{row.title}</Strong>, will no longer be visible on
                      Career Coach, and <Strong>{employers[row.employer.id].name}</Strong> will be
                      notified automatically.
                    </Fragment>
                  }
                  confirmationButton={
                    <Button
                      background={color.red}
                      color={color.white}
                      onClick={message => {
                        const employer = employers[row.employer.id];
                        updatePosting({
                          postingId: row.postingId,
                          employer: {
                            id: employer.id,
                            name: employer.name,
                            email: employer.email,
                            location: employer.location
                          },
                          endOn: moment()
                            .subtract(1, 'days')
                            .format('MM-DD-YYYY'),
                          subdomain: row.subdomain,
                          title: row.title,
                          message
                        });
                        modalHide(`end${row.postingId}`);
                      }}
                    >
                      End
                    </Button>
                  }
                />
              )}
            </Fragment>
          )
        }
      ]}
    />
  );
};

LivePostings.propTypes = {
  data: PropTypes.object.isRequired,
  employers: PropTypes.object.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  hasLot: PropTypes.bool,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    linkColor: PropTypes.string
  }),
  updated: PropTypes.bool,
  updatePosting: PropTypes.func.isRequired
};

export default withTheme(LivePostings);
