import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Hint, Strong } from 'components';
import styled from 'styled-components';

const Section = styled.p`
  margin-bottom: 1rem;
`;

const WidgetViewsChart = props => {
  return (
    <Chart
      {...props}
      subheading="Total Views"
      a11yCaption="Chart of number of views by month."
      a11ySummary="Total Widget Views"
      a11yHeader="Dates"
      tooltip={
        <Hint id="widget_views_chart">
          <Section>
            <Strong>Total Views</Strong>: this is the total number of widget loads that can be
            interpreted as widget views
          </Section>
        </Hint>
      }
    />
  );
};

WidgetViewsChart.propTypes = {
  data: PropTypes.object,
  heading: PropTypes.string,
  loaded: PropTypes.bool,
  subheading: PropTypes.string
};

export default WidgetViewsChart;
