import React from 'react';
import PropTypes from 'prop-types';
import { fetchAssessmentDownload } from '../../../services';
import { DownloadButton } from 'components';

const downloadPdf = async response => {
  const blob = await response.blob();
  const text = await blob.text();
  const href = `data:application/pdf;base64,${text}`;
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = href;
  anchor.download = 'assessment_results.pdf';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  return true;
};

const AssessmentDownloadButton = ({ assessment, id, name, nation, subdomain }) => {
  const userName = name === '--- ---' ? '' : name;
  const handleClick = async () => {
    const { results, questionSet } = assessment;
    const downloadResponse = await fetchAssessmentDownload({
      clientId: subdomain,
      nation,
      results,
      questionSet,
      userName: name
    });
    downloadPdf(downloadResponse);
  };

  return (
    <DownloadButton download={() => handleClick()} id={id} name={userName} type="assessment" />
  );
};

AssessmentDownloadButton.propTypes = {
  assessment: PropTypes.shape({
    questionSet: PropTypes.string,
    results: PropTypes.array
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  nation: PropTypes.string,
  subdomain: PropTypes.string.isRequired
};

export default AssessmentDownloadButton;
