import moment from 'moment';

const createEmptyArrays = employers => {
  for (const key in employers) {
    employers[key].livePostings = [];
    employers[key].endedPostings = [];
    employers[key].hires = [];
  }
  return employers;
};
const isEnded = endOn => moment().isAfter(moment(endOn, 'MM-DD-YYYY'), 'day');

const postingHasHire = ({ name }) =>
  name && name.toLowerCase() !== 'none' && name.toLowerCase() !== 'other';

export const addPostingsToApprovedEmployers = (postings, employers) => {
  // create fresh arrays so postings aren't duplicated on subsequent renderings
  const employersWithPostings = createEmptyArrays(employers);

  // Loops through postings (not employers), adding each posting to its respective employer.
  for (const key in postings) {
    const posting = postings[key];
    const {
      employer: { id },
      endOn,
      selectedApplicant,
      title,
      jobType
    } = posting;
    let employer = employersWithPostings[id];

    if (employer) {
      if (posting.status === 'approved' && !isEnded(endOn)) {
        employer.livePostings.push(posting);
      } else if (posting.status !== 'denied' && isEnded(endOn)) {
        employer.endedPostings.push(posting);
        if (selectedApplicant && postingHasHire(selectedApplicant)) {
          employer.hires.push({ hireName: selectedApplicant.name, title, jobType });
        }
      }
    }
  }
  return employersWithPostings;
};
