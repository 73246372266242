export const color = {
  // black
  black: '#323232',
  trueBlack: '#000000',

  // blue
  lighterBlue: '#E0EBFF',
  lightBlue: '#4A90E2',
  blue: '#3D6DCC',
  darkBlue: '#2E5299',

  // gray
  lightGray: '#F2F2F2',
  gray: '#F0F1F3',
  mediumGray: '#bfbfbf',
  mediumDarkGray: '#D9D9D9',
  darkGray: '#6F6F70',
  darkerGray: '#585858',

  // green
  lightGreen: '#EDFDF3',
  green: '#41D592',
  darkGreen: '#307848',
  turquoise: '#0E8B93',

  // yellow
  lightYellow: '#FCF4E8',
  yellow: '#dddb4c',
  goldYellow: '#977D19',

  // misc
  background: '#F7FAFA',
  orange: '#f14802',
  purple: '#793A99',
  red: '#DA324D',
  white: '#FFFFFF',

  // lightcast
  overcastD40: '#676767',
  sapphire: '#4D88FF',
  sapphireD20: '#3E6DCC',
  sapphireD40: '#2E5299'
};

export const borderRadius = '3px';

export const boxShadow = 'inset 0 0 0 20rem rgba(0, 0, 0, 0.1)';
