import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WorkOpportunities from '../../components/pages/Statistics/WorkOpportunities';
import { fetchPostingAnalyticsRequest } from '../../actions/actionCreators';

const WorkOpportunitiesContainer = ({
  employerAnalytics,
  postingAnalytics,
  fetchPostingAnalytics,
  isPostingsDataLoaded,
  subdomain,
  timeframe
}) => {
  useEffect(() => {
    fetchPostingAnalytics({ subdomain, timeframe });
  }, [timeframe]);

  return (
    <WorkOpportunities
      isPostingsDataLoaded={isPostingsDataLoaded}
      employerAnalytics={employerAnalytics}
      postingAnalytics={postingAnalytics}
      subdomain={subdomain}
      timeframe={timeframe}
    />
  );
};

WorkOpportunitiesContainer.propTypes = {
  employerAnalytics: PropTypes.shape({
    byApplicants: PropTypes.arrayOf(
      PropTypes.shape({
        employerName: PropTypes.string.isRequired,
        result: PropTypes.number.isRequired
      })
    ),
    byPostings: PropTypes.arrayOf(
      PropTypes.shape({
        employerName: PropTypes.string.isRequired,
        result: PropTypes.number.isRequired
      })
    )
  }),
  postingAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      result: PropTypes.number.isRequired
    })
  ),
  fetchPostingAnalytics: PropTypes.func.isRequired,
  isPostingsDataLoaded: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired
};

export const mapStateToProps = ({ postings, sites, stats }) => ({
  employerAnalytics: postings.analytics && postings.analytics.employers,
  postingAnalytics: postings.analytics && postings.analytics.topPostingsByApplicants,
  isPostingsDataLoaded: postings.loaded,
  subdomain: sites.currentSite,
  timeframe: stats
});

export default connect(mapStateToProps, {
  fetchPostingAnalytics: fetchPostingAnalyticsRequest
})(WorkOpportunitiesContainer);
