import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ExcelDownloadButton, ExcelWidgetStatisticsWorkbook } from 'components';

const WidgetStatisticsDownload = ({
  careerData,
  data,
  getData,
  hasAssessmentWidget,
  hasLot,
  hasProgramWidget,
  isLoaded,
  isLoading,
  nation,
  programData,
  subdomain,
  timeframe
}) => {
  return (
    <Fragment>
      <ExcelDownloadButton
        isLoading={isLoading}
        handleClick={() => {
          getData({ hasProgramWidget, hasLot, nation, subdomain, timeframe });
        }}
      >
        Download Report
      </ExcelDownloadButton>

      {isLoaded && (
        <ExcelWidgetStatisticsWorkbook
          careerData={careerData}
          data={data}
          hasAssessmentWidget={hasAssessmentWidget}
          hasProgramWidget={hasProgramWidget}
          timeframe={timeframe}
          programData={programData}
        />
      )}
    </Fragment>
  );
};

WidgetStatisticsDownload.propTypes = {
  careerData: PropTypes.object,
  data: PropTypes.object,
  getData: PropTypes.func.isRequired,
  hasAssessmentWidget: PropTypes.bool,
  hasLot: PropTypes.bool,
  hasProgramWidget: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  programData: PropTypes.object,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

WidgetStatisticsDownload.defaultProps = {
  isLoaded: false,
  isLoading: false,
  data: []
};

export default WidgetStatisticsDownload;
