import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { BoldLabel, WidgetBuilderToggle } from 'components';

const Inputs = styled.div`
  padding: 1.5rem;
`;

const Select = styled(ReactSelect)`
  margin-bottom: 2rem;
`;

const WidgetBuilderGeography = ({
  configurations,
  hasHasAllLocations = true,
  hasHasNationalData = true,
  label = 'Geography',
  subLabel = 'Select Default Location',
  setConfigurations,
  siteSettings
}) => {
  const { areas } = siteSettings;
  const { hasAllLocations, hasNationalData, locationName } = configurations;

  const handleAreaChange = areaName => {
    const selectedArea = areas.filter(area => area.name === areaName)[0];
    setConfigurations({
      ...configurations,
      locationName: selectedArea.name
    });
  };

  return (
    <Fragment>
      <BoldLabel label={label} component="legend" />
      <Inputs>
        <label htmlFor="default-location">{subLabel}</label>
        <Select
          id="default-location"
          value={locationName}
          clearable={false}
          onChange={option => handleAreaChange(option.value)}
          options={areas.map(({ name }) => ({ value: name, label: name }))}
        />
        {hasHasAllLocations && (
          <WidgetBuilderToggle
            id="has-all-locations"
            label="Include All Locations"
            handleChange={() =>
              setConfigurations({ ...configurations, hasAllLocations: !hasAllLocations })
            }
            isChecked={hasAllLocations}
          />
        )}
        {hasHasNationalData && (
          <WidgetBuilderToggle
            id="has-national-data"
            label="Show National Data"
            handleChange={() =>
              setConfigurations({ ...configurations, hasNationalData: !hasNationalData })
            }
            isChecked={hasNationalData}
          />
        )}
      </Inputs>
    </Fragment>
  );
};

WidgetBuilderGeography.propTypes = {
  configurations: PropTypes.shape({
    hasAllLocations: PropTypes.bool,
    hasEmployment: PropTypes.bool,
    locationName: PropTypes.string.isRequired
  }).isRequired,
  hasHasAllLocations: PropTypes.bool,
  hasHasNationalData: PropTypes.bool,
  label: PropTypes.string,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    areas: PropTypes.array.isRequired,
    nation: PropTypes.string.isRequired
  }).isRequired,
  subLabel: PropTypes.string
};

export default WidgetBuilderGeography;
