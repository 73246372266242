import ProgramWidgetBuilder from '../../components/pages/ProgramWidgetBuilder';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;
  const {
    areas,
    enabled,
    hasManyInstitutions,
    nation,
    name,
    relatedCareersSort,
    styles,
    subdomainAliases,
    widgetBuilderSettings
  } = items[currentSite];
  const { accentColor } = styles;
  const {
    currentlyEmployedLabel,
    jobOpeningsLabel,
    medianSalaryLabel,
    projectedGrowthLabel,
    visitCareerCoachLabel
  } = widgetBuilderSettings;

  const customLabels = [
    currentlyEmployedLabel,
    jobOpeningsLabel,
    medianSalaryLabel,
    projectedGrowthLabel,
    visitCareerCoachLabel
  ];

  const hasCustomLabels = customLabels.filter(label => label !== '').length > 0;

  let orderCareersBy = 'None';
  if (relatedCareersSort === 'Alphabetical' || relatedCareersSort === 'Salary') {
    orderCareersBy = relatedCareersSort;
  }

  return {
    siteSettings: {
      accentColor,
      areas,
      currentSite,
      hasCcEnabled: enabled,
      hasCustomLabels,
      hasManyInstitutions,
      nation: nation,
      orderCareersBy,
      schoolName: name,
      subdomainAliases
    }
  };
};

export default connect(mapStateToProps)(ProgramWidgetBuilder);
