import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components';
import { color, borderRadius } from '../../../styles';

const Wrapper = styled.div`
  position: relative;

  > input {
    padding: 1rem 2.7rem;
  }
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 4px;
  top: 11px;
  z-index: 1;
  pointer-events: none;
  ${({ disabled }) =>
    // prettier-ignore
    disabled ? `
    color: ${color.darkGray};
    opacity: 0.5;
  ` : `
    color: ${color.darkGray};
  `};
`;

const Input = styled(({ component: Component, children, isSearch = false, ...rest }) => {
  if (isSearch) {
    return (
      <Wrapper>
        <SearchIcon size="2" type="search" hasHover={false} disabled={rest.disabled} />
        <Component {...rest} />
      </Wrapper>
    );
  }
  return <Component {...rest} />;
}).withConfig({ displayName: 'Input' })`
  position: relative;
  width: 100%;
  border: 1px solid ${props => (props.error ? color.red : color.mediumGray)};
  box-sizing: border-box;
  border-radius: ${borderRadius};
  display: block;
  font-size: 1.5rem;
  outline: 0;
  padding: 1rem;

  &::placeholder {
    color: ${color.darkGray};
  }

  &:disabled {
    border-color: ${color.mediumGray};
    color: ${color.mediumGray};
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

Input.defaultProps = {
  component: 'input'
};

Input.propTypes = {
  component: PropTypes.string,
  isSearch: PropTypes.bool
};

export default Input;
