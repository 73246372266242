import styled from 'styled-components';

const Truncate = styled.span`
  max-width: calc(100% - 3rem);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
`;

export default Truncate;
