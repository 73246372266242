import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HomeSettingsForm } from 'components';
import {
  patchSiteRequest
} from '../../actions/actionCreators';

class HomeSettingsFormContainer extends React.Component {
  render() {
    return <HomeSettingsForm {...this.props} handleFormSubmit={this.handleFormSubmit} />;
  }
  /**
   * updates the site with the new home page settings
   * @method handleFormSubmit
   * @param  {object}         settings the settings passed in
   * @return {function}                returns a call to send off an action
   */
  handleFormSubmit = settings => this.props.patchSite({ ...settings });
}
HomeSettingsFormContainer.propTypes = {
  patchSite: PropTypes.func.isRequired
};

export const mapStateToProps = ({ sites }) => {
  const {
    headlineText,
    introText,
    videoUrl,
    militarySearch,
    hasEoeEnabled,
    aboutHeadline,
    aboutText,
    socialMedia,
    collegeUrl
  } = sites.items[sites.currentSite];
  return {
    initialValues: {
      subdomain: sites.currentSite,
      headlineText,
      introText,
      videoUrl,
      militarySearch,
      hasEoeEnabled,
      aboutHeadline,
      aboutText,
      socialMedia,
      collegeUrl
    }
  };
};

export const mapDispatchToProps = (dispatch) => ({
  patchSite: data => dispatch(patchSiteRequest(data))
});

export { HomeSettingsFormContainer as PureHomeSettingsFormContainer };

export default connect(mapStateToProps, mapDispatchToProps)(
  HomeSettingsFormContainer
);
