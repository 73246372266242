import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { color } from '../../../styles';

const { darkerGray, lightGray, mediumDarkGray } = color;

const CardLink = styled(NavLink)`
  width: 34rem;
  height: 13.5rem;
  margin-top: 3rem;
  margin-right: 2rem;
  border: 1px solid ${mediumDarkGray};
  padding: 2.5rem 2rem;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background: ${lightGray};
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  :hover {
    border: 1px solid ${darkerGray};
  }
`;

const Header = styled.h3`
  font-weight: 600;
  font-size: 2.1rem;
`;

const Description = styled.div`
  margin-top: 1rem;
  font-size: 1.5rem;
`;

const WidgetLinkCard = ({ description, headerText, to }) => (
  <CardLink to={to}>
    <Header>{headerText}</Header>
    <Description>{description}</Description>
  </CardLink>
);

WidgetLinkCard.propTypes = {
  description: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default WidgetLinkCard;
