import { connect } from 'react-redux';
import { AssessmentWidgetCareerCoachVisitsTable } from 'components';

const mapStateToProps = ({ sites, stats }) => {
  const { hasLot, nation } = sites.items[sites.currentSite];

  return {
    hasLot,
    nation,
    subdomain: sites.currentSite,
    timeframe: stats
  };
};

export default connect(mapStateToProps)(AssessmentWidgetCareerCoachVisitsTable);
