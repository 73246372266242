import React from 'react';
import { Col, Row, StatCard } from 'components';
import { FetchKeen } from 'containers';
import { keenTopCities, keenTopZips } from '../../../services/keen/queries';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';

const Geography = withNationHOC(({ nation }) => {
  return (
    <div>
      <Row>
        <Col>
          <FetchKeen {...keenTopCities}>
            {props => (
              <StatCard
                heading="Top Cities"
                hintMessage="The location of a visitor to Career Coach is determined by the visitor's IP address. A visitor's IP address will indicate the correct city 50-80% of the time."
                {...props}
              />
            )}
          </FetchKeen>
        </Col>
        <Col>
          <FetchKeen {...keenTopZips}>
            {props => (
              <StatCard
                heading={`Top ${locale.location.code[nation]} Codes`}
                hintMessage="The location of a visitor to Career Coach is determined by the visitor's IP address. A visitor's IP address will indicate the correct city 50-80% of the time."
                {...props}
              />
            )}
          </FetchKeen>
        </Col>
      </Row>
    </div>
  );
});

export default Geography;
