import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react';

import { generateProgramWidgetSrcUrl } from '../../../helpers/widget-embed-code-utils';

const Preview = styled.div`
  padding: 3rem;
  width: 100%;
  height: 65rem;
  overflow: scroll;
  ${({ isHidden }) => isHidden && 'visibility: hidden;'}
`;

const ProgramWidgetBuilderPreview = props => {
  const { isHidden } = props;
  const previewWidgetUrl = generateProgramWidgetSrcUrl({ ...props });

  return (
    <Preview isHidden={isHidden}>
      <IframeResizer
        title="Widget Builder Preview"
        heightCalculationMethod="taggedElement"
        checkOrigin="false"
        src={`${previewWidgetUrl}&isPreview=true`}
        style={{ width: '1px', minWidth: '100%' }}
      />
    </Preview>
  );
};

ProgramWidgetBuilderPreview.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired
};

export default ProgramWidgetBuilderPreview;
