import React from 'react';
import { Col, Metric, Row } from 'components';
import { FetchKeen } from 'containers';
import { keenResumeStarts, keenResumeDownloads } from '../../../services/keen/queries';
import { withNationHOC } from '../../../hocs/withNation';
import { locale } from '../../../helpers/locale';

const Resumes = withNationHOC(({ nation }) => {
  return (
    <div>
      <Row>
        <Col>
          <FetchKeen {...keenResumeStarts}>
            {props => <Metric heading={`${locale.resume[nation]}s Started`} {...props} />}
          </FetchKeen>
        </Col>
        <Col>
          <FetchKeen {...keenResumeDownloads}>
            {props => <Metric heading={`${locale.resume[nation]}s Downloaded`} {...props} />}
          </FetchKeen>
        </Col>
      </Row>
    </div>
  );
});

export default Resumes;
