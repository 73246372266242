import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../../../config';
import theme from '../../../styles/theme';
import TrashIcon from '../../../images/trash-icon.svg';
import PdfIcon from '../../../images/pdf-icon.svg';

import { deleteDisclaimer, uploadDisclaimer } from '../../../services/uploads';
import { Card, Upload } from 'components';

const { cloudfrontUrl } = config;

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const HalfWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1.5rem 1rem;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0.5rem 0 1rem;
  }
`;

const H3 = styled.h3`
  margin: 0.6rem 0;
  font-weight: 600;
`;

const H2 = styled.h2`
  margin-bottom: 2rem;
  font-size: 2.1rem;
`;

const LinkWrapper = styled.span`
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0 0;
`;

const StyledLink = styled.a`
  margin: 0 1rem;
  color: ${theme.linkColor};
  text-decoration: none;
`;

const StyledTrashIcon = styled(TrashIcon)`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  margin-right: 1.3rem;
  fill: ${theme.linkColor};
  cursor: pointer;
`;

const DisclaimersUpload = props => {
  const { employerDisclaimer, studentDisclaimer, subdomain, updateDisclaimer } = props;

  const removeDisclaimer = disclaimerType => {
    deleteDisclaimer(disclaimerType, subdomain).then(() => {
      updateDisclaimer(disclaimerType, '');
    });
  };

  const maxFileSize = 5000000;
  const tooBigMessage = `Please limit PDFs to less than ${maxFileSize / 1000000}MB in size.`;

  return (
    <Card>
      <H2>Résumé Disclaimers</H2>
      <Wrapper>
        <HalfWrapper>
          <H3>
            {employerDisclaimer ? 'Replace Employer Disclaimer' : 'Upload Employer Disclaimer'}
          </H3>
          <Upload
            {...props}
            ariaLabel="Upload employer disclaimer"
            caveat={tooBigMessage}
            mimeType="application/pdf"
            maxSize={maxFileSize}
            updateDisclaimer={updateDisclaimer}
            handleUpload={({ acceptedFile, uploadFilename, setUploading }) => {
              const disclaimerType = 'prosecution-warning';
              uploadDisclaimer(acceptedFile, uploadFilename, disclaimerType).then(() => {
                updateDisclaimer(disclaimerType, uploadFilename);
                setUploading(false);
              });
            }}
          />
          {employerDisclaimer && (
            <LinkWrapper>
              <PdfIcon />
              <StyledLink
                href={`${cloudfrontUrl}/prosecution-warning/${employerDisclaimer}`}
                download={`prosecution-warning-${subdomain}.pdf`}
                target="_blank"
              >
                Click here to open current disclaimer
              </StyledLink>
              <StyledTrashIcon
                aria-label="delete employer disclaimer"
                tabIndex="0"
                onKeyDown={e => e.keyCode === 13 && removeDisclaimer('prosecution-warning')}
                onClick={() => removeDisclaimer('prosecution-warning')}
              />
            </LinkWrapper>
          )}
        </HalfWrapper>
        <HalfWrapper>
          <H3>{studentDisclaimer ? 'Replace Student Disclaimer' : 'Upload Student Disclaimer'}</H3>
          <Upload
            {...props}
            ariaLabel="Upload student disclaimer"
            caveat={tooBigMessage}
            mimeType="application/pdf"
            maxSize={maxFileSize}
            updateDisclaimer={updateDisclaimer}
            handleUpload={({ acceptedFile, uploadFilename, setUploading }) => {
              const disclaimerType = 'spam-warning';
              uploadDisclaimer(acceptedFile, uploadFilename, disclaimerType).then(() => {
                updateDisclaimer(disclaimerType, uploadFilename);
                setUploading(false);
              });
            }}
          />
          {studentDisclaimer && (
            <LinkWrapper>
              <PdfIcon />
              <StyledLink
                href={`${cloudfrontUrl}/spam-warning/${studentDisclaimer}`}
                download={`spam-warning-${subdomain}.pdf`}
                target="_blank"
              >
                Click here to open current disclaimer
              </StyledLink>
              <StyledTrashIcon
                aria-label="delete student disclaimer"
                tabIndex="0"
                onKeyDown={e => e.keyCode === 13 && removeDisclaimer('spam-warning')}
                onClick={() => removeDisclaimer('spam-warning')}
              />
            </LinkWrapper>
          )}
        </HalfWrapper>
      </Wrapper>
    </Card>
  );
};

DisclaimersUpload.propTypes = {
  employerDisclaimer: PropTypes.string,
  studentDisclaimer: PropTypes.string,
  subdomain: PropTypes.string.isRequired,
  updateDisclaimer: PropTypes.func.isRequired
};

export default DisclaimersUpload;
