import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GradientScroll from '../GradientScroll';

import { color } from '../../../styles';
import CircleX from '../../../images/circle-x.svg';

const { sapphireD20 } = color;

const SelectedPrograms = styled.div`
  height: ${({ height }) => height};
  min-height: 12rem;
  max-height: 20rem;
`;

const SelectedProgramList = styled.ul`
  padding-top: 0.75rem;
  padding-bottom: 1.25rem;
`;

const SelectedProgram = styled.li`
  width: 95%;
  display: flex;
`;

const RemoveSelectedProgramButton = styled.button`
  height: 2rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const RemoveSelectedProgramIcon = styled(CircleX)`
  width: 1.7rem;
  height: 1.7rem;
  position: relative;
  top: 3px;
  margin-right: 1rem;
  fill: ${sapphireD20};
`;

const WidgetBuilderSelectedPrograms = ({
  hasManyInstitutions = false,
  institutionsById,
  selectedPrograms,
  setSelectedPrograms
}) => {
  const removeSelectedProgram = code => {
    const newSelectedPrograms = selectedPrograms.filter(program => program.code !== code);
    setSelectedPrograms(newSelectedPrograms);
  };

  return (
    <SelectedPrograms height={`${selectedPrograms.length * 3.5 + 3.5}rem`}>
      <GradientScroll>
        <SelectedProgramList>
          {selectedPrograms.map(({ name, credential, code, institutionId, subdomain }, index) => {
            const institutionName =
              hasManyInstitutions && institutionsById[`${subdomain}-${institutionId}`].displayName;
            return (
              <SelectedProgram key={`selected-program-${code}`}>
                <RemoveSelectedProgramButton
                  aria-label={`Remove ${name}, ${credential} ${
                    hasManyInstitutions ? `(${institutionName})` : ''
                  } from selected programs`}
                  onClick={() => removeSelectedProgram(code)}
                  color="black"
                >
                  <RemoveSelectedProgramIcon />
                </RemoveSelectedProgramButton>
                <div data-cy={`selected-program-${index}`}>{`${name}, ${credential} ${
                  hasManyInstitutions ? `(${institutionName})` : ''
                }`}</div>
              </SelectedProgram>
            );
          })}
        </SelectedProgramList>
      </GradientScroll>
    </SelectedPrograms>
  );
};

WidgetBuilderSelectedPrograms.propTypes = {
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedPrograms: PropTypes.array.isRequired,
  setSelectedPrograms: PropTypes.func.isRequired
};

export default WidgetBuilderSelectedPrograms;
