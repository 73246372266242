import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SortButton = styled.button`
  width: 2rem;
  display: flex;
  margin-left: 0.5rem;
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const SortIcon = styled.div`
  ${({ isSelected }) => (isSelected ? 'color: #000000' : 'color: #757575')};
  ${({ isFlipped }) => (isFlipped ? 'transform: scale(1, -1);' : '')}
`;

const ProfilesTableSortButton = ({
  columnHeader,
  defaultSortField,
  isLoading,
  setDefaultSortField,
  setSelectedSortBy,
  sortField
}) => {
  const [isAscendingSort, setIsAscendingSort] = useState(true);

  const determineIfAscending = () => {
    if (defaultSortField !== sortField) {
      return true;
    } else {
      return !isAscendingSort;
    }
  };

  const setSort = () => {
    const newIsAscending = determineIfAscending();
    setIsAscendingSort(determineIfAscending());
    setDefaultSortField(sortField);
    const minusOrEmptyString = !newIsAscending ? '' : '-';
    const newSortBy =
      sortField === 'name'
        ? `${minusOrEmptyString}givenName,${minusOrEmptyString}familyName`
        : `${minusOrEmptyString}${sortField}`;

    setSelectedSortBy(newSortBy);
  };

  return (
    <SortButton
      aria-label={`sort by ${columnHeader}, ${determineIfAscending() ? 'ascending' : 'descending'}`}
      disabled={isLoading}
      onClick={() => {
        setSort({ sortField, isAscendingSort });
      }}
    >
      {(!isAscendingSort || defaultSortField !== sortField) && (
        <SortIcon isFlipped isSelected={defaultSortField === sortField}>
          &#9662;
        </SortIcon>
      )}
      {(isAscendingSort || defaultSortField !== sortField) && (
        <SortIcon isSelected={defaultSortField === sortField}>&#9662;</SortIcon>
      )}
    </SortButton>
  );
};

ProfilesTableSortButton.propTypes = {
  columnHeader: PropTypes.string.isRequired,
  defaultSortField: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  setDefaultSortField: PropTypes.func.isRequired,
  setSelectedSortBy: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired
};

export default ProfilesTableSortButton;
