import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { Field, reduxForm } from 'redux-form';

import { isRequired, isEmail, isPassword } from '../../../helpers/validation';
import { FormInput, Header, Message } from 'components';
import { color } from '../../../styles';

const { sapphireD20 } = color;

const ForgotPasswordButton = styled.button`
  position: absolute;
  right: 0;
  width: fit-content;
  border: none;
  margin-right: 3rem;
  background: none;
  color: #3E6DCC;
  font-size: 1.6rem;
  cursor: pointer;
  z-index: 2;

  :hover {
    text-decoration: underline;
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    width: 48%;
    margin-bottom: 1.5rem;

    @media (max-width: 549px) {
      width: 100%;
    }
  }
`;

const Submit = styled.button`
  display: block;
  margin-top: 2rem;
  background: ${sapphireD20};
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 500;
  padding: 1.2rem 2rem;
  border-radius: 3px;

  @media (max-width: 549px) {
    margin-top: 3rem;
    width: 100%;
  }

  :hover {
    background: ${darken(0.05, sapphireD20)};
  }
`;

class LoginForm extends React.Component {
  render() {
    const { errorMessage, handleSubmit, login, setHasForgottenPassword } = this.props;

    return (
      <div>
        <Header component="h2" type="title" bold>
          Administrative Login
        </Header>

        <ForgotPasswordButton onClick={() => setHasForgottenPassword(true)}>
          Forgot Password?
        </ForgotPasswordButton>

        <form onSubmit={handleSubmit(login)}>
          <FieldsWrapper>
            <Field
              type="text"
              name="email"
              label="Email"
              component={FormInput}
              validate={[isRequired, isEmail]}
              data-cy="login-email-input"
            />
            <Field
              type="password"
              name="password"
              label="Password"
              component={FormInput}
              validate={[isRequired, isPassword]}
              data-cy="login-password-input"
            />
          </FieldsWrapper>
          {errorMessage && (
            <Message type="error" data-cy="login-error-message">
              {errorMessage}
            </Message>
          )}
          <Submit type="submit" data-cy="login-submit-button">
            Login
          </Submit>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  setHasForgottenPassword: PropTypes.func.isRequired
};

export default reduxForm({ form: 'login' })(LoginForm);
