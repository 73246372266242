import styled from 'styled-components';

const HiddenLabel = styled.label`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

export default HiddenLabel;
