import PropTypes from 'prop-types';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

const LinkStyled = styled(ReactRouterLink)`
  color: #fff;
  text-decoration: none;
`;

const Link = ({ children, to, ...other }) => {
  return (
    <LinkStyled to={to} {...other}>
      {children}
    </LinkStyled>
  );
};

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Link;
