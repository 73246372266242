import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FavoritesModal } from 'components';
import { fetchCareerTitles, fetchInstitutions, fetchProgramsBySlug } from '../../services';
import { selectSubdomain } from '../../selectors/subdomain';

class FavoritesModalContainer extends React.Component {
  state = {};
  async componentDidMount() {
    const { favorites, nation, subdomain, hasLot = false, hasManyInstitutions } = this.props;
    const { careers, programs } = favorites;

    if (careers && careers.length) {
      const favoriteCareers = await fetchCareerTitles(careers.join(), nation, hasLot);
      this.setState({ careers: Object.values(favoriteCareers) });
    }
    if (programs && programs.length) {
      const programSlugs = programs.map(favorite => favorite.split('/')[0]);
      const favoritePrograms = await fetchProgramsBySlug(subdomain, programSlugs);
      const institutions = await fetchInstitutions(subdomain);

      this.setState({
        programs: programs
          .filter(program => favoritePrograms[program.split('/')[0]])
          .map(program => {
            const [programSlug, institutionId] = program.split('/');
            const institutionSlug = `${subdomain}-${institutionId}`;
            const currentFavorite = favoritePrograms[programSlug];
            const institutionName =
              hasManyInstitutions &&
              !!institutions[institutionSlug] &&
              institutions[institutionSlug].displayName;
            return {
              titleSlug: program,
              singularTitle: currentFavorite.name,
              credential: currentFavorite.credential,
              institutionName
            };
          })
      });
    }
  }

  render() {
    return <FavoritesModal {...this.props} favorites={this.state} />;
  }
}

export const mapStateToProps = ({ profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    hasLot: sites.items[currentSite].hasLot || false,
    hasManyInstitutions: sites.items[currentSite].hasManyInstitutions,
    nation: sites.items[currentSite].nation,
    subdomain: currentSite
  };
};

FavoritesModalContainer.propTypes = {
  favorites: PropTypes.object.isRequired,
  hasLot: PropTypes.bool,
  hasManyInstitutions: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(FavoritesModalContainer);
