import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import { locale } from '../../../helpers/locale';
import {
  Card,
  ContentWrap,
  SubNav,
  AssessmentWidgetStatistics,
  ProgramWidgetStatistics
} from 'components';
import { color } from '../../../styles';

const { black, white } = color;

const StyledCard = styled(Card)`
  max-width: 112rem;
  padding: 0;
  max-width: 112rem;
`;

const Line = styled.hr`
  width: 100%;
  border: 2px solid ${color.lightGray};
  position: absolute;
  margin: 0;
  top: 61px;
`;

const WidgetStatistics = ({ isAssessmentWidgetEnabled, isProgramWidgetEnabled, nation }) => {
  const navigation = [
    {
      title: 'Assessment Widget',
      path: '/analytics/widget/assessment-widget',
      component: AssessmentWidgetStatistics
    },
    {
      title: `${locale.edTrack[nation]} Widget`,
      path: '/analytics/widget/program-widget',
      component: ProgramWidgetStatistics
    }
  ].filter(Boolean);
  return (
    <ContentWrap>
      <StyledCard>
        {isAssessmentWidgetEnabled && isProgramWidgetEnabled && (
          <Fragment>
            <Line />
            <SubNav navigation={navigation} backgroundColor={white} linkColor={black} />
            <Switch>
              {navigation.map((nav, index) => (
                <Route
                  key={`route-${index}}`}
                  path={nav.path}
                  render={props => <nav.component {...props} />}
                />
              ))}
              <Redirect to="/analytics/widget/assessment-widget" />
            </Switch>
          </Fragment>
        )}
        {isAssessmentWidgetEnabled && !isProgramWidgetEnabled && <AssessmentWidgetStatistics />}
        {!isAssessmentWidgetEnabled && isProgramWidgetEnabled && <ProgramWidgetStatistics />}
      </StyledCard>
    </ContentWrap>
  );
};

WidgetStatistics.propTypes = {
  isAssessmentWidgetEnabled: PropTypes.bool.isRequired,
  isProgramWidgetEnabled: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired
};

export default WidgetStatistics;
