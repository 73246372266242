import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormInput } from 'components';
import { withNationHOC } from '../../../hocs/withNation';
import { locale } from '../../../helpers/locale';

export const DesignFields = withNationHOC(({ nation }) => {
  return (
    <div>
      <Field
        id="settings-button-color"
        // This is labeled "Button Text Color" even though the setting is called buttonColor. The attr name refers to the CSS property
        // for text color ("color") while the label is meant to be human readable.
        label={`Button Text ${locale.spellings.color[nation]}`}
        placeholder="Enter Hex Code (default is #ffffff)"
        name="styles.buttonColor"
        component={FormInput}
        type="text"
        aria-label={'settings for button color'}
      />
      <Field
        id="settings-accent-color"
        // This is labeled Button Color for human readability on the admin. It does not correspond to the buttonColor attribute 
        // (which sets the font CSS "color" attribute on the buttons) but the accentColor setting which is primarily used to set 
        // the button "background-color" attribute. 
        label={`Button ${locale.spellings.color[nation]}`}
        placeholder="Enter Hex Code"
        name="styles.accentColor"
        component={FormInput}
        type="text"
        aria-label={'settings for accent color'}
      />
      <Field
        id="settings-menu-color"
        label={`Menu Text ${locale.spellings.color[nation]}`}
        placeholder="Enter Hex Code"
        name="styles.menuColor"
        component={FormInput}
        type="text"
        aria-label={'settings for menu color'}
      />
      <Field
        id="settings-menu-background-color"
        label={`Menu Background ${locale.spellings.color[nation]}`}
        placeholder="Enter Hex Code"
        name="styles.menuBackgroundColor"
        component={FormInput}
        type="text"
        aria-label={'settings for menu background color'}
      />
      <Field
        id="settings-link-color"
        label={`Link ${locale.spellings.color[nation]}`}
        placeholder="Enter Hex Code"
        name="styles.linkColor"
        component={FormInput}
        type="text"
        aria-label={'settings for menu link color'}
      />
    </div>
  );
});

DesignFields.propTypes = {
  logoFile: PropTypes.string
};

export default DesignFields;
