import styled from 'styled-components';
import { color } from '../../../styles';
import { Button, SearchInput } from 'components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding 1rem 1rem 1rem 0;
`;

export const Checkbox = styled.input`
  height: 3.5rem;
  vertical-align: middle;
  border: none;
  cursor: pointer;
`;

export const HorizontalRule = styled.div`
  margin-top: 1.5rem;
  border-bottom: 2px solid ${color.gray};
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: white;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media only screen and (min-width: 500px) {
    width: 40rem;
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  align-items: flex-start;
  padding: 0 2rem;
  overflow-y: auto;
`;

export const OptionWrapper = styled.label`
  display: inline-block;
  text-align: left;
`;

export const SaveButton = styled(Button)`
  margin-left: 1rem;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 2rem;
`;
