import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'components';
import { color } from 'styles';

const { red, lightGray, mediumGray, sapphireD20 } = color;

const StyledInput = styled.input`
  border: 1px solid ${props => (props.isError ? red : mediumGray)};
  border-radius: 3px;
  display: block;
  font-size: 1.5rem;
  outline: 0;
  padding: 1rem;
  width: 100%;

  &:focus {
    border: 1px solid ${sapphireD20};
  }

  &:disabled {
    background-color: ${lightGray};
    border-color: #e5e5e5;
    color: #7f7f7f;
    cursor: not-allowed;
  }
`;

const Input = ({
  onChange,
  handleRef,
  dataCy,
  disabled,
  required,
  error,
  message,
  placeholder,
  children,
  ...other
}) => {
  return (
    <span>
      {children}
      <StyledInput
        ref={el => {
          handleRef(el);
        }}
        onChange={onChange}
        data-cy={dataCy}
        disabled={disabled}
        aria-disabled={disabled}
        aria-required={required}
        aria-invalid={error}
        placeholder={placeholder}
        isError={!!error}
        {...other}
      />
      {
        <span>
          <Text error={error} type="caption" data-cy={`${dataCy}-error`}>
            {message}
          </Text>
        </span>
      }
    </span>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  handleRef: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  children: PropTypes.any,
  other: PropTypes.any
};

Input.defaultProps = {
  component: 'input',
  disabled: false,
  required: false,
  error: false,
  onChange: function noop() {},
  handleRef: function noop() {}
};

export default Input;
