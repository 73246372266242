import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'query-string';

import { locale } from '../../../helpers/locale';
import {
  Card,
  Header,
  Icon,
  TabButton,
  PendingPostings,
  Button,
  PostingModal,
  LivePostings,
  EndedPostings,
  HideContentOnMobile,
  Bubble
} from 'components';

const StyledCard = styled(Card)`
  padding-bottom: 0 !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = styled.div`
  flex-grow: 1;
`;

const Plus = styled(Icon)`
  padding-right: 1rem;
  vertical-align: bottom;
`;

const NewPostingButton = styled(Button)`
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
`;

const PostingsCard = ({
  created,
  createPosting,
  employers,
  enabledJobTypes,
  endedPostings,
  hasLot,
  livePostings,
  location,
  modalHide,
  modals,
  modalShow,
  nation,
  pendingPostings,
  subdomain,
  updated,
  updatePosting
}) => {
  /* The url is parsed to determine the current tab.
  The url is in the format of "/analytics/employers?employers=approved&postings=pending" */
  const tabs = location.search
    ? qs.parse(location.search)
    : { employers: 'approved', postings: 'live' };
  const pendingLength = Object.keys(pendingPostings).length;
  return (
    <StyledCard>
      <Header component="h2" type="subtitle" bold>
        Postings
      </Header>
      <HideContentOnMobile message="Please view the list of postings on desktop!">
        <ButtonWrapper>
          <Tabs>
            <TabButton
              isFirst
              selected={tabs.postings !== 'ended' && tabs.postings !== 'pending'}
              to={`/analytics/employers?${qs.stringify({
                ...tabs,
                postings: 'live'
              })}`}
            >
              Live
            </TabButton>
            <TabButton
              selected={tabs.postings === 'ended'}
              to={`/analytics/employers?${qs.stringify({
                ...tabs,
                postings: 'ended'
              })}`}
            >
              Ended
            </TabButton>
            <TabButton
              isLast
              selected={tabs.postings === 'pending'}
              to={`/analytics/employers?${qs.stringify({
                ...tabs,
                postings: 'pending'
              })}`}
            >
              Pending
              {pendingLength > 0 && <Bubble>{pendingLength}</Bubble>}
            </TabButton>
          </Tabs>
          <NewPostingButton
            className="ion-add-circle"
            type="button"
            onClick={() => modalShow('newPostingModal')}
          >
            <Plus type="plus" size="1" hasHover={false} color="#ffffff" />
            Add New Posting
          </NewPostingButton>
          {modals['newPostingModal'] && (
            <PostingModal
              modalHide={modalHide}
              nation={nation}
              hasLot={hasLot}
              subdomain={subdomain}
              isCompleted={created}
              createPosting={createPosting}
              submitText="Publish Posting"
              title="New Posting"
              employers={Object.values(employers)
                .filter(({ status }) => status !== 'denied')
                .map(employer => employer)}
              name="newPostingModal"
              initialValues={{
                application: {
                  type: 'standard',
                  buttonText: `Submit ${locale.resume[nation]}...`
                },
                createdOn: moment().format('MM-DD-YYYY'),
                publishedOn: moment().format('MM-DD-YYYY'),
                status: 'approved',
                jobType: enabledJobTypes[0],
                // The default is thirty days past today,
                // and the date selector component is expecting it in this format.
                endOn: moment()
                  .add(30, 'days')
                  .format('YYYY-MM-DD')
              }}
              enabledJobTypes={enabledJobTypes}
            />
          )}
        </ButtonWrapper>
        {tabs.postings === 'live' && (
          <LivePostings
            data={livePostings}
            employers={employers}
            enabledJobTypes={enabledJobTypes}
            modalHide={modalHide}
            modals={modals}
            modalShow={modalShow}
            nation={nation}
            subdomain={subdomain}
            updated={updated}
            updatePosting={updatePosting}
          />
        )}
        {tabs.postings === 'ended' && (
          <EndedPostings
            data={endedPostings}
            employers={employers}
            enabledJobTypes={enabledJobTypes}
            modals={modals}
            modalShow={modalShow}
            nation={nation}
          />
        )}
        {tabs.postings === 'pending' && (
          <PendingPostings
            data={pendingPostings}
            employers={employers}
            enabledJobTypes={enabledJobTypes}
            modalHide={modalHide}
            modals={modals}
            modalShow={modalShow}
            nation={nation}
            updatePosting={updatePosting}
          />
        )}
      </HideContentOnMobile>
    </StyledCard>
  );
};

PostingsCard.propTypes = {
  created: PropTypes.bool,
  createPosting: PropTypes.func.isRequired,
  employers: PropTypes.object.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  endedPostings: PropTypes.object,
  hasLot: PropTypes.bool,
  livePostings: PropTypes.object,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  pendingPostings: PropTypes.object,
  subdomain: PropTypes.string.isRequired,
  updated: PropTypes.bool,
  updatePosting: PropTypes.func.isRequired
};

export default PostingsCard;
