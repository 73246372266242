import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Element } from 'components';

/**
 * Float component
 * @type {[type]}
 */
const Float = styled(Element)`
  float: ${({ value }) => value};
  clear: both;
`;

Float.defaultProps = {
  value: 'right'
};

Float.propTypes = {
  value: PropTypes.string.isRequired
};

export default Float;
