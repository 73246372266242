import { connect } from 'react-redux';

import Login from '../../components/pages/Login';
import { clearProfileErrorMessage, login } from '../../actions/actionCreators';

const mapStateToProps = ({ profile }) => {
  return {
    /* used for resetting Cognito password */
    email: profile.customData.email,
    errorMessage: profile.errorMessage,
    resettingPassword: profile.resettingPassword
  };
};

const mapDispatchToProps = dispatch => ({
  clearProfileErrorMessage: () => dispatch(clearProfileErrorMessage()),
  login: ({ email, password }) => dispatch(login(email.toLowerCase(), password))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
