import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Button } from 'components';
import SettingsIcon from '../../../images/settings-icon.svg';
import BackArrowIcon from '../../../images/back-arrow-icon.svg';

const StyledButton = styled(Button)`
  width: 20rem;
  height: 4rem;
  padding: 0;
`;

const StyledIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  height: 13px;
  position: relative;
  margin-right: 1rem;
  top: 1.5px;
  fill: white;
  width: 2rem;
`;

const ConfigureSettingsButton = ({ text, to, history }) => {
  return (
    <StyledButton onClick={() => history.push(to)}>
      <StyledIcon
        aria-hidden="true"
        icon={to === '/analytics/employers/settings' ? SettingsIcon : BackArrowIcon}
      />
      {text}
    </StyledButton>
  );
};

ConfigureSettingsButton.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default withRouter(ConfigureSettingsButton);
