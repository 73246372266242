export function pagination(currentPage, lastPage) {
  let range = [1];
  let offset = 1; // show how many to left or right
  const atATime = 3; // show how many in the middle
  const maxShow = offset + atATime + offset; // show how many total

  /* error case */

  if (currentPage > lastPage) {
    throw new Error('Error: Current page must be less than last page');
  }

  /* easy case; we show all the items */

  if (lastPage <= maxShow) {
    for (let i = 2; i <= lastPage; i += 1)
      range.push(i);
    return range;
  }

  /* not going to show all the items */
  if (currentPage - offset < atATime) {
    // page is near the beginning
    for (let i = 2; i <= atATime + 1; i += 1)
      range.push(i);
    if (atATime <= lastPage) range.push('...');
  } else if (currentPage + offset >= lastPage) {
    // page is near the end
    range.push('...');
    for (let i = lastPage - atATime; i < lastPage; i += 1)
      range.push(i);
  } else {
    // page is in the middle
    range.push('...');
    range.push(currentPage - offset);
    range.push(currentPage);
    range.push(currentPage + offset);
    if (lastPage - currentPage !== 2) range.push('...');
  }

  range.push(lastPage);
  return range;
}
