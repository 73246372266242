import { takeLatest } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { flashSuccess } from '../helpers/flashmessage';
import { handleErrors } from 'helpers/handle-errors';
import * as t from '../actions/constants';
import {
  fetchSitesSuccess,
  fetchSiteSuccess,
  fetchFailure as failureAction,
  createSiteSuccess,
  patchSiteSuccess,
  updateStatsDates
} from '../actions/actionCreators';
import { fetchSites, createSite, patchSite, fetchSite } from '../services';
import { defaultStatTimeframe } from '../reducers/stats';

export function* getAll() {
  try {
    const sites = yield call(fetchSites);
    yield put(fetchSitesSuccess(sites));
  } catch (e) {
    yield put(failureAction(t.FETCH_SITES_FAILURE, e));
  }
}

export function* get({ payload }) {
  yield put(updateStatsDates(defaultStatTimeframe));
  try {
    const site = yield call(fetchSite, payload);
    yield put(fetchSiteSuccess(site));
  } catch (e) {
    yield put(failureAction(t.FETCH_SITE_FAILURE, e));
  }
}

export function* create(action) {
  const { data } = action.payload;
  try {
    const site = yield call(createSite, data);
    yield flashSuccess(`${data.subdomain} Created!`);
    yield put(createSiteSuccess(site));
  } catch (e) {
    const errors = yield handleErrors(data.subdomain, e);
    yield put(failureAction(t.CREATE_SITE_FAILURE, errors));
  }
}

export function* update(action) {
  const { data } = action.payload;
  try {
    const site = yield call(patchSite, data);
    yield flashSuccess(`${data.subdomain} Updated!`);
    yield put(patchSiteSuccess(site));
  } catch (e) {
    const errors = yield handleErrors(data.subdomain, e);
    yield put(failureAction(t.PATCH_SITE_FAILURE, errors));
  }
}

export function* patchAPI() {
  yield call(takeLatest, t.PATCH_SITE_REQUEST, update);
}

export function* createAPI() {
  yield call(takeLatest, t.CREATE_SITE_REQUEST, create);
}

export function* fetchAllAPI() {
  yield call(takeLatest, t.FETCH_SITES_REQUEST, getAll);
}

export function* fetchAPI() {
  yield call(takeLatest, t.FETCH_SITE_REQUEST, get);
}
