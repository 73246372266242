import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { endOfDay, format, formatISO, subDays } from 'date-fns';
import { DateRangePicker as RangePicker } from 'react-date-range';

import '../../../styles/react-date-range--styles.css';
import '../../../styles/react-date-range--default.css';
import { defaultStaticRanges } from './defaultRanges';

const InputBox = styled.input`
  width: 20.5rem;
  height: 4rem;
  padding: 0.5rem 0.8rem 0.4rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  font-size: 1.6rem;

  @media (max-width: 719px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const CalendarWrap = styled.div`
  position: relative;
  display: inline-table;
  margin-right: 2rem;

  @media (max-width: 719px) {
    width: 100%;
  }
`;

const CalendarElement = styled.div`
  position: absolute;
  left: 50%;
  top: 4.5rem;
  border: ${({ isOpen }) => (isOpen ? '0.1rem solid #ccc' : 'none')};
  z-index: 1;
`;

const DateRangePicker = ({ endDate, startDate, updateStatsDates }) => {
  const [range, setRange] = useState([
    {
      key: 'selection',
      endDate: new Date(endDate || new Date()),
      startDate: new Date(startDate || subDays(new Date(), 7))
    }
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const refOne = useRef(null);

  const hideOnEscape = e => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      document.addEventListener('keydown', hideOnEscape, true);
      document.addEventListener('click', hideOnClickOutside, true);
    } else {
      document.removeEventListener('keydown', hideOnEscape, true);
      document.removeEventListener('click', hideOnClickOutside, true);
    }
  }, [isOpen]);

  return (
    <CalendarWrap>
      <InputBox
        value={`${format(range[0].startDate, 'MM/dd/yyyy')} to ${format(
          range[0].endDate,
          'MM/dd/yyyy'
        )}`}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
      />
      <div ref={refOne}>
        <CalendarElement isOpen={isOpen}>
          {isOpen && (
            <RangePicker
              onChange={item => {
                setRange([item.selection]);
                item.selection.startDate != item.selection.endDate && setIsOpen(!isOpen);
                const statsObject = {
                  key: '7 days',
                  end: formatISO(endOfDay(item.selection.endDate)),
                  start: formatISO(item.selection.startDate)
                };
                updateStatsDates(statsObject);
              }}
              editableDateInputs
              moveRangeOnFirstSelection={false}
              ranges={range}
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
              months={2}
              direction="horizontal"
              dayContentRenderer={null}
            />
          )}
        </CalendarElement>
      </div>
    </CalendarWrap>
  );
};

DateRangePicker.propTypes = {
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  updateStatsDates: PropTypes.func.isRequired
};

export default DateRangePicker;
