import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { DesignFields, FormLayout } from 'components';

const DesignForm = ({
  handleSubmit,
  submitting,
  reset,
  pristine,
  handleFormSubmit
}) => {
  return (
    <FormLayout
      title="Design Settings"
      submitForm={handleSubmit(handleFormSubmit)}
      submitting={submitting}
      pristine={pristine}
      reset={reset}>
      <DesignFields />
    </FormLayout>
  );
};

DesignForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  handleLogoUpload: PropTypes.func
};

const DesignSettingsForm = reduxForm({
  form: 'designSettingsForm',
  enableReinitialize: true
})(DesignForm);

export default DesignSettingsForm;
