export const fetchToken = async ({ nation, profileId, subdomain }) => {
  try {
    const response = await fetch(
      `/api/cc-api/token/sign?nation=${nation}&profileId=${profileId}&subdomain=${subdomain}`
    );
    const json = await response.json();
    const { inviteToken } = json.data.attributes;
    return inviteToken;
  } catch (error) {
    return false;
  }
};

export const verifyToken = async ({ token }) => {
  try {
    const response = await fetch(`/api/cc-api/token/verify?token=${token}`);
    const json = await response.json();

    if (json.errors) {
      return {
        errors: 'Your invitation link has expired.'
      };
    }
    const { subdomain, profileId, nation, email } = json.data.attributes;

    return { subdomain, profileId, nation, email };
  } catch (error) {
    return false;
  }
};
