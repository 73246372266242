import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CareerSettingsForm } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

class CareerSettingsFormContainer extends React.Component {
  static propTypes = {
    patchSite: PropTypes.func,
    currentSite: PropTypes.string,
    initialValues: PropTypes.object.isRequired
  };

  render() {
    return (
      <CareerSettingsForm
        handleFormSubmit={this.handleFormSubmit}
        initialValues={this.props.initialValues}
      />
    );
  }
  /**
   * after saving your career settings we update the sitesAPI
   * @method handleFormSubmit
   * @param  {object}         styles the new styles to be updated
   */
  handleFormSubmit = ({ ...careerSettings }) => {
    this.props.patchSite({
      subdomain: this.props.currentSite,
      ...careerSettings
    });
  };
}
/**
 * redux mapping the global state to props in the child component
 * @method mapStateToProps
 * @param  {object}        state    the global state object
 * @return {object}                 returns an object with the sites and orgs mapped to props
 */
export const mapStateToProps = ({ sites }) => ({
  // redux-form initialValues
  initialValues: {
    careerSettings: sites.items[sites.currentSite].careerSettings
  },
  currentSite: sites.currentSite
});
/**
 * redux mapping the actions to props
 * @method mapDispatchToProps
 * @param  {function}           dispatch the redux dispatch function
 * @return {object}                      returns an object with mapped functions to props in the AddSiteForm component
 */
export const mapDispatchToProps = dispatch => ({
  patchSite: data => dispatch(patchSiteRequest(data))
});

export { CareerSettingsFormContainer as PureCareerSettingsFormContainer }; // used in tests

export default connect(mapStateToProps, mapDispatchToProps)(CareerSettingsFormContainer);
