import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import 'chartist-plugin-legend';
import ChartistAccessibility from 'react-chartist-plugin-accessibility';
import 'chartist-plugin-tooltips';
import { Card, Header, Loading } from 'components';

const StyledCard = styled(Card)`
  color: rgb(32, 58, 69);
  min-height: 130px;
  padding: 1.5rem;

  @media (max-width: 539px) {
    text-align: center;
  }

  @media (min-width: 768px) {
    padding: 3rem;
  }

  @media (min-width: 540px) {
    min-height: 500px;
    padding-bottom: 70px;
  }
`;

const Heading = styled.h2`
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const SubHeadingWrapper = styled.div`
  display: flex;

  @media (max-width: 540px) {
    justify-content: center;
  }
`;

// uncomment this style to visually test react-chartist-plugin-accessibility
/* const style = {
  position: 'absolute',
  top: '100%',
  width: '100%',
  fontSize: '11px',
  overFlowX: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  padding: '10px'
};*/

const Chart = styled(ChartistGraph)`
  @media (max-width: 540px) {
    display: none;
  }
`;

const ChartCard = ({
  heading,
  subheading,
  data,
  loaded,
  tooltip,
  a11yCaption,
  a11ySummary,
  a11yHeader,
  legendNames = []
}) => {
  const options = {
    showArea: true,
    fullWidth: true,
    chartPadding: {
      right: 50
    },
    lineSmooth: Chartist.Interpolation.none({
      fillHoles: false
    }),
    axisY: {
      low: 0,
      onlyInteger: true
    },
    axisX: {
      showGrid: false
    },
    plugins: [
      Chartist.plugins.legend({
        position: 'bottom',
        clickable: false,
        legendNames
      }),
      Chartist.plugins.tooltip({ metaIsHTML: true })
    ]
  };
  return (
    <StyledCard>
      <Loading isAbsolute loaded={loaded}>
        <Heading>{heading}</Heading>
        <SubHeadingWrapper>
          <Header component="h2" type="subtitle" bold>
            {subheading}
          </Header>
          {tooltip}
        </SubHeadingWrapper>
        <Chart
          // Test to see if data is not {}, and has more than 100 points. If so, stop displaying points on chart
          className={Object.keys(data).length > 0 && data.labels.length > 100 && 'hide-point'}
          data={data}
          options={options}
          type="Line"
        >
          <ChartistAccessibility
            caption={a11yCaption}
            summary={a11ySummary}
            seriesHeader={a11yHeader}
            // uncomment this style to visually test react-chartist-plugin-accessibility
            // visuallyHiddenStyles={style}
            valueTransform={({ value }) => `${value} visits`}
          />
        </Chart>
      </Loading>
    </StyledCard>
  );
};

ChartCard.propTypes = {
  a11yCaption: PropTypes.string.isRequired,
  a11ySummary: PropTypes.string.isRequired,
  a11yHeader: PropTypes.string.isRequired,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    series: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number
        })
      )
    )
  }),
  heading: PropTypes.string,
  legendNames: PropTypes.array,
  loaded: PropTypes.bool,
  subdomain: PropTypes.string,
  subheading: PropTypes.string,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired,
  tooltip: PropTypes.element
};

export default ChartCard;
