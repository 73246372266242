import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultischoolStatisticsDownload } from 'components';
import { fetchKeenData } from '../../services';
import { multiSchoolStatisticsReportQueries } from '../../services/keen/excel-export-queries';
import {
  combineAssessmentBySchoolData,
  processKeenResult
} from '../../helpers/process-keen-result';
import { selectHasPrograms, selectProgramAlias } from '../../selectors/sites';

class MultischoolStatisticsDownloadContainer extends React.Component {
  static propTypes = {
    programAlias: PropTypes.string
  };

  state = {
    data: {}
  };
  render() {
    return (
      <MultischoolStatisticsDownload
        {...this.props}
        getData={params => this.loadManyKeenData(params)}
        {...this.state}
      />
    );
  }

  async loadManyKeenData({ subdomain, timeframe }) {
    const { programAlias } = this.props;

    this.setState({
      isLoaded: false,
      isLoading: true
    });

    const allResults = {};

    const queries = multiSchoolStatisticsReportQueries;

    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];
      const { resultKey } = query;

      if (programAlias && query.key === 'top-program-searches')
        query.extraFilters.property_value.push(programAlias);

      const res = await fetchKeenData({
        subdomain,
        event_collection: query.event,
        timeframe,
        ...query
      });

      let queryKey = query.key;
      let newResult = res;
      if (query.key === 'visits' || query.key === 'visitors') {
        newResult = res;
      } else if (query.key === 'assessment-completions-by-school') {
        newResult = combineAssessmentBySchoolData(allResults['assessment-starts-by-school'], res);
        queryKey = 'assessment-by-school';
      } else if (Array.isArray(res)) {
        newResult = processKeenResult(res, resultKey, true);
      }
      allResults[queryKey] = newResult;
    }

    return this.setState({
      data: allResults,
      isLoaded: true,
      isLoading: false
    });
  }
}

export const mapStateToProps = ({ sites, stats }) => ({
  hasPrograms: selectHasPrograms(sites, sites.currentSite),
  programAlias: selectProgramAlias(sites),
  subdomain: sites.currentSite,
  timeframe: stats
});

export default connect(mapStateToProps)(MultischoolStatisticsDownloadContainer);
