import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';
import { Message, Input } from 'components';
import { color } from '../../../styles';

const { red } = color;

const Wrapper = styled.div`
  position: relative;
`;

const Selection = styled.button`
  ${({ isInverted, hasError }) => {
    return isInverted
      ? `
      background: #fff;
      border: 1px solid #d8d8d8;
      `
      : `
      background: #f5f5f5;
      border: 1px solid ${hasError ? red : '#f5f5f5'};
      `;
  }};
  border-radius: 3px;
  position: relative;
  padding: 1rem;
  padding-right: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  font-size: 1.5rem;
  width: 100%;
  cursor: pointer;
`;

const StyledMessage = styled(Message)`
  text-align: right;
  margin: 0;
`;

const Arrow = styled.i`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.8rem;
`;

const Content = styled.div`
  max-height: 24rem;
  overflow-y: auto;
  margin-top: 1rem;
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(82, 97, 115, 0.2);
`;

const InputWrapper = styled.div`
  position: relative;
  padding: 1rem;
  input {
    text-indent: 18px;
  }
`;

const Search = styled.i`
  position: absolute;
  top: 20px;
  left: 21px;
  z-index: 1;
  font-size: 1.8rem;
`;

const Item = styled.div`
  position: relative;
  cursor: pointer;
  padding: 0.25rem 0 0.25rem 2.7rem;
  ${({ isHovering }) => {
    return (
      isHovering &&
      `
        background-color: #f5f5f5;
        transition: background-color .15s ease-in-out;
      `
    );
  }};
`;

const StyledHR = styled.hr`
  width: 100%;
  height: 0.2rem;
  background-color: #e5e3e3;
  border: hidden;
`;

const Checkmark = styled.i`
  position: absolute;
  left: 7px;
`;

const SelectAutocomplete = ({
  items,
  onChange,
  selectedValue,
  placeholder = 'Change Selection',
  ariaLabel = 'Change Selection',
  meta: { error, touched },
  icons = {},
  isInverted = false,
  hasNoSearch = false,
  hrAfterIndices
}) => {
  const hasError = error && touched;
  return (
    <Downshift
      onChange={onChange}
      render={({
        getInputProps,
        getItemProps,
        getButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        clearSelection,
        toggleMenu
      }) => {
        return (
          <Wrapper {...getRootProps({ refKey: 'innerRef' })}>
            <Selection
              hasError={hasError}
              isInverted={isInverted}
              {...getButtonProps({
                // turned off, so we focus on the input inside the dropdown when it opens
                onBlur: e => placeholder && e.preventDefault(),
                onClick: e => {
                  e.preventDefault();
                  clearSelection();
                  toggleMenu();
                }
              })}
              aria-label={ariaLabel}
            >
              {selectedValue || placeholder}
              <Arrow className="ion-android-arrow-dropdown" aria-hidden="true" />
            </Selection>
            {hasError && <StyledMessage type="error">{error}</StyledMessage>}
            {isOpen && (
              <Content>
                {!hasNoSearch && placeholder && (
                  <InputWrapper>
                    <Search className="ion-ios-search-strong" aria-hidden="true" />
                    <Input
                      {...getInputProps({
                        placeholder
                      })}
                      autoFocus
                    />
                  </InputWrapper>
                )}
                {items
                  .filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
                  .map((item, index) => (
                    <Fragment key={`${item}_${index}_fragment`}>
                      <Item
                        {...getItemProps({
                          item,
                          index,
                          key: `${item}_${index}`,
                          isHovering: highlightedIndex === index
                        })}
                      >
                        {(icons && icons[item]) ||
                          (selectedValue === item && <Checkmark className="ion-checkmark" />)}
                        {item}
                      </Item>
                      {/* Add Divider line */}
                      {hrAfterIndices.includes(index) && <StyledHR />}
                    </Fragment>
                  ))}
              </Content>
            )}
          </Wrapper>
        );
      }}
    />
  );
};

SelectAutocomplete.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  // onChange will send back up the item which is a string
  onChange: PropTypes.func.isRequired,
  // the default string value
  selectedValue: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string
  }),
  icons: PropTypes.objectOf(PropTypes.node),
  isInverted: PropTypes.bool,
  hasNoSearch: PropTypes.bool,
  hrAfterIndices: PropTypes.array
};

SelectAutocomplete.defaultProps = {
  meta: {
    error: ''
  },
  hrAfterIndices: []
};

export default SelectAutocomplete;
