import React from 'react';
import { connect } from 'react-redux';
import { modalHide } from '../../reducers/modal';
import { showModalSelector } from '../../selectors/modal';
import { Modal } from 'components';

const ModalContainer = props => <Modal {...props} />;

const mapStateToProps = (state, { isOpen, name }) => ({
  isOpen: isOpen || showModalSelector(state, name)
});

const mapDispatchToProps = (dispatch, { name, onClose }) => ({
  close: () => {
    onClose && onClose();
    dispatch(modalHide(name));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
