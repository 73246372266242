import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components';
import theme from '../../../styles/theme';

const Wrapper = styled.div`
  display: inline-flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 1rem;
  ${props => props.disabled && 'opacity:0.5; >svg { cursor:not-allowed } '};
`;

const StyledLabel = styled.label`
  vertical-align: middle;
  margin: 0 1.2rem 0 0.5rem;
  text-transform: capitalize;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const StyledIcon = styled(Icon)`
  margin: 0.2rem 0;
`;

const Toggle = ({
  ariaLabel,
  checked = false,
  className,
  color,
  dataCy,
  disabled,
  label = '',
  onChange,
  tabIndex = '0',
  type
}) => {
  return (
    <Wrapper
      data-cy={dataCy}
      className={className}
      onClick={onChange}
      tabIndex={tabIndex}
      role={type}
      aria-checked={checked}
      aria-disabled={disabled}
      disabled={disabled}
      aria-label={ariaLabel}
      onKeyDown={e => {
        if (e.keyCode === 32) {
          e.preventDefault();
          onChange();
        }
      }}
    >
      <StyledIcon
        type={checked ? `${type}Checked` : type}
        size="2"
        color={color || theme.accentColor}
      />
      {label && (
        <StyledLabel data-cy={`${dataCy}-label`} disabled={disabled}>
          {label}
        </StyledLabel>
      )}
    </Wrapper>
  );
};

Toggle.propTypes = {
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  // className is used to force styled-components to pass styles down
  className: PropTypes.string,
  color: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired
};

export default Toggle;
