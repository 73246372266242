import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import { color } from '../../../styles';

import { locale } from '../../../helpers/locale';
import { updateProgram } from 'services';
import {
  Button,
  EditProgramModal,
  ProgramTableModal,
  NoTableData,
  Loading,
  StickyColumnTable
} from 'components';

const { blue, lightGreen, red } = color;

const Error = styled.div`
  text-align: center;
  margin: 2.5rem;
  color: ${red};
`;

const determineRowColor = ({ createdAt }) => {
  if (!!createdAt) {
    const now = moment();
    const programAgeInHours = now.diff(createdAt, 'hours');
    if (programAgeInHours < 24) {
      return lightGreen;
    }
  }

  return false;
};

const ProgramTable = props => {
  const {
    categoryOptions,
    fetchProgramData,
    hasManyInstitutions,
    institutionsById,
    isError,
    isLoading,
    modalHide,
    modals,
    modalShow,
    nation,
    programData,
    programTotal,
    renderColumns,
    setCategoryIds,
    setIsLoading,
    setSortColumn,
    sortColumn,
    subdomain,
    ...tableProps
  } = props;

  if (isError) {
    return (
      <Error>{`We are having trouble loading ${locale.edTrack[nation]}s, please try again later!`}</Error>
    );
  }

  if (!isLoading && (!programData || programData.length === 0)) {
    return <NoTableData statusType="" tableType="programs" isFiltered />;
  }

  return (
    <Loading loaded={!isLoading}>
      <StickyColumnTable
        columns={renderColumns({
          hasManyInstitutions,
          modalHide,
          modalShow,
          modals,
          categoryOptions,
          fetchProgramData,
          institutionsById,
          setIsLoading,
          nation
        })}
        categoryOptions={categoryOptions}
        dataCy="program-table"
        determineRowColor={determineRowColor}
        isLoading={isLoading}
        setSortColumn={setSortColumn}
        setCategoryIds={setCategoryIds}
        sortColumn={sortColumn}
        tableData={programData}
        totalRows={programTotal}
        {...tableProps}
      />
      {modals['add-program-modal'] && (
        <EditProgramModal
          isEdit={false}
          programData={{
            categories: [],
            credential: '',
            code: '',
            institutionId: hasManyInstitutions
              ? ''
              : institutionsById[Object.keys(institutionsById)[0]].ipeds,
            name: '',
            transfers: [],
            url: '',
            subdomain
          }}
          categoryOptions={categoryOptions}
          closeModal={() => modalHide('add-program-modal')}
          openAddedProgramModal={() => modalShow('added-program-modal')}
          fetchProgramData={fetchProgramData}
          hasManyInstitutions={hasManyInstitutions}
          institutionsById={institutionsById}
          name="add-program-modal"
          setIsLoading={setIsLoading}
          updateProgram={updateProgram}
        />
      )}
      {modals['added-program-modal'] && (
        <ProgramTableModal
          closeModal={() => modalHide('added-program-modal')}
          name="added-program-modal"
          headingText={`Add ${locale.edTrack[nation]}`}
          text={`The ${locale.edTrack[nation]} has been added and is live! Would you like to map careers to this ${locale.edTrack[nation]}?`}
          buttons={[
            <NavLink
              key="added-program-yes-button"
              to="/programs/career-mappings"
              onClick={() => {
                modalHide('added-program-modal');
              }}
            >
              <Button
                background={blue}
                border={`1px solid ${blue}`}
                data-cy="go-to-career-mapping-button"
              >
                Yes, take me to career mapping
              </Button>
            </NavLink>,
            <Button
              key="added-program-no-button"
              background="white"
              color={blue}
              border={`1px solid ${blue}`}
              onClick={() => modalHide('added-program-modal')}
              data-cy="close-added-program-modal-button"
            >
              No, I’m good
            </Button>
          ]}
        />
      )}
    </Loading>
  );
};

ProgramTable.propTypes = {
  categoryOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  fetchProgramData: PropTypes.func.isRequired,
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string,
  pageNumber: PropTypes.number,
  programData: PropTypes.array,
  programTotal: PropTypes.number,
  renderColumns: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setCategoryIds: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func,
  setSearchTerm: PropTypes.func,
  setSortColumn: PropTypes.func,
  sortColumn: PropTypes.string,
  subdomain: PropTypes.string
};

export default ProgramTable;
