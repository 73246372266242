import { Button } from 'components';
import styled from 'styled-components';
import theme from '../../../styles/theme';

/**
 * This button provides a text only, link style button element with a transparent
 * background for simple link-like buttons.
 */
const TextButton = styled(Button)`
  width: 97%;
  border: 0;
  padding: 0 !important;
  background: transparent !important;
  color: ${({ color }) => (!!color ? color : theme.linkColor)} !important;
  font-weight: ${({ fontWeight }) => (!!fontWeight ? fontWeight : '400')} !important;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;

  :hover {
    box-shadow: none !important;
    text-decoration: underline;
  }
  :focus {
    outline: 5px !important;
    outline-color: rgb(77, 144, 254) !important;
    outline-offset: -2px !important;
    outline-style: auto !important;
    box-shadow: none !important;
  }
`;

export default TextButton;
