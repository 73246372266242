import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { WidgetViewsChart } from 'components';
import { fetchKeenData } from '../../services';
import { keenWidgetLoads } from '../../services/keen/queries';
import { reduceTimeLabels } from '../../helpers/timeLabels';
import { selectSubdomain } from '../../selectors/subdomain';

class WidgetViewsChartContainer extends React.Component {
  static propTypes = {
    subdomain: PropTypes.string.isRequired,
    timeframe: PropTypes.object.isRequired
  };

  state = {
    data: {},
    heading: '0',
    loaded: false
  };

  // on initial load, grab the data from the currentSite in state
  componentDidMount() {
    if (Object.keys(this.props.timeframe).length > 0) {
      this.getData();
    }
    this.mounted = true;
  }
  // called when the site changes
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      /* eslint-disable react/no-did-update-set-state */
      this.getData();
      this.setState({ loaded: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return <WidgetViewsChart {...this.state} {...this.props} />;
  }

  async getData() {
    const userParams = {
      subdomain: this.props.subdomain,
      timeframe: this.props.timeframe
    };
    const res = await Promise.all([
      fetchKeenData({
        ...userParams,
        ...keenWidgetLoads
      })
    ]).then(([totalViews]) => {
      const totalViewData = totalViews.map(({ timeframe, value }) => ({
        // meta tag is used for Tooltip text in Chartist
        meta: `${moment(timeframe.start).format('MMM D, YYYY')}<br>Total Visits`,
        value
      }));
      // add up the total visits
      const total = totalViewData.reduce((sum, current) => {
        sum += current.value;
        return sum;
      }, 0);
      const labels = totalViews.map(item => {
        return item.timeframe.start;
      });
      return {
        data: {
          series: [totalViewData],
          labels
        },
        heading: new Intl.NumberFormat().format(total)
      };
    });
    // format the labels for the chart
    const { data } = reduceTimeLabels(res);
    if (this.mounted) {
      this.setState({
        data,
        heading: res.heading,
        loaded: true
      });
    }
  }
  mounted = false;
}

export const mapStateToProps = ({ profile, sites, stats }) => {
  return {
    // figure out which default site to query the keen data for the client's subdomain or the default `testsite` one
    subdomain: selectSubdomain(sites, profile),
    timeframe: stats
  };
};

export default connect(mapStateToProps)(WidgetViewsChartContainer);
