export const getDateFromISO = (isoString, nation) => {
  const date = new Date(isoString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (nation === 'uk') {
    return `${day}/${month}/${year}`;
  }
  return `${month}/${day}/${year}`;
};
