import config from '../../config';
import { flashError } from '../../helpers/flashmessage';
import { formatRequest, get, post } from '../utils';

const geoId = {
  us: '0',
  ca: '01',
  uk: 'K02000001'
};

export const fetchAllCareers = async ({ nation = 'us', hasLot = false }) => {
  const datarun = config[`${nation}DataRun`].replace('.', '_');
  try {
    return await get(
      `/api/cc-api/careers/${nation}/${hasLot ? 'lot/' : ''}nation/${
        geoId[nation]
      }?fields=title,title-slug&datarun=${datarun}`
    );
  } catch (apiError) {
    flashError('Sorry, we are unable to load career options at this point');
  }
};

/**
 * Attempt to fetch a career's short-title. It expects a comma-separated string of onet/noc/soc codes.
 * Catch any error by displaying a flash error message.
 */
export const fetchCareerTitles = (careerIds, nation = 'us', hasLot = false) => {
  const datarun = config[`${nation}DataRun`].replace('.', '_');
  const careerIdTypes = {
    us: 'onets',
    ca: 'nocs',
    uk: 'socs'
  };
  const ids =
    careerIds !== 'all' ? `&${hasLot ? 'lot-ids' : careerIdTypes[nation]}=${careerIds}` : '';

  return get(
    `/api/cc-api/careers/${nation}/${hasLot ? 'lot/' : ''}nation/${
      geoId[nation]
    }?fields=title,singular-title,title-slug${ids}&datarun=${datarun}`
  ).catch(() => {
    flashError('Sorry, we are unable to load top career matches at this point.');
  });
};

export const fetchCareerSlugsByPost = async (careerIds, nation = 'us', hasLot = false) => {
  const datarun = config[`${nation}DataRun`].replace('.', '_');
  try {
    const response = await post(
      `/api/cc-api/careers/${nation}/${
        hasLot ? 'lot/' : ''
      }nation?fields=title-slug&datarun=${datarun}`,
      {
        geoids: [geoId[nation]],
        'career-codes': careerIds
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const searchCareers = async ({ query, nation = 'us', hasLot = false }) => {
  try {
    const response = await get(
      `/api/cc-api/careers/${nation}/${hasLot ? 'lot/' : ''}search?query=${query}&fields=*&limit=20`
    );
    const responseArray = Object.values(response);

    return responseArray.map(career => career);
  } catch (error) {
    throw error;
  }
};
 