import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';

import { Card, Header, Select, TabButton } from 'components';

const StyledCollapse = styled(Collapse)`
  margin-bottom: 1rem;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  > :first-child {
    margin: 0;
  }

  select {
    padding: 1rem 2rem 1rem 1rem;
  }

  @media (min-width: 560px) {
    > :first-child {
      flex-grow: 0;
      margin: 0;
    }
  }
`;

const StyledHeader = styled(Header)`
  flex-grow: 1;
  margin-bottom: 1rem !important;
  text-align: center;
  @media (min-width: 560px) {
    text-align: left;
  }
   transition: width 2s, height 2s, background-color 2s, transform 2s;
`;

const StyledCard = styled(Card)`
  min-height: 33rem;
  padding-bottom: 0;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 560px) {
    margin-bottom: 0;
    width: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class TabCard extends React.Component {
  state = { selectedTabIndex: 0 };

  /**
   * renders the tab buttons using the labels from the tabs prop
   * @method renderButtons
   * @param  {array} tabs an array of objects each with a label (string) and renderData (function) property and optionally showSelect (boolean) that determines whether the select box should be rendered for that tab and properties selectOnChange (the onChange function for the select input) and selectOptions (the options passed to the select)
   * @param  {number} selectedTabIndex index of the selected tab within the tabs array
   * @return {array} returns array of buttons
   */
  renderButtons = (tabs, selectedTabIndex) => {
    if (tabs.length > 1) {
      return tabs.map((tab, i) => {
        return (
          <TabButton
            key={i}
            selected={selectedTabIndex === i}
            aria-label={
              selectedTabIndex === i ? `${tab.label}, is selected` : `${tab.label}, is unselected`
            }
            isFirst={i === 0}
            isLast={i === tabs.length - 1}
            onClick={() => this.setState({ selectedTabIndex: i })}
          >
            {tab.label}
          </TabButton>
        );
      });
    }
  };

  render() {
    const {
      tabs,
      hasSelect,
      heading,
      selectOnChange,
      selectOptions,
      selectedInstitution
    } = this.props;
    const { selectedTabIndex } = this.state;
    return (
      <StyledCard>
        <Content>
          <StyledHeader component="h2" type="subtitle" bold>
            {heading}
          </StyledHeader>
          <ButtonWrapper>{this.renderButtons(tabs, selectedTabIndex)}</ButtonWrapper>
        </Content>
        <StyledCollapse isOpened={!!tabs[selectedTabIndex].showSelect}>
          <SelectWrapper>
            {hasSelect && (
              <Select
                onChange={selectOnChange}
                options={selectOptions}
                selection={selectedInstitution}
              />
            )}
          </SelectWrapper>
        </StyledCollapse>
        {tabs[selectedTabIndex].renderData()}
      </StyledCard>
    );
  }
}

TabCard.propTypes = {
  hasSelect: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  selectedInstitution: PropTypes.string,
  selectOnChange: PropTypes.func,
  selectOptions: PropTypes.array
};

export default TabCard;
