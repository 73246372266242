import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles';
import { Icon, Input } from 'components';

const Wrapper = styled.div`
  position: relative;
  width: 350px;
  margin-right: 2rem;
`;

const StyledInput = styled(Input)`
  padding: 1rem 1rem 1rem 3.5rem !important;

  &::placeholder {
    color: ${color.darkGray};
  }
`;

const SearchIcon = styled(Icon)`
  color: ${color.darkGray};
  position: absolute;
  left: 1rem;
  top: 10px;
  z-index: 2;
  pointer-events: none;
`;

const SearchInput = props => (
  <Wrapper className={props.className}>
    <SearchIcon size="2" type="search" hasHover={false} />
    <StyledInput {...props} />
  </Wrapper>
);

export default SearchInput;
