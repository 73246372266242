import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { FormInput, FormLayout, FormRadio } from 'components';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';

const PreventCollapsedMargins = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionHeader = styled.h3`
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
`;

const Rows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const StyledFormInput = styled(FormInput)`
  width: 100%;

  @media (min-width: 800px) {
    width: 48%;
  }
`;

const ProgramSettings = withNationHOC(
  ({ handleSubmit, nation, submitting, pristine, saveProgramSettings, reset }) => {
    return (
      <FormLayout
        title={`${locale.edTrack[nation]} Settings`}
        submitForm={handleSubmit(saveProgramSettings)}
        submitting={submitting}
        pristine={pristine}
        reset={reset}
      >
        <PreventCollapsedMargins>
          <Section>
            <SectionHeader>{`General ${locale.edTrack[nation]} Settings`}</SectionHeader>
            <Field
              label={`${locale.edTrack[nation]} Alias`}
              name="programAlias"
              component={FormInput}
              type="text"
            />
            <Field
              label={`${locale.edTrack[nation]} Location Filter Enabled`}
              name="hasProgramFilter"
              component={FormRadio}
            />
            <Field
              label={`${locale.edTrack[nation]} Location Label`}
              name="programFilterLabel"
              component={FormInput}
              type="text"
            />
          </Section>
          <Section>
            <SectionHeader>Institution Buttons</SectionHeader>
            <Field
              label={`${locale.edTrack[nation]} Button Text (First Button)`}
              name="programInfoButtonText"
              component={FormInput}
              type="text"
              maxLength="25"
              placeholder={`${locale.edTrack[nation]} Overview`}
            />
            <Rows>
              <Row>
                <Field
                  label="Tuition Info Button Text"
                  name="tuitionInfoButtonText"
                  component={StyledFormInput}
                  type="text"
                  maxLength="25"
                  placeholder="Tuition Information"
                />
                <Field
                  label="Tuition Info Button Link"
                  name="tuitionLink"
                  component={StyledFormInput}
                  type="text"
                  placeholder="http://yourcollege.edu/tuition"
                />
              </Row>
              <Row>
                <Field
                  label="Admission Info Button Text"
                  name="admissionInfoButtonText"
                  component={StyledFormInput}
                  type="text"
                  maxLength="25"
                  placeholder="Apply Now"
                />
                <Field
                  label="Admission Info Button Link"
                  name="admissionsLink"
                  component={StyledFormInput}
                  type="text"
                  placeholder="http://yourcollege.edu/admissions"
                />
              </Row>
            </Rows>
          </Section>
          <SectionHeader>No Related-Careers Options</SectionHeader>
          <Field label="Description Text" name="noCareersText" component={FormInput} type="text" />
        </PreventCollapsedMargins>
      </FormLayout>
    );
  }
);

ProgramSettings.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  patchSite: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveProgramSettings: PropTypes.func.isRequired
};

export const validate = values => {
  const errors = {};

  if (!values) return errors;

  if (!values.programAlias) errors.programAlias = true;

  return errors;
};

const ProgramSettingsForm = reduxForm({
  form: 'programSettingsForm',
  enableReinitialize: true,
  validate
})(ProgramSettings);

export default ProgramSettingsForm;
