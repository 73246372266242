import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Prompt } from 'react-router';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { isRequired } from '../../../helpers/validation';
import { EmployerWhitelist } from 'containers';
import { Button, Float, Header, JobTypeForm, Line, Margin } from 'components';

const StyledButton = styled(Button)`
  width: 14rem;
  height: 4rem;
  padding: 0;
`;

const EmployerSettingsForm = ({
  defaultJobTypes,
  dirty,
  handleSubmit,
  initialValues,
  subdomain,
  patchSiteRequest,
  whitelistEmployer
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  return (
    <form
      onSubmit={handleSubmit(({ jobTypes, employerWhitelist }) => {
        let jobTypeObjects = jobTypes.map(jobType => {
          return {
            name: jobType
          };
        });
        patchSiteRequest({
          subdomain,
          employers: { jobTypes: jobTypeObjects },
          employerWhitelist
        });
        // if we are adding any new employers, then they need to go through additional steps, e.g. update their status to `approved`
        employerWhitelist.forEach(employerEmail => {
          if (!initialValues.employerWhitelist.includes(employerEmail)) {
            whitelistEmployer({ employerEmail, subdomain });
          }
        });
      })}
    >
      <Prompt
        when={dirty}
        message="Any unsaved changes you have made to your employer portal settings will be lost if you leave this page. Do you want to continue?"
      />
      <Margin value="0 0 2rem 0">
        <Header component="h2" type="title">
          Posting Settings
        </Header>
      </Margin>
      <Field
        component={JobTypeForm}
        name="jobTypes"
        options={defaultJobTypes}
        setIsSubmitDisabled={setIsSubmitDisabled}
        validate={isRequired}
      />
      <Line />
      <Margin value="2rem 0 0 0">
        <Header component="h2" type="title">
          Whitelisted Users
        </Header>
      </Margin>
      These users may create an Employer Portal account and post jobs without approval.
      <FieldArray component={EmployerWhitelist} name="employerWhitelist" />
      <Margin value="1rem 0 6rem 0">
        <Float component="span" value="left">
          <StyledButton disabled={!dirty || isSubmitDisabled} type="submit" id="save-button">
            Save Changes
          </StyledButton>
        </Float>
      </Margin>
    </form>
  );
};

EmployerSettingsForm.propTypes = {
  defaultJobTypes: PropTypes.array.isRequired,
  // redux form prop
  dirty: PropTypes.bool.isRequired,
  // redux form prop
  handleSubmit: PropTypes.func.isRequired,
  // redux form prop
  initialValues: PropTypes.object.isRequired,
  patchSiteRequest: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired,
  whitelistEmployer: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'EmployerSettingsForm',
  enableReinitialize: true
})(EmployerSettingsForm);
