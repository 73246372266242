import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const StyledPageHeader = styled.div`
  @media (min-width: 800px) {
    padding: 1rem 0 0 0;
  }
  color: ${theme.menuColor};
`;

const StyledHeader = styled(Header)`
  margin-bottom: 2rem;
  font-size: 3rem !important;
  font-weight: 600;
`;

const PageHeader = ({ children }) => {
  return (
    <StyledPageHeader>
      <StyledHeader type="display">{children}</StyledHeader>
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageHeader;
