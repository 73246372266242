import moment from 'moment';
import { locale } from '../../../helpers/locale';
import { objectifyArray } from '../../../helpers/objectify-array';

const returnResultOrZero = datum => (datum[0] ? datum[0].result : 0);

const formatIsoTimestamp = (timestamp, nation) => moment(timestamp).format(locale.date[nation]);

export const formatAssessmentWidgetCareerClicksByCareerData = clicksByCareer => {
  return clicksByCareer.map(({ careerTitle, result }) => {
    return {
      career: careerTitle,
      clicks: result
    };
  });
};

export const formatAssessmentWidgetProgramClicksData = clicksByProgram => {
  return clicksByProgram.map(({ programName, programCredential, result }) => {
    return {
      program: `${programName}, ${programCredential}`,
      clicks: result
    };
  });
};

const calculateTotalForTimeframe = data => {
  const newTotal = data.reduce((accumulator, currentDatum) => {
    if (currentDatum.value && currentDatum.value[0] && currentDatum.value[0].result) {
      return accumulator + currentDatum.value[0].result;
    } else return accumulator;
  }, 0);
  return newTotal;
};

export const formatAssessmentWidgetUsageData = data => [
  {
    title: '' /* just for spacing */,
    result: ''
  },
  {
    title: 'Total Started',
    result: returnResultOrZero(data['assessment-widget-total-starts'])
  },
  {
    title: 'Total Completed',
    result: returnResultOrZero(data['assessment-widget-total-completions'])
  },
  {
    title: '% Completed',
    result: `${Math.round(
      (returnResultOrZero(data['assessment-widget-total-completions']) /
        returnResultOrZero(data['assessment-widget-total-starts'])) *
        100
    )}%`
  },
  {
    title: 'Total Downloads',
    result: calculateTotalForTimeframe(data['assessment-widget-downloads'])
  },
  {
    title: 'Total Program Page Clicks',
    result: returnResultOrZero(data['assessment-widget-total-program-link-clicks'])
  },
  {
    title: 'Total Career Coach Clicks',
    result: calculateTotalForTimeframe(data['assessment-widget-career-link-clicks'])
  }
];

export const formatDailyUsageData = (data, nation) => {
  const dataKeys = Object.keys(data);
  const dataLength = data[dataKeys[0]].length;
  let newDailyUsageData = [];

  for (let index = 0; index < dataLength; index++) {
    const newDailyUsageItem = {};
    for (let jndex = 0; jndex < dataKeys.length; jndex++) {
      const currentKey = dataKeys[jndex];

      newDailyUsageItem[currentKey] = returnResultOrZero(data[currentKey][index].value);
    }
    newDailyUsageItem.day = formatIsoTimestamp(data[dataKeys[0]][index].timeframe.start, nation);
    newDailyUsageData.push(newDailyUsageItem);
  }
  return newDailyUsageData;
};

export const formatProgramWidgetCareerCoachClicksData = ({ careerCoachClicks, careerData }) => {
  const careerDataArray = Object.values(careerData).map(currentDatum => currentDatum);
  const careerTitlesBySlug = objectifyArray(careerDataArray, 'titleSlug', 'title');
  return careerCoachClicks.map(click => {
    const currentSlug = click.careerCoachUrl.split('careers/')[1];
    return {
      career: careerTitlesBySlug[currentSlug] || currentSlug,
      careerCoachClicks: click.result
    };
  });
};

export const formatProgramWidgetDailyUsageByProgramData = ({
  loads,
  clicks,
  visitButtonClicks,
  programData
}) => {
  const programs = loads.map(event => event.programs);

  const programDataArray = Object.values(programData).map(({ code, name, credential }) => ({
    code,
    title: `${name} - ${credential}`
  }));
  const programTitlesByCode = objectifyArray(programDataArray, 'code', 'title');

  const clicksDataObject = objectifyArray(clicks, 'programs', 'result');
  const visitButtonClicksDataObject = objectifyArray(visitButtonClicks, 'programs', 'result');
  const loadsDataObject = objectifyArray(loads, 'programs', 'result');

  const newFormattedData = [];
  for (let index = 0; index < programs.length; index++) {
    const currentCodes = programs[index].split(',');
    const currentProgramTitles = currentCodes
      .map(currentCode => programTitlesByCode[currentCode] || currentCode)
      .join(', ');
    const newDatum = {
      program: currentProgramTitles,
      loads: loadsDataObject[currentCodes] || 0,
      clicks: clicksDataObject[currentCodes] || 0,
      visitButtonClicks: visitButtonClicksDataObject[currentCodes] || 0
    };
    newFormattedData.push(newDatum);
  }

  return newFormattedData;
};

export const formatProgramWidgetUsageData = data => [
  {
    title: '' /* just for spacing */,
    result: ''
  },
  {
    title: 'Total View Count',
    result: returnResultOrZero(data['widget-total-loads'])
  },
  {
    title: 'Count of Clicks',
    result: returnResultOrZero(data['widget-total-clicks'])
  },
  {
    title: 'Count of Button Clicks',
    result: returnResultOrZero(data['widget-total-visit-button-clicks'])
  }
];
