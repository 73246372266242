import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchInstitutions } from '../../services';
import {
  keenTopCareerFavorites,
  keenTopProgramFavorites
} from '../../services/keen/queries';
import { TabCard, TabBody } from 'components';
import { FetchKeen } from 'containers';
import { selectSubdomain } from '../../selectors/subdomain';
import { locale } from '../../helpers/locale';
import { withNationHOC } from '../../hocs/withNation';

const defaultOptions = {
  options: [
    {
      label: 'All Institutions',
      value: 'All Institutions'
    }
  ]
};

class TopFavoritesStats extends React.Component {
  state = {
    ...defaultOptions,
    loaded: false,
    selectedInstitution: 'All Institutions'
  };

  componentDidMount() {
    this.getData(this.props);
  }

  render() {
    const { selectedInstitution } = this.state;
    const { hasPrograms, hasManyInstitutions, nation } = this.props;
    const programStatsTab = {
      label: `${locale.edTrack[nation]}s`,
      showSelect: true,
      renderData: () => {
        return (
          <FetchKeen
            {...keenTopProgramFavorites}
            extraFilters={
              selectedInstitution !== 'All Institutions'
                ? [
                  ...keenTopProgramFavorites.extraFilters,
                  {
                    operator: 'eq',
                    property_name: 'institution.displayName',
                    property_value: selectedInstitution
                  }
                ]
                : keenTopProgramFavorites.extraFilters
            }
          >
            {props => <TabBody {...props} />}
          </FetchKeen>
        );
      }
    };

    const careerStatsTab = {
      label: 'Careers',
      showSelect: false,
      renderData: () => {
        return (
          <FetchKeen {...keenTopCareerFavorites}>
            {props => <TabBody {...props} />}
          </FetchKeen>
        );
      }
    };

    // if the site does not have programs the programs/careers tab button will not appear,
    // so the heading is different in that case so the user knows they are looking at career stats

    // the TabCard is expecting a heading and an array of objects each with a label (string) to be used in the tab button and renderData (function) property to render the stats and optionally showSelect (boolean) that determines whether the select box should be rendered for that tab
    return (
      <TabCard
        heading={
          hasPrograms
            ? `Top ${locale.spellings.favorite[nation]}d`
            : `Top ${locale.spellings.favorite[nation]}d Careers`
        }
        tabs={
          hasPrograms ? [careerStatsTab, programStatsTab] : [careerStatsTab]
        }
        selectOnChange={this.handleInstitutionChange}
        selectOptions={this.state.options}
        hasSelect={hasPrograms && hasManyInstitutions}
        selectedInstitution={selectedInstitution}
      />
    );
  }

  handleInstitutionChange = e => {
    this.setState({ selectedInstitution: e.target.value });
  };

  /**
   * calls the institutions api
   * @method getData
   * @param  {object} props (subdomain, hasManyInstitutions, hasPrograms) passed to and destructured by the function
   * @return {function} sets up the select options to pass down to the select component
   */
  getData = ({ subdomain, hasManyInstitutions, hasPrograms }) => {
    // check here to reduce unnecessary API calls
    if (hasPrograms && hasManyInstitutions) {
      fetchInstitutions(subdomain).then(res =>
        this.setState(state => ({
          // take the previous state which is our default options for `All Institutions`
          // and combine with the response options coming from the API
          options: [
            ...state.options,
            ...Object.values(res).map(item => ({
              label: item.displayName,
              value: item.displayName
            }))
          ],
          loaded: true
        }))
      );
    } else {
      return null;
    }
  };
}

export const mapStateToProps = ({ profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    hasPrograms: sites.items[currentSite].hasPrograms,
    hasManyInstitutions: sites.items[currentSite].hasManyInstitutions,
    subdomain: currentSite
  };
};

TopFavoritesStats.propTypes = {
  hasPrograms: PropTypes.bool.isRequired,
  hasManyInstitutions: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default withNationHOC(connect(mapStateToProps)(TopFavoritesStats));
