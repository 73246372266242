import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BoldLabel, Input } from 'components';
import { color } from '../../../styles';

const Wrapper = styled.div`
  margin: 4rem 0;
  position: relative;
`;

const Error = styled.span`
  color: ${color.red};
  position: absolute;
  right: 0px;
  top: 100%;
`;
// this is a class because react autosuggest needs to access the `ref` of this input
// and you can't access refs in stateless components
class SelectInput extends React.Component {
  render() {
    const {
      disabled,
      input,
      label,
      meta: { error, active, dirty, submitFailed },
      name,
      onChange,
      placeholder,
      type = 'text',
      ...props
    } = this.props;

    const hasError = (error && active && dirty) || submitFailed;
    return (
      <Wrapper>
        <BoldLabel name={name} label={label} />
        <Input
          error={hasError ? error : undefined}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          {...input}
          {...props}
        />
        {hasError && <Error>{error}</Error>}
      </Wrapper>
    );
  }
}

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

export default SelectInput;
