import camelCase from 'camelcase-keys-deep';
import dasherize from 'dasherize';

import { flashSuccess, flashError } from '../../helpers/flashmessage';
import { patch, post, formatRequest, formatPostingAnalyticsData } from '../utils';
import { getCognitoToken } from '../';

export const fetchPostings = async subdomain => {
  let shouldKeepGoing = true;
  let postings = [];
  let offset = 0;

  while (shouldKeepGoing) {
    const response = await fetchPostingsBatch({ subdomain, offset });
    const { data } = await response.json();
    const newBatch = camelCase(data).map(({ attributes }) => attributes);

    if (newBatch.length > 0) {
      postings = [...postings, ...newBatch];
      offset += 99;
    } else {
      shouldKeepGoing = false;
    }
  }
  return postings;
};

const fetchPostingsBatch = async ({ subdomain, offset }) => {
  try {
    const response = await fetch(`/api/cc-api/postings/${subdomain}?limit=99&skip=${offset}`, {
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      })
    });
    return response;
  } catch (error) {
    flashError(`Sorry, we are unable to load all of ${subdomain}'s postings data at this point.`);
  }
};

export const fetchPostingAnalytics = async ({ subdomain, timeframe }) => {
  try {
    const postings = fetchPostings(subdomain);
    return formatPostingAnalyticsData({ postings, timeframe });
  } catch (error) {
    flashError(`Sorry, we are unable to load ${subdomain}'s posting analytics data at this point.`);
  }
};

export const createPosting = async (subdomain, data) => {
  return post(
    `/api/cc-api/postings/${subdomain}`,
    formatRequest(data, 'postings'),
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  )
    .then(res => {
      flashSuccess('Posting Created!');
      return res;
    })
    .catch(() => {
      flashError('Sorry, we are unable to create a posting at the moment.');
    });
};

export const updatePosting = async (subdomain, id, data) => {
  return patch(
    `/api/cc-api/postings/${subdomain}/${id}`,
    formatRequest(data, 'postings'),
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  )
    .then(res => {
      flashSuccess('Posting Status Updated!');
      return res;
    })
    .catch(() => {
      flashError('Sorry, we are unable to update a posting at this point.');
    });
};

export const updatePostings = async (subdomain, ids, data) =>
  patch(
    `/api/cc-api/postings/${subdomain}`,
    dasherize({
      data: {
        postingIds: ids,
        attributes: data
      }
    }),
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );
