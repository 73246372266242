import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Strong, Hint } from 'components';

const Wrapper = styled.div`
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-weight: 700;
`;

const Metric = ({ heading, data, loaded, hintId = '', hintMessage = '' }) => {
  return (
    <Card>
      <Wrapper>
        <Content>
          <Title component="h2" type="subtitle" bold>
            {heading}
          </Title>
          {hintMessage.length > 0 && <Hint id={hintId}>{hintMessage}</Hint>}
        </Content>
        {!loaded && <Text type="body">Loading...</Text>}
        {loaded && <Strong>{data}</Strong>}
      </Wrapper>
    </Card>
  );
};

Metric.propTypes = {
  heading: PropTypes.string.isRequired,
  hintMessage: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  loaded: PropTypes.bool,
  hintId: PropTypes.string
};

export default Metric;