import React from 'react';

import { DisclaimersUpload, LogoUpload, Margin, ProgramUpload } from 'components';

const UploadSettings = props => {
  return (
    <Margin value="3rem 0 0 0">
      <LogoUpload {...props} />
      <DisclaimersUpload {...props} />
      <ProgramUpload {...props} />
    </Margin>
  );
};

export default UploadSettings;
