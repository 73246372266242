import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { generateAssessmentEmbedCode } from '../../../helpers/widget-embed-code-utils';
import { WidgetBuilderEmbedCode } from 'components';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 57rem;
  padding: 3.5rem;
  padding-top: 1rem;
  background: white;
  overflow-y: scroll;
`;

const AssessmentWidgetBuilderEmbed = ({ clientId, configurations }) => (
  <Wrapper>
    <WidgetBuilderEmbedCode
      id="embed-code-assessment"
      embedCode={generateAssessmentEmbedCode({ clientId, configurations })}
    />
  </Wrapper>
);

AssessmentWidgetBuilderEmbed.propTypes = {
  clientId: PropTypes.string.isRequired,
  configurations: PropTypes.object.isRequired
};

export default AssessmentWidgetBuilderEmbed;
