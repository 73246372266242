import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { AddSiteForm } from 'components';
import { formatAreas } from '../../helpers/format-areas';
import { createSiteRequest } from '../../actions/actionCreators';
import { selectGeoLevels } from '../../helpers/selectGeoLevels';
import { validateSubdomainAliases } from '../../helpers/state-validation';

// HOCS
const enhance = compose(
  connect(
    state => {
      const formSelector = formValueSelector('addSiteForm');
      return {
        employerPortal: formSelector(state, 'employerPortal'),
        employerNotificationEmail: formSelector(state, 'employerNotificationEmail'),
        hasJobPostings: formSelector(state, 'hasJobPostings'),
        /* the initialValues object is used in redux form to map the defaults to the form inputs */
        initialValues: {
          assessmentLengths: ['short', 'long'],
          areUnmappedCareersHiddenByDefault: false,
          areas: [
            {
              activeRadiusIndex: 0,
              center: {
                centroid: {}
              },
              distanceUnit: 'miles',
              geoids: [],
              name: '',
              radiusInput: '',
              radiusRegions: []
            }
          ],
          employerNotificationEmail: '',
          employerPortal: false,
          enabled: true,
          googleAnalyticsId: '',
          hasEoeEnabled: false,
          hasJobPostings: true,
          hasManyInstitutions: false,
          hasPrograms: true,
          hasLot: false,
          hasSimilarCareers: false,
          isHighSchoolLevelFilterOn: false,
          loginlessResumeBuilder: false,
          militarySearch: false,
          nation: 'us',
          organization: {
            label: ''
          },
          relatedCareersSort: 'Custom',
          requestEmail: '',
          requiresAuth: false,
          requiresResumeApproval: false,
          resumeBuilder: true,
          selectableProfileSchools: [],
          studentIdEnabled: false,
          subdomainAliases: [],
          typicalEdEnabled: true
        },
        nation: formSelector(state, 'nation'),
        selectLevels: selectGeoLevels(formSelector(state, 'nation')),
        subdomainAliases: formSelector(state, 'subdomainAliases'),
        studentIdEnabled: formSelector(state, 'studentIdEnabled'),
        sites: state.sites
      };
    },
    {
      createSiteRequest
    }
  ),
  reduxForm({
    form: 'addSiteForm',
    validate: (values, state) => {
      const errors = validateSubdomainAliases(values, state);

      if (state.sites.items[values.subdomain]) {
        errors.subdomain = `${values.subdomain} subdomain is already taken. Please try something else.`;
      }

      return errors;
    },
    shouldValidate: params => !params.initialRender
  })
);

class AddSiteFormContainer extends React.Component {
  static propTypes = {
    createSiteRequest: PropTypes.func.isRequired,
    currentSite: PropTypes.string,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    nation: PropTypes.string,
    patchSite: PropTypes.func,
    sites: PropTypes.shape({ created: PropTypes.bool.isRequired }).isRequired
  };

  componentDidUpdate({ sites }) {
    if (!sites.created && this.props.sites.created) {
      this.props.history.push('/settings/general');
    }
  }

  render() {
    return <AddSiteForm {...this.props} createSite={this.createSite} />;
  }
  /**
   * sends off an action with all of data from the add site formatAreas
   * @method createSite
   * @param  {object}   organization the selected org from the select box
   * @param  {array}    areas        the selected geo areas, id and name
   * @param  {string}   level        the selected level for the areas, State, MSA, County, Zip
   * @param  {object}   rest         the rest of the properties from the add site form
   * @return {function}              returns an function call to send off the createSiteRequest action with the payload
   */
  createSite = ({ organization, areas, ...rest }) => {
    this.props.createSiteRequest({
      ...rest,
      organization: organization.value,
      areas: formatAreas(areas).map(area => ({
        ...area,
        distanceUnit: this.props.nation === 'us' ? 'miles' : 'kilometers'
      }))
    });
  };
}
export { AddSiteFormContainer as PureAddSiteFormContainer }; // pure component. Used in tests

export default enhance(withRouter(AddSiteFormContainer));
