import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import { fetchProgramLogs } from '../../../services';
import { Loading, SearchInput, StickyColumnTable } from 'components';

const { mediumGray, lightGray } = color;

const Wrapper = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
`;

const TopPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: ${lightGray};
  border: 1px solid ${mediumGray};
  border-radius: 4px;
`;

const modifyLogData = ({ hasManyInstitutions, institutionsById, logs, nation }) => {
  return logs.map(log => {
    const modifiedLog = { ...log };

    if (log.method) {
      if (log.method === 'POST') {
        modifiedLog.activity = `New ${locale.edTrack[nation]} added`;
      } else if (log.method === 'DELETE') {
        modifiedLog.activity = `${locale.edTrack[nation]} was removed`;
      }
    }

    if (log.hasOwnProperty('diff')) {
      modifiedLog.stringifiedDiff = JSON.stringify(log.diff);
      if (log.diff.hasOwnProperty('careers')) {
        modifiedLog.activity = 'Career mapping edited';
      } else {
        modifiedLog.activity = `${locale.edTrack[nation]} details edited`;
      }
    }

    if (log.createdAt) {
      modifiedLog.createdAt = moment(log.createdAt).format('MM/DD/YYYY hh:mm A');
    }
    if (hasManyInstitutions) {
      const { institutionId } = log;
      if (institutionId) {
        const institutionKey = `${log.subdomain}-${institutionId}`;
        const institutionName = institutionsById[institutionKey].displayName;
        modifiedLog.institutionName = institutionName;
      }
    }
    return modifiedLog;
  });
};

const ProgramLogTable = ({
  hasManyInstitutions,
  institutionsById,
  isDeveloper,
  nation,
  renderColumns,
  subdomain,
  tableMaxWidth
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [programLogData, setProgramLogData] = useState([]);
  const [programLogTotal, setProgramLogTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('-createdAt');

  const rowsPerPage = 10;
  let limit;

  const fetchLogData = async () => {
    try {
      const programLogsResponse = await fetchProgramLogs({
        limit: rowsPerPage,
        offset: (pageNumber - 1) * rowsPerPage,
        search: searchTerm,
        subdomain,
        sort: sortColumn
      });
      const { logs, count } = programLogsResponse;
      setProgramLogTotal(count);
      const tableData = modifyLogData({ hasManyInstitutions, institutionsById, logs, nation });
      setProgramLogData(tableData);
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLogData();
  }, [pageNumber, searchTerm, sortColumn]);

  return (
    <Wrapper maxWidth={tableMaxWidth}>
      <TopPanel>
        <SearchInput
          placeholder={`${locale.edTrack[nation]} Log Search`}
          onChange={({ target: { value } }) => {
            setSearchTerm(value);
          }}
          aria-label={`Search ${locale.edTrack.lower[nation]}`}
          value={searchTerm}
        />
      </TopPanel>
      <Loading loaded={!isLoading}>
        <StickyColumnTable
          columns={renderColumns({ hasManyInstitutions, isDeveloper, nation })}
          dataCy="program-log"
          isError={isError}
          isLoading={isLoading}
          limit={limit}
          pageNumber={pageNumber}
          programLogTotal={programLogTotal}
          rowsPerPage={rowsPerPage}
          searchTerm={searchTerm}
          setPageNumber={setPageNumber}
          tableData={programLogData}
          tableMaxWidth={tableMaxWidth}
          totalRows={programLogTotal}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
        />
      </Loading>
    </Wrapper>
  );
};

ProgramLogTable.propTypes = {
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isDeveloper: PropTypes.bool,
  nation: PropTypes.string,
  subdomain: PropTypes.string,
  renderColumns: PropTypes.func,
  tableMaxWidth: PropTypes.string
};

export default ProgramLogTable;
