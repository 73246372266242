import is from 'is_js';
import moment from 'moment';

export const isRequired = (value = '') =>
  // if the value is an empty string or an empty object
  ((value && !value.length > 0 && is.string(value)) || is.not.existy(value) || is.empty(value)) &&
  'Required';

export const isEmail = value => !!value && !is.email(value) && 'Invalid email address';

export const isNumber = value => value && !Number.isInteger(Number(value)) && 'Invalid number';

export const isGaId = value =>
  value &&
  !/(^ua-\d{4,9}-\d{1,4}$)|(^g-[A-Za-z0-9]{10}$)/i.test(value.toString()) &&
  'Invalid Google Analytics ID (proper format: UA-xxxx-yy or G-111222xxyy)';

const maxAmount = max => value => Number(value) > max && `Max value: ${max}`;
const jobRadiusMax = 100;
const geoRadiusMax = 200;
export const overJobRadMax = maxAmount(jobRadiusMax);
export const overGeoRadMax = maxAmount(geoRadiusMax);

export const isPassword = value =>
  !/^(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) &&
  '8 characters, one number, upper and lower, no whitespace';

export const isMatching = (value, { password, confirmPassword }) =>
  password !== confirmPassword && "Passwords Don't Match";

export const isExpiredDate = value =>
  moment(value).isBefore(moment()) && 'Date must be after today';

export const isSubdomain = value =>
  value && !value.match(/^[a-zA-Z0-9-]+$/) && 'Alphanumeric and hyphens only';

export const isUrl = value =>
  !!value && !value.match(/^(http|https).*$/) && 'Must be valid URL prepended with http or https';

export const areSubdomains = value => {
  let result;

  for (let index = 0; index < value.length; index++) {
    const currentValue = value[index];
    if (isSubdomain(currentValue) !== false) {
      result = 'Subdomain aliases may only be alphanumeric characters and hyphens.';
    }
  }
  return result;
};

export const correlatingFieldCheck = name => (value, allValues) => {
  // The following split/reduce is necessary for form fields in dot notion. (Example, the career
  // settings form fields.) If we get a name as a string in dot notation, split it on the dot,
  // and then rejoin each "key" with brackets on to the original "allValues" object, and voila!
  // Bracket notation property access.
  const correlatedFieldValue = name
    .split('.')
    .reduce((accumulatedObject, newBracketKey) => accumulatedObject[newBracketKey], allValues);
  return !value && correlatedFieldValue && 'Required';
};
