import * as types from '../actions/constants';

const defaultState = {
  analytics: {
    isLoading: false
  },
  fetching: false,
  loaded: false,
  error: false,
  items: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case '@ReduxToastr/toastr/REMOVE':
      return {
        ...state,
        created: false,
        updated: false
      };
    case types.CREATE_POSTING_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.postingId]: {
            ...action.payload
          }
        },
        fetching: false,
        created: true,
        error: false
      };

    case types.FETCH_POSTINGS_REQUEST:
      return {
        ...state,
        fetching: true,
        loaded: false,
        error: false
      };
    case types.FETCH_POSTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...action.payload
        },
        fetching: false,
        loaded: true,
        error: false
      };

    case types.FETCH_POSTING_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          isLoading: true
        },
        fetching: true,
        loaded: false,
        error: false
      };
    case types.FETCH_POSTING_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...action.payload,
          isLoading: false
        },
        fetching: false,
        loaded: true,
        error: false
      };

    case types.UPDATE_POSTINGS_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.payload
        },
        fetching: false,
        loaded: true,
        error: false
      };
    case types.UPDATE_POSTING_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.postingId]: {
            ...action.payload
          }
        },
        fetching: false,
        updated: true,
        loaded: true,
        error: false
      };

    default:
      return state;
  }
}
