import React from 'react';
import { connect } from 'react-redux';
import { EmployerWhitelist } from 'components';

const EmployerWhitelistContainer = props => <EmployerWhitelist {...props} />;

export const mapStateToProps = ({ employers }) => ({
  employers: Object.values(employers.items)
});

export default connect(mapStateToProps)(EmployerWhitelistContainer);