import React from 'react';
import PropTypes from 'prop-types';
import ReduxToastr from 'react-redux-toastr';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { colors } from '../../../styles/theme';
import { GlobalFooter, Loading } from 'components';
import { GlobalHeader } from 'containers';
import { initCookieConsent } from '../../../helpers/cookie-consent';

class App extends React.Component {
  static propTypes = {
    allSitesLoaded: PropTypes.bool.isRequired,
    fetchSite: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    updateCurrentSite: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    site: PropTypes.object,
    currentSite: PropTypes.string,
    loggedIn: PropTypes.bool.isRequired,
    siteLoaded: PropTypes.bool.isRequired,
    siteFetching: PropTypes.bool.isRequired
  };

  componentDidMount() {
    if (!this.props.allSitesLoaded) {
      this.props.fetchSites();
    }
    const { sso } = queryString.parse(window.location.search);
    // Everything on the admin requires a user to be logged in.
    // Every time we mount the application, we call this function.
    // It first checks to see if a user is already logged in, if yes, get their info and proceed,
    // else, continue to a login step.
    this.props.login(null, null, sso);
  }

  componentDidUpdate(prevProps) {
    const {
      allSitesLoaded,
      currentSite,
      fetchSite,
      loggedIn,
      site,
      siteFetching,
      siteLoaded,
      updateCurrentSite
    } = this.props;

    if (prevProps !== this.props) {
      // This checks that the current set of props has site styles loaded. Since the props
      // change several times, we also check that the previous set of props did NOT have
      // styles loaded. This forces the cookie consent to initialize only once.
      const enablePendo = () => {
        const location =
          process.env.NODE_ENV === 'production'
            ? 'https://admin.lightcastcc.com/'
            : 'https://admin.lightcastcc-qa.com/';

        pendo.initialize({
          visitor: {
            id: localStorage.visitorId
          },
          account: {
            id: location
          }
        });
      };

      if (
        document.cookie.replace(
          /(?:(?:^|.*;\s*)cookieconsent_status\s*\=\s*([^;]*).*$)|^.*$/,
          '$1'
        ) != 'deny'
      ) {
        enablePendo();
      }

      if (site && site.styles && prevProps.site && !prevProps.site.styles) {
        initCookieConsent({
          buttonTextColor: site.styles.buttonColor,
          buttonBackgroundColor: site.styles.accentColor,
          backgroundColor: site.styles.menuBackgroundColor,
          textColor: site.styles.menuColor
        });
      }

      if (loggedIn && !siteLoaded && !siteFetching && allSitesLoaded) {
        fetchSite(currentSite);
        updateCurrentSite(currentSite);
      }
    }
  }

  render() {
    const { loggedIn, site, children, siteLoaded, allSitesLoaded } = this.props;
    return (
      <div id="wrap-content">
        <div role="alert" aria-live="assertive" id="flash-message">
          <ReduxToastr
            timeOut={4000}
            newestOnTop
            position="top-left"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
          />
        </div>
        <Loading loaded={loggedIn ? siteLoaded && allSitesLoaded : allSitesLoaded} isAbsolute>
          {allSitesLoaded && (
            <ThemeProvider theme={siteLoaded && loggedIn ? site.styles : colors}>
              <div>
                <GlobalHeader />
                <div id="main-content">{children}</div>
                <GlobalFooter />
              </div>
            </ThemeProvider>
          )}
        </Loading>
      </div>
    );
  }
}

export default App;
