import React from 'react';
import { ContentWrap, EmployerSettingsCard } from 'components';

const EmployerSettings = props => (
  <ContentWrap>
    <EmployerSettingsCard {...props} />
  </ContentWrap>
);

export default EmployerSettings;
