import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import xlsx from 'xlsx';

import { fetchKeenData } from '../../services';
import { keenProfilesCreated } from '../../services/keen/queries';
import {
  fetchCareerTitles,
  fetchInstitutions,
  fetchProgramsBySlug,
  getProfiles
} from '../../services';

import { selectSubdomain } from '../../selectors/subdomain';
import { ProfilesReportDownload } from 'components';
import { locale } from '../../helpers/locale';

const ProfilesReportDownloadContainer = props => {
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [isDownloading, setIsDownloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keenData, setKeenData] = useState([]);
  const [profiles, setProfiles] = useState([]);

  /*  Downloads and creates an excel file for all profiles on a given subdomain.
   *   There are 4 useEffects that listen to different states that declare the stage of the downloading process
   *   The first effect gets the profiles. The 2nd reformats the profiles after they have been downloaded, the 3rd
   *   creates and saves the excel file if the user clicks the download profiles button. The 4th
   *   updates the keenData one a new dateFilter selection
   */

  // useEffect 1, fetches all profiles that belong to subdomain in batches of 20,000
  useEffect(() => {
    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    const fetchProfiles = async () => {
      const totalProfiles = await getProfiles(props.subdomain, '?limit=1', abortSignal).then(
        response => response.total
      );

      const limit = 20000;
      const totalRequests = Math.ceil(totalProfiles / limit);
      const promises = [];

      for (let i = 0; i < totalRequests; i++) {
        const skip = i * limit;
        promises.push(
          getProfiles(props.subdomain, `?limit=${limit}&skip=${skip}`, abortSignal).then(
            response => response.profiles
          )
        );
      }

      Promise.all(promises).then(values => {
        const flattenedProfiles = values.flat();
        setProfiles(flattenedProfiles);
        setIsDownloading(false);
      });
    };

    fetchProfiles();

    return () => {
      abortController.abort();
    };
  }, []);

  const createProfilesExcelSheet = ({
    isStudentIdEnabled,
    requiresResumeApproval,
    nation,
    profilesList
  }) => {
    const columnLabels = {
      familyName: 'Last Name',
      givenName: 'First Name',
      email: 'Email',
      institutionUserId: 'Student ID',
      address: 'Address',
      city: 'City',
      state: 'State',
      postalCode: 'Zip Code',
      phoneNumber: 'Phone Number',
      createdAt: 'Sign Up',
      lastLogin: 'Last Session',
      currentMajor: 'Current Major',
      currentSchool: 'Current School',
      resumeApprovalStatus: `${locale.resume[nation] + 's Approved?'}`,
      dateCompleted: 'Assessment Completion Date',
      trait1: 'Trait 1',
      trait2: 'Trait 2',
      trait3: 'Trait 3',
      trait4: 'Trait 4',
      trait5: 'Trait 5',
      trait6: 'Trait 6',
      careers: 'Favorited Careers',
      programs: 'Favorited Programs'
    };

    const ukCaColumns = ['email', 'createdAt', 'lastLogin', 'careers', 'programs'];
    const usColumns = [
      'familyName',
      'givenName',
      'email',
      `${isStudentIdEnabled ? 'institutionUserId' : ''}`,
      'address',
      'city',
      'state',
      'postalCode',
      'phoneNumber',
      'createdAt',
      'lastLogin',
      'currentMajor',
      'currentSchool',
      `${requiresResumeApproval ? 'resumeApprovalStatus' : ''}`,
      'dateCompleted',
      'trait1',
      'trait2',
      'trait3',
      'trait4',
      'trait5',
      'trait6',
      'careers',
      'programs'
    ];

    const columnHeaders = nation === 'us' ? usColumns : ukCaColumns;
    const profilesSheetData = [];
    const profileHeaders = [];

    // Creates the header row for Profile List
    for (const column in columnHeaders) {
      if (columnHeaders[column] in columnLabels) {
        profileHeaders.push(columnLabels[columnHeaders[column]]);
      }
    }

    const createCellValue = (element, endOfSheet = false) => {
      if (endOfSheet) {
        return element ? `${element.join(' | ')}` : null;
      }
      return element ? `${element}` : null;
    };

    // The mapping on profilesList creates every excel row
    profilesList.map(
      ({
        address,
        assessment,
        city,
        createdAt,
        currentMajor,
        currentSchool,
        email,
        familyName,
        favorites,
        givenName,
        institutionUserId,
        lastLogin,
        phoneNumber,
        postalCode,
        resumeApprovalStatus,
        state
      }) => {
        const profileRow = {};

        if (nation === 'us') {
          profileRow['Last Name'] = createCellValue(familyName);
          profileRow['First Name'] = createCellValue(givenName);
          profileRow['Address'] = createCellValue(address);
          profileRow['City'] = createCellValue(city);
          profileRow['State'] = createCellValue(state);
          profileRow['Zip Code'] = createCellValue(postalCode);
          profileRow['Phone Number'] = createCellValue(phoneNumber);
          profileRow['Current Major'] = createCellValue(currentMajor);
          profileRow['Current School'] = createCellValue(currentSchool);

          currentSchool === '---' ? (currentSchool = '') : null;

          isStudentIdEnabled
            ? (profileRow['Student ID'] = createCellValue(institutionUserId))
            : null;

          if (requiresResumeApproval) {
            profileRow[`${locale.resume[nation] + 's Approved?'}`] =
              resumeApprovalStatus === 'approved' ? 'Yes' : null;
          }

          if (typeof assessment != 'undefined') {
            const { dateCompleted, traits } = assessment;
            profileRow['Assessment Completion Date'] = createCellValue(
              moment(dateCompleted).format(locale.date[nation])
            );
            profileRow['Trait 1'] = createCellValue(traits[0]);
            profileRow['Trait 2'] = createCellValue(traits[1]);
            profileRow['Trait 3'] = createCellValue(traits[2]);
            profileRow['Trait 4'] = createCellValue(traits[3]);
            profileRow['Trait 5'] = createCellValue(traits[4]);
            profileRow['Trait 6'] = createCellValue(traits[5]);
          } else {
            for (let i = 1; i < 7; i++) {
              profileRow['Trait ' + i] = null;
            }
          }
        }

        profileRow['Email'] = createCellValue(email);
        profileRow['Sign Up'] = createCellValue(moment(createdAt).format(locale.date[nation]));
        profileRow['Last Session'] = createCellValue(moment(lastLogin).format(locale.date[nation]));

        if (typeof favorites != 'undefined') {
          const { careers, programs } = favorites;
          profileRow['Favorited Careers'] = careers ? `${careers.join(' | ')}` : null;
          profileRow['Favorited Programs'] = programs ? `${programs.join(' | ')}` : null;
        } else {
          profileRow['Favorited Careers'] = null;
          profileRow['Favorited Programs'] = null;
        }
        profilesSheetData.push(profileRow);
      }
    );
    return {
      profiles: profilesSheetData,
      headers: profileHeaders
    };
  };

  const reformatProfileData = async () => {
    const {
      hasLot,
      hasManyInstitutions,
      isStudentIdEnabled,
      nation,
      requiresResumeApproval,
      subdomain
    } = props;

    const careers = await fetchCareerTitles('all', nation, hasLot);

    let programSlugs = new Set();
    profiles.forEach(currentProfile => {
      currentProfile.favorites.programs.forEach(fave => {
        programSlugs.add(fave.split('/')[0]);
      });
    });

    const institutions = await fetchInstitutions(subdomain);

    let programs;
    if (programSlugs.size > 0) {
      try {
        const programsData = await fetchProgramsBySlug(subdomain, programSlugs);
        programs = programsData;
      } catch (error) {
        programs = [];
      }
    }

    const profilesList = profiles.map(profile => ({
      ...profile,
      currentSchool: profile.currentSchool && profile.currentSchool.displayName,
      favorites: {
        careers: profile.favorites.careers
          .filter(careerId => !!careers[careerId])
          .map(careerId => careers[careerId].singularTitle),
        programs: profile.favorites.programs
          .filter(programId => {
            const [programSlug, institutionId] = programId.split('/');
            const institutionSlug = `${subdomain}-${institutionId}`;
            return programs[programSlug] && (institutions[institutionSlug] || !hasManyInstitutions);
          })
          .map(programId => {
            const [programSlug, institutionId] = programId.split('/');
            const { credential, name } = programs[programSlug];
            const institutionSlug = `${subdomain}-${institutionId}`;
            if (hasManyInstitutions) {
              return `${name} - ${credential}, ${institutions[institutionSlug].displayName}`;
            } else {
              return `${name} - ${credential}`;
            }
          })
      }
    }));

    let formattedProfilesJSON = createProfilesExcelSheet({
      isStudentIdEnabled,
      nation,
      profilesList,
      requiresResumeApproval
    });
    const profilesExcelSheet = xlsx.utils.json_to_sheet(formattedProfilesJSON.profiles, {
      header: formattedProfilesJSON.headers
    });

    setProfiles(profilesExcelSheet);
    setIsLoading(false);
    setIsLoaded(true);
  };

  const createExcelBook = () => {
    const wb = xlsx.utils.book_new();
    wb.SheetNames.push(
      'Total Profiles Created',
      'Profiles Created by Date',
      'Profile List',
      'Introduction'
    );
    wb.Sheets['Profile List'] = profiles;

    const totalProfilesCreated = xlsx.utils.json_to_sheet(
      [
        {
          ['Total Profiles Created']: keenData.reduce(function(prev, cur) {
            return prev + cur.value;
          }, 0)
        }
      ],
      { header: ['Total Profiles Created'] }
    );
    wb.Sheets['Total Profiles Created'] = totalProfilesCreated;

    const createdData = keenData.map(({ date, value }) => {
      return {
        Date: date,
        ['Profiles Created']: value
      };
    });
    const profilesCreatedByDate = xlsx.utils.json_to_sheet(createdData, {
      header: ['Date', 'Profiles Created']
    });
    wb.Sheets['Profiles Created by Date'] = profilesCreatedByDate;

    const introduction = xlsx.utils.json_to_sheet(
      [
        {
          Introduction:
            'The Career Coach Profiles workbook is comprised of three worksheets: "Total Profiles Created" shows the number of profiles created during the selected time period. "Profiles Created by Date" displays two columns: “Date” and “Profiles Created”. In each row, the value of the “Profiles Created” column shows the number of Career Coach profiles created on the date shown in the “Date" column. “Profile List” displays the data points stored in each user’s profile. Each data point is self-reported by the user, and Lightcast does not validate the information given or guarantee that it is accurate or up-to-date.'
        }
      ],
      { header: ['Introduction'] }
    );
    wb.Sheets['Introduction'] = introduction;

    xlsx.writeFile(wb, 'Career_Coach_Profiles.xlsx');
    setIsLoading(false);
    setDownloadClicked(false);
  };

  const getKeenData = async () => {
    const { subdomain, timeframe, nation } = props;
    const keenDataResponse = await fetchKeenData({
      subdomain,
      event_collection: keenProfilesCreated.event,
      timeframe,
      ...keenProfilesCreated
    });
    const formattedKeenData = keenDataResponse.map(item => {
      item.date = moment(item.timeframe.start).format(locale.date[nation]);
      return item;
    });
    setKeenData(formattedKeenData);
  };

  // useEffect 2, checks if profiles are downloaded and reformats the data for the excel file
  useEffect(() => {
    if (!isDownloading) {
      reformatProfileData();
    }
  }, [isDownloading]);

  // useEffect 3, listens to click event for the profile download button and starts excel save to local disk process
  useEffect(() => {
    if (downloadClicked && isLoaded && keenData.length > 0) {
      createExcelBook();
    }
  }, [downloadClicked, isLoaded]);

  // useEffect 4, listens to props.timeframe and updates the keenData
  useEffect(() => {
    getKeenData();
  }, [props.timeframe]);

  return (
    <ProfilesReportDownload
      startLoadingState={() => {
        setIsLoading(true);
        setDownloadClicked(true);
      }}
      isLoading={isLoading}
    />
  );
};

export const mapStateToProps = ({ profile, sites, stats }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    hasLot: sites.items[currentSite].hasLot || false,
    hasManyInstitutions: sites.items[currentSite].hasManyInstitutions,
    isStudentIdEnabled: sites.items[currentSite].studentIdEnabled,
    nation: sites.items[currentSite].nation,
    requiresResumeApproval: sites.items[currentSite].requiresResumeApproval,
    subdomain: currentSite,
    timeframe: stats
  };
};

ProfilesReportDownloadContainer.propTypes = {
  hasManyInstitutions: PropTypes.bool.isRequired,
  isStudentIdEnabled: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired,
  requiresResumeApproval: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ProfilesReportDownloadContainer);
