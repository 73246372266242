import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import { FormLayout, Message, GeoForm } from 'components';

const LocationSettings = ({
  areasLength,
  saveLocationSettings,
  handleSubmit,
  initialValues,
  hasJobPostings,
  selectLevels,
  nation,
  ...rest
}) => {
  return (
    <FormLayout
      title="Locations"
      submitForm={handleSubmit(saveLocationSettings)}
      disabled={areasLength > 100}
      {...rest}
    >
      {areasLength > 100 && (
        <Message type="warning">
          Warning: you cannot update location settings because there are too many Geographies ( >
          100). Contact the CC Dev Team to update this site or delete some areas.
        </Message>
      )}
      <FieldArray
        name="areas"
        component={GeoForm}
        hasJobPostings={hasJobPostings}
        selectLevels={selectLevels}
        {...initialValues}
        nation={nation}
        formId="LocationSettingsForm"
      />
    </FormLayout>
  );
};

LocationSettings.propTypes = {
  areasLength: PropTypes.number,
  handleSubmit: PropTypes.func,
  hasJobPostings: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  nation: PropTypes.string.isRequired,
  pristine: PropTypes.bool,
  saveLocationSettings: PropTypes.func.isRequired,
  selectLevels: PropTypes.object.isRequired,
  submitting: PropTypes.bool
};

const LocationSettingsForm = reduxForm({
  form: 'LocationSettingsForm',
  enableReinitialize: true,
  shouldValidate: params => !params.initialRender
})(LocationSettings);

export default LocationSettingsForm;
