import dasherize from 'dasherize';
import camelCase from 'camelcase-keys-deep';
import removeEmpty from '../helpers/remove-empty';

/**
 * options to pass into the fetch call
 * @method fetchOpts
 * @param  {String}  [type='GET']  the type of API call GET, POST, PATCH ...
 * @param  {object}  data          if it's a POST request pass in the data
 * @param  {object}  headers       additional headers to send along with the request
 * @param  {object}  otherSettings more settings
 * @return {object}                the config object with every fetch call
 */
export function fetchOpts(type = 'GET', data, headers, ...otherSettings) {
  return {
    method: type,
    body: data ? JSON.stringify(data) : undefined,
    headers,
    ...otherSettings
  };
}
/**
 * takes the json data and returns an object camelCase
 * @method normalizeResponse
 * @param  {object}          response the json response
 * @return {object}                   the normalized response
 */
export function normalizeResponse(response) {
  if (response) {
    return {
      ...camelCase(response.attributes),
      ...{
        id: response.id
      }
    };
  }
}

/**
 * returns the response as well as the error status
 * @param  {object} the json response from an api call
 * @return {object} response + error status
 */
export function handleResponse(response) {
  if (response.status === 200 || response.status === 201) {
    return response.json();
  } else if (response.status === 404) {
    return {
      ...response.json(),
      status: response.status
    };
  }
}

/**
 * loops over the data that is an array and formats each object inside the array
 * @method normalizeResponseArray
 * @param  {array}               data an array of objects
 * @return {object}                   returns the object with the id as the key for each object
 */
export function normalizeResponseArray(data) {
  let normalizedResponse = {};

  data.forEach(response => {
    // normalizeResponseArray expects a response with an 'id' attribute,
    // which it won't get from the employer postings API, so this little check here makes sure it will still work.
    if (response.attributes['posting-id']) {
      response.id = response.attributes['posting-id'];
    }
    normalizedResponse[response.id] = normalizeResponse(response);
  });

  return normalizedResponse;
}
/**
 * used to format the request on POSTS and PATCHES
 * @method formatRequest
 * @param  {object}      data the json payload
 * @param  {string}      type the type of API, site for most cases
 * @param  {string}      id   the id is the subdomain of the site we are modifying
 * @return {object}           removes any empty fields in the object and dasherizes the data
 */
export function formatRequest(data, type, id) {
  return removeEmpty({
    data: {
      type,
      id,
      attributes: {
        ...dasherize(data)
      }
    }
  });
}

/**
 * checks the data to see if it is a single object or an array
 * @method responseFormatter
 * @param  {object}          data could be an array or object, but since arrays are objects we need to use the native check
 * @return {function}             returns a call to normalize the response
 */
export function responseFormatter(data) {
  if (Array.isArray(data)) return normalizeResponseArray(data);
  return normalizeResponse(data);
}

/**
 * a curry function that takes a type param and returns a function with more parameters
 * @method request
 * @param  {string} type which type of API call are we making
 * @return {function}    returns a function that makes a `fetch` call
 */
export const request = type => (endpoint, data, headers, settings) =>
  fetch(endpoint, fetchOpts(type, data, headers, settings)).then(response =>
    response.json().then(json => {
      if (!response.ok) return Promise.reject(json);
      return responseFormatter(json.data);
    })
  );

// simple functions for API methods
export const get = request('GET');
export const post = request('POST');
export const patch = request('PATCH');

/**
 * this comment is just for visual consistency
 * @param  {object}
 * @return {object}
 */
export const formatPostingAnalyticsData = ({ postings, timeframe }) => {
  const employersData = {};
  let filteredPostings = [];

  for (let index = 0; index < postings.length; index++) {
    const { applicants, employer, status, title, ...posting } = postings[index].attributes;

    const [month, day, year] = posting['created-on'].split('-');
    const createdOn = [year, month, day].join('');
    const startDate = timeframe.start.replaceAll('-', '');

    const [endMonth, endDay, endYear] = posting['end-on'].split('-');
    const endedOn = [endYear, endMonth, endDay].join('');
    const endDate = timeframe.end.replaceAll('-', '');

    const isActiveInTimeRange = createdOn < endDate && endedOn > startDate;

    if (status === 'approved' && isActiveInTimeRange) {
      filteredPostings.push({
        name: title,
        result: applicants.length,
        applicants,
        ...posting
      });

      if (!employersData[employer.id]) {
        employersData[employer.id] = {
          employerId: employer.id,
          employerName: employer.name,
          postingCount: 1,
          applicantCount: applicants.length,
          createdOn: posting['created-on']
        };
      } else {
        employersData[employer.id].applicantCount += applicants.length;
        employersData[employer.id].postingCount += 1;
      }
    }
  }

  const sortByResult = (a, b) => (a.result > b.result ? -1 : 1);
  const formatEmployerData = dataPoint =>
    Object.values(employersData)
      .map(({ employerId, employerName, ...rest }) => ({
        employerId,
        employerName,
        result: rest[dataPoint]
      }))
      .sort(sortByResult);

  const byApplicants = formatEmployerData('applicantCount');
  const byPostings = formatEmployerData('postingCount');
  const topPostingsByApplicants = filteredPostings.sort(sortByResult);

  return {
    employers: {
      byApplicants,
      byPostings
    },
    topPostingsByApplicants
  };
};
