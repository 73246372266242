import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  ConfigureSettingsButton,
  ContentWrap,
  HideContentOnMobile,
  Layout,
  Margin,
  Statistics,
  Profiles,
  NoStatistics
} from 'components';
import {
  Employers,
  EmployerInfoDownload,
  EmployerSettings,
  DateFilter,
  ResumeApproval,
  WidgetStatistics
} from 'containers';
import { withSiteHOC } from '../../../hocs/withSite';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Analytics = withSiteHOC()(
  ({
    employerPortal: employerEnabled,
    location,
    widgetBuilderSettings,
    enabled: isSiteEnabled
  }) => {
    const navigation = [
      isSiteEnabled && {
        title: 'Career Coach Statistics',
        path: '/analytics/statistics',
        component: Statistics
      },
      isSiteEnabled && {
        title: 'Profiles',
        path: '/analytics/profiles',
        component: Profiles
      },
      isSiteEnabled &&
        employerEnabled && {
          title: 'Employers',
          path: '/analytics/employers',
          exact: true,
          component: Employers
        },
      (widgetBuilderSettings.programWidget.isEnabled ||
      widgetBuilderSettings.assessmentWidget.isEnabled)  && {
        title: 'Widget Statistics',
        path: '/analytics/widget',
        component: WidgetStatistics
      }
    ].filter(Boolean);

    let defaultPagePath;
    if (isSiteEnabled) {
      defaultPagePath = '/analytics/statistics';
    } else if (widgetBuilderSettings.programWidget.isEnabled ||
      widgetBuilderSettings.assessmentWidget.isEnabled) {
      defaultPagePath = '/analytics/widget';
    } else {
      defaultPagePath = '/analytics/not-available';
    }

    return (
      <Layout title="Analytics" navigation={navigation}>
        <ContentWrap>
          {location.pathname === '/analytics/profiles' && <ResumeApproval />}
          {!location.pathname.includes('not-available') &&
            !location.pathname.includes('employers') && <DateFilter key={location.pathname} />}
          {location.pathname === '/analytics/employers' && (
            <HideContentOnMobile message="Employer Settings are only available on desktop!">
              <Margin value="7.2rem 0rem 2rem 0rem">
                <ButtonContainer>
                  <EmployerInfoDownload />
                  <ConfigureSettingsButton
                    text="Configure Settings"
                    to="/analytics/employers/settings"
                  />
                </ButtonContainer>
              </Margin>
            </HideContentOnMobile>
          )}
          {location.pathname.includes('employers/settings') && (
            <Margin value="7.2rem 0rem 2rem 0rem">
              <ConfigureSettingsButton text="Back to Employers" to="/analytics/employers" />
            </Margin>
          )}
          {location.pathname === '/analytics/not-available' && <NoStatistics />}
        </ContentWrap>
        <Switch>
          {navigation.map((nav, index) => (
            <Route
              key={`route-${index}}`}
              path={nav.path}
              exact={nav.exact}
              render={props => <nav.component {...props} />}
            />
          ))}
          <Route
            key="route-settings"
            path="/analytics/employers/settings"
            component={EmployerSettings}
          />
          <Redirect to={defaultPagePath} />
        </Switch>
      </Layout>
    );
  }
);

Analytics.defaultProps = {
  employerPortal: false
};

Analytics.propTypes = {
  employerPortal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Analytics;
