import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { locale } from '../../../helpers/locale';
import { withNationHOC } from '../../../hocs/withNation';
import {
  CareerSettings,
  ContentWrap,
  DesignSettings,
  GeneralSettings,
  HomeSettings,
  Layout,
  LocationSettings,
  ProgramSettings
} from 'components';

import {
  AdministratorSettings,
  SkillsSettings,
  UploadSettings,
  WidgetBuilderSettingsForm
} from 'containers';

const Settings = withNationHOC(({ nation }) => {
  const navigation = [
    {
      path: '/settings/general',
      title: 'General',
      component: GeneralSettings
    },
    {
      path: '/settings/design',
      title: 'Design',
      component: DesignSettings
    },
    {
      path: '/settings/uploads',
      title: 'Uploads',
      component: UploadSettings
    },
    {
      path: '/settings/home',
      title: 'Home',
      component: HomeSettings
    },
    {
      path: '/settings/program',
      title: locale.edTrack[nation],
      component: ProgramSettings
    },
    {
      path: '/settings/career',
      title: 'Career',
      component: CareerSettings
    },
    {
      path: '/settings/skills',
      title: 'Skills',
      component: SkillsSettings
    },
    {
      path: '/settings/location',
      title: 'Location',
      component: LocationSettings
    },
    {
      path: '/settings/widget-builder',
      title: 'Widget Builder',
      component: WidgetBuilderSettingsForm
    },
    {
      path: '/settings/administrators',
      title: 'Administrators',
      component: AdministratorSettings
    }
  ];
  return (
    <Layout title="Settings" navigation={navigation}>
      <ContentWrap>
        <Switch>
          {navigation.map((nav, index) => (
            <Route
              key={`route-${index}}`}
              path={nav.path}
              title={nav.title}
              component={nav.component}
            />
          ))}
        </Switch>
      </ContentWrap>
    </Layout>
  );
});

export default Settings;
