import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../Select';

class MonthSelect extends React.Component {
  render() {
    const formats = {
      long: 'months',
      short: 'monthsShort'
    };
    const { format: fmt, ...props } = this.props;
    const format = fmt && formats[fmt] ? fmt : 'long';
    const formatFunc = formats[format];
    const months = moment
      .localeData()
      [formatFunc]()
      .map((month, index) => {
        return {
          value: index,
          label: month
        };
      });
    return <Select options={months} {...props} />;
  }

  static get dateType() {
    return 'month';
  }
}

MonthSelect.propTypes = {
  format: PropTypes.string
};

export default MonthSelect;
