import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { color } from '../../../styles';
const { sapphireD20 } = color;

const renderDynamicComponent = ({
  bold,
  color = 'inherit',
  component,
  children,
  className,
  type
}) => {
  let fontStyles, colorStyles;
  const linkColor = sapphireD20,
    weight = bold ? 700 : 400;

  switch (type) {
    case 'display':
      fontStyles = css`
        font-size: 4rem;
        font-weight: ${weight};
        line-height: 6rem;
      `;
      break;
    case 'headline':
      fontStyles = css`
        font-size: 2.5rem;
        font-weight: ${weight};
        line-height: 3.5rem;
      `;
      break;
    case 'title':
      fontStyles = css`
        font-size: 2.2rem;
        font-weight: ${weight};
        line-height: 3rem;
      `;
      break;
    case 'subtitle':
      fontStyles = css`
        font-size: 1.8rem;
        font-weight: ${weight};
        line-height: 3rem;
      `;
      break;
    case 'body':
      fontStyles = css`
        font-size: 1.5rem;
        font-weight: ${weight};
        line-height: 2.5rem;
      `;
      break;
    case 'caption':
      fontStyles = css`
        font-size: 1.3rem;
        font-weight: ${weight};
        line-height: 2rem;
      `;
      break;
    case 'footnote':
      fontStyles = css`
        font-size: 1rem;
        font-weight: ${weight};
        line-height: 1.5rem;
      `;
      break;
  }

  switch (color) {
    case 'inherit':
      colorStyles = css`
        color: inherit;
      `;
      break;
    case 'primary':
      colorStyles = css`
        color: black;
      `;
      break;
    case 'alternate':
      colorStyles = css`
        color: ${linkColor};
      `;
      break;
  }

  const DynamicComponent = styled[component]`
    ${fontStyles}
    ${colorStyles}
    margin-bottom: 2rem;
  `;

  return <DynamicComponent className={className}>{children}</DynamicComponent>;
};

const Header = props => renderDynamicComponent(props);

Header.defaultProps = {
  component: 'h1'
};

Header.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.string,
  type: PropTypes.string
};

export default Header;
