import * as types from '../actions/constants';

// Reducer
export const defaultState = {
  fetching: false,
  loaded: false,
  siteLoaded: false,
  siteFetching: false,
  error: false,
  items: {},
  creating: false,
  created: false,
  updating: false,
  updated: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.USER_LOGOUT:
      return {
        ...state,
        siteLoaded: false,
        currentSite: null
      };
    case types.FETCH_SITES_REQUEST:
      return {
        ...state,
        fetching: true,
        loaded: false,
        error: false
      };

    case types.FETCH_SITES_SUCCESS:
      return {
        ...state,
        fetching: false,
        loaded: true,
        error: false,
        items: {
          ...action.payload
        }
      };
    case types.FETCH_SITE_REQUEST:
      return {
        ...state,
        siteFetching: true,
        siteLoaded: false
      };
    case types.FETCH_SITE_SUCCESS:
      return {
        ...state,
        siteFetching: false,
        siteLoaded: true,
        error: false,
        items: {
          ...state.items,
          [action.payload.subdomain]: {
            ...action.payload
          }
        }
      };

    case types.UPDATE_CURRENT_SITE:
      return {
        ...state,
        currentSite: action.payload.site
      };

    case types.CREATE_SITE_REQUEST:
      return {
        ...state,
        creating: true,
        created: false
      };

    case types.CREATE_SITE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.subdomain]: {
            ...action.payload
          }
        },
        creating: false,
        created: true,
        currentSite: action.payload.subdomain
      };

    case types.PATCH_SITE_REQUEST:
      return {
        ...state,
        updating: true
      };

    case types.PATCH_SITE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...action.payload
          }
        }
      };

    default:
      return state;
  }
}
