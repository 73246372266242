import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExcelDownloadButton, ExcelMultiSchoolStatisticsWorkbook } from 'components';

const InvisibleOnMobile = styled.div`
  display: flex;
  min-width: 25rem;
  margin-left: 2rem;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const MultischoolStatisticsDownload = ({
  data,
  getData,
  hasPrograms,
  isLoaded,
  isLoading,
  subdomain,
  timeframe
}) => {
  return (
    <InvisibleOnMobile>
      <ExcelDownloadButton
        isLoading={isLoading}
        handleClick={() => {
          getData({ subdomain, timeframe });
        }}
      >
        Download Schools Report
      </ExcelDownloadButton>

      {isLoaded && <ExcelMultiSchoolStatisticsWorkbook data={data} hasPrograms={hasPrograms} />}
    </InvisibleOnMobile>
  );
};

MultischoolStatisticsDownload.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func.isRequired,
  hasPrograms: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

MultischoolStatisticsDownload.defaultProps = {
  data: [],
  isLoaded: false,
  isLoading: false
};

export default MultischoolStatisticsDownload;
