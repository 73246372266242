import styled from 'styled-components';
import { color } from '../../../styles';

const Bubble = styled.span`
  border: 2px solid white;
  background: ${color.red};
  color: white;
  border-radius: 100px;
  position: absolute;
  padding: 3px 8px;
  top: -10px;
  left: 90px;
  background-clip: padding-box;
`;

export default Bubble;
