/* A translator object for getting nation-specific words and spellings, e.g. Program -> Programme */

export const locale = {
  careerType: {
    us: 'onet',
    ca: 'noc',
    uk: 'soc',
    assessment: {
      us: 'onetId',
      ca: 'noc',
      uk: 'soc'
    }
  },
  postings: {
    placeholder: {
      us: 'e.g. Accountants, Registered Nurses',
      ca: 'e.g. Architects, Executive Assistants',
      uk: 'e.g. Farmers, Office Managers'
    }
  },
  date: {
    us: 'MM-DD-YYYY',
    ca: 'YYYY-MM-DD',
    uk: 'DD-MM-YYYY'
  },
  edTrack: {
    us: 'Program',
    ca: 'Program',
    uk: 'Course',

    lower: {
      us: 'program',
      ca: 'program',
      uk: 'course'
    }
  },
  location: {
    code: {
      us: 'ZIP',
      ca: 'Postal',
      uk: 'Postal'
    }
  },
  profilesTable: {
    placeholder: {
      us: 'Search by name or email',
      ca: 'Search by email',
      uk: 'Search by email'
    }
  },
  resume: {
    us: 'Résumé',
    ca: 'Résumé',
    uk: 'CV',
    lower: {
      us: 'résumé',
      ca: 'résumé',
      uk: 'CV'
    }
  },
  spellings: {
    authorization: {
      us: 'Authorization',
      ca: 'Authorization',
      uk: 'Authorisation'
    },
    color: {
      us: 'Color',
      ca: 'Colour',
      uk: 'Colour'
    },
    favorite: {
      us: 'Favorite',
      ca: 'Favourite',
      uk: 'Favourite'
    },
    labor: {
      us: 'Labor',
      ca: 'Labor',
      uk: 'Labour'
    },
    organization: {
      us: 'Organization',
      ca: 'Organization',
      uk: 'Organisation'
    }
  }
};
