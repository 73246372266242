import React from 'react';
import ReactDOM from 'react-dom';
import uuid from 'uuid';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import configureStore from './stores/store';
import RoutesLayout from './routes';

// The initial state doesn't change so it's a const.

const initialState = {};

const store = configureStore(initialState);

const visitorId = localStorage.getItem('visitorId');
if (!visitorId) {
  localStorage.setItem('visitorId', uuid.v4());
}

const render = Component => {
  ReactDOM.render(
    <AppContainer warnings={false}>
      <Provider store={store}>
        <Component store={store} />
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
};

render(RoutesLayout);

if (module.hot) {
  module.hot.accept(RoutesLayout, () => {
    const NextLayout = require('./routes').default;
    render(NextLayout);
  });
}
