import React from 'react';
import PropTypes from 'prop-types';

import { ExcelDownloadButton } from 'components';

const ProfilesReportDownload = ({ isLoading, startLoadingState }) => {
  return (
    <ExcelDownloadButton
      isLoading={isLoading}
      handleClick={startLoadingState}
    >
      Download Profiles
    </ExcelDownloadButton>
  );
};

ProfilesReportDownload.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  startLoadingState: PropTypes.func.isRequired
};

ProfilesReportDownload.defaultProps = {
  isLoading: false
};

export default ProfilesReportDownload;
