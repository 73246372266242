import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';

import { isRequired } from '../../../helpers/validation';
import { FormInput, FormLayout, FormRadio, FormTextArea, Loading } from 'components';
import { withNationHOC } from '../../../hocs/withNation';

const StyledFormTextArea = styled(FormTextArea)`
  max-width: 55rem;
`;

const SectionHeader = styled.div`
  margin-top: 6rem;
  margin-bottom: 2rem;
`;

const SectionLabel = styled.div`
  font-size: 1.8rem;
  font-weight: 800;
`;

const WidgetBuilderSettings = withNationHOC(
  ({
    currentSite,
    currentValues,
    handleSubmit,
    pristine,
    reset,
    saveWidgetBuilderSettings,
    submitting
  }) => {
    if (!currentValues) {
      return <Loading />;
    }

    const assessmentWidgetEnabled = currentValues.assessmentWidget.isEnabled;
    const programWidgetEnabled = currentValues.programWidget.isEnabled;

    const isEnabled = assessmentWidgetEnabled || programWidgetEnabled;


    return (
      <FormLayout
        title="Widget Builder Settings"
        submitForm={handleSubmit(settings => saveWidgetBuilderSettings({ currentSite, settings }))}
        submitting={submitting}
        pristine={pristine}
        reset={reset}
      >
        <Field
          aria-label="Assessment Widget Builder Enabled"
          label="Assessment Widget Builder Enabled"
          name="assessmentWidget.isEnabled"
          component={FormRadio}
        />
        <Field
          aria-label="Program Widget Builder Enabled"
          label="Program Widget Builder Enabled"
          name="programWidget.isEnabled"
          component={FormRadio}
        />
        <Field
          aria-label="Allowed Domains"
          label="Allowed Domains"
          name="allowedDomains"
          disabled={!isEnabled}
          component={StyledFormTextArea}
          subtext="Each new line represents an approved domain for the client to display the widget"
          format={value => value && value.join('\n')}
          parse={value => (value ? value.split('\n') : [])}
          placeholder={'economicmodeling.com.universityoflightcast.edu'}
          rows="9"
          validate={isEnabled ? isRequired : undefined}
        />

        <SectionHeader>
          <SectionLabel>Custom Widget Labels</SectionLabel>
        </SectionHeader>

        <Field
          id="widget-median-salary-label"
          aria-label="Median Salary"
          label="Median Salary"
          placeholder="Max Character Count: 25"
          name="programWidget.customLabels.medianSalaryLabel"
          maxLength="25"
          component={FormInput}
          type="text"
        />
        <Field
          id="widget-job-openings-label"
          aria-label="Job Openings"
          label="Job Openings"
          placeholder="Max Character Count: 25"
          name="programWidget.customLabels.jobOpeningsLabel"
          maxLength="25"
          component={FormInput}
          type="text"
        />
        <Field
          id="widget-currently-employed-label"
          aria-label="Currently Employed"
          label="Currently Employed"
          placeholder="Max Character Count: 25"
          name="programWidget.customLabels.currentlyEmployedLabel"
          maxLength="25"
          component={FormInput}
          type="text"
        />
        <Field
          id="widget-projected-outlook-label"
          aria-label="Projected Outlook"
          label="Projected Outlook"
          placeholder="Max Character Count: 25"
          name="programWidget.customLabels.projectedGrowthLabel"
          maxLength="25"
          component={FormInput}
          type="text"
        />
        <Field
          id="widget-visit-career-coach-label"
          aria-label="Visit Career Coach"
          label="Visit Career Coach"
          placeholder="Max Character Count: 25"
          name="programWidget.customLabels.visitCareerCoachLabel"
          maxLength="25"
          component={FormInput}
          type="text"
        />
      </FormLayout>
    );
  }
);

WidgetBuilderSettings.propTypes = {
  handleSubmit: PropTypes.func,
  patchSite: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  saveWidgetBuilderSettings: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

const WidgetBuilderSettingsForm = reduxForm({
  form: 'WidgetBuilderSettingsForm',
  enableReinitialize: true
})(WidgetBuilderSettings);

export default WidgetBuilderSettingsForm;
