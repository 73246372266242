import React, { Fragment } from 'react';
import styled from 'styled-components';
import { borderRadius, color } from '../../../styles';
import { Card, Header, Hint, Table, Truncate } from 'components';
import { ResumeDownloadButton, Modal } from 'containers';
import { sortDates } from '../../../helpers/sort';
import PropTypes from 'prop-types';
import Star from '../../../images/star.svg';

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  @media (min-width: 1042px) {
    border-radius: ${borderRadius};
    width: 90rem;
  }
`;

const Title = styled(Header)`
  font-size: 2.4rem !important;
  font-weight: 500 !important;
  line-height: 2.5rem;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledStar = styled(Star)`
  fill: ${color.green};
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  top: 2px;
`;

const ApplicantsModal = ({ applicants, name, selectedApplicant, title }) => {
  return (
    <Modal name={name} width={90}>
      <Wrapper>
        <Title type="title">{title ? title : 'View Applicants'}</Title>
        <Table
          data={applicants}
          options={{
            defaultSortName: 'submittedOn',
            defaultSortOrder: 'desc'
          }}
          columns={[
            {
              dataField: 'name',
              text: 'Name',
              dataFormat: cell => (
                <NameWrapper>
                  {selectedApplicant.name === cell && (
                    <Fragment>
                      <Hint id="hiredApplicant" icon={<StyledStar />} place="bottom">
                        Hired for this position.
                      </Hint>
                    </Fragment>
                  )}
                  <Truncate>{cell}</Truncate>
                </NameWrapper>
              )
            },
            {
              dataField: 'submittedOn',
              text: 'Date Submitted',
              sortFunc: sortDates
            },
            {
              isKey: true,
              dataField: 'resumeId',
              text: 'Résumé',
              dataSort: false,
              dataFormat: (cell, row) => (
                <ResumeDownloadButton id={row.resumeId} type="résumé" name={row.name} />
              )
            }
          ]}
        />
      </Wrapper>
    </Modal>
  );
};

ApplicantsModal.propTypes = {
  applicants: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  selectedApplicant: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default ApplicantsModal;
