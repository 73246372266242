import { connect } from 'react-redux';
import { Programs } from 'components';
import { modalShow } from '../../reducers/modal';

const mapStateToProps = ({ modal, profile, sites }) => {
  const { hasLot, hasManyInstitutions, nation, subdomain } = sites.items[sites.currentSite];
  const { isDeveloper } = profile;
  return {
    hasLot,
    hasManyInstitutions,
    isDeveloper,
    modals: modal,
    nation,
    subdomain
  };
};

export default connect(mapStateToProps, { modalShow })(Programs);
