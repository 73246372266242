import { connect } from 'react-redux';

import SignUp from '../../components/pages/SignUp';
import { login, signUp } from '../../actions/actionCreators';

const mapDispatchToProps = dispatch => ({
  login: ({ email, password }) => dispatch(login(email.toLowerCase(), password)),
  createAdmin: payload => {
    return dispatch(signUp(payload));
  }
});

export default connect(() => {}, mapDispatchToProps)(SignUp);
