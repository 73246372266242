import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GlobalHeader } from 'components';
import { selectSubdomain } from '../../selectors/subdomain';

const GlobalHeaderContainer = props => <GlobalHeader {...props} />;

export const mapStateToProps = state => ({
  adminSubdomains: state.profile.customData.adminSubdomains,
  sites: state.sites,
  currentSite: selectSubdomain(state.sites, state.profile)
});

export { GlobalHeaderContainer as PureGlobalHeaderContainer }; // used in tests

export default withRouter(connect(mapStateToProps)(GlobalHeaderContainer));
