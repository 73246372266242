import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  ContentWrap,
  LoginForm,
  ResetPasswordForm,
  SendResetPasswordEmailForm
} from 'components';
import styled from 'styled-components';

const Wrapper = styled(ContentWrap)`
  margin-top: 8rem;
  max-width: 960px;

  @media (max-width: 549px) {
    h2 {
      text-align: center;
    }
  }
`;

const Login = ({ resettingPassword, ...props }) => {
  const [hasForgottenPassword, setHasForgottenPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState();

  useEffect(() => {
    setIsResettingPassword(resettingPassword);
  }, [resettingPassword]);

  return (
    <Wrapper>
      <Card>
        {!hasForgottenPassword && !isResettingPassword && (
          <LoginForm setHasForgottenPassword={setHasForgottenPassword} {...props} />
        )}
        {hasForgottenPassword && !isResettingPassword && (
          <SendResetPasswordEmailForm
            setResetPasswordEmail={setResetPasswordEmail}
            setIsResettingPassword={setIsResettingPassword}
          />
        )}
        {isResettingPassword && (
          <ResetPasswordForm
            {...props}
            hasForgottenPassword={hasForgottenPassword}
            email={props.email || resetPasswordEmail}
          />
        )}
      </Card>
    </Wrapper>
  );
};

Login.propTypes = {
  email: PropTypes.string,
  resettingPassword: PropTypes.bool.isRequired
};

export default Login;
