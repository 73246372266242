import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ background }) => (!!background ? background : 'white')} ;
  margin-bottom: 2rem;
  padding: 1.5rem;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    padding: 3rem;
  }
`;

const Card = ({ background, children, ...props }) => {
  return <StyledDiv background={background} {...props}>{children}</StyledDiv>;
};

Card.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Card;
