import config from '../../config';
import { handleResponse } from '../utils';

const gisDataRun = {
  uk: config.ukGisDatarun,
  ca: config.caGisDatarun,
  us: config.usGisDatarun
};

const stripLeadingZeros = geoids => {
  return geoids.map(id => (id.length && id[0] === '0' ? id.substr(1) : id));
};

const padLeadingZeros = geoids => {
  return geoids.map(id => id.padStart(5, '0'));
};

const fetchFromGis = (url, data, level = null) => {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response =>
    handleResponse(response).then(rsp => {
      // Zip codes that start with zeros come back from GIS without them, so we need to pad the ones that are shorter than 5 characters with zeros
      if (level === 'zip' && rsp.codes) {
        rsp.codes = padLeadingZeros(rsp.codes);
      }

      return rsp;
    })
  );
};

const gisMapping = {
  CensusDivision: 'cd',
  CensusSubdivision: 'csd',
  CensusMetropolitanArea: 'cma'
};

// Fetch region geoJson
export const fetchCentroid = (level, geoids, nation) => {
  // prettier-ignore

  // Zip codes that start with zeros need those zeros stripped before sending them to GIS. See CC-6014
  if (level === 'zip') {
    geoids = stripLeadingZeros(geoids);
  }
  const url = `/api/emsi-services/gis/v1/${nation}/${gisDataRun[nation]}/${gisMapping[level] ||
    level}/centroid`;
  const data = { codes: geoids };

  return fetchFromGis(url, data);
};

export const fetchWithinProximity = (level, centroid, radius, nation) => {
  // prettier-ignore
  const url = `/api/emsi-services/gis/v1/${nation}/${gisDataRun[nation]}/${gisMapping[level] || level}/withinproximity`;
  const data = {
    centroid,
    radius: nation === 'us' ? Number(radius) : Number(radius * 1000),
    radiusUnit: nation === 'us' ? 'miles' : 'meters',
    calculationType: 'geodetic',
    intersectionMethod: 'centroid'
  };

  return fetchFromGis(url, data, level);
};

export const fetchGeoNames = (level, codes, nation) => {
  let areaLevel = level;
  if (level === 'pr') {
    areaLevel = 'Provterr';
  }
  if (level === 'fips') {
    areaLevel = 'County';
  }
  const data = {
    codes,
    level: areaLevel,
    nation
  };

  return fetch('/api/cc-api/geo-names', {
    method: 'POST',
    body: JSON.stringify(data)
  }).then(response => handleResponse(response));
};
