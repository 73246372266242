import WidgetBuilderDashboard from '../../components/pages/WidgetBuilderDashboard';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;

  const {
    assessmentWidget: { isEnabled: isAssessmentWidgetEnabled },
    programWidget: { isEnabled: isProgramWidgetEnabled }
  } = items[currentSite].widgetBuilderSettings;

  return {
    isAssessmentWidgetEnabled,
    isProgramWidgetEnabled,
    nation: !!items[currentSite] ? items[currentSite].nation : 'us'
  };
};

export default connect(mapStateToProps)(WidgetBuilderDashboard);
