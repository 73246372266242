import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Toggle } from 'components';

const Wrapper = styled.div`
  display: flex;
`;

const CheckboxGroup = ({ options, checkedOptions, onChange }) => {
  return (
    <Wrapper>
      {options.map((option, index) => {
        const isChecked = checkedOptions.includes(option);
        return (
          <Toggle
            key={`option-${index}`}
            onChange={() =>
              isChecked
                ? onChange(checkedOptions.filter(v => v !== option))
                : onChange([...checkedOptions, option])
            }
            label={option}
            type="checkbox"
            checked={isChecked}
          />
        );
      })}
    </Wrapper>
  );
};

CheckboxGroup.propTypes = {
  checkedOptions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CheckboxGroup;
