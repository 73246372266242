import * as t from '../actions/constants';

const defaultState = {
  loggedIn: false,
  resettingPassword: false,
  customData: {
    subdomain: 'testsite'
  }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    // For Cognito password reset
    case t.USER_LOGIN: {
      return {
        ...state,
        customData: {
          ...state.customData,
          email: action.email
        }
      };
    }
    case t.USER_LOGIN_SUCCESS: {
      return {
        loggedIn: true,
        isSuperAdmin: action.payload.userRole === 'super',
        isDeveloper: action.payload.developer === true,
        errorMessage: '',
        customData: {
          ...action.payload,
          subdomain: action.payload.subdomain || defaultState.customData.subdomain
        },
        user_id: action.payload.user_id
      };
    }
    case t.USER_LOGIN_FAILURE:
      if (action.error.message === 'Password reset required for the user') {
        return {
          ...state,
          resettingPassword: true,
          errorMessage: ''
        };
      }
      return {
        ...state,
        errorMessage: action.error.description || action.error.message
      };
    case t.USER_LOGOUT:
      return defaultState;
    case t.FETCH_PROFILES_REQUEST:
      return {
        ...state,
        profilesFailure: false,
        profilesLoaded: false
      };
    case t.FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        profilesFailure: false,
        profilesLoaded: true
      };
    case t.FETCH_PROFILES_FAILURE:
      return {
        ...state,
        profilesFailure: true,
        profilesLoaded: false
      };
    case t.CLEAR_PROFILE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: ''
      };
    default:
      return state;
  }
}
