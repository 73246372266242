import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import { upper } from 'case';

import { color } from '../../../styles';
import theme from '../../../styles/theme';
import 'react-quill/dist/quill.snow.css';

const Wrapper = styled.div`
  padding: 0.5rem 0;
`;

const Label = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 2px;
`;

const Content = styled.div`
  a {
    color: ${theme.linkColor};
  }
`;

const Line = styled.hr`
  height: 1px;
  border-width: 0;
  background: ${color.lightGray};
`;

const InfoEntry = ({ label, children }) => {
  const dirty = !children || children === '---' ? 'N/A' : children;

  const getContent = () => {
    return typeof dirty === 'string' ? (
      <Content
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(dirty, { ADD_ATTR: ['target'] })
        }}
      />
    ) : (
      dirty
    );
  };

  return (
    <Wrapper>
      <Label>{upper(label)}</Label>
      {getContent()}
      <Line />
    </Wrapper>
  );
};

InfoEntry.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default InfoEntry;
