import React from 'react';
import PropTypes from 'prop-types';

import { FetchKeen } from 'containers';
import { MiniChart } from 'components';

const AnalyticsMiniChart = ({ keenQuery, ...props }) => {
  return (
    <FetchKeen {...keenQuery}>
      {keenProps => <MiniChart {...{ ...props, ...keenProps }} />}
    </FetchKeen>
  );
};

AnalyticsMiniChart.propTypes = {
  keenQuery: PropTypes.object.isRequired
};

export default AnalyticsMiniChart;
