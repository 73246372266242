import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import MediaQuery from 'react-media';

import {
  Bubble,
  Button,
  Card,
  ConfirmationModal,
  HideContentOnMobile,
  Icon,
  Loading,
  Margin,
  ResumeDownloadModal,
  Strong,
  Table,
  TextButton,
  Truncate
} from 'components';

import { updateProfile, sendApprovedResumes, sendDeniedResumes } from '../../../services';
import { sortStrings } from '../../../helpers/sort';
import { locale } from '../../../helpers/locale';
import { color } from '../../../styles';

const { green, red } = color;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: flex;
`;

const StyledTextButton = styled(TextButton)`
  width: auto;
  svg {
    margin-left: 1rem;
  }
`;

const StyledBubble = styled(Bubble)`
  position: initial;
  margin-top: -2px;
  margin-right: 8px;
  height: 28px;
  min-width: 28px;
  padding: 0 8px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
`;

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    width: ${props => (props.nation === 'us' ? '20rem' : 'inherit')};
  }

  th:nth-last-child(2),
  td:nth-last-child(2),
  th:nth-last-child(3),
  td:nth-last-child(3),
  th:nth-last-child(4),
  td:nth-last-child(4) {
    width: 15rem;
    text-align: center !important;
  }
`;

const SmallButton = styled(Button)`
  border-radius: 5px;
  letter-spacing: 0.15rem;
  font-size: 1.25rem;
  font-variant: small-caps;
  font-weight: 600;
  margin-left: 0.7rem;
  padding: 0.3rem 1rem !important;
`;

const Error = styled.div`
  text-align: center;
  margin: 2.5rem;
  color: ${red};
`;

class ResumeApproval extends React.Component {
  state = { isOpen: false, submitError: '' };
  render() {
    const {
      adminEmail,
      allProfiles,
      hasLoadingError,
      isLoaded,
      modalShow,
      modalHide,
      nation,
      reloadProfiles,
      resumes,
      whiteLabel
    } = this.props;
    const { isOpen, submitError } = this.state;
    let profilesNeedingApproval = [];
    if (isLoaded) {
      profilesNeedingApproval = allProfiles
        .filter(profile => profile.resumeApprovalStatus === 'pending')
        .map(({ givenName, familyName, ...rest }) => {
          return {
            name: givenName + ' ' + familyName,
            familyName,
            givenName,
            ...rest
          };
        });
    }
    const submit = async (data, modalName) => {
      const isSuccessful = await updateProfile(data.subdomain, data.profileId, data);
      if (isSuccessful) {
        reloadProfiles(data.subdomain);
        modalHide(modalName);
        if (data.resumeApprovalStatus === 'approved') {
          sendApprovedResumes(data.email, nation, data.subdomain, whiteLabel);
        } else if (data.resumeApprovalStatus === 'denied') {
          sendDeniedResumes(
            adminEmail,
            data.email,
            data.resumeFeedback,
            nation,
            data.subdomain,
            whiteLabel
          );
        }
      }
    };
    return (
      <Margin value="5rem 0 0 0">
        <Card>
          {hasLoadingError ? (
            <Error>We are having trouble loading profiles. Please try again later!</Error>
          ) : (
            <Loading loaded={isLoaded}>
              <div>
                <TopWrapper>
                  <Flex>
                    <StyledBubble>{profilesNeedingApproval.length}</StyledBubble>
                    New {locale.resume.lower[nation]} review requests
                    <MediaQuery query="(max-width: 999px)">
                      {matches =>
                        matches && (
                          <Fragment>
                            <br />
                            To see requests, please switch to the desktop version.
                          </Fragment>
                        )
                      }
                    </MediaQuery>
                  </Flex>
                  <HideContentOnMobile>
                    {profilesNeedingApproval.length > 0 && (
                      <StyledTextButton
                        onClick={() =>
                          this.setState(state => ({
                            isOpen: !state.isOpen
                          }))
                        }
                      >
                        {isOpen ? 'Hide' : 'View'} requests
                        <Icon size="3" type={isOpen ? 'up' : 'down'} />
                      </StyledTextButton>
                    )}
                  </HideContentOnMobile>
                </TopWrapper>
                <HideContentOnMobile>
                  {profilesNeedingApproval.length > 0 && (
                    <Collapse isOpened={isOpen}>
                      <StyledTable
                        nation={nation}
                        isHiddenFromScreenReader={!isOpen}
                        data={profilesNeedingApproval}
                        columns={[
                          nation === 'us' && {
                            dataField: 'name',
                            sortFunc: sortStrings,
                            text: 'Name',
                            dataFormat: cell => <Truncate>{cell}</Truncate>
                          },
                          {
                            dataField: 'email',
                            sortFunc: sortStrings,
                            text: 'Email',
                            dataFormat: cell => <Truncate>{cell}</Truncate>
                          },
                          {
                            dataField: null,
                            dataSort: false,
                            dataFormat: (_, row) => (
                              <Fragment>
                                <SmallButton
                                  aria-label={`View ${locale.resume.lower[nation]}s for ${
                                    nation === 'us' ? row.name : row.email
                                  }`}
                                  onClick={() =>
                                    modalShow(`resume-approval-detail-${row.profileId}`)
                                  }
                                >
                                  view
                                </SmallButton>
                                <ResumeDownloadModal
                                  userName={nation === 'us' ? row.name : row.email}
                                  modalName={`resume-approval-detail-${row.profileId}`}
                                  resumes={resumes.filter(res => res.profileId === row.profileId)}
                                />
                              </Fragment>
                            ),
                            text: `${locale.resume[nation]}s`
                          },
                          {
                            dataField: null,
                            text: 'Approve',
                            dataSort: false,
                            dataFormat: (_, row) => (
                              <Fragment>
                                <Icon
                                  type="checkmark"
                                  size="2"
                                  tabIndex="0"
                                  role="button"
                                  aria-label={`Approve ${locale.resume.lower[nation]}s for ${
                                    nation === 'us' ? row.name : row.email
                                  }`}
                                  color={green}
                                  onKeyDown={e =>
                                    e.keyCode === 13 && modalShow(`approve-resume-${row.profileId}`)
                                  }
                                  onClick={() => modalShow(`approve-resume-${row.profileId}`)}
                                />
                                <ConfirmationModal
                                  name={`approve-resume-${row.profileId}`}
                                  heading={`Approve ${locale.resume[nation]}s?`}
                                  modalHide={modalHide}
                                  text={
                                    <Fragment>
                                      An approval notification will be emailed to{' '}
                                      <Strong>{nation === 'us' ? row.name : row.email}</Strong>.
                                    </Fragment>
                                  }
                                  confirmationButton={
                                    <Button
                                      onClick={() =>
                                        submit(
                                          {
                                            email: row.email,
                                            subdomain: row.subdomain,
                                            profileId: row.profileId,
                                            resumeApprovalStatus: 'approved'
                                          },
                                          `approve-resume-${row.profileId}`
                                        )
                                      }
                                    >
                                      Approve
                                    </Button>
                                  }
                                />
                              </Fragment>
                            )
                          },
                          {
                            dataField: null,
                            text: 'Request Changes',
                            dataSort: false,
                            dataFormat: (_, row) => (
                              <Fragment>
                                <Icon
                                  type="close"
                                  tabIndex="0"
                                  size="2"
                                  role="button"
                                  aria-label={`Request changes on ${
                                    locale.resume.lower[nation]
                                  }s of ${nation === 'us' ? row.name : row.email}`}
                                  color={red}
                                  onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                      this.setState({ submitError: '' });
                                      modalShow(`deny-resume-${row.profileId}`);
                                    }
                                  }}
                                  onClick={() => {
                                    this.setState({ submitError: '' });
                                    modalShow(`deny-resume-${row.profileId}`);
                                  }}
                                />
                                <ConfirmationModal
                                  name={`deny-resume-${row.profileId}`}
                                  heading="Request Changes"
                                  modalHide={modalHide}
                                  deny
                                  inputPlaceholder="Provide your feedback here..."
                                  error={submitError}
                                  text={
                                    <Fragment>
                                      <Strong>{nation === 'us' ? row.name : row.email}</Strong> will
                                      be notified of your feedback and will be able to re-request a
                                      review in the future.
                                    </Fragment>
                                  }
                                  confirmationButton={
                                    <Button
                                      onClick={message => {
                                        if (!message) {
                                          this.setState({ submitError: 'Feedback is required' });
                                        } else {
                                          this.setState({ submitError: '' });
                                          submit(
                                            {
                                              email: row.email,
                                              subdomain: row.subdomain,
                                              profileId: row.profileId,
                                              resumeApprovalStatus: 'denied',
                                              resumeFeedback: message
                                            },
                                            `deny-resume-${row.profileId}`
                                          );
                                        }
                                      }}
                                    >
                                      Submit Feedback
                                    </Button>
                                  }
                                />
                              </Fragment>
                            )
                          },
                          {
                            hidden: true,
                            isKey: true,
                            dataField: 'profileId',
                            text: 'key column'
                          }
                        ]}
                      />
                    </Collapse>
                  )}
                </HideContentOnMobile>
              </div>
            </Loading>
          )}
        </Card>
      </Margin>
    );
  }
}

ResumeApproval.propTypes = {
  adminEmail: PropTypes.string.isRequired,
  allProfiles: PropTypes.array,
  hasLoadingError: PropTypes.bool,
  isLoaded: PropTypes.bool,
  modalShow: PropTypes.func.isRequired,
  modalHide: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  reloadProfiles: PropTypes.func.isRequired,
  resumes: PropTypes.array,
  whiteLabel: PropTypes.string
};

export default ResumeApproval;
