import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import toRGB from 'hex-rgb';
import { NavLink } from 'react-router-dom';
import { NavPanel } from 'components';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import _ from 'lodash/function';

const SubNavWrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: ${props => (props.hasPadding ? '0 2rem' : '0')};
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    max-width: 120rem;
    padding: ${props => (props.hasPadding ? '0 4rem' : '0')};
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3rem;
    pointer-events: none;
    transition: opacity 0.3s;
    z-index: 1;
    opacity: 0;
    top: 0;
    box-sizing: content-box;
  }

  &::before {
    left: 0;
    padding-left: inherit;
    background: -webkit-linear-gradient(
      right,
      rgba(${props => toRGB(props.backgroundColor).join(',')}, 0),
      ${props => props.backgroundColor}
    );
  }

  &::after {
    right: 0;
    padding-right: inherit;
    background: -webkit-linear-gradient(
      left,
      rgba(${props => toRGB(props.backgroundColor).join(',')}, 0),
      ${props => props.backgroundColor}
    );
  }

  &.fade-left::before,
  &.fade-right::after {
    opacity: 1;
  }
`;

const SubNavPanel = styled(NavPanel)`
  ul {
    scrollbar-width: none;
    position: relative;
  }
  ul::-webkit-scrollbar {
    display: none;
  }
`;

const SubNavLink = styled(({ linkColor, ...props }) => <NavLink {...props} />)`
  color: ${props => props.linkColor || theme.menuColor};
  font-weight: 400;
  text-decoration: none;

  &.active {
    font-weight: 600;
  }
`;

export class SubNav extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();
    this.content = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, true);
    this.handleScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    const { navigation, theme: styles, backgroundColor, hasPadding, linkColor } = this.props;
    return (
      <SubNavWrapper>
        <Content
          innerRef={this.content}
          backgroundColor={backgroundColor || styles.menuBackgroundColor}
          hasPadding={hasPadding}
        >
          <SubNavPanel forwardRef={this.scrollContainer}>
            {navigation.filter(Boolean).map(nav => (
              <SubNavLink
                key={nav.path}
                data-test-selector={`detail-${nav.title.toLowerCase()}-link`}
                to={nav.path}
                linkColor={linkColor}
              >
                {nav.title}
              </SubNavLink>
            ))}
          </SubNavPanel>
        </Content>
      </SubNavWrapper>
    );
  }
  handleScroll = _.throttle(() => {
    const container = this.scrollContainer.current;
    const content = this.content.current;

    // avoid spamming console
    if (!container || !content) return;

    // if there is more to scroll to the right then set the class
    if (container.scrollLeft + container.offsetWidth < container.scrollWidth) {
      content.classList.add('fade-right');
    } else {
      content.classList.remove('fade-right');
    }

    // if there is more to scroll to the left then set the class
    if (container.scrollLeft) {
      content.classList.add('fade-left');
    } else {
      content.classList.remove('fade-left');
    }
  }, 16);
}

SubNav.defaultProps = {
  backgroundColor: '',
  hasPadding: false,
  linkColor: ''
};

SubNav.propTypes = {
  backgroundColor: PropTypes.string,
  navigation: PropTypes.array.isRequired,
  hasPadding: PropTypes.bool,
  linkColor: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default withTheme(SubNav);
