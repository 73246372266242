import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLogo = styled.div`
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 1060px) {
    margin: 0 2rem;
  }
`;

const HomeImage = styled.img`
  max-height: 10rem;
  max-width: 25rem;

  margin: 0 1rem 0 2rem;

  @media (max-width: 1199px) {
    max-height: 8rem;
  }
`;

const Logo = ({ image }) => {
  return (
    <StyledLogo>
      <HomeImage alt="Home Page" src={image} styleName="image" />
    </StyledLogo>
  );
};

Logo.defaultProps = {
  image: 'https://d24eua068y5lt4.cloudfront.net/logos/default.png'
};

Logo.propTypes = {
  image: PropTypes.string.isRequired
};

export default Logo;
