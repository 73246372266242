import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';
import { WidgetBuilderExportButton } from 'components';

const { sapphireD20, lightGray } = color;

const SideHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 8rem;
`;

const TabsWrapper = styled.div`
  display: flex;
  width: 28rem;
  height: 7rem;
  margin: 2rem 3rem;
  margin-top: 0;
  border-bottom: 6px solid ${lightGray};
`;

const TabButton = styled.button`
  background: transparent;
  border: 0;
  border-bottom: 6px solid ${({ isSelected }) => (isSelected ? `${sapphireD20}` : `${lightGray}`)};
  box-shadow: none;
  color: ${sapphireD20};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  height: 6rem;
  margin-top: 1rem;
  padding: 2rem 0;
  text-align: center;
  width: 12.5rem;

  :hover {
    border-bottom: 6px solid ${sapphireD20};
  }
`;

const PreviewTabButton = styled(TabButton)`
  width: 15rem;
  margin-right: 1rem;
`;

const WidgetBuilderTabHeader = ({
  configurations,
  isAlternativeEmbedCode,
  isPreview,
  selectedPrograms,
  setIsPreview,
  siteSettings
}) => {
  const hasExportButton =
    !!selectedPrograms && !!selectedPrograms.length && !isPreview && configurations.isBatch;

  return (
    <SideHeader>
      <TabsWrapper>
        <PreviewTabButton
          isSelected={isPreview}
          onClick={() => setIsPreview(true)}
          role="button"
          aria-pressed={isPreview}
        >
          Widget Preview
        </PreviewTabButton>
        <TabButton
          isSelected={!isPreview}
          onClick={() => setIsPreview(false)}
          role="button"
          aria-pressed={!isPreview}
        >
          Embed Code
        </TabButton>
      </TabsWrapper>
      {hasExportButton && (
        <WidgetBuilderExportButton
          configurations={configurations}
          isAlternativeEmbedCode={isAlternativeEmbedCode}
          selectedPrograms={selectedPrograms}
          siteSettings={siteSettings}
        />
      )}
    </SideHeader>
  );
};

WidgetBuilderTabHeader.propTypes = {
  configurations: PropTypes.shape({
    isBatch: PropTypes.bool
  }).isRequired,
  isAlternativeEmbedCode: PropTypes.bool,
  isPreview: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array,
  setIsPreview: PropTypes.func.isRequired,
  siteSettings: PropTypes.object.isRequired
};

export default WidgetBuilderTabHeader;
