import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { color } from '../../../styles';
import { fetchAllCareers } from '../../../services';
import { CareerMappingForm } from 'containers';
import { Loading, StickyColumnTable } from 'components';

const { lightGreen, lightYellow } = color;

const fetchCareerData = async ({ hasLot, nation, setCareerData }) => {
  const careersResponse = await fetchAllCareers({ hasLot, nation });
  setCareerData(careersResponse);
};

const createDrawers = ({ programData, setRowDrawers, ...props }) => {
  const newDrawers = {};
  for (let index = 0; index < programData.length; index++) {
    const { code } = programData[index];
    newDrawers[code] = {
      height: 52,
      isOpen: false,
      contents: <CareerMappingForm {...props} programData={programData[index]} />
    };
  }
  setRowDrawers(newDrawers);
};

const determineRowColor = ({ careers, createdAt }) => {
  if (!!createdAt) {
    const now = moment();
    const programAgeInHours = now.diff(createdAt, 'hours');
    if (programAgeInHours < 24) {
      return lightGreen;
    }
  }

  if (careers.length === 0) {
    return lightYellow;
  }

  return false;
};

const CareerMappingTable = ({
  fetchProgramData,
  hasLot,
  hasManyInstitutions,
  isLoading,
  nation,
  programData,
  programTotal,
  renderColumns,
  setIsLoading,
  ...tableProps
}) => {
  const [careerData, setCareerData] = useState(false);
  const [rowDrawers, setRowDrawers] = useState({});

  useEffect(() => {
    fetchCareerData({ hasLot, nation, setCareerData });
  }, []);

  useEffect(() => {
    createDrawers({
      careerData,
      fetchProgramData,
      hasLot,
      nation,
      programData,
      setIsLoading,
      setRowDrawers
    });
  }, [careerData]);

  const openDrawer = rowData =>
    setRowDrawers({
      ...rowDrawers,
      [rowData.code]: {
        ...rowDrawers[rowData.code],
        isOpen: !rowDrawers[rowData.code].isOpen
      }
    });

  return (
    <Loading loaded={!isLoading}>
      <StickyColumnTable
        columns={renderColumns({ hasManyInstitutions, nation, openDrawer, rowDrawers })}
        dataCy="program-career-mapping-table"
        hasDrawers
        rowDrawers={rowDrawers}
        determineRowColor={determineRowColor}
        isLoading={isLoading}
        shouldAlternateRowColor={false}
        tableData={programData}
        totalRows={programTotal}
        {...tableProps}
      />
    </Loading>
  );
};

CareerMappingTable.propTypes = {
  fetchProgramData: PropTypes.func,
  hasLot: PropTypes.bool,
  hasManyInstitutions: PropTypes.bool,
  isLoading: PropTypes.bool,
  nation: PropTypes.string,
  programData: PropTypes.array,
  programTotal: PropTypes.number,
  renderColumns: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setIsLoading: PropTypes.func,
  setPageNumber: PropTypes.func
};

export default CareerMappingTable;
