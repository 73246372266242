import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, change, arrayPush, arrayPop, arrayUnshift, arrayRemove } from 'redux-form';
import { withState } from 'recompose';
import { Button, FormInput, MultiSelectOption } from 'components';
import { fetchWithinProximity, fetchGeoNames } from '../../../services';

import { isNumber, overGeoRadMax } from '../../../helpers/validation';

const AddRadius = styled(Button)`
  font-size: 2em;
  font-weight: 300;
  height: 4.25rem;
  margin-left: 1rem;
  margin-top: 2.6rem;
  padding: 0;
  width: 28%;
`;

const RadiusInput = styled(FormInput)`
  width: 72%;

  input {
    height: 4.4rem;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadiusList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  padding-top: 0.5rem;
`;

const RadiusItem = styled.li`
  width: 50%;
`;

const isDisabled = ({ radiusInput, center, radiusRegions }, isSelecting) => {
  if (
    !radiusInput ||
    radiusRegions.length === 8 ||
    overGeoRadMax(radiusInput) ||
    isNumber(radiusInput) ||
    Number(radiusInput) <= 0 ||
    !center ||
    !center.value ||
    isSelecting
  ) {
    return true;
  } else {
    // Check to see if radius is already in the list
    return radiusRegions.some(region => {
      return region.radius === Number(radiusInput);
    });
  }
};

const loadGeosWithinRadius = (centroid, radius, level, nation) =>
  fetchWithinProximity(level, centroid, radius, nation).then(resp => resp.codes);

const loadGeoNames = (level, geoids, nation) =>
  fetchGeoNames(level, geoids, nation).then(resp =>
    resp.map(({ displayName, id }) => ({
      label: displayName,
      value: id
    }))
  );

/**
 * when a user clicks on the radius button to switch out which geoids show up
 * @method handleRadiusChange
 * @param  {string}           level         the level of geographies, msa, state, etc
 * @param  {array}            geoids        array of geoids
 * @param  {string}           areaKey       the key to update which area
 * @param  {number}           radiusIndex   the current selected radius
 * @param  {string}           radius        the input value of the radius
 * @param  {function}         dispatch      redux form dispatch to update state
 */
const handleRadiusChange = async (
  level,
  geoids,
  areaKey,
  radiusIndex,
  radius,
  dispatch,
  nation
) => {
  const geoNames = await loadGeoNames(level.value, geoids, nation);
  dispatch(`${areaKey}.geoids`, geoNames);
  dispatch(`${areaKey}.activeRadiusIndex`, radiusIndex);
};

/**
 * when a user clicks on the 'radius plus' button we fetch the geoids based on centroid, level, and radius number
 * and then fetch the geoNames based on geoids returned from loadGeosWithinRadius request
 * and then update state
 * @method handleRadiusSelection
 * @param  {object}              center        contains the `centroid` lat and long
 * @param  {string}              radiusInput   the value from the radius input
 * @param  {array}               radiusRegions the array inside the `areas` object
 * @param  {string}              level         the level of geographies, msa, state, etc
 * @param  {string}              areaKey       the key to update which area
 * @param  {function}            dispatch      redux form dispatch to update state
 * @param  {array}               radii         an array of radii selections
 */
const handleRadiusSelection = async (
  { center: { centroid }, radiusInput, radiusRegions },
  level,
  areaKey,
  dispatch,
  radii,
  handleSelection,
  nation
) => {
  const geoids = await loadGeosWithinRadius(centroid, radiusInput, level, nation);
  const geoNames = await loadGeoNames(level, geoids, nation);
  const newRadii = {
    radius: Number(radiusInput),
    geoids: geoNames
  };
  const newRadiusCheck = radii.length === 1 && !radiusRegions[0].radius;
  dispatch(`${areaKey}.geoids`, geoNames);
  dispatch(`${areaKey}.activeRadiusIndex`, newRadiusCheck ? 0 : radii.length);
  // If the first area does not have a radius saved to it, we have to add the new
  // values and remove the old ones. We have to do it in that order or state will break.
  // this is used for sites that have already created regions and want to turn them into radiusRegions
  if (newRadiusCheck) {
    // move the new radius created to the beginning of the radiusRegion
    dispatch(radii.name, newRadii, arrayUnshift);
    // remove the last/old radiusRegion that didn't contain a radius
    dispatch(radii.name, null, arrayPop);
  } else {
    // otherwise continue to push into the radiusRegion array the new radius and geoids
    dispatch(radii.name, newRadii, arrayPush);
  }
  // set the local state variable `isSelecting` back to false
  handleSelection(false);
};

const handleRadiusRemoval = (e, area, areaKey, radiusIndex, radii, dispatch, nation) => {
  e.stopPropagation();
  if (radii.length === 1) {
    dispatch(radii.name, radiusIndex, arrayRemove);
    dispatch(`${areaKey}.geoids`, []);
    dispatch(`${areaKey}.radiusInput`, '');
    dispatch(`${areaKey}.center`, { centroid: {} });
  } else {
    // grab the radius and geoids for the previous radiusRegion
    const newIndex = radiusIndex === 0 ? radiusIndex + 1 : radiusIndex - 1;
    const { radius, geoids } = area.radiusRegions[newIndex];
    const { level, activeRadiusIndex } = area;
    const codes = geoids.map(region => region.value || region.id);
    dispatch(radii.name, radiusIndex, arrayRemove);

    handleRadiusChange(
      level,
      codes,
      areaKey,
      activeRadiusIndex === 0 ? activeRadiusIndex : activeRadiusIndex - 1,
      radius,
      dispatch,
      nation
    );
  }
};

const RadiusGroup = withState(
  'isSelecting',
  'handleSelection',
  false
)(
  ({
    fields,
    area,
    areaIndex,
    dispatch,
    formId,
    level,
    isSelecting,
    handleSelection,
    nation,
    parentIndex
  }) => {
    const areaKey = `areas[${areaIndex}]`;
    const dispatchAction = (key, values, action = change) => dispatch(action(formId, key, values));

    return (
      <Fragment>
        <FirstRow>
          <Field
            component={RadiusInput}
            id={`area-radius-${parentIndex}`}
            label="Radius"
            aria-label="Radius"
            name={`${areaKey}.radiusInput`}
            placeholder={`Radius (${nation === 'us' ? 'mi' : 'km'})`}
            type="text"
            validate={[isNumber, overGeoRadMax]}
          />
          <AddRadius
            id={`add-radius-${parentIndex}`}
            label="Add Radius"
            aria-label="Add Radius"
            disabled={isDisabled(area, isSelecting)}
            onClick={() => {
              // trigger the button disabled as soon as you click
              handleSelection(true);
              handleRadiusSelection(
                area,
                level,
                areaKey,
                dispatchAction,
                fields,
                handleSelection,
                nation
              );
            }}
            type="button"
          >
            +
          </AddRadius>
        </FirstRow>
        <RadiusList>
          {fields.map((field, index) => {
            return (
              <RadiusItem key={`radius-item-${index}`}>
                <Field
                  component={({ input: { value } }) => {
                    const selectedArea = value[areaIndex];
                    const { radius, geoids } = selectedArea.radiusRegions[index];
                    // If the geoids come from hitting the 'radius plus' button, we want region.value
                    // If the geoids come from loading data from the sites api, we want region.id
                    const codes = geoids.map(region => region.value || region.id);
                    let distanceUnitAbbreviation =
                      !!selectedArea.distanceUnit && selectedArea.distanceUnit === 'kilometers'
                        ? ' km'
                        : ' mi';
                    return radius ? (
                      <MultiSelectOption
                        active={index === selectedArea.activeRadiusIndex}
                        onRemove={e =>
                          handleRadiusRemoval(
                            e,
                            selectedArea,
                            areaKey,
                            index,
                            fields,
                            dispatchAction,
                            nation
                          )
                        }
                        onClick={() =>
                          handleRadiusChange(
                            area.level,
                            codes,
                            areaKey,
                            index,
                            radius,
                            dispatchAction,
                            nation
                          )
                        }
                      >
                        {`${radius}${distanceUnitAbbreviation}`}
                      </MultiSelectOption>
                    ) : null;
                  }}
                  name="areas"
                />
              </RadiusItem>
            );
          })}
        </RadiusList>
      </Fragment>
    );
  }
);

RadiusGroup.propTypes = {
  fields: PropTypes.object.isRequired,
  area: PropTypes.shape({
    center: PropTypes.object,
    radiusInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    radiusRegions: PropTypes.array
  }).isRequired,
  areaIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  level: PropTypes.string,
  nation: PropTypes.string.isRequired
};

RadiusGroup.defaultProps = {
  level: ''
};

export default RadiusGroup;
