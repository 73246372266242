import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keenTopCareerSearches, keenTopProgramSearches } from '../../services/keen/queries';
import { TabCard, TabBody } from 'components';
import { FetchKeen } from 'containers';
import { selectProgramAlias } from '../../selectors/sites';
import { selectSubdomain } from '../../selectors/subdomain';
import { locale } from '../../helpers/locale';
import { withNationHOC } from '../../hocs/withNation';

const TopSearchesStats = withNationHOC(({ hasPrograms, programAlias, nation }) => {
  const programStatsTab = {
    label: `${locale.edTrack[nation]}s`,
    renderData: () => {
      const query = keenTopProgramSearches;
      if (programAlias) {
        query.extraFilters.property_value.push(programAlias);
      }
      return (
        <FetchKeen {...query}>{props => <TabBody searchType="Programs" {...props} />}</FetchKeen>
      );
    }
  };

  const careerStatsTab = {
    label: 'Careers',
    renderData: () => {
      return (
        <FetchKeen {...keenTopCareerSearches}>
          {props => <TabBody searchType="Careers" {...props} />}
        </FetchKeen>
      );
    }
  };

  // if the site does not have programs the programs/careers tab button will not appear,
  // so the heading is different in that case so the user knows they are looking at career stats

  // the TabCard is expecting a heading and an array of objects each with a label (string) to be used in the tab button and renderData (function) property to render the stats and optionally showSelect (boolean) that determines whether the select box should be rendered for that tab
  return (
    <TabCard
      hasSelect={false}
      heading="Top Searches"
      tabs={hasPrograms ? [careerStatsTab, programStatsTab] : [careerStatsTab]}
    />
  );
});

export const mapStateToProps = ({ profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    hasPrograms: sites.items[currentSite].hasPrograms,
    programAlias: selectProgramAlias(sites),
    subdomain: currentSite
  };
};

TopSearchesStats.propTypes = {
  hasPrograms: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(TopSearchesStats);
