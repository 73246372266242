import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApplicantsModal, ExternalLink, Float, Strong, TextButton, Truncate } from 'components';
import styled from 'styled-components';

const Li = styled.li`
  padding-bottom: 1rem;
`;

const StatItem = ({
  applicants,
  modals,
  modalShow,
  nation,
  postingId,
  selectedApplicant,
  title,
  value,
  url
}) => {
  const hasApplicantModal = nation === 'us' && postingId && value > 0;
  let formattedApplicants = [];
  if (postingId && applicants) {
    applicants.forEach(applicant => {
      const { 'submitted-on': submittedOn, 'resume-id': resumeId, ...rest } = applicant;
      formattedApplicants.push({
        submittedOn,
        resumeId,
        ...rest
      });
    });
  }

  return (
    <Fragment>
      <Li>
        {hasApplicantModal && (
          <TextButton onClick={() => modalShow(`applicant${postingId}`)}>{title}</TextButton>
        )}

        {url && (
          <ExternalLink url={url}>
            <Truncate>{title}</Truncate>
          </ExternalLink>
        )}

        {!url && !hasApplicantModal && <Truncate>{title}</Truncate>}

        <Float component="span">
          <Strong>{value}</Strong>
        </Float>
      </Li>
      {postingId && modals[`applicant${postingId}`] && (
        <ApplicantsModal
          title={`Applicants for ${title}`}
          applicants={formattedApplicants}
          selectedApplicant={selectedApplicant}
          name={`applicant${postingId}`}
        />
      )}
    </Fragment>
  );
};

StatItem.propTypes = {
  applicants: PropTypes.array,
  modals: PropTypes.object,
  modalShow: PropTypes.func,
  nation: PropTypes.string,
  postingId: PropTypes.string,
  selectedApplicant: PropTypes.object,
  title: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.number.isRequired
};

export default StatItem;
