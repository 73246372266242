import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getProfiles } from '../../../services/profiles';
import { withSiteHOC } from '../../../hocs/withSite';
import { AdminInviteForm, Card, HorizontalRule } from 'components';
import { AdminInviteTable, SuperAdminList } from 'containers';

const StyledCard = styled(Card)`
  overflow: visible;
  margin-top: 3rem;
`;

const Header = styled.h2`
  font-size: 2.1rem;
`;

const Row = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const AdministratorSettings = withSiteHOC()(({ isDeveloper, name, nation, subdomain }) => {
  const [adminProfiles, setAdminProfiles] = useState([]);
  const [isInviting, setIsInviting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProfiles = async () => {
    const profilesResponse = await getProfiles(
      subdomain,
      '?isAdmin=true&includeInvited=true&limit=2000'
    );
    const { profiles } = profilesResponse;
    setAdminProfiles(profiles);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isInviting) {
      fetchProfiles();
    }
  }, [isInviting]);

  return (
    <Fragment>
      <StyledCard>
        <Header component="h2" type="title">
          Administrators
        </Header>
        <Row>
          <AdminInviteForm
            name={name}
            nation={nation}
            subdomain={subdomain}
            isInviting={isInviting}
            setIsInviting={setIsInviting}
          />
        </Row>
        <HorizontalRule />
        <Row>
          <AdminInviteTable
            institutionName={name}
            adminProfiles={adminProfiles}
            fetchProfiles={fetchProfiles}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Row>
      </StyledCard>

      {isDeveloper && (
        <StyledCard>
          <Header component="h2" type="title">
            Super Admins
          </Header>
          <SuperAdminList />
        </StyledCard>
      )}
    </Fragment>
  );
});

AdministratorSettings.propTypes = {
  isDeveloper: PropTypes.bool,
  name: PropTypes.string,
  nation: PropTypes.string,
  subdomain: PropTypes.string
};

export default AdministratorSettings;
