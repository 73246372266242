import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingImage from '../../../images/loading-gray.gif';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 2rem;
  ${props => props.isAbsolute && ' position:absolute; top: 0;  bottom: 0;left:0;right:0'};
`;

const Content = styled.div`
  max-width: 10rem;
  text-align: center;
  color: #576b78;
`;

const Image = styled.img`
  width: 50%;
  height: 50%;
  display: block;
  margin: 2.5rem;
`;

const Loading = ({ children, loaded, isAbsolute }) => {
  if (loaded) {
    return <Fragment>{children}</Fragment>;
  }
  return (
    <Wrapper isAbsolute={isAbsolute}>
      <Content>
        <Image alt="Loading" src={LoadingImage} />
        <p>Loading...</p>
      </Content>
    </Wrapper>
  );
};

Loading.propTypes = {
  isAbsolute: PropTypes.bool,
  loaded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object, PropTypes.any])
};

export default Loading;
