import AssessmentWidgetBuilder from '../../components/pages/AssessmentWidgetBuilder';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;
  const { areas, enabled, hasManyInstitutions, nation, name, styles } = items[currentSite];

  const { accentColor, primaryColor } = styles;

  return {
    siteSettings: {
      accentColor,
      areas,
      currentSite,
      hasCcEnabled: enabled,
      hasManyInstitutions,
      nation: nation,
      primaryColor,
      schoolName: name
    }
  };
};

export default connect(mapStateToProps)(AssessmentWidgetBuilder);
