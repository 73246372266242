import React from 'react';
import PropTypes from 'prop-types';
import { Col, Metric, Row } from 'components';
import { AssessmentBySchoolStatCard, FetchKeen } from 'containers';
import { keenAssessmentStarts, keenAssessmentCompletions } from '../../../services/keen/queries';

const Assessment = ({ hasSelectableProfileSchools }) => {
  return (
    <div>
      <Row>
        <Col>
          <FetchKeen {...keenAssessmentStarts}>
            {props => <Metric heading="Assessments Started" {...props} />}
          </FetchKeen>
        </Col>
        <Col>
          <FetchKeen {...keenAssessmentCompletions}>
            {props => <Metric heading="Assessments Completed" {...props} />}
          </FetchKeen>
        </Col>
      </Row>
      {hasSelectableProfileSchools && (
        <Row>
          <AssessmentBySchoolStatCard />
        </Row>
      )}
    </div>
  );
};

Assessment.propTypes = {
  hasSelectableProfileSchools: PropTypes.bool
};

export default Assessment;
