import WidgetStatistics from '../../components/pages/WidgetStatistics';
import { connect } from 'react-redux';

export const mapStateToProps = ({ sites }) => {
  const { currentSite, items } = sites;

  const {
    assessmentWidget: { isEnabled: isAssessmentWidgetEnabled },
    programWidget: { isEnabled: isProgramWidgetEnabled }
  } = items[currentSite].widgetBuilderSettings;

  return {
    isAssessmentWidgetEnabled,
    isProgramWidgetEnabled: isProgramWidgetEnabled,
    nation: items[currentSite].nation
  };
};

export default connect(mapStateToProps)(WidgetStatistics);
