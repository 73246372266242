import moment from 'moment';
import { takeLatest } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchEmployersSuccess,
  updateEmployerSuccess,
  updatePostingsSuccess
} from '../actions/actionCreators';
import * as t from '../actions/constants';
import {
  fetchEmployers,
  updateEmployer,
  updatePostings,
  sendApprovedEmployer,
  sendDeniedEmployer,
  sendWhitelistedEmployer
} from '../services';
import { selectEmployerByEmail } from '../selectors/employers';

export function* fetchEmployersSaga(action) {
  const employers = yield call(fetchEmployers, action.payload);
  employers && (yield put(fetchEmployersSuccess(employers)));
}

export function* updateEmployerSaga({ payload: { subdomain, employerId, message, ...data } }) {
  const site = yield select(state => state.sites.items[subdomain]);
  const employer = yield call(updateEmployer, subdomain, employerId, data);
  if (data.status === 'approved') {
    sendApprovedEmployer(data.email, site.name, subdomain, site.whiteLabel);
  } else if (data.status === 'denied') {
    sendDeniedEmployer(
      data.email,
      site.name,
      subdomain,
      site.employerNotificationEmail,
      message,
      site.whiteLabel
    );
  }
  if (employer) {
    yield put(updateEmployerSuccess(employer));
    // If a current employer is removed,
    // we will also set all of their postings to ended (patch yesterday's date to end-on)
    const postingIds = yield select(({ postings: { items } }) =>
      Object.keys(items).filter(key => items[key].employer.id === employerId)
    );
    if (postingIds.length > 0 && data.status === 'denied') {
      const yesterday = moment()
        .subtract(1, 'days')
        .format('MM-DD-YYYY');
      const postings = yield call(updatePostings, subdomain, postingIds, {
        endOn: yesterday
      });
      postings && (yield put(updatePostingsSuccess(postings)));
    }
  }
}

// When a new employer is added to the employer whitelist, we need to have some updates on them and their postings
export function* whitelistEmployerSaga({ payload: { employerEmail, subdomain } }) {
  const employer = yield select(state => selectEmployerByEmail(state, employerEmail.toLowerCase()));
  // only if an existing employer is added to whitelist
  if (employer) {
    // update employer status to `approved`
    if (employer.status !== 'approved') {
      yield call(updateEmployer, subdomain, employer.id, {
        status: 'approved'
      });
    }
    const site = yield select(state => state.sites.items[subdomain]);
    sendWhitelistedEmployer(employer.email, site.name, subdomain, site.whiteLabel);
    // patch all of the employer's pending postings to `approved`
    const postingIds = yield select(({ postings: { items } }) =>
      Object.keys(items).filter(
        key => items[key].employer.id === employer.id && items[key].status === 'pending'
      )
    );
    if (postingIds.length > 0) {
      const postings = yield call(updatePostings, subdomain, postingIds, {
        publishedOn: moment().format('MM-DD-YYYY'),
        status: 'approved'
      });
      postings && (yield put(updatePostingsSuccess(postings)));
    }
  }
}

export function* fetchEmployersAPI() {
  yield call(takeLatest, t.FETCH_EMPLOYERS_REQUEST, fetchEmployersSaga);
}

export function* updateEmployerAPI() {
  yield call(takeLatest, t.UPDATE_EMPLOYER_REQUEST, updateEmployerSaga);
}

export function* whitelistEmployer() {
  yield call(takeLatest, t.WHITELIST_EMPLOYER, whitelistEmployerSaga);
}
