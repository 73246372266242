import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  &::before {
    content: ' ';
    display: table;
  }

  margin: 0 auto;
  padding: 0 4rem;
`;

const ContentWrap = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default ContentWrap;
