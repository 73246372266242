import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-media';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';

import { ExternalLink, Link } from 'components';
import { Authenticated, HasWidgetBuilder, LogoutLink } from 'containers';
import theme from '../../../styles/theme';
import { withNationHOC } from '../../../hocs/withNation';

const IconButton = styled.button`
  margin: 0.5rem 0;
  padding: 0;
  border: none;
  font-size: 3.5rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  color: ${props => (props.navOpen ? theme.accentColor : theme.menuColor)};
  float: ${props => (props.navOpen ? 'right' : 'initial')};
  align-self: flex-end;
`;

const SideNav = styled.div`
  background: white;
  position: fixed;
  height: 100%;
  width: 80%;
  max-width: 30rem;
  top: 0;
  left: 0;
  z-index: 20;
  box-shadow: ${props => (props.isOpen ? '0 0 4rem rgba(0, 0, 0, 0.4)' : 'none')};
  transition: transform 0.3s;
  transform: ${props => (props.isOpen ? 'translateX(0)' : ' translateX(-100%)')};
  overflow-y: auto;
`;

const Wrapper = styled.div`
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: ${props => (props.isOpen ? 'flex' : ' none')};
`;
const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
`;

const Line = styled.hr`
  width: 100%;
  height: 2px;
  border-width: 0;
  background-color: ${theme.accentColor};
`;

const NavItem = styled(({ active, ...props }) => <Link {...props} />)`
  border-radius: 3px;
  display: block;
  padding: 1rem;
  margin: 0.5rem;
  font-size: 1.5rem;
  color: black;
  background-color: ${props => (props.active ? '#DDD' : ' white')};
`;

const StyledExternalLink = styled(ExternalLink)`
  border-radius: 3px;
  display: block;
  padding: 1rem;
  margin: 0.5rem;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
`;

class MobileMenu extends React.Component {
  static propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
    nation: PropTypes.string,
    subdomain: PropTypes.string.isRequired
  };
  state = { isOpen: false };
  render() {
    const { isOpen } = this.state;
    const path = this.props.location.pathname.split('/')[1];
    return (
      <MediaQuery
        query="(max-width: 1060px)"
        render={() => (
          <Authenticated>
            <div>
              <IconButton
                className="ion-android-menu"
                aria-label="open menu"
                aria-expanded={isOpen}
                onClick={this.handleClick}
                navOpen={isOpen}
              />
              <FocusTrap active={isOpen}>
                <SideNav isOpen={isOpen}>
                  <Wrapper isOpen={isOpen}>
                    <NavWrapper>
                      <IconButton
                        className="ion-android-close"
                        aria-label="close menu"
                        aria-expanded={isOpen}
                        onClick={this.handleClick}
                        navOpen={isOpen}
                      />
                      <HasWidgetBuilder>
                        <NavItem
                          onClick={this.handleClick}
                          active={path === 'widget-builder'}
                          to="/widget-builder"
                        >
                          Widget Builder
                        </NavItem>
                      </HasWidgetBuilder>
                      <NavItem
                        onClick={this.handleClick}
                        active={path === 'analytics'}
                        to="/analytics/statistics"
                      >
                        Analytics
                      </NavItem>
                      <NavItem
                        onClick={this.handleClick}
                        active={path === 'programs'}
                        to="/programs"
                      >
                        Program Information
                      </NavItem>
                      <Authenticated requiresRole="super">
                        <NavItem
                          onClick={this.handleClick}
                          active={path === 'settings'}
                          to="/settings/general"
                        >
                          Settings
                        </NavItem>
                      </Authenticated>
                      <Line />
                      <Authenticated requiresRole="super">
                        <div>
                          <NavItem
                            onClick={this.handleClick}
                            active={path === 'change-site'}
                            to="/change-site"
                          >
                            Change Institution
                          </NavItem>
                          <NavItem
                            onClick={this.handleClick}
                            active={path === 'add-site'}
                            to="/add-site"
                          >
                            Add New Site
                          </NavItem>
                        </div>
                      </Authenticated>
                      <Authenticated requiresRole="admin">
                        <div>
                          {this.props.nation === 'uk' && (
                            <StyledExternalLink url="https://kb.emsicareercoach.co.uk/">
                              Knowledge Base
                            </StyledExternalLink>
                          )}
                          <StyledExternalLink url={`https://${this.props.subdomain}.lightcastcc.com`}>
                            Go to Career Coach
                          </StyledExternalLink>
                        </div>
                      </Authenticated>
                    </NavWrapper>
                    <LogoutLink handleClick={this.handleClick} isButton />
                  </Wrapper>
                </SideNav>
              </FocusTrap>
            </div>
          </Authenticated>
        )}
      />
    );
  }

  handleClick = () => this.setState({ isOpen: !this.state.isOpen });
}

export default withRouter(withNationHOC(MobileMenu));
