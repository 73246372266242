import camelCase from 'camelcase-keys-deep';
import dasherize from 'dasherize';

import { getCognitoToken } from '../';
import { flashError, flashSuccess } from '../../helpers/flashmessage';
import { get, post } from '../utils';
import { locale } from '../../helpers/locale';

export const deleteProgram = async ({ institutionId, subdomain, slug }) => {
  const deleteResponse = await fetch(
    `/api/cc-api/programs/${subdomain}/${slug}?institutionId=${institutionId}`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      })
    }
  );

  if (deleteResponse.status === 200) {
    flashSuccess(`${slug} has been successfully deleted!`);
    return true;
  } else {
    flashError('Sorry, we cannot delete that program at the moment');
    return false;
  }
};

export const fetchProgramCategories = async subdomain => {
  try {
    const response = await fetch(`/api/cc-api/program-categories/${subdomain}?limit=1000`, {
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      })
    });

    const json = await response.json();
    const { data, meta } = json;
    const categories = data.map(({ attributes }) => camelCase(attributes));
    const { count: total } = meta;

    return {
      categories,
      total
    };
  } catch (error) {
    flashError('Sorry, we cannot fetch program categories at the moment');
    return false;
  }
};

export const fetchPrograms = async ({
  categoryIds,
  limit = 20,
  offset = 0,
  searchFields = [
    'careers',
    'code',
    'cip',
    'name',
    'url',
    'institution-id',
    'locations',
    'request-info-email',
    'transfers'
  ],
  searchTerm,
  sortBy,
  subdomain
}) => {
  encodeURIComponent;
  try {
    const response = await fetch(
      `/api/cc-api/programs/${subdomain}?limit=${limit}&offset=${offset}${
        !!sortBy ? `&sort=${sortBy}` : ''
      }${
        !!searchTerm ? `&search=${encodeURIComponent(searchTerm)}&searchFields=${searchFields}` : ''
      }${!!categoryIds ? `&categoryId=${categoryIds}` : ''}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${await getCognitoToken()}`
        })
      }
    );

    if (response.status === 200) {
      const json = await response.json();
      const { data, meta } = json;
      const programs = data.map(({ attributes }) => camelCase(attributes));
      const { count: total } = meta;

      return {
        programs,
        total
      };
    } else if (response.status === 404) {
      return {
        programs: [],
        total: 0
      };
    }
  } catch (error) {
    return error;
  }
};

const fetchProgramsBatch = async ({ subdomain, nation, offset }) => {
  try {
    const response = await fetch(`/api/cc-api/programs/${subdomain}?limit=100&offset=${offset}`, {
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      })
    });

    return response;
  } catch (error) {
    flashError(
      `Sorry, we are unable to load ${locale.edTrack[nation].toLowerCase()} at this point.`
    );
  }
};

export const fetchProgramsBySubdomain = async ({ subdomain, nation }) => {
  let shouldKeepGoing = true;
  let programs = [];
  let offset = 0;

  while (shouldKeepGoing) {
    const response = await fetchProgramsBatch({ subdomain, nation, offset });
    const { data } = await response.json();
    const newBatch = camelCase(data).map(({ attributes }) => attributes);
    if (newBatch.length > 0) {
      programs = [...programs, ...newBatch];
      offset += 100;
    } else {
      shouldKeepGoing = false;
    }
  }
  return programs;
};

export const fetchProgramByCode = async ({ code, subdomain }) =>
  get(
    `/api/cc-api/programs/${subdomain}?code=${code}&limit=1000`,
    null,
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );

export const fetchProgramsBySlug = async (subdomain, programSlugs) => {
  return post(
    `/api/cc-api/programs/${subdomain}/get-programs-by-slug`,
    {
      data: { slugs: programSlugs }
    },
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );
};

export const updateProgram = async ({ data, institutionId, subdomain, slug }) => {
  const deleteResponse = await fetch(
    `/api/cc-api/programs/${subdomain}/${slug}?institutionId=${institutionId}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      }),
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'program',
          attributes: {
            ...dasherize(data)
          }
        }
      })
    }
  );

  if (deleteResponse.status === 200) {
    flashSuccess(`${slug} has been successfully updated!`);
    return true;
  } else {
    flashError('Sorry, we cannot update that program at the moment');
    return false;
  }
};

export const createProgram = async ({ data, subdomain }) => {
  const postResponse = await fetch(`/api/cc-api/programs/${subdomain}`, {
    headers: new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    }),
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'program',
        attributes: {
          ...dasherize(data)
        }
      }
    })
  });

  if (postResponse.status === 201) {
    flashSuccess('A new program has been successfully created!');
    const json = await postResponse.json();
    return camelCase(json.data.attributes);
  } else {
    flashError('Sorry, we cannot create that program at the moment');
    return false;
  }
};
