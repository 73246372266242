import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { color } from '../../../styles';
import { isEmail } from '../../../helpers/validation';
import { fetchToken } from '../../../services/inviteToken';
import { sendAdminInvitation } from '../../../services/mandrill';
import { createProfile, getProfiles } from '../../../services/profiles';
import { LoadingButton, Input } from 'components';

const { red } = color;

const SectionHeader = styled.label`
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
`;

const Text = styled.p`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const InvitationForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 660px) {
    flex-direction: row;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 4rem;

  @media (min-width: 660px) {
    width: 37rem;
  }
`;

const Button = styled(LoadingButton)`
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  margin-left: 0;
  line-height: 1;
  white-space: nowrap;

  @media (min-width: 660px) {
    width: inherit;
    margin-top: 0;
    margin-left: 2rem;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 0.5rem;
  color: ${red};
`;

const AdminInviteForm = ({ isInviting, name, nation, setIsInviting, subdomain }) => {
  const [email, setEmail] = useState();
  const [invitationError, setInvitationError] = useState(false);

  const handleSubmit = async () => {
    setIsInviting(true);
    // check if profile with that email already exists
    const checkProfilesResponse = await getProfiles(
      subdomain,
      `?search=${encodeURIComponent(email)}&includeInvited=true`
    );
    const { profiles } = checkProfilesResponse;
    if (profiles.length > 0) {
      setIsInviting(false);
      return setInvitationError(
        'A profile already exists with this email address. Please navigate to Analytics -> Profiles and grant the user admin access there.'
      );
    }

    // create a temporary profileId
    const profileId = `invite-${uuidv4()}`;

    // get the token
    const token = await fetchToken({ nation, profileId, subdomain });
    if (!token) {
      setIsInviting(false);
      return setInvitationError('invitation token could not be generated');
    }

    // POST the invitee profile
    const data = {
      data: {
        type: 'profile',
        attributes: {
          email: email.toLowerCase(),
          'invitation-date': moment(),
          'is-invited': true,
          subdomain,
          'profile-id': profileId,
          'user-role': 'admin'
        }
      }
    };
    const createProfileResponse = await createProfile({ subdomain, data });
    if (createProfileResponse === 'error') {
      setIsInviting(false);
      return setInvitationError('invitation profile creation failed');
    }

    // send the invitation email
    const sendInviteEmailResponse = await sendAdminInvitation({
      institutionName: name,
      token,
      email
    });
    if (sendInviteEmailResponse.status !== 200) {
      setIsInviting(false);
      return setInvitationError('send invitation email failed');
    }

    // Success! reset the form
    setEmail();
    setIsInviting(false);
  };

  return (
    <Fragment>
      <SectionHeader id="invite-admins">Create New Admins</SectionHeader>
      <Text>
        Enter the email address for each new admin for this account. Note: if they are currently CC
        Admins they don't need to be entered here.
      </Text>
      <InvitationForm>
        <StyledInput
          aria-labelledby="invite-admins"
          placeholder="Email"
          value={email || ''}
          onChange={({ target: { value } }) => setEmail(value)}
          onBlur={() => {
            const validationError = isEmail(email);
            if (!!validationError) {
              setInvitationError(validationError);
            } else {
              setInvitationError(false);
            }
          }}
          onFocus={() => setInvitationError(false)}
        />
        <Button
          disabled={!email || !!invitationError || isInviting}
          isLoading={isInviting}
          onClick={handleSubmit}
        >
          Send Activation Email
        </Button>
      </InvitationForm>
      {!!invitationError && <ErrorMessage>{invitationError}</ErrorMessage>}
    </Fragment>
  );
};

AdminInviteForm.propTypes = {
  isInviting: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  nation: PropTypes.string.isRequired,
  setIsInviting: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default AdminInviteForm;
