import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import { Badge, Button, ConfirmationModal, Icon, PostingsTable, Strong } from 'components';
import { sortDates } from '../../../helpers/sort';
import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';

const { green, red } = color;
const Table = styled(PostingsTable)`
  /* first column heading and data */
  th:first-child,
  td:first-child {
    width: 20rem;
  }

  /* Deny Column Styles */
  th:last-child,
  td:last-child {
    text-align: right !important;
    width: 7rem;
  }
  td:last-child {
    padding-right: 8px;
  }

  /* Approve Column Styles */
  th:nth-last-child(2),
  td:nth-last-child(2) {
    text-align: center !important;
    width: 7rem;
  }
`;

const PendingPostings = ({
  data,
  employers,
  enabledJobTypes,
  modalHide,
  modals,
  modalShow,
  nation,
  updatePosting
}) => {
  return (
    <Table
      data={data}
      employers={employers}
      enabledJobTypes={enabledJobTypes}
      modals={modals}
      modalShow={modalShow}
      options={{
        defaultSortName: 'createdOn',
        defaultSortOrder: 'desc',
        sortFunc: sortDates
      }}
      postingType="pending"
      tableColumns={[
        {
          dataField: 'createdOn',
          text: 'Date Created',
          sortFunc: sortDates,
          dataFormat: cell =>
            cell ? moment(cell, 'MM-DD-YYYY').format(locale.date[nation]) : '---'
        },
        {
          dataField: 'endOn',
          text: 'End Date',
          sortFunc: sortDates,
          dataFormat: cell =>
            cell ? moment(cell, 'MM-DD-YYYY').format(locale.date[nation]) : '---'
        },
        {
          dataField: 'jobType',
          text: 'Type',
          dataFormat: cell => <Badge type={cell}>{cell}</Badge>
        },
        {
          dataField: null,
          text: 'Approve',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="checkmark"
                size="2"
                tabIndex="0"
                role="button"
                aria-label={`Approve Posting: ${row.title}`}
                color={green}
                onKeyDown={e => e.keyCode === 13 && modalShow(`approve${row.postingId}`)}
                onClick={() => modalShow(`approve${row.postingId}`)}
              />
              {modals[`approve${row.postingId}`] && (
                <ConfirmationModal
                  name={`approve${row.postingId}`}
                  heading="Approve Posting?"
                  modalHide={modalHide}
                  text={
                    <Fragment>
                      This posting: <Strong>{row.title}</Strong>, will become live on Career Coach
                      and can be ended at any time by you or the employer.
                    </Fragment>
                  }
                  confirmationButton={
                    <Button
                      onClick={() => {
                        const employer = employers[row.employer.id];
                        updatePosting({
                          employer: {
                            id: employer.id,
                            name: employer.name,
                            email: employer.email,
                            location: employer.location
                          },
                          endOn: row.endOn,
                          postingId: row.postingId,
                          publishedOn: moment().format('MM-DD-YYYY'),
                          status: 'approved',
                          subdomain: row.subdomain,
                          title: row.title
                        });
                        modalHide(`approve${row.postingId}`);
                      }}
                    >
                      Approve
                    </Button>
                  }
                />
              )}
            </Fragment>
          )
        },
        {
          dataField: null,
          text: 'Deny',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="close"
                tabIndex="0"
                size="2"
                role="button"
                aria-label={`Deny Posting: ${row.title}`}
                color={red}
                onKeyDown={e => e.keyCode === 13 && modalShow(`deny${row.postingId}`)}
                onClick={() => modalShow(`deny${row.postingId}`)}
              />
              {modals[`deny${row.postingId}`] && (
                <ConfirmationModal
                  name={`deny${row.postingId}`}
                  heading="Deny Posting?"
                  modalHide={() => modalHide(`deny${row.postingId}`)}
                  deny
                  text={
                    <Fragment>
                      Do you wish to deny the <Strong>{row.title}</Strong> posting? <br /> The
                      employer will be alerted automatically and may edit the posting for
                      re-approval.
                    </Fragment>
                  }
                  confirmationButton={
                    <Button
                      color="#ffffff"
                      background={red}
                      onClick={message => {
                        const { email, id, location, name } = employers[row.employer.id];
                        updatePosting({
                          postingId: row.postingId,
                          employer: { email, id, location, name },
                          endOn: moment().format('MM-DD-YYYY'),
                          status: 'denied',
                          subdomain: row.subdomain,
                          title: row.title,
                          message
                        });
                        modalHide(`deny${row.postingId}`);
                      }}
                    >
                      Deny
                    </Button>
                  }
                />
              )}
            </Fragment>
          )
        }
      ]}
    />
  );
};

PendingPostings.propTypes = {
  data: PropTypes.object.isRequired,
  employers: PropTypes.object.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  updatePosting: PropTypes.func.isRequired
};

export default withTheme(PendingPostings);
