import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LocationSettingsForm } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';
import { formatAreas } from '../../helpers/format-areas';
import { selectGeoLevels } from '../../helpers/selectGeoLevels';

const LocationSettingsFormContainer = ({ patchSite, currentSite: subdomain, ...rest }) => {
  return (
    <LocationSettingsForm
      {...rest}
      saveLocationSettings={({ areas }) => {
        patchSite({
          subdomain,
          areas: formatAreas(areas)
        });
      }}
    />
  );
};

LocationSettingsFormContainer.propTypes = {
  currentSite: PropTypes.string.isRequired,
  nation: PropTypes.string,
  patchSite: PropTypes.func.isRequired
};

const mapStateToProps = ({ sites: { items, currentSite } }) => {
  const site = items[currentSite];
  const activeRadiusIndex = 0;
  return {
    currentSite,
    areasLength: site.areas.length,
    initialValues: {
      areas: site.areas.map(({ ...rest }) => ({
        ...rest,
        // Show the geoids for the first region
        geoids: rest.radiusRegions[activeRadiusIndex].geoids.map(geo => ({
          label: geo.name,
          value: geo.id
        })),
        center: {
          label: rest.center.name,
          value: rest.center.name,
          centroid: rest.center.centroid
        },
        radiusInput: rest.radiusRegions[activeRadiusIndex].radius || '',
        activeRadiusIndex: activeRadiusIndex,
        level: { label: rest.level, value: selectGeoLevels(site.nation)[rest.level].id }
      }))
    },
    hasJobPostings: site.hasJobPostings,
    nation: site.nation,
    selectLevels: selectGeoLevels(site.nation)
  };
};

export { LocationSettingsFormContainer as PureLocationSettingsFormContainer }; // used in tests

export default connect(mapStateToProps, {
  patchSite: patchSiteRequest
})(LocationSettingsFormContainer);
