import * as types from '../actions/constants';
import moment from 'moment';

export const defaultStatTimeframe = {
  start: moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD'),
  key: '7 Days'
};

export default function(state = defaultStatTimeframe, action) {
  switch (action.type) {
    case types.UPDATE_STATS_DATES:
      return {
        ...action.payload
      };

    default:
      return state;
  }
}
