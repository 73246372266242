import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, StatCard } from 'components';
import {
  FetchKeen,
  CareersProgramsStats,
  TopSearchesStats,
  TopInfoRequestsStats
} from 'containers';
import { keenTopCareersByJobPosting } from '../../../services/keen/queries';
import { withSiteHOC } from '../../../hocs/withSite';
import TopFavoritesStats from '../../../containers/TopFavoritesStats';

const Activity = withSiteHOC()(({ hasJobPostings, requestEmail, subdomain }) => {
  return (
    <div>
      <Row>
        <Col>
          <CareersProgramsStats />
        </Col>
        <Col>
          <TopFavoritesStats key={`${subdomain}-favorites`} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TopSearchesStats />
        </Col>
        {hasJobPostings && (
          <Col>
            <FetchKeen {...keenTopCareersByJobPosting}>
              {props => <StatCard {...props} heading="Top Careers by Job Posting Clicks" />}
            </FetchKeen>
          </Col>
        )}
      </Row>
      {requestEmail && (
        <Row>
          <Col>
            <TopInfoRequestsStats />
          </Col>
        </Row>
      )}
    </div>
  );
});

Activity.defaultProps = {
  hasJobPostings: false
};

Activity.propTypes = {
  hasJobPostings: PropTypes.bool
};

export default Activity;
