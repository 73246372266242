import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'components';

const ProfilesChart = props => {
  return (
    <Chart
      {...props}
      legendNames={['Profiles Created']}
      subheading="Total Profiles Created"
      a11yCaption="Chart of profiles created by month."
      a11ySummary="Overall total profiles created."
      a11yHeader="Dates"
    />
  );
};

ProfilesChart.propTypes = {
  data: PropTypes.object,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  loaded: PropTypes.bool
};

export default ProfilesChart;
