import React from 'react';
import styled from 'styled-components';
import { Card, ContentWrap } from 'components';

const StyledContentWrap = styled(ContentWrap)`
  margin-top: 3rem;
`;

const StyledCard = styled(Card)`
   padding: 0;
`;

const Padding = styled.div`
  padding: 2.5rem 2rem 2.5rem;
`;

const Statistics = () => {
  return (
    <StyledContentWrap>
      <StyledCard>
        <Padding>
          No products are enabled to show statistics for. Please enable one of the career coach
          products to view analytics for that product.
        </Padding>
      </StyledCard>
    </StyledContentWrap>
  );
};

export default Statistics;
