import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { DateFilter } from 'components';
import { updateStatsDates } from '../../actions/actionCreators';
import { selectHasProfileSchools } from '../../selectors/sites';

export class DateFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: {
        '7 Days': {
          end: moment().format('YYYY-MM-DD'),
          start: moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD')
        },
        '2 Weeks': {
          end: moment().format('YYYY-MM-DD'),
          start: moment()
            .subtract(2, 'weeks')
            .format('YYYY-MM-DD')
        },
        '1 Month': {
          end: moment().format('YYYY-MM-DD'),
          start: moment()
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
        },
        '3 Months': {
          end: moment().format('YYYY-MM-DD'),
          start: moment()
            .subtract(3, 'months')
            .format('YYYY-MM-DD')
        },
        '6 Months': {
          end: moment().format('YYYY-MM-DD'),
          start: moment()
            .subtract(6, 'months')
            .format('YYYY-MM-DD')
        }
      }
    };

    // Add 11 previous months to dates and dateRangeKeys
    for (let i = 1; i <= 11; i++) {
      let month = moment().subtract(i, 'months');
      this.state.dates[month.format('MMMM YYYY')] = {};
      this.state.dates[month.format('MMMM YYYY')].start = month
        .startOf('month')
        .format('YYYY-MM-DD');
      this.state.dates[month.format('MMMM YYYY')].end = month
        .endOf('month')
        .add(1, 'day') // To include the data of the final day of the month
        .format('YYYY-MM-DD');
    }
  }

  render() {
    const { hasProfileSchools, isDeveloper, isSuperAdmin, selectedDateRange } = this.props;
    return (
      <DateFilter
        {...this.state}
        dateRangeKeys={Object.keys(this.state.dates)}
        handleDateUpdate={this.handleDateUpdate}
        isDeveloper={isDeveloper}
        isSuperAdmin={isSuperAdmin}
        hasProfileSchools={hasProfileSchools}
        selectedDateRange={selectedDateRange}
      />
    );
  }
  /**
   * updates the `dates` state value for either the start or end date
   * @method handleDateUpdate
   * @param  {Object}         value the newly-selected value from SelectDateRange
   * @return {Function}             function to update the global stats date values
   */
  handleDateUpdate = value => {
    // clearSelection() in SelectAutoComplete will send a null value
    if (value !== null) {
      const newStatsObj = {
        key: value,
        ...this.state.dates[value]
      };
      this.props.updateStatsDates(newStatsObj);
    }
  };
}

DateFilterContainer.propTypes = {
  hasProfileSchools: PropTypes.bool.isRequired,
  isDeveloper: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  selectedDateRange: PropTypes.string.isRequired,
  updateStatsDates: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateStatsDates
};

export const mapStateToProps = ({ profile, sites, stats }) => {
  return {
    hasProfileSchools: selectHasProfileSchools(sites, sites.currentSite),
    isDeveloper: profile.isDeveloper,
    isSuperAdmin: profile.isSuperAdmin,
    selectedDateRange: stats.key
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilterContainer);
