import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from 'containers';
import { InfoBody } from 'components';

const Title = styled.h2`
  padding: 3rem 5rem;
  margin-bottom: 1rem !important;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.5rem;
`;

const InfoModal = ({ name, title, ...rest }) => {
  return (
    <Modal name={name}>
      <Fragment>
        <Title type="headline" component="h2">
          {title}
        </Title>
        <InfoBody {...rest} />
      </Fragment>
    </Modal>
  );
};

InfoModal.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired
};

export default InfoModal;
