import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Authenticated = ({ children, loggedIn, requiresRole, userRole }) => {
  const allowed = loggedIn && (!requiresRole || requiresRole === userRole);
  return allowed ? children : null;
};

const mapStateToProps = ({ profile }) => ({
  loggedIn: profile.loggedIn,
  userRole: profile.customData.userRole
});

Authenticated.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  requiresRole: PropTypes.string,
  userRole: PropTypes.string
};

export default connect(mapStateToProps)(Authenticated);
