import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components';
import { color, borderRadius } from '../../../styles';
import theme from '../../../styles/theme';

const Wrapper = styled.div`
  background: ${props => (props.active ? theme.linkColor : color.lightGray)};
  color: ${props => (props.active ? color.lightGray : theme.linkColor)};
  padding: 0 5px;
  border-radius: ${borderRadius};
  font-size: 1.4rem;
  margin: 2px;
  ${({ hasOnClick }) =>
    hasOnClick
      ? `
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    `
      : `
      display: inline-block;
      cursor: default;
    `};
`;

const MultiSelectOption = ({ children, onClick, onRemove, active = false, className }) => {
  return (
    <Wrapper hasOnClick={onClick ? true : false} active={active} className={className}>
      <span onClick={onClick}>{children} </span>
      <Icon
        type="close"
        size="3"
        onClick={onRemove}
        color={active ? `${color.lightGray}` : `${theme.linkColor}`}
      />
    </Wrapper>
  );
};

MultiSelectOption.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  onRemove: PropTypes.func.isRequired
};

export default MultiSelectOption;
