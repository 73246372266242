import {
  startOfMonth,
  endOfMonth,
  format,
  isSameDay,
  subDays,
  subMonths,
  subWeeks
} from 'date-fns';

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: '7 Days',
    range: () => ({
      startDate: subWeeks(new Date(), 1),
      endDate: subDays(new Date(), 1)
    })
  },
  {
    label: '2 Weeks',
    range: () => ({
      startDate: subWeeks(new Date(), 2),
      endDate: subDays(new Date(), 1)
    })
  },
  {
    label: '1 Month',
    range: () => ({
      startDate: subMonths(new Date(), 1),
      endDate: subDays(new Date(), 1)
    })
  },
  {
    label: '3 Months',
    range: () => ({
      startDate: subMonths(new Date(), 3),
      endDate: subDays(new Date(), 1)
    })
  },
  {
    label: '6 Months',
    range: () => ({
      startDate: subMonths(new Date(), 6),
      endDate: subDays(new Date(), 1)
    })
  },
  {
    label: '12 Months',
    range: () => ({
      startDate: subMonths(new Date(), 12),
      endDate: subDays(new Date(), 1)
    })
  },

  {
    label: `${format(subMonths(new Date(), 1), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 1))
    })
  },
  {
    label: `${format(subMonths(new Date(), 2), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 2)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 2))
    })
  },
  {
    label: `${format(subMonths(new Date(), 3), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 3))
    })
  },
  {
    label: `${format(subMonths(new Date(), 4), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 4)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 4))
    })
  },
  {
    label: `${format(subMonths(new Date(), 5), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 5)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 5))
    })
  },
  {
    label: `${format(subMonths(new Date(), 6), 'MMMM, yyyy')}`,
    range: () => ({
      startDate: startOfMonth(subMonths(new Date(), 6)),
      endDate: endOfMonth(subMonths(subDays(new Date(), 1), 6))
    })
  }
]);
