import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AssessmentWidgetBuilderEmbed,
  AssessmentWidgetBuilderPreview,
  AssessmentWidgetBuilderSidebar,
  HideContentOnMobile,
  Layout,
  WidgetBuilderTabHeader
} from 'components';

const Wrapper = styled.div`
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 2.5rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 0 2.5rem 2rem;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const WidgetBuilderOutput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
  overflow-x: auto;
`;

const EmbedWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const AssessmentWidgetBuilder = ({ siteSettings }) => {
  const { accentColor, areas, currentSite, hasCcEnabled, primaryColor } = siteSettings;

  const defaultLocation = areas[0];
  const [configurations, setConfigurations] = useState({
    accentColor: accentColor,
    clientId: currentSite,
    hasCcLink: !!hasCcEnabled,
    locationName: defaultLocation.name,
    primaryColor: primaryColor
  });

  const [isPreview, setIsPreview] = useState(true);

  return (
    <Layout title="Assessment Widget Builder">
      <Wrapper>
        <HideContentOnMobile message="The Widget Builder is only available on desktop!">
          <Card>
            <AssessmentWidgetBuilderSidebar
              configurations={configurations}
              siteSettings={siteSettings}
              setConfigurations={setConfigurations}
            />
            <WidgetBuilderOutput>
              <WidgetBuilderTabHeader
                configurations={configurations}
                isPreview={isPreview}
                setIsPreview={setIsPreview}
                siteSettings={siteSettings}
              />
              {!isPreview && (
                <EmbedWrapper>
                  <AssessmentWidgetBuilderEmbed
                    clientId={currentSite}
                    configurations={configurations}
                  />
                </EmbedWrapper>
              )}
              <AssessmentWidgetBuilderPreview
                isHidden={!isPreview}
                configurations={configurations}
                siteSettings={siteSettings}
              />
            </WidgetBuilderOutput>
          </Card>
        </HideContentOnMobile>
      </Wrapper>
    </Layout>
  );
};

AssessmentWidgetBuilder.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  siteSettings: PropTypes.shape({
    hasCcEnabled: PropTypes.bool.isRequired,
    nation: PropTypes.string.isRequired
  })
};

export default AssessmentWidgetBuilder;
