import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ColorPicker } from 'components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;

const WidgetBuilderColorPicker = ({ defaultColor, handleChange, label }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <ColorPicker
        id={`${label}-color-picker`}
        pickedColor={defaultColor}
        handleChange={handleChange}
      />
    </Wrapper>
  );
};

WidgetBuilderColorPicker.propTypes = {
  defaultColor: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default WidgetBuilderColorPicker;
