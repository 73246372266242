import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import BlueI from '../../../images/blue-i.svg';
import { Modal } from 'containers';
import { Card } from 'components';

const { mediumGray } = color;

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  overflow: visible;

  @media (min-width: 1042px) {
    border-radius: 3px;
    width: ${({ width }) => `${width}rem`};
  }
`;

const Header = styled.h2`
  margin-bottom: 2.2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const HeaderIcon = styled(BlueI)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
`;

const HorizontalRule = styled.div`
  position: relative;
  left: -3rem;
  width: calc(100% + 6rem);
  border-top: 1px solid ${mediumGray};
`;

const TextWrapper = styled.div`
  margin: 1.5rem 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ isJustOneButton }) => (isJustOneButton ? 'flex-end' : 'space-between')};
`;

const ProgramTableModal = ({ buttons, name, width = 50, headingText, text }) => {
  const renderButton = button =>
    React.cloneElement(button, {
      onClick: () => button.props.onClick()
    });
  return (
    <Modal name={name} width={width}>
      <Wrapper width={width}>
        <Header>
          {headingText && (
            <Fragment>
              <HeaderIcon />
              {headingText}
            </Fragment>
          )}
        </Header>
        <HorizontalRule />
        {!!text && <TextWrapper>{text}</TextWrapper>}
        {!!buttons && buttons.length > 0 && (
          <ButtonWrapper isJustOneButton={buttons.length === 1}>
            {buttons.map(button => renderButton(button))}
          </ButtonWrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

ProgramTableModal.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.element),
  name: PropTypes.string.isRequired,
  headingText: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.number
};

export default ProgramTableModal;
