import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';

const { red } = color;

const styles = {
  error: red,
  success: '#41d492',
  warning: '#f14802',
  default: '#203a45'
};

const Message = styled.p`
  color: ${({ type }) => styles[type]};
`;

Message.defaultProps = {
  type: 'default'
};

Message.propTypes = {
  type: PropTypes.string.isRequired
};

export default Message;
