export function processKeenResult(res, resultKey, isMultiSchool = false) {
  return (
    res
      .map(result => {
        // this is the step where we remove the domain from the url
        if (resultKey === 'referrer_url' && result[resultKey]) {
          // split the referrer_url on `//` if `//` exists in the url
          let url = result[resultKey].includes('//')
            ? result[resultKey].split('//')[1]
            : result[resultKey];
          // if www is included in the url split again on that
          // this also filters out any urls that begin with `http` or `https`
          if (url.includes('www')) {
            url = url.split('www.')[1];
          }
          return {
            long_url: result[resultKey],
            referrer_url: url,
            result: result.result
          };
        }
        // Truncate spaces at the end of searchTerm, and convert to lowercase.
        // For example, "nursing" and " Nursing " will have counts added up into one entry
        if (resultKey === 'searchTerm' && result[resultKey]) {
          const resultData = {
            searchTerm: result[resultKey].trim().toLowerCase(),
            result: result.result
          };
          if (isMultiSchool) resultData.schoolName = result.schoolName;
          return resultData;
        }
        if (resultKey === 'page_url' && result[resultKey]) {
          const resultData = {
            page_url: result[resultKey].split('?')[0],
            result: result.result
          };
          if (isMultiSchool) resultData.schoolName = result.schoolName;
          return resultData;
        }
        return result;
      })
      // result is initialized with an empty array for referrer_url otherwise its the data from the previous function
      // the current value coming from the map return
      .reduce(
        (result, current) => {
          // only build the array for the Top Referrals or Top Searches stat card
          // make sure there is a referrer_url or searchTerm in the `current` object
          // check if value is truthy and exclude entries where value is null or empty string (e.g. empty searchTerm or null referral_url)
          if (
            (resultKey === 'referrer_url' ||
              resultKey === 'searchTerm' ||
              resultKey === 'page_url') &&
            current[resultKey]
          ) {
            // check the results for where in the array the string values match
            // occurs is the index of `result` array
            let occurs = result.reduce((number, item, i) => {
              return item[resultKey] === current[resultKey] ? i : number;
            }, -1);
            // if there is match add the results together
            if (occurs >= 0) {
              result[occurs].result += current.result;
            } else {
              // just push into the empty array the current object
              result.push(current);
            }
          }
          // otherwise just send the data on
          return result;
          // setup a blank array for referrer_url or searchTerm to build into
          // otherwise we use the data coming from the response
        },
        resultKey === 'referrer_url' || resultKey === 'searchTerm' || resultKey === 'page_url'
          ? []
          : res
      )
      // filter out any null or empty values
      .filter(result => result[resultKey] !== null && result[resultKey] !== '')
      // sort descending
      .sort((a, b) => b.result - a.result)
  );
}

export const combineAssessmentBySchoolData = (startsBySchool, completionsBySchool) => {
  const completionsBySchoolObject = {};
  for (let i = 0; i < completionsBySchool.length; i++) {
    const school = completionsBySchool[i].schoolName;
    completionsBySchoolObject[school] = completionsBySchool[i].result;
  }

  const data = startsBySchool
    .filter(({ schoolName }) => schoolName && schoolName !== '')
    .map(({ schoolName, result }) => ({
      schoolName,
      starts: result,
      completions: completionsBySchoolObject[schoolName] || 0
    }))
    .sort((a, b) => (a.completions > b.completions ? -1 : 1));
  return data;
};
