import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { ProfilesChart } from 'components';
import { fetchKeenData } from '../../services';
import { keenProfilesCreated } from '../../services/keen/queries';
import { reduceTimeLabels } from '../../helpers/timeLabels';
import { selectSubdomain } from '../../selectors/subdomain';

class ProfileChartContainer extends React.Component {
  static propTypes = {
    subdomain: PropTypes.string.isRequired,
    timeframe: PropTypes.object.isRequired
  };

  state = {
    data: {},
    heading: '0',
    loaded: false
  };

  // on inital load, grab the data from the currentSite in state`
  componentDidMount() {
    this.getData();
  }
  // called when the site changes or the timeframe for analytics changes
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ loaded: false }, () => this.getData());
    }
  }

  render() {
    return <ProfilesChart {...this.state} {...this.props} />;
  }

  async getData() {
    const { subdomain, timeframe } = this.props;
    const res = await fetchKeenData({
      subdomain,
      timeframe,
      ...keenProfilesCreated
    }).then(response => {
      const totalProfilesCreated = response.map(({ timeframe: time, value }) => ({
        // meta tag is used for Tooltip text in Chartist
        meta: `${moment(time.start).format('MMM D, YYYY')}<br>Total Created`,
        value
      }));
      // add up the total visits
      const total = totalProfilesCreated.reduce((sum, current) => {
        sum += current.value;
        return sum;
      }, 0);
      const labels = response.map(item => {
        return item.timeframe.start;
      });
      return {
        data: {
          series: [totalProfilesCreated],
          labels
        },
        heading: new Intl.NumberFormat().format(total)
      };
    });
    // format the labels for the chart
    const { data } = reduceTimeLabels(res);
    this.setState({
      data,
      heading: res.heading,
      loaded: true
    });
  }
}

export const mapStateToProps = ({ profile, sites, stats }) => ({
  // figure out which default site to query the keen data for the client's subdomain or the default `testsite` one
  subdomain: selectSubdomain(sites, profile),
  timeframe: stats
});

export { ProfileChartContainer as PureProfileChartContainer }; // used in tests

export default connect(mapStateToProps)(ProfileChartContainer);
