import React from 'react';
import { connect } from 'react-redux';
import { selectSubdomain } from '../selectors/subdomain';

export const WithNation = connect(({ profile, sites }) => ({
  nation: sites.items[selectSubdomain(sites, profile)].nation
}))(({ children, ...props }) => children(props));

export const withNationHOC = WrappedComponent =>
  connect(({ profile, sites }) => ({
    nation: sites.items[selectSubdomain(sites, profile)].nation
  }))(props => <WrappedComponent {...props} />);
