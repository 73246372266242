import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { logout as logoutAction } from '../../actions/actionCreators';
import { Button } from 'components';

// LogoutLink is actually a button, but styled like a link
const LogoutLink = styled.button`
  height: 100px; //so that focus outline is consistent with other NavItems
  color: ${theme.menuColor};
  text-decoration: none;
  background: transparent;
  font-weight: normal;
  border: none;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
  }
`;

const LogoutLinkContainer = ({ logout, isButton, handleClick, history }) => {
  const Component = isButton ? Button : LogoutLink;
  return (
    <Component
      onClick={e => {
        e.preventDefault();
        logout();
        handleClick && handleClick();
        history.push('/login');
      }}
    >
      Logout
    </Component>
  );
};

LogoutLinkContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  isButton: PropTypes.bool,
  handleClick: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired
};

export default withRouter(connect(null, { logout: logoutAction })(LogoutLinkContainer));
