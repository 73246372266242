import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';

const { sapphireD20 } = color;

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
`;

const AlphabetButton = styled.button`
  margin-right: 0.5rem;
  padding: 0.5rem;
  background: none;
  border: none;
  border-bottom: ${({ isSelected }) =>
    isSelected ? `3px solid ${sapphireD20}` : '3px solid white'};
  color: ${({ disabled }) => (disabled ? 'gray' : `${sapphireD20}`)};
  font-size: 1.3rem;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '500')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
];

const AlphabeticalScrollTo = ({ filteredPrograms }) => {
  const [selectedLetter, setSelectedLetter] = useState();

  const getFirstOfEachLetter = () => {
    const newObject = {};
    for (let index = 0; index < alphabet.length; index++) {
      const currentLetter = alphabet[index];
      for (let jndex = 0; jndex < filteredPrograms.length; jndex++) {
        const { code, slug } = filteredPrograms[jndex];
        if (slug[0] === currentLetter) {
          newObject[currentLetter] = code;
          break;
        }
      }
    }
    return newObject;
  };

  const scrollToProgram = code => {
    if (code) {
      let selectedElement = document.getElementsByClassName(code)[0];
      selectedElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const firstOfEachLetter = getFirstOfEachLetter();

  return (
    <Wrapper>
      {alphabet.map(currentLetter => (
        <AlphabetButton
          key={`alphabetlink-${currentLetter}`}
          onClick={() => {
            scrollToProgram(firstOfEachLetter[currentLetter]);
            setSelectedLetter(currentLetter);
          }}
          disabled={!firstOfEachLetter[currentLetter]}
          isSelected={selectedLetter === currentLetter}
        >
          {currentLetter.toUpperCase()}
        </AlphabetButton>
      ))}
    </Wrapper>
  );
};

AlphabeticalScrollTo.propTypes = {
  filteredPrograms: PropTypes.array
};

export default AlphabeticalScrollTo;
