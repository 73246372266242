import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BackGround = styled.div`
  background-color: ${({ color }) => color};
  border: none;
`;

BackGround.defaultProps = {
  color: '#f7f7f7'
};

BackGround.propTypes = {
  color: PropTypes.string.isRequired
};

export default BackGround;
