import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PostingsTable, Badge, TextButton, ApplicantsModal } from 'components';
import { sortDates } from '../../../helpers/sort';
import { locale } from '../../../helpers/locale';

const EndedPostings = ({ data, employers, enabledJobTypes, modals, modalShow, nation }) => {
  return (
    <PostingsTable
      data={data}
      employers={employers}
      enabledJobTypes={enabledJobTypes}
      modals={modals}
      modalShow={modalShow}
      options={{
        defaultSortName: 'endOn',
        defaultSortOrder: 'desc'
      }}
      postingType="ended"
      tableColumns={[
        {
          dataField: 'applicants',
          text: 'Applicants',
          dataFormat: (cell, row) =>
            cell.length > 0 && nation === 'us' ? (
              <Fragment>
                <TextButton onClick={() => modalShow(`applicant${row.postingId}`)}>
                  {cell.length}
                </TextButton>
                {modals[`applicant${row.postingId}`] && (
                  <ApplicantsModal
                    applicants={cell}
                    selectedApplicant={row.selectedApplicant}
                    name={`applicant${row.postingId}`}
                  />
                )}
              </Fragment>
            ) : (
              cell.length
            )
        },
        {
          dataField: 'endOn',
          text: 'End Date',
          sortFunc: sortDates,
          dataFormat: cell =>
            cell ? moment(cell, 'MM-DD-YYYY').format(locale.date[nation]) : '---'
        },
        {
          dataField: 'jobType',
          text: 'Type',
          dataFormat: cell => <Badge type={cell}>{cell}</Badge>
        }
      ]}
    />
  );
};

EndedPostings.propTypes = {
  data: PropTypes.object.isRequired,
  employers: PropTypes.object.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired
};

export default EndedPostings;
