import React from 'react';
import { connect } from 'react-redux';
import { GlobalSelect } from 'components';
import { updateCurrentSite, fetchSiteRequest } from '../../actions/actionCreators';
import { selectSubdomain } from '../../selectors/subdomain';

const GlobalSelectContainer = props => <GlobalSelect {...props} />;

export const mapStateToProps = state => ({
  subdomains: state.sites.items,
  defaultValue: selectSubdomain(state.sites, state.profile)
});

export default connect(mapStateToProps, {
  updateCurrentSite,
  fetchSite: fetchSiteRequest
})(GlobalSelectContainer);
