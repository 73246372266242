import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactSelect from 'react-select';
import { WidgetBuilderToggle } from 'components';

const InputWrapper = styled.div`
  width: 20rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;
`;

const StyledLabel = styled.label`
  font-weight: 600;
`;

const WidgetBuilderSubdomainAliases = ({ configurations, setConfigurations, siteSettings }) => {
  const { currentSite, subdomainAliases } = siteSettings;

  const subdomainAliasOptions = subdomainAliases.map(currentAlias => ({
    value: currentAlias,
    label: currentAlias
  }));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAliasOption, setSelectedAliasOption] = useState(subdomainAliasOptions[0]);

  const handleChange = newSelection => {
    setSelectedAliasOption(newSelection);
    setConfigurations({ ...configurations, clientId: newSelection.value });
  };

  return (
    <Fragment>
      <WidgetBuilderToggle
        label="Use Subdomain Alias"
        handleChange={() => {
          setIsDrawerOpen(!isDrawerOpen);
          if (isDrawerOpen) {
            setConfigurations({ ...configurations, clientId: currentSite });
          } else {
            setConfigurations({ ...configurations, clientId: selectedAliasOption.value });
          }
        }}
        isChecked={isDrawerOpen}
      />
      {isDrawerOpen && (
        <InputWrapper>
          <StyledLabel htmlFor="widget-build-subdomain-alias">Select a Subdomain Alias</StyledLabel>
          <ReactSelect
            id="widget-build-subdomain-alias"
            onChange={newSelection => {
              handleChange(newSelection);
            }}
            options={subdomainAliasOptions}
            value={selectedAliasOption.value}
            clearable={false}
          />
        </InputWrapper>
      )}
    </Fragment>
  );
};

WidgetBuilderSubdomainAliases.propTypes = {
  configurations: PropTypes.shape({
    hasEmployment: PropTypes.bool.isRequired
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    currentSite: PropTypes.string.isRequired
  }).isRequired
};

export default WidgetBuilderSubdomainAliases;
