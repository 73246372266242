import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { flashSuccess, flashError } from '../../../helpers/flashmessage';
import { updateProfile, updateCognitoUser } from '../../../services';
import { LoadingButton } from 'components';

const SuperDiv = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

const SuperButton = styled(LoadingButton)`
  margin-top: 3rem;
  width: 30ren;
`;

const CreateSuper = ({ closeModal, email, profileId, reloadProfiles, subdomain }) => {
  const [isSupering, setIsSupering] = useState(false);

  const makeProfileSuper = async () => {
    setIsSupering(true);

    const authResponse = await updateCognitoUser({
      email,
      userRole: 'super',
      subdomain: 'universal'
    });
    if (!authResponse.ok) {
      return flashError('Sorry, we cannot change that user at the moment');
    }

    const apiResponseOk = await updateProfile(subdomain, profileId, {
      userRole: 'super',
      subdomain: 'universal'
    });

    if (apiResponseOk) {
      flashSuccess(`${email} is now a Super Admin!`);
      reloadProfiles();
      closeModal();
    }

    setIsSupering(false);
  };

  return (
    <SuperDiv>
      {`Grant ${email} Super Admin Permissions?`}
      <SuperButton isLoading={isSupering} onClick={makeProfileSuper}>
        Yes
      </SuperButton>
    </SuperDiv>
  );
};

CreateSuper.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  reloadProfiles: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default CreateSuper;
