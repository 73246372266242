import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchInstitutions } from '../../services';
import { keenTopInfoRequests } from '../../services/keen/queries';
import { StatCard } from 'components';
import { FetchKeen } from 'containers';
import { selectSubdomain } from '../../selectors/subdomain';
import { withNationHOC } from '../../hocs/withNation';
import { locale } from '../../helpers/locale';


const defaultOptions = {
  options: [
    {
      label: 'All Institutions',
      value: 'All Institutions'
    }
  ]
};

class TopInfoRequestsStats extends React.Component {
  state = {
    ...defaultOptions,
    loaded: false,
    selectedInstitution: 'All Institutions'
  };

  componentDidMount() {
    this.getData(this.props);
  }

  render() {
    const { selectedInstitution } = this.state;
    const { hasPrograms, hasManyInstitutions, nation } = this.props;
    return (
      <FetchKeen
        {...keenTopInfoRequests}
        extraFilters={              
          selectedInstitution !== 'All Institutions'
            ? [
              {
                operator: 'eq',
                property_name: 'program.displayName',
                property_value: selectedInstitution
              },
              {
                operator: 'exists',
                property_name: 'program.displayName',
                property_value: true
              }
            ]
            : [{
              operator: 'exists',
              property_name: 'program.displayName',
              property_value: true
            }]
          }

      >
        {props => <StatCard
          heading={`Top Info Requests by ${locale.edTrack[nation]}`}
          selectOnChange={this.handleInstitutionChange}
          selectOptions={this.state.options}
          hasSelect={hasPrograms && hasManyInstitutions}
          selectedInstitution={selectedInstitution}
          {...props} />}
      </FetchKeen>
    );


    // if the site does not have programs the programs/careers tab button will not appear,
    // so the heading is different in that case so the user knows they are looking at career stats
  }

  handleInstitutionChange = e => {
    this.setState({ selectedInstitution: e.target.value });
  };

  /**
   * calls the institutions api
   * @method getData
   * @param  {object} props (subdomain, hasManyInstitutions, hasPrograms) passed to and destructured by the function
   * @return {function} sets up the select options to pass down to the select component
   */
  getData = ({ subdomain, hasManyInstitutions, hasPrograms }) => {
    // check here to reduce unnecessary API calls
    if (hasPrograms && hasManyInstitutions) {
      fetchInstitutions(subdomain).then(res =>
        this.setState(state => ({
          // take the previous state which is our default options for `All Institutions`
          // and combine with the response options coming from the API
          options: [
            ...state.options,
            ...Object.values(res).map(item => ({
              label: item.displayName,
              value: item.displayName
            }))
          ],
          loaded: true
        }))
      );
    } else {
      return null;
    }
  };
}

export const mapStateToProps = ({ profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    hasPrograms: sites.items[currentSite].hasPrograms,
    hasManyInstitutions: sites.items[currentSite].hasManyInstitutions,
    subdomain: currentSite
  };
};

TopInfoRequestsStats.propTypes = {
  hasPrograms: PropTypes.bool.isRequired,
  hasManyInstitutions: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default withNationHOC(connect(mapStateToProps)(TopInfoRequestsStats));
