import React from 'react';
import PropTypes from 'prop-types';

import { color } from '../../../styles/index';
import { locale } from '../../../helpers/locale';
import { HelpModal } from 'components';

const { lightGreen } = color;

const ProgramTableHelpModal = ({ nation }) => {
  const modalData = [
    {
      subtitle: `Add ${locale.edTrack[nation]}s`,
      description: `To add a new ${locale.edTrack.lower[nation]}, click the Add ${locale.edTrack[nation]} button and complete the entry form. Be sure to save the new ${locale.edTrack.lower[nation]}’s information.`
    },
    {
      subtitle: `Edit ${locale.edTrack[nation]}s`,
      description: `To edit a current ${locale.edTrack.lower[nation]}’s information, click the pencil icon and edit the ${locale.edTrack.lower[nation]} form. Be sure to save any changes you’ve made.`
    },
    {
      subtitle: `Delete ${locale.edTrack[nation]}s`,
      description: `To delete a ${locale.edTrack.lower[nation]}, click the trashcan icon within the Delete column.`
    },
    {
      subtitle: `Sort ${locale.edTrack[nation]}s Table`,
      description: 'To sort by column, click the arrows next to the header name.'
    }
  ];

  const legendData = [
    {
      legendText: `New ${locale.edTrack[nation]}`,
      background: `${lightGreen}`
    }
  ];

  return <HelpModal legendData={legendData} modalData={modalData} />;
};

ProgramTableHelpModal.propTypes = {
  nation: PropTypes.string.isRequired
};

export default ProgramTableHelpModal;
