import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EmptyEmployersIcon from '../../../images/empty-employers.png';
import EmptyPostingsIcon from '../../../images/empty-job-postings.png';
import EmptySearchResults from '../../../images/empty-search-results.png';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 20rem;
  margin: 4rem 0;
  overflow: auto;
`;

const Image = styled.img`
  max-width: ${props => props.maxWidth};
  margin-bottom: 3rem;
`;

const displayIcon = (isFiltered, tableType) => {
  if (isFiltered) return EmptySearchResults;
  return tableType === 'employers' ? EmptyEmployersIcon : EmptyPostingsIcon;
};

const NoTableData = ({ isFiltered, statusType = '', tableType = '' }) => (
  <StyledDiv>
    <Image src={displayIcon(isFiltered, tableType)} alt="" maxWidth="20rem" />
    {isFiltered
      ? `There are no ${statusType} ${tableType} that matched your criteria`
      : `There are no ${statusType} ${tableType}`}
  </StyledDiv>
);

NoTableData.propTypes = {
  statusType: PropTypes.string.isRequired,
  tableType: PropTypes.string.isRequired,
  isFiltered: PropTypes.bool
};

export default NoTableData;
