/**
 * This function removes empty or null properties from an object
 *  copied from here: http://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
 * @param {object} obj - any generic JS object
 * @return {object} an object with null and '' keys removed
 */

/* eslint-disable*/
export default function removeEmpty(obj) {
  Object.keys(obj).forEach(
    key =>
      (obj[key] && typeof obj[key] === 'object' && removeEmpty(obj[key])) ||
      (obj[key] === null && delete obj[key])
  );
  return obj;
}
//
/* eslint-enable*/
