import is from 'is_js';
import camelCase from 'camelcase-keys-deep';

import { getCognitoToken } from '../';
import { flashSuccess, flashError } from '../../helpers/flashmessage';
import { get, patch, formatRequest, fetchOpts } from '../utils';

export const fetchProfilesById = async profileId => {
  return fetch(`/api/cc-api/profiles?profileId=${profileId}`, {
    headers: new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  })
    .then(response => response.json())
    .then(({ data }) => data.map(({ attributes }) => camelCase(attributes)));
};

const formatValue = field => (is.truthy(field) && is.not.empty(field) ? field : '---');

/**
 * fetchProfiles calls Profiles API and fetches all profiles under the specified subdomain
 * @return an array of profiles
 */
// The Profiles Table had formerly used fetchProfiles to populate its rows, but it now uses
// getProfiles. For the Time Being we still use fetchProfiles to populate the Profiles Excel
// download.
export const fetchProfiles = async site => {
  const header = new Headers({
    Authorization: `Bearer ${await getCognitoToken()}`
  });

  return get(`/api/cc-api/profiles/${site}`, null, header).then(profiles => {
    // do some pre-processing for the table display.
    return Object.values(profiles).map(profile => {
      // if the current school exists we return the display name, else '---'
      const currentSchool = formatValue(profile.currentSchool).displayName || '---';
      // same here: if the name exists we display it (after trimming off any whitespace), otherwise we fill in '---'
      const name = `${formatValue(profile.givenName)} ${formatValue(profile.familyName)}`.trim();
      return {
        ...profile,
        currentSchool,
        name
      };
    });
  });
};

export const getProfiles = async (site, query = '', signal = null) => {
  try {
    const response = await fetch(`/api/cc-api/profiles/${site}/${query}`, {
      headers: new Headers({
        Authorization: `Bearer ${await getCognitoToken()}`
      }),
      signal
    });

    const json = await response.json();
    const { data, meta } = json;
    const profiles = data.map(({ attributes }) => camelCase(attributes));
    const { total } = meta;

    return {
      profiles,
      total
    };
  } catch (error) {
    if (!error.message.includes('The user aborted a request.')) {
      flashError('Sorry, we cannot display profile data at the moment');
    }
    return false;
  }
};

/**
 * Updates profile.
 * @return true if the patch succeeds, false if there is an error.
 */
export const updateProfile = async (subdomain, profileId, data) => {
  const header = new Headers({
    Authorization: `Bearer ${await getCognitoToken()}`
  });
  return patch(
    `/api/cc-api/profiles/${subdomain}/${profileId}`,
    formatRequest(data, 'profile'),
    header
  )
    .then(() => {
      flashSuccess('Your changes have been saved!');
      return true;
    })
    .catch(error => {
      if (subdomain === 'universal' && error.errors[0].status) {
        // changing the subdomain from universal always throws a 404
        return;
      }

      flashError('Sorry, we cannot update that profile at the moment');
      return false;
    });
};

export const updateSignUpProfile = async (subdomain, profileId, data) => {
  const header = new Headers({
    Authorization: `Bearer ${await getCognitoToken()}`
  });
  patch(
    `/api/cc-api/profiles/${subdomain}/${profileId}`,
    formatRequest(data.attributes, 'profile', data.id),
    header
  );

  return true;
};

export const updateCognitoUser = ({ email, userRole, subdomain }) => {
  const postBody = { email };
  if (userRole) {
    postBody['new-user-role'] = userRole;
  }
  if (subdomain) {
    postBody.subdomain = subdomain;
  }

  return fetch('/api/cc-api/update-cognito-user', fetchOpts('POST', formatRequest(postBody)));
};

const updateCognitoUserRole = (email, userRole) => {
  // patch user-role to Cognito through the /update-cognito-user lambda function
  return fetch(
    '/api/cc-api/update-cognito-user',
    fetchOpts(
      'POST',
      formatRequest({
        email,
        'new-user-role': userRole
      })
    )
  );
};

export const updateCognitoMultiAdminSubdomains = (email, multiAdminSubdomains) => {
  // patch user-role to Cognito through the /update-cognito-user lambda function
  return fetch(
    '/api/cc-api/update-cognito-user',
    fetchOpts(
      'POST',
      formatRequest({
        email,
        'multi-admin-subdomains': multiAdminSubdomains
      })
    )
  );
};

/**
 * Patch a new userRole to users.
 * Attempts to patch to Cognito first, and then Profiles API.
 * @return true if the function succeeds (as long as the Cognito patch was successful), false if there is an error.
 */
export const toggleUserRole = async ({ subdomain, id, email, userRole }) => {
  let authResponse;
  authResponse = await updateCognitoUserRole(email, userRole);
  if (authResponse.ok) {
    return await updateProfile(subdomain, id, { userRole });
  } else {
    flashError("Sorry, we cannot change that profile's admin status at the moment");
  }
};

export const deleteProfile = async ({ subdomain, profileId }) => {
  return fetch(`/api/cc-api/profiles/${subdomain}/${profileId}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  });
};

export const createProfile = async ({ subdomain, data }) => {
  return fetch(`/api/cc-api/profiles/${subdomain}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  }).then(res => {
    if (res.status === 201) {
      return res;
    } else {
      flashError('Something went wrong');
      return 'error';
    }
  });
};
