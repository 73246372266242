import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

import { withNationHOC } from '../../../hocs/withNation';
import { ExcelStatisticsWrapper } from 'containers';
import {
  formatAssessmentWidgetUsageData,
  formatAssessmentWidgetProgramClicksData,
  formatAssessmentWidgetCareerClicksByCareerData,
  formatDailyUsageData,
  formatProgramWidgetCareerCoachClicksData,
  formatProgramWidgetUsageData,
  formatProgramWidgetDailyUsageByProgramData
} from './formatters';

const ExcelWidgetStatisticsWorkbook = withNationHOC(
  ({ careerData, data, hasAssessmentWidget, hasProgramWidget, nation, programData, timeframe }) => {
    const introduction = [
      {
        text: `The Widget Analytics workbook is comprised of up to 8 worksheets (not including the introduction worksheet). 
          
          AssessmentWidgetUsage displays general usage data for the Assessment Widget in the selected timeframe.
          
          AssessmentWidgetDailyUsage displays usage data for the Assessment Widget in the selected timeframe by day.

          ProgramsVisitedFromAssessment displays Program Pages visited from the Assessment Widget in the selected timeframe.

          CareersVisitedFromAssessment displays Career Pages visited from the Assessment Widget in the selected timeframe.
          
          ProgramWidgetUsage displays general usage data for the Program Widget in the selected timeframe.

          ProgramWidgetDailyUsage displays usage data for the Program Widget in the selected timeframe by day.
          
          ProgramWidgetTopActivity displays usage data for the Program Widget in the selected timeframe by programs.

          CareersVisitedFromProgramWidget displays Career Pages visited from the Program Widget in the selected timeframe.`
      }
    ];

    const { start, end } = timeframe;

    return (
      <ExcelStatisticsWrapper
        filename="Widget_Analytics"
        hasAssessmentWidget={hasAssessmentWidget}
        hasProgramWidget={hasProgramWidget}
      >
        <ExcelSheet data={formatAssessmentWidgetUsageData(data)} name="AssessmentWidgetUsage">
          <ExcelColumn label="Time Period" value="title" />
          <ExcelColumn label={`${start} to ${end}`} value="result" />
        </ExcelSheet>

        <ExcelSheet
          data={formatDailyUsageData(
            {
              starts: data['assessment-widget-starts'],
              completions: data['assessment-widget-completions'],
              downloads: data['assessment-widget-downloads'],
              programClicks: data['assessment-widget-program-link-clicks'],
              careerClicks: data['assessment-widget-career-link-clicks']
            },
            nation
          )}
          name="AssessmentWidgetDailyUsage"
        >
          <ExcelColumn label="Days" value="day" />
          <ExcelColumn label="Starts" value="starts" />
          <ExcelColumn label="Completions" value="completions" />
          <ExcelColumn label="Downloads" value="downloads" />
          <ExcelColumn label="Program Page Clicks" value="programClicks" />
          <ExcelColumn label="Career Coach Clicks" value="careerClicks" />
        </ExcelSheet>
        <ExcelSheet
          data={formatAssessmentWidgetProgramClicksData(
            data['assessment-widget-program-link-clicks-by-program']
          )}
          name="ProgramsVisitedFromAssessment"
        >
          <ExcelColumn label="Program" value="program" />
          <ExcelColumn label="Program Clicks" value="clicks" />
        </ExcelSheet>
        <ExcelSheet
          data={formatAssessmentWidgetCareerClicksByCareerData(
            data['assessment-widget-career-link-clicks-by-career']
          )}
          name="CareersVisitedFromAssessment"
        >
          <ExcelColumn label="Career Name" value="career" />
          <ExcelColumn label="Career Coach Clicks" value="clicks" />
        </ExcelSheet>
        <ExcelSheet data={formatProgramWidgetUsageData(data)} name="ProgramWidgetUsage">
          <ExcelColumn label="Time Period" value="title" />
          <ExcelColumn label={`${start} to ${end}`} value="result" />
        </ExcelSheet>
        <ExcelSheet
          data={formatDailyUsageData(
            {
              loads: data['widget-loads'],
              clicks: data['widget-clicks'],
              visitButtonClicks: data['widget-visit-button-clicks']
            },
            nation
          )}
          name="ProgramWidgetDailyUsage"
        >
          <ExcelColumn label="Day" value="day" />
          <ExcelColumn label="Views" value="loads" />
          <ExcelColumn label="Widget Clicks" value="clicks" />
          <ExcelColumn label="Career Coach Clicks" value="visitButtonClicks" />
        </ExcelSheet>

        <ExcelSheet
          data={formatProgramWidgetDailyUsageByProgramData({
            loads: data['widget-total-loads-by-programs'],
            clicks: data['widget-total-clicks-by-programs'],
            visitButtonClicks: data['widget-total-visit-button-clicks-by-programs'],
            programData
          })}
          name="ProgramWidgetTopActivity"
        >
          <ExcelColumn label="Program" value="program" />
          <ExcelColumn label="Views" value="loads" />
          <ExcelColumn label="Widget Clicks" value="clicks" />
          <ExcelColumn label="Career Coach Clicks" value="visitButtonClicks" />
        </ExcelSheet>

        <ExcelSheet
          data={formatProgramWidgetCareerCoachClicksData({
            careerCoachClicks: data['widget-visit-button-clicks-by-career-by-career-coach-url'],
            careerData
          })}
          name="CareersVisitedFromProgramWidget"
        >
          <ExcelColumn label="Career Name" value="career" />
          <ExcelColumn label="Career Coach Clicks" value="careerCoachClicks" />
        </ExcelSheet>

        <ExcelSheet data={introduction} name="Introduction">
          <ExcelColumn label={`${introduction[0].text}`} value="value" />
        </ExcelSheet>
      </ExcelStatisticsWrapper>
    );
  }
);

ExcelWidgetStatisticsWorkbook.propTypes = {
  data: PropTypes.object,
  hasAssessmentWidget: PropTypes.bool,
  hasProgramWidget: PropTypes.bool,
  nation: PropTypes.string,
  timeframe: PropTypes.object
};

export default ExcelWidgetStatisticsWorkbook;
