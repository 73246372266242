import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '../../../images/warning-red.svg';
import { color } from '../../../styles';
import { searchCareers, updateProgram } from '../../../services';
import { Button, Loading, ProgramTableModal } from 'components';
import {
  ButtonWrapper,
  InputWrapper,
  Label,
  LeftArrowIcon,
  MultiSelect,
  MultiSelectWrapper,
  Option,
  RightArrowIcon,
  SaveButton,
  StyledButton,
  StyledSearchInput,
  UnsavedWarning,
  Wrapper
} from './styled';

const { black, blue, mediumGray } = color;
const multiSelectTip = 'Use the command (Mac)/ctrl (PC) key to select multiple careers at a time.';

const CareerMappingForm = ({
  careerData,
  fetchProgramData,
  hasLot,
  modalHide,
  modalShow,
  modals,
  nation,
  programData,
  setIsLoading
}) => {
  const [careerOptions, setCareerOptions] = useState(false);
  const [filteredCareerOptions, setFilteredCareerOptions] = useState(false);
  const [initialSelectedCareerOptions, setInitialSelectedCareerOptions] = useState(false);
  const [selectedCareerOptions, setSelectedCareerOptions] = useState(false);
  const [filteredSelectedCareerOptions, setFilteredSelectedCareerOptions] = useState(false);
  const [availableCareersSearchTerm, setAvailableCareersSearchTerm] = useState('');
  const [selectedCareersSearchTerm, setSelectedCareersSearchTerm] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (!selectedCareerOptions && !!careerData) {
      const newInitialSelectedCareerOptions = programData.careers.filter(
        careerId => !!careerData && !!careerData[careerId]
      );
      setInitialSelectedCareerOptions(newInitialSelectedCareerOptions);
      setSelectedCareerOptions(newInitialSelectedCareerOptions);
      setFilteredSelectedCareerOptions(newInitialSelectedCareerOptions);
    }

    if (!careerOptions && careerData) {
      const newCareerOptions = Object.keys(careerData);
      setCareerOptions(newCareerOptions);
      setFilteredCareerOptions(newCareerOptions);
    }
  }, [careerData]);

  const fetchSearchResults = async query => {
    const searchResults = await searchCareers({
      nation,
      query,
      hasLot
    });
    return searchResults;
  };

  const handleAvailableCareerFiltering = async () => {
    if (availableCareersSearchTerm === '') {
      setFilteredCareerOptions(careerOptions);
    } else if (availableCareersSearchTerm.length > 2) {
      const searchResults = await fetchSearchResults(availableCareersSearchTerm);
      const newCareerOptions = searchResults.map(({ id }) => id);
      setFilteredCareerOptions(newCareerOptions);
    }
  };

  useEffect(() => {
    handleAvailableCareerFiltering();
  }, [availableCareersSearchTerm]);

  const handleSelectedCareerFiltering = async () => {
    if (selectedCareersSearchTerm === '') {
      setFilteredSelectedCareerOptions(selectedCareerOptions);
    } else if (selectedCareersSearchTerm.length > 2) {
      const searchResults = await fetchSearchResults(selectedCareersSearchTerm);

      const newFilteredSelectedCareerOptions = searchResults
        .filter(({ id }) => selectedCareerOptions.includes(id))
        .map(({ id }) => id);

      setFilteredSelectedCareerOptions(newFilteredSelectedCareerOptions);
    }
  };

  useEffect(() => {
    handleSelectedCareerFiltering();
  }, [selectedCareersSearchTerm, selectedCareerOptions]);

  const handleSave = async ({ code, credential, institutionId, name }) => {
    await updateProgram({
      ...programData,
      data: { careers: selectedCareerOptions, credential, institutionId, name }
    });
    setHasUnsavedChanges(false);
    modalShow(`saved-mapping-modal-${code}`);
    setIsLoading(true);
    const enoughTimeForTheProgramsDbToUpdate = 100;
    setTimeout(() => fetchProgramData(), enoughTimeForTheProgramsDbToUpdate);
  };

  const checkForUnsavedChanges = newSelectedCareerOptions => {
    if (initialSelectedCareerOptions.toString() === newSelectedCareerOptions.toString()) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  };

  const { code } = programData;

  return (
    <Loading loaded={!!careerOptions}>
      {!!careerOptions && !!selectedCareerOptions && (
        <Wrapper>
          <InputWrapper>
            <MultiSelectWrapper>
              <Label>
                Available Careers
                <StyledSearchInput
                  placeholder="Search Careers"
                  onChange={({ target: { value } }) => {
                    setAvailableCareersSearchTerm(value);
                  }}
                  aria-label={`Search available career mappings for ${programData.name}, ${programData.credential} (${programData.institutionId})`}
                  value={availableCareersSearchTerm}
                />
                <MultiSelect name={`career-select-${code}`} multiple id={`career-select-${code}`}>
                  {filteredCareerOptions.map((careerOption, index) => {
                    const { title } = careerData[careerOption];
                    return (
                      <Option
                        key={`career-option-${index}`}
                        disabled={selectedCareerOptions.includes(careerOption)}
                        value={careerOption}
                      >{`${title} ${careerOption}`}</Option>
                    );
                  })}
                </MultiSelect>
              </Label>
              {multiSelectTip}
            </MultiSelectWrapper>
            <div>
              <ButtonWrapper>
                <StyledButton
                  background={blue}
                  onClick={() => {
                    let selected = [];
                    for (let option of document.getElementById(`career-select-${code}`).options) {
                      if (option.selected && !selectedCareerOptions.includes(option.value)) {
                        selected.push(option.value);
                      }
                    }
                    const newSelectedCareerOptions = [...selectedCareerOptions, ...selected];
                    setSelectedCareerOptions(newSelectedCareerOptions);
                    checkForUnsavedChanges(newSelectedCareerOptions);
                  }}
                >
                  <RightArrowIcon />
                  Add
                </StyledButton>
                <StyledButton
                  color={black}
                  background={mediumGray}
                  onClick={() => {
                    let deselected = [];
                    for (let option of document.getElementById(`career-deselect-${code}`).options) {
                      if (option.selected) {
                        deselected.push(option.value);
                      }
                    }
                    const newSelectedCareerOptions = selectedCareerOptions.filter(
                      selectedOption => !deselected.includes(selectedOption)
                    );
                    setSelectedCareerOptions(newSelectedCareerOptions);
                    checkForUnsavedChanges(newSelectedCareerOptions);
                  }}
                >
                  <LeftArrowIcon />
                  Remove
                </StyledButton>
              </ButtonWrapper>
              {hasUnsavedChanges && (
                <UnsavedWarning>
                  <WarningIcon />
                  You Have Unsaved Changes
                </UnsavedWarning>
              )}
            </div>
            <MultiSelectWrapper>
              <Label>
                Mapped Careers
                <StyledSearchInput
                  placeholder="Search Careers"
                  onChange={({ target: { value } }) => {
                    setSelectedCareersSearchTerm(value);
                  }}
                  aria-label={`Search selected career mappings for ${programData.name}, ${programData.credential} (${programData.institutionId})`}
                  value={selectedCareersSearchTerm}
                />
                <MultiSelect
                  name={`career-deselect-${code}`}
                  multiple
                  id={`career-deselect-${code}`}
                >
                  {filteredSelectedCareerOptions.map((careerOption, index) => {
                    const { title } = careerData[careerOption];
                    return (
                      <Option
                        key={`selected-career-option-${index}`}
                        value={careerOption}
                      >{`${title} ${careerOption}`}</Option>
                    );
                  })}
                </MultiSelect>
              </Label>
              {multiSelectTip}
            </MultiSelectWrapper>
          </InputWrapper>
          <SaveButton
            background={blue}
            disabled={!hasUnsavedChanges}
            onClick={() => {
              const { credential, name, institutionId } = programData;
              return handleSave({ code, credential, institutionId, name });
            }}
          >
            Save Changes
          </SaveButton>
          {modals[`saved-mapping-modal-${programData.code}`] && (
            <ProgramTableModal
              closeModal={() => modalHide(`saved-mapping-modal-${programData.code}`)}
              name={`saved-mapping-modal-${programData.code}`}
              headingText="Edit Career Mappings"
              text={`Career mapping changes to ${programData.name}, ${programData.credential} have been applied!`}
              buttons={[
                <Button
                  key="saved-mapping-modal-button"
                  background="white"
                  color={blue}
                  border={`1px solid ${blue}`}
                  onClick={() => modalHide(`saved-mapping-modal-${programData.code}`)}
                  data-cy="close-saved-mapping-modal"
                >
                  Close
                </Button>
              ]}
            />
          )}
        </Wrapper>
      )}
    </Loading>
  );
};

CareerMappingForm.propTypes = {
  careerData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  fetchProgramData: PropTypes.func.isRequired,
  hasLot: PropTypes.bool.isRequired,
  modalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  nation: PropTypes.string.isRequired,
  programData: PropTypes.shape({
    careers: PropTypes.array,
    code: PropTypes.string.isRequired,
    credential: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  setIsLoading: PropTypes.func.isRequired
};

export default CareerMappingForm;
