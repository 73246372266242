import { connect } from 'react-redux';
import { ProgramTable } from 'components';
import { modalShow, modalHide } from '../../reducers/modal';

const mapStateToProps = ({ modal, sites }) => ({
  modals: modal,
  subdomain: sites.items[sites.currentSite].subdomain
});

export default connect(mapStateToProps, { modalShow, modalHide })(ProgramTable);
