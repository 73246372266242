import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';

const { mediumGray } = color;

const Track = styled.button`
  position: relative;
  width: ${({ width }) => width}rem;
  height: ${({ height }) => height}rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${({ trackColor }) => trackColor};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: none;
  border-radius: 20px;
  color: ${({ textColor }) => textColor}
  font-size: ${({ fontSize }) => fontSize}rem;
  font-weight: 500;
  overflow: hidden;
`;

const Thumb = styled.div`
  position: absolute;
  left: ${({ isChecked, width }) => (isChecked ? width / 2 - 0.1 : 0.1)}rem;
  transition: left 0.5s;
  height: ${({ height }) => height - 0.2}rem;
  width: ${({ width }) => width / 2}rem;
  background: ${({ thumbColor }) => thumbColor};
  border-radius: 20px;
`;

const DichotomousSlider = ({
  checkedOptionLabel,
  className,
  fontSize = '1.4',
  handleChange,
  height = 2.5,
  isChecked,
  textColor = 'inherit',
  thumbColor = 'white',
  trackColor = mediumGray,
  uncheckedOptionLabel,
  width = 16.5
}) => {
  const ariaLabel = `enable ${
    isChecked ? `${checkedOptionLabel}` : `${uncheckedOptionLabel}`
  } embed code`;

  return (
    <Track
      aria-label={ariaLabel}
      onClick={handleChange}
      width={width}
      height={height}
      trackColor={trackColor}
      textColor={textColor}
      fontSize={fontSize}
      className={className}
    >
      <Thumb isChecked={isChecked} width={width} height={height} thumbColor={thumbColor} />
      <div>{checkedOptionLabel}</div>
      <div>{uncheckedOptionLabel}</div>
    </Track>
  );
};

DichotomousSlider.propTypes = {
  checkedOptionLabel: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  height: PropTypes.number,
  isChecked: PropTypes.bool.isRequired,
  textColor: PropTypes.string,
  thumbColor: PropTypes.string,
  trackColor: PropTypes.string,
  uncheckedOptionLabel: PropTypes.string.isRequired,
  width: PropTypes.number
};

export default DichotomousSlider;
