import React from 'react';
import { AddSiteForm } from 'containers';
import { ContentWrap, Layout } from 'components';

const AddSite = () => {
  return (
    <Layout title="Add Site">
      <ContentWrap>
        <AddSiteForm />
      </ContentWrap>
    </Layout>
  );
};

export default AddSite;
