import { handleActions, createAction } from 'redux-actions';

export const MODAL_SHOW = '@@cc-admin/modal/MODAL.SHOW';
export const MODAL_HIDE = '@@cc-admin/modal/MODAL.HIDE';

export const modalShow = createAction(MODAL_SHOW, name => ({
  name
}));

export const modalHide = createAction(MODAL_HIDE, name => ({
  name
}));

const initialState = {};

export default handleActions(
  {
    [MODAL_SHOW]: (state, action) => ({
      ...state,
      [action.payload.name]: true
    }),
    [MODAL_HIDE]: (state, action) => {
      if (action.payload.name) {
        return {
          ...state,
          [action.payload.name]: false
        };
      }
      return initialState;
    }
  },
  initialState
);
