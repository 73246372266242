import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 1;
  top: 0px;
  pointer-events: none;
  &:before, &:after {
    content: "";
    width: 100%;
    height: 40px;
    background: transparent;
    position: absolute;
  }
  &:before {
    top: 0px;
    background: linear-gradient(white, rgba(255,255,255,0));
  }
  &:after {
    bottom: 0px;
    background: linear-gradient(rgba(255,255,255,0), white);
  }
  &.top {
    &:before {
      display: none;
    }
  }
  &.bottom {
    &:after {
      display: none;
    }
  }
`;

const contentStyles = {
  zIndex: '0',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: '0px'
};
const scrollableStyles = {
  overflowY: 'auto',
  position: 'relative',
  maxHeight: '100%',
  height: '100%',
  width: '100%'
};

const Content = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={contentStyles}>
      {props.children}
    </div>
  );
});
const Scrollable = React.forwardRef((props, ref) => {
  return (
    <div onScroll={props.onScroll} ref={ref} style={scrollableStyles}>
      {props.children}
    </div>
  );
});

class GradientScroll extends React.Component {
  constructor(props) {
    super(props);
    this.scrollableRef = React.createRef();
    this.contentRef = React.createRef();
  }

  state = {
    overlayClasses: []
  }

  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.handleScroll();
    }
  }

  render() {
    const { overlayClasses } = this.state;
    return (
      <Container>
        <Scrollable onScroll={this.handleScroll} ref={this.scrollableRef}>
          <Overlay className={overlayClasses} />
          <Content ref={this.contentRef}>
            {this.props.children}
          </Content>
        </Scrollable>
      </Container>
    );
  }

  handleScroll = () => {
    // minus 5px to add a little wiggle room for being near the bottom
    const atBottom = this.scrollableRef.current.scrollHeight - 5 === this.contentRef.current.clientHeight + this.scrollableRef.current.scrollTop - 5;

    const overlayClasses = [];
    if (this.scrollableRef.current.scrollTop <= 5) {
      overlayClasses.push('top');
    } else if (atBottom) {
      overlayClasses.push('bottom');
    }
    this.setState({ overlayClasses });
  };
}

export default GradientScroll;
