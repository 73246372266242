import React from 'react';
import { Layout } from 'components';

const Callback = () => {

  return (
    <Layout title="Callback">
      it's the callback! yeah!
    </Layout>
  );
};

export default Callback;
