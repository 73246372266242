import React from 'react';
import { ContentWrap } from 'components';
import { ProfilesChart, ProfilesTableCard } from 'containers';

const Profiles = () => {
  return (
    <ContentWrap>
      <ProfilesChart />
      <ProfilesTableCard />
    </ContentWrap>
  );
};

export default Profiles;
