import styled from 'styled-components';

const typeColors = {
  apprenticeship: '#A24ECC',
  custom: '#C4374E',
  'full-time': '#3E6DCC',
  internship: '#307848',
  'part-time': '#0A5D62',
  'work-study': '#994813'
};

const Badge = styled.span`
  text-align: center;
  font-weight: bold;
  border-radius: 3rem;
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  width: 102px;
  background-color: ${props =>
    props.color || typeColors[props.type && props.type.toLowerCase()] || typeColors.custom};
`;

export default Badge;
