export const selectGeoLevels = nation => {
  if (nation === 'ca') {
    return {
      ProvTerr: {
        id: 'pr',
        name: 'ProvTerr'
      },
      CD: {
        id: 'CensusDivision',
        name: 'CD'
      },
      CSD: {
        id: 'CensusSubdivision',
        name: 'CSD'
      },
      CMA: {
        id: 'CensusMetropolitanArea',
        name: 'CMA'
      }
    };
  }
  if (nation === 'uk') {
    return {
      Country: {
        id: 'country',
        name: 'Country'
      },
      NUTS1: {
        id: 'nuts1',
        name: 'NUTS1'
      },
      NUTS3: {
        id: 'nuts3',
        name: 'NUTS3'
      },
      LAU1: {
        id: 'lau1',
        name: 'LAU1'
      }
    };
  }
  return {
    State: {
      id: 'state',
      name: 'State'
    },
    MSA: {
      id: 'msa',
      name: 'MSA'
    },
    ZIP: {
      id: 'zip',
      name: 'ZIP'
    },
    County: {
      id: 'fips',
      name: 'County'
    }
  };
};
