import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Modal = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 20;
`;

const OptionsModal = ({ className, closeModal, children }) => {
  const handleFocusOut = event => {
    if (!event.target.closest(`.${className}`)) {
      closeModal();
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleFocusOut);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleFocusOut);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return <Modal className={'program-category-filter-modal'}>{children}</Modal>;
};

OptionsModal.propTypes = {
  className: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default OptionsModal;
