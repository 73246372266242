import { connect } from 'react-redux';
import { EmployerSettings } from 'components';
import { patchSiteRequest, whitelistEmployer } from '../../actions/actionCreators';
import { selectSubdomain } from '../../selectors/subdomain';

export const mapStateToProps = ({ sites, profile }) => {
  const subdomain = selectSubdomain(sites, profile);
  const defaultJobTypes = ['Full-Time', 'Part-Time', 'Internship', 'Apprenticeship', 'Work-Study'];
  const lowercaseDefaultJobTypes = [
    'full-time',
    'part-time',
    'internship',
    'apprenticeship',
    'work-study'
  ];
  const currentJobTypes = sites.items[subdomain].employers.jobTypes;

  // Finds the selected Default Job Types and Orders them properly
  const selectedDefaultJobTypes = defaultJobTypes.filter(defaultJobType => {
    if (
      currentJobTypes.includes(defaultJobType) ||
      currentJobTypes
        .map(currentJobType => currentJobType.name.toLowerCase())
        .includes(defaultJobType.toLowerCase())
    ) {
      return defaultJobType;
    }
  });

  // Finds the Clients Custom Job Type if it exists
  const customJobType = currentJobTypes.filter(stateJobType => {
    if (
      defaultJobTypes.includes(stateJobType.name) ||
      lowercaseDefaultJobTypes.includes(stateJobType.name)
    ) {
      // returns nothing if it finds the defaults
    } else {
      return stateJobType;
    }
  });

  if (customJobType[0]) selectedDefaultJobTypes.push(customJobType[0].name);

  return {
    employerWhitelist: sites.items[subdomain].employerWhitelist,
    jobTypes: selectedDefaultJobTypes.length > 0 ? selectedDefaultJobTypes : defaultJobTypes,
    subdomain
  };
};

export default connect(mapStateToProps, { patchSiteRequest, whitelistEmployer })(EmployerSettings);
