import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import DownloadIcon from '../../../images/other-download-icon.svg';
import {
  generateAlternativeProgramWidgetEmbedCode,
  generateStandardEmbedCode
} from '../../../helpers/widget-embed-code-utils';
import { color } from '../../../styles';
const { sapphireD20 } = color;

const StyledButton = styled.button`
  background: white;
  border: 1px solid ${sapphireD20};
  border-radius: 3px;
  color: ${sapphireD20};
  cursor: pointer;
  height: 4.5rem;
  margin-right: 3.5rem;
  margin-top: 2.5rem;
  padding: 0 1.5rem 0 1rem;
  width: auto;

  @media (min-width: 1160px) {
    width: 20rem;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 2px;
  margin-right: 1rem;
  fill: ${sapphireD20};
`;

const WidgetBuilderExportButton = ({
  configurations,
  isAlternativeEmbedCode,
  selectedPrograms,
  siteSettings
}) => {
  const exportAll = () => {
    const zip = new JSZip();
    selectedPrograms.map(program => {
      /* eslint-disable indent */
      const embedCode = isAlternativeEmbedCode
        ? generateAlternativeProgramWidgetEmbedCode({
            configurations,
            siteSettings,
            programs: [program]
          })
        : generateStandardEmbedCode({
            configurations,
            siteSettings,
            programs: [program]
          });
      /* eslint-disable indent */
      zip.file(
        `widgets/${program.name.replaceAll('/', ' ')} ${program.credential.replaceAll(
          '/',
          ' '
        )}.html`,
        embedCode
      );
    });

    zip.generateAsync({ type: 'blob' }).then(function(content) {
      saveAs(content, 'Widget_Bulk_Export.zip');
    });
  };

  return (
    <StyledButton onClick={exportAll}>
      <StyledDownloadIcon />
      Export All
    </StyledButton>
  );
};

WidgetBuilderExportButton.propTypes = {
  configurations: PropTypes.object.isRequired,
  isAlternativeEmbedCode: PropTypes.bool.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  siteSettings: PropTypes.shape({
    hasCcEnabled: PropTypes.bool.isRequired,
    nation: PropTypes.string.isRequired,
    orderCareersBy: PropTypes.oneOf(['Alphabetical', 'Salary', 'None']),
    schoolName: PropTypes.string.isRequired
  })
};

export default WidgetBuilderExportButton;
