import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ExcelProgramsWorkbook = ({
  categoryData,
  institutionData,
  nation,
  programData,
  subdomain
}) => {
  const geoLevels = {
    us: ['state', 'county', 'msa', 'zip'],
    uk: ['country', 'nuts1', 'nuts3', 'lau1'],
    ca: ['provterr', 'cd', 'csd', 'cma']
  };

  return (
    <ExcelFile filename={subdomain} hideElement>
      <ExcelSheet data={programData} name="programs">
        <ExcelColumn label="name" value="name" />
        <ExcelColumn label="credential" value="credential" />
        <ExcelColumn label="url" value="url" />
        <ExcelColumn label="categories" value="categories" />
        <ExcelColumn label="cip" value="cip" />
        <ExcelColumn label="code" value="code" />
        <ExcelColumn label="careers" value="careers" />
        <ExcelColumn label="credits" value="credits" />
        <ExcelColumn label="ipeds" value="institutionId" />
        <ExcelColumn label="curricularSkillsGroupId" value="curricularSkillsGroupId" />
        <ExcelColumn label="length" value="length" />
        <ExcelColumn label="lengthUnit" value="lengthUnit" />
        <ExcelColumn label="locations" value="locations" />
        <ExcelColumn label="applicationLink" value="applicationLink" />
        <ExcelColumn label="tuitionLink" value="tuitionLink" />
        <ExcelColumn label="applicationLink" value="applicationLink" />
        <ExcelColumn label="requestInfoEmail" value="requestInfoEmail" />
        <ExcelColumn label="tuitionRange" value="tuitionRange" />
        <ExcelColumn label="transferNames" value="transferNames" />
        <ExcelColumn label="transferUrls" value="transferUrls" />
      </ExcelSheet>
      <ExcelSheet data={categoryData} name="categories">
        <ExcelColumn label="name" value="name" />
        <ExcelColumn label="description" value="description" />
        <ExcelColumn label="color" value="color" />
        <ExcelColumn label="image" value="image" />
      </ExcelSheet>
      <ExcelSheet data={institutionData} name="institutions">
        <ExcelColumn label="displayName" value="displayName" />
        <ExcelColumn label="ipeds" value="ipeds" />
        <ExcelColumn label="link" value="link" />
        {geoLevels[nation].map(currentGeoLevel => (
          <ExcelColumn key={currentGeoLevel} label={currentGeoLevel} value={currentGeoLevel} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

ExcelProgramsWorkbook.propTypes = {
  categoryData: PropTypes.array.isRequired,
  institutionData: PropTypes.array.isRequired,
  nation: PropTypes.string.isRequired,
  programData: PropTypes.array.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default ExcelProgramsWorkbook;
