import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash/function';
import { Hint, WidgetBuilderToggle } from 'components';
import { locale } from '../../../helpers/locale';
import { color } from '../../../styles';
import CirclePlus from '../../../images/circle-plus.svg';

const { mediumDarkGray, sapphireD20 } = color;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const HeaderRightItems = styled.div`
  display: flex;
`;

const InsertProgramNameButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  color: ${sapphireD20};
  font-size: 1.5rem;
  cursor: pointer;
`;

const EditProgramsIcon = styled(CirclePlus)`
  height: 1.7rem;
  width: 1.7rem;
  position: relative;
  top: 0.2rem;
  margin-right: 0.75rem;
`;

const TextArea = styled.textarea`
  border: 1px solid ${mediumDarkGray};
  border-radius: 3px;
`;

const WidgetBuilderSetHeading = props => {
  const { configurations, siteSettings, setConfigurations } = props;
  const { nation, schoolName, hasCcEnabled } = siteSettings;
  const { hasHeading, isBatch } = configurations;

  const [localHeading, setLocalHeading] = useState('');

  const localizedEdTrack = locale.edTrack[nation];

  const updateParentState = _.debounce(async () => {
    const normalizedHeading = localHeading.split(`${localizedEdTrack}Name`).join('ProgramName');
    setConfigurations({ ...configurations, headingText: normalizedHeading });
  }, 400);

  useEffect(() => {
    const defaultHeadingText = isBatch
      ? `At ${schoolName}, our students who study {${localizedEdTrack}Name} can go into these careers:`
      : 'Our students can go into these careers:';
    setLocalHeading(defaultHeadingText);
  }, [isBatch]);

  useEffect(() => {
    updateParentState();
  }, [localHeading]);

  return (
    <Fragment>
      <HeaderWrapper>
        <WidgetBuilderToggle
          label="Heading"
          ariaLabel={`${hasHeading ? 'disable' : 'enable'} Heading Text`}
          handleChange={() => setConfigurations({ ...configurations, hasHeading: !hasHeading })}
          isChecked={hasHeading}
        />
        {isBatch && hasHeading && (
          <HeaderRightItems>
            <InsertProgramNameButton
              onClick={() => {
                const newHeading = `${localHeading} {${localizedEdTrack}Name}`;
                setLocalHeading(newHeading);
              }}
              aria-label={`Insert ${localizedEdTrack} name to header text`}
            >
              <EditProgramsIcon aria-hidden="true" />
              {`Insert ${localizedEdTrack} Name`}
            </InsertProgramNameButton>
            <Hint id="add-program-name-tooltip">
              This inserts the program name at the end of the text entered into the header text box.
              You can move the placement of the variable by deleting, adding spaces, or copying and
              pasting it where you want the name to appear within your heading.
            </Hint>
          </HeaderRightItems>
        )}
      </HeaderWrapper>
      {hasHeading && (
        <TextArea
          id="header-text"
          rows="4"
          cols="50"
          value={localHeading}
          aria-label="edit widget heading text"
          onChange={event => {
            setLocalHeading(event.target.value);
          }}
        />
      )}
    </Fragment>
  );
};

WidgetBuilderSetHeading.propTypes = {
  configurations: PropTypes.shape({
    isBatch: PropTypes.bool.isRequired
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired,
    schoolName: PropTypes.string.isRequired
  })
};

export default WidgetBuilderSetHeading;
