import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';
import debounce from 'lodash.debounce';

import { Col, Row } from 'components';
import { fetchOrganizations } from '../../../services';
import {
  isEmail,
  isGaId,
  isRequired,
  isSubdomain,
  correlatingFieldCheck,
  areSubdomains
} from '../../../helpers/validation';
import {
  FormCheckboxGroup,
  FormInput,
  FormRadio,
  FormSelect,
  FormTextArea,
  Line,
  Margin
} from 'components';
import { locale } from '../../../helpers/locale';

const OrgSelect = styled(FormSelect)`
  .Select-input {
    padding-left: 5px;
    margin-left: 0;
  }
`;

const StyledHr = styled(Line)`
  margin: 5rem 0 4rem;
`;

const HelpText = ({ children }) => <Margin value="-3rem 0 2rem">{children}</Margin>;

const validateHeaderCustomLinkText = correlatingFieldCheck('headerCustomLinkUrl');
const validateHeaderCustomLinkUrl = correlatingFieldCheck('headerCustomLinkText');
const validateEmployerEmail = (value, allValues) => allValues.employerPortal && isEmail(value);

const SiteFields = ({
  change,
  disabled,
  employerPortal,
  employerNotificationEmail,
  nation = 'us',
  isAdvancedAssessmentExportEnabled,
  isStudentIdEnabled
}) => {
  const [orgInputValue, setOrgInputValue] = useState('');

  const _getOrganizationOptions = async (value, callback) => {
    if (value.length > 0) {
      return fetchOrganizations(value).then(response => {
        const options = Object.values(response).map(item => ({
          value: item,
          label: item.name
        }));
        callback(null, { options });
      });
    }
    callback(null, {
      complete: true
    });
  };
  const getOrganizationOptions = debounce(_getOrganizationOptions, 300);

  const assessmentLengths = ['short', 'medium', 'long'];
  return (
    <div>
      <Field
        onChange={value => {
          setOrgInputValue(value);
        }}
        value={orgInputValue}
        label={`${locale.spellings.organization[nation]} Name`}
        name="organization"
        loadOptions={(value, callback) => {
          getOrganizationOptions(value, callback);
        }}
        disabled={disabled}
        validate={value => value && isRequired(value)}
        placeholder={`Search for ${locale.spellings.organization[nation].toLowerCase()}`}
        component={OrgSelect}
      />
      <Field
        label="Site Name"
        name="name"
        component={FormInput}
        validate={isRequired}
        placeholder="Test College"
        type="text"
      />
      <Field
        label="Site Subdomain"
        postfixText=".lightcastcc.com"
        disabled={disabled}
        name="subdomain"
        component={FormInput}
        parse={value => value.toLowerCase()}
        validate={[isRequired, isSubdomain]}
        placeholder="testsite"
        type="text"
      />
      <Field
        component={FormTextArea}
        label="Subdomain Aliases (separate by new lines, no commas)"
        name="subdomainAliases"
        format={value => value && value.join('\n')}
        parse={value =>
          !!value && value.length > 0
            ? value.split('\n').map(alias => alias.trim().toLowerCase())
            : []
        }
        placeholder="Subdomain alias list"
        rows="6"
        validate={areSubdomains}
        type="array"
      />
      <Field label="White Label" name="whiteLabel" component={FormInput} type="text" />
      <HelpText>
        Replaces "Career Coach" throughout the app, except the headline text and the privacy policy.
      </HelpText>
      <Row>
        <Col>
          <Field label="Site Enabled" name="enabled" component={FormRadio} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label="Taxonomy"
            name="hasLot"
            options={[
              { label: 'Standard Government', value: false },
              { label: 'Lightcast Occupation', value: true }
            ]}
            component={FormRadio}
          />
        </Col>
        <Col>
          <Field label="Has Colleges" name="hasManyInstitutions" component={FormRadio} />
        </Col>
        <Col>
          <Field
            label="Nation"
            name="nation"
            options={[
              { label: 'US', value: 'us' },
              { label: 'CA', value: 'ca' },
              { label: 'UK', value: 'uk' }
            ]}
            component={FormRadio}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormCheckboxGroup}
            label="Assessment Lengths"
            name="assessmentLengths"
            options={assessmentLengths}
          />
        </Col>
        <Col>
          <Field label="Military Search Enabled" name="militarySearch" component={FormRadio} />
        </Col>
        <Col>
          <Field
            label={`Has ${locale.edTrack[nation]}s`}
            name="hasPrograms"
            component={FormRadio}
          />
        </Col>
      </Row>
      <Field
        label="Default Request Information Email Address"
        name="requestEmail"
        component={FormInput}
        validate={isEmail}
        placeholder="example@gmail.com"
        type="text"
      />
      <Field
        label="Google Analytics ID (US Only)"
        name="googleAnalyticsId"
        component={FormInput}
        validate={isGaId}
        type="text"
      />
      <StyledHr />
      <Field
        label="Header Custom Link Text"
        name="headerCustomLinkText"
        component={FormInput}
        maxLength="25"
        validate={validateHeaderCustomLinkText}
        type="text"
      />
      <Field
        label="Header Custom Link Url"
        name="headerCustomLinkUrl"
        component={FormInput}
        validate={validateHeaderCustomLinkUrl}
        placeholder="https://customlink.com"
        type="text"
      />
      <StyledHr />
      <Row>
        <Col>
          <Field
            label={`Requires ${locale.spellings.authorization[nation]}`}
            name="requiresAuth"
            component={FormRadio}
          />
        </Col>
        <Col>
          <Field
            label="SSO Connection (Developer Only)"
            name="ssoConnection"
            component={FormInput}
            placeholder="prod-ccc-idp"
            type="text"
            disabled={isStudentIdEnabled}
          />
        </Col>
        <Col>
          <Field
            label="Profile Student ID Field Enabled (US Only)"
            name="studentIdEnabled"
            component={FormRadio}
            onChange={() => {
              !isStudentIdEnabled
                ? alert(
                    'Please Note: Enabling the Student ID setting will disable SSO Connection as these two settings may not be enabled at the same time.'
                  )
                : undefined;
            }}
          />
        </Col>
      </Row>
      <Field component={FormInput} label="Login/Sign up Text" name="authHelpText" type="text" />
      <StyledHr />
      <Row>
        <Col>
          <Field label="Typical Ed Level Enabled" name="typicalEdEnabled" component={FormRadio} />
        </Col>
        <Col>
          <Field
            label="High School Level Filter On By Default"
            name="isHighSchoolLevelFilterOn"
            component={FormRadio}
          />
        </Col>
        <Col>
          <Field
            component={FormRadio}
            hintText='Selecting "Custom" allows you to sort Related Careers manually.
                They will remain unsorted by default.'
            label="Sort Related Careers"
            name="relatedCareersSort"
            options={[
              {
                label: 'Salary',
                value: 'Salary'
              },
              {
                label: 'Match %',
                value: 'Match %'
              },
              {
                label: 'Alphabetical',
                value: 'Alphabetical'
              },
              {
                label: 'Custom',
                value: 'Custom'
              }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field label="Live Job Postings Enabled" name="hasJobPostings" component={FormRadio} />
        </Col>
        <Col>
          <Field
            label="Similar Careers Enabled (US and UK only)"
            name="hasSimilarCareers"
            component={FormRadio}
            disabled={nation === 'ca'}
          />
        </Col>
        <Col>
          <Field
            label="Only Show Careers with Programs by Default"
            name="areUnmappedCareersHiddenByDefault"
            component={FormRadio}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field label="Equal Opportunity Employer" name="hasEoeEnabled" component={FormRadio} />
        </Col>
      </Row>
      <Margin value="-4rem 0 0 0">
        <StyledHr />
      </Margin>
      <Row>
        <Col>
          <Field
            label={`${locale.resume[nation]} Builder`}
            name="resumeBuilder"
            component={FormRadio}
          />
        </Col>
        <Col>
          <Field
            label="Employer Portal Enabled"
            name="employerPortal"
            component={FormRadio}
            onChange={() => {
              !!change && change('requiresResumeApproval', false);
            }}
          />
        </Col>
        <Col>
          <Field
            label={`Requires ${locale.resume[nation]} Approval (US only)`}
            name="requiresResumeApproval"
            component={FormRadio}
            disabled={!employerPortal || !employerNotificationEmail}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={`${locale.resume[nation]} Builder Without Login Enabled`}
            name="loginlessResumeBuilder"
            component={FormRadio}
          />
        </Col>
      </Row>
      <Field
        label="Employer Portal Notification Email Address"
        name="employerNotificationEmail"
        component={FormInput}
        placeholder="example@gmail.com"
        validate={!!employerPortal ? [isRequired, validateEmployerEmail] : validateEmployerEmail}
        disabled={!employerPortal}
        type="text"
      />
      <StyledHr />
      <Row>
        <Col>
          <Field
            label="Enable Advanced Assessment Export"
            name="advancedAssessmentExportSettings.isEnabled"
            component={FormRadio}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label='"About Us" Text for Advanced Assessment'
            name="advancedAssessmentExportSettings.aboutUs"
            subtext="If text is entered in the field below, it will be inserted into the export after the introduction. 1000 character maximum. HTML/CSS will not be rendered."
            component={FormTextArea}
            placeholder="Since 1889, the University of Idaho has provided motivated students with a transformative higher education experience that..."
            rows="9"
            maxLength="1000"
            disabled={!isAdvancedAssessmentExportEnabled}
          />
        </Col>
      </Row>
      <Margin value="-4rem 0 0 0">
        <StyledHr />
      </Margin>
      <Row>
        <Col>
          <Field
            label="Student Profile Selectable Schools (UK/CA only)"
            name="selectableProfileSchools"
            component={FormTextArea}
            subtext="Each new line is a new option in the dropdown list on the Sign Up page."
            format={value => value.join('\n')}
            parse={value => value.split('\n')}
            placeholder={`School 1
School 2
School 3`}
            rows="9"
            disabled={nation === 'us'}
          />
        </Col>
      </Row>
    </div>
  );
};

SiteFields.propTypes = {
  change: PropTypes.func,
  disabled: PropTypes.bool,
  employerPortal: PropTypes.bool,
  employerNotificationEmail: PropTypes.string,
  selectOptions: PropTypes.object,
  nation: PropTypes.string,
  isAdvancedAssessmentExportEnabled: PropTypes.bool,
  isStudentIdEnabled: PropTypes.bool
};

export default SiteFields;
