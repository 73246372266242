import { filter } from 'ramda';
import { createSelector } from 'reselect';
import { addPostingsToApprovedEmployers } from '../helpers/add-postings-to-approved-employers';

const getEmployers = state => state.employers.items;
const getPostings = state => state.postings.items;

// Based on the email provided, return only one employer object that matches the email
// If no employer exists with that email, return undefined
export const selectEmployerByEmail = (state, email) =>
  Object.values(getEmployers(state)).filter(
    employer => employer.email === email
  )[0];

// Based on the status filter provided, return a multi-employer object in that status
export const selectEmployersByStatus = (state, status) =>
  filter(employer => employer.status === status, getEmployers(state));

// Return a multi-employer object of all the approved employers, each with their corresponding postings as an attribute
export const getApprovedEmployersWithPostings = createSelector(
  [getPostings, selectEmployersByStatus],
  (postings, employers) => addPostingsToApprovedEmployers(postings, employers)
);
