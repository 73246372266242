import createTheme from 'styled-components-theme';
import { color } from '.';

// default keys and values for the theme of the sites
// defaults to demo styles
export const colors = {
  accentColor: color.sapphireD20,
  buttonColor: color.trueBlack,
  linkColor: color.sapphireD20,
  logoUrl: 'https://d24eua068y5lt4.cloudfront.net/logos/default.png',
  primaryColor: color.overcastD40,
  menuColor: color.trueBlack,
  menuBackgroundColor: color.white
};

const theme = createTheme(...Object.keys(colors));

export default theme;
