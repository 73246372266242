import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, OptionsModal } from 'components';
import {
  ButtonWrapper,
  Checkbox,
  Contents,
  HorizontalRule,
  OptionsList,
  OptionWrapper,
  SaveButton,
  StyledSearchInput,
  Wrapper
} from './styled';

const ProgramTableCategoryFilterModal = ({
  categoryIds,
  categoryOptions,
  closeModal,
  setCategoryIds
}) => {
  const [formValues, setFormValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const newValuesObject = {};
    const categoryOptionValues = Object.values(categoryOptions);
    for (let index = 0; index < categoryOptionValues.length; index++) {
      const currentCategoryId = categoryOptionValues[index].id;
      newValuesObject[currentCategoryId] = categoryIds.includes(currentCategoryId);
    }
    setFormValues(newValuesObject);

    document.getElementById('program-category-filter-modal-search-input').focus();
  }, []);

  const handleClick = categoryId => {
    setFormValues({ ...formValues, [categoryId]: !formValues[categoryId] });
  };

  const filteredOptions = categoryOptions.filter(option => {
    return option.name.toLowerCase().includes(searchTerm);
  });

  return (
    <OptionsModal className={'program-category-filter-modal'} closeModal={closeModal}>
      <Contents>
        <Wrapper>
          <StyledSearchInput
            aria-label="Search Categories"
            id="program-category-filter-modal-search-input"
            placeholder="Category Search"
            onChange={({ target: { value } }) => setSearchTerm(value.toLowerCase())}
            value={searchTerm}
            onKeyDown={({ key, shiftKey }) => {
              if (shiftKey && key === 'Tab') {
                closeModal();
              }
            }}
          />
        </Wrapper>
        <form>
          <OptionsList>
            {filteredOptions.map(option => {
              return (
                <div key={option.id}>
                  <OptionWrapper>
                    <Checkbox
                      type="checkbox"
                      size="2rem"
                      checked={formValues[option.id] || false}
                      onChange={() => handleClick(option.id)}
                    />
                    {'    '}
                    {option.name}
                  </OptionWrapper>
                </div>
              );
            })}
          </OptionsList>
          <div>
            <HorizontalRule />
            <ButtonWrapper>
              <Button onClick={closeModal} isInverted>
                Cancel
              </Button>
              <SaveButton
                onClick={() => {
                  let selectedCategoryIds = [];
                  for (const [categoryId, isSelected] of Object.entries(formValues)) {
                    if (isSelected) {
                      selectedCategoryIds.push(categoryId);
                    }
                  }
                  setCategoryIds(selectedCategoryIds);
                }}
                onKeyDown={({ key, shiftKey }) => {
                  if (!shiftKey && key === 'Tab') {
                    closeModal();
                  }
                }}
              >
                OK
              </SaveButton>
            </ButtonWrapper>
          </div>
        </form>
      </Contents>
    </OptionsModal>
  );
};

ProgramTableCategoryFilterModal.propTypes = {
  categoryIds: PropTypes.array.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  closeModal: PropTypes.func.isRequired,
  setCategoryIds: PropTypes.func.isRequired
};

export default ProgramTableCategoryFilterModal;
