import React from 'react';
import styled from 'styled-components';
import { Card, ExternalLink, Layout } from 'components';

const Margin = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 5rem 4rem;

  @media only screen and (max-width: 1023px) {
    padding: 5rem 2rem;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const OnetOnline = <StyledExternalLink url="https://www.onetonline.org/" children="O*NET OnLine" />;

const CreativeCommons = (
  <StyledExternalLink url="https://creativecommons.org/licenses/by/4.0/" children="CC BY 4.0" />
);

const ONET = () => {
  return (
    <Layout title="O*NET Information">
      <Margin>
        <Card>
          <p>
            This page includes information from {OnetOnline} by the U.S. Department of Labor,
            Employment and Training Administration (USDOL/ETA). Used under the {CreativeCommons}{' '}
            license. O*NET&reg; is a trademark of USDOL/ETA. Lightcast has modified all or some of
            this information. USDOL/ETA has not approved, endorsed, or tested these modifications.
          </p>
        </Card>
      </Margin>
    </Layout>
  );
};

export default ONET;
