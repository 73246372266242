import React from 'react';
import PropTypes from 'prop-types';
import { Strong } from 'components';

const BoldLabel = ({ name = '', label = '', component: Component }) => {
  return (
    <Component htmlFor={name}>
      <Strong>{label}</Strong>
    </Component>
  );
};

BoldLabel.defaultProps = {
  component: 'label',
  name: '',
  label: ''
};

BoldLabel.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  component: PropTypes.string
};

export default BoldLabel;
