import React from 'react';
import { connect } from 'react-redux';
import { App } from 'components';
import {
  fetchSitesRequest,
  login,
  fetchSiteRequest,
  updateCurrentSite
} from '../../actions/actionCreators';
import { withRouter } from 'react-router-dom';
import { selectSubdomain } from '../../selectors/subdomain';

const AppContainer = props => <App {...props} />;

export const mapStateToProps = state => ({
  allSitesLoaded: state.sites.loaded,
  siteLoaded: state.sites.siteLoaded,
  siteFetching: state.sites.siteFetching,
  site: state.sites.items[state.sites.currentSite],
  loggedIn: state.profile.loggedIn,
  currentSite: selectSubdomain(state.sites, state.profile)
});

export { AppContainer as PureAppContainer }; // used in testing
export default withRouter(
  connect(mapStateToProps, {
    login,
    updateCurrentSite,
    fetchSites: fetchSitesRequest,
    fetchSite: fetchSiteRequest
  })(AppContainer)
);
