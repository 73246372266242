import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const List = styled.ul`
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
  li > * {
    text-decoration: none;
    display: block;
    padding: 1.75rem;
    transition: border-color 0.3s ease;
    border-${props => (props.isTop ? 'top' : 'bottom')}: 5px solid;
    border-color: transparent;
  }
  a.active {
    font-weight: 600;
  },
  a.active, li > *:hover {
    border-color: ${theme.accentColor};
  }
`;

const NavItem = styled.li`
  margin: 0 3px;
`;

const NavPanel = ({ forwardRef, className, children, isTop }) => (
  <nav className={className}>
    <List innerRef={forwardRef} isTop={isTop}>
      {React.Children.map(children, child => (
        <NavItem>{child}</NavItem>
      ))}
    </List>
  </nav>
);

NavPanel.propTypes = {
  isTop: PropTypes.bool,
  forwardRef: PropTypes.object
};

NavPanel.props = {
  isTop: false
};

export default NavPanel;
