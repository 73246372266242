import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Message, Toggle } from 'components';

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.7rem;
`;

const HelperText = styled.div`
  margin-top: 1rem;
`;

const Italic = styled.span`
  font-style: italic;
`;

const InputWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const CustomJobTypeInput = styled(Input)`
  max-width: 30rem;
  margin-bottom: 1.5rem;
`;

const JobTypeForm = ({
  setIsSubmitDisabled,
  options,
  input: { onChange, value: values },
  meta: { error = '' }
}) => {
  const existingCustomLabel = values.filter(value => !options.includes(value))[0] || '';

  const [customJobType, setCustomJobType] = useState(existingCustomLabel);
  const [hasEmptyInputError, setHasEmptyInputError] = useState(false);
  const [isCustomOptionChecked, setIsCustomOptionChecked] = useState(
    values.filter(value => !options.includes(value)).length > 0
  );

  return (
    <Fragment>
      <Label>Job Types</Label>
      <HelperText>
        Any live postings that contain a <Italic>deselected</Italic> job type will be hidden.
      </HelperText>

      {options.map((option, index) => {
        const isChecked = values.includes(option);
        return (
          <Toggle
            key={`option-${index}`}
            onChange={() => {
              return isChecked
                ? onChange(values.filter(value => value !== option))
                : onChange([...values, option]);
            }}
            label={option}
            type="checkbox"
            checked={isChecked}
          />
        );
      })}
      <Toggle
        key="option-custom"
        onChange={() => {
          if (isCustomOptionChecked) {
            onChange(values.filter(value => options.includes(value)));
            setIsSubmitDisabled(false);
            setHasEmptyInputError(false);
          } else if (customJobType !== '') {
            onChange([...values, customJobType]);
          } else {
            setIsSubmitDisabled(true);
          }

          setIsCustomOptionChecked(!isCustomOptionChecked);
        }}
        label="Custom Option"
        type="checkbox"
        checked={isCustomOptionChecked}
      />
      {error && <Message type="error">Please select at least one option</Message>}

      {isCustomOptionChecked && (
        <InputWrapper>
          <Label htmlFor="custom-job-type-input">Custom Option Label</Label>
          <CustomJobTypeInput
            id="custom-job-type-input"
            autoFocus
            disabled={!isCustomOptionChecked}
            placeholder="Please enter a custom option name"
            onChange={({ target }) => {
              const nonCustomValues = values.filter(value => value !== customJobType);
              if (target.value !== '') {
                onChange([...nonCustomValues, target.value]);
                setIsSubmitDisabled(false);
                setHasEmptyInputError(false);
              } else {
                setIsSubmitDisabled(true);
              }
              setCustomJobType(target.value);
            }}
            onBlur={() => {
              if (customJobType === '') {
                setHasEmptyInputError(true);
              }
            }}
            defaultValue={customJobType || ''}
          />
          {hasEmptyInputError && (
            <Message type="error">Custom option must not be empty if selected</Message>
          )}
        </InputWrapper>
      )}
    </Fragment>
  );
};

JobTypeForm.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
  }).isRequired,
  // redux form props
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired,
  options: PropTypes.array.isRequired,
  setIsSubmitDisabled: PropTypes.func.isRequired
};

export default JobTypeForm;
