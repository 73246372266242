import styled from 'styled-components';
import { color as colors } from '../../../styles';

export const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Card = styled.div`
  width: 100%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 2rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 3px;
`;

export const HiddenA11yTableWrapper = styled.div`
  position: absolute;
  opacity: 0;
`;

export const Tooltip = styled.div`
  position: absolute;
  ${({ mousePosition }) => `left: ${mousePosition.x - 74}px; top: ${mousePosition.y - 77}px;`}
  width: 16rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
`;

export const TooltipTail = styled.div`
  width: 12px;
  height: 10px;
  overflow: hidden;
  position: absolute;
bottom: -10px;
box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

:after {
  content: '';
  background: white;
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-71%,0);
`;

export const TooltipValue = styled.div`
  font-weight: 600;
`;

export const Total = styled.span`
  display: flex;
  margin-right: 0.5rem;
  font-size: 4rem;
  font-weight: 600;
  color: ${({ color }) => color};
`;

export const TotalLabel = styled.div`
  height: 1.8rem;
  font-size: 1.5rem;
  white-space: nowrap;
`;

export const TotalRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const TotalWrapper = styled.div`
  height: 5rem;
  display: flex;
  align-items: flex-end;
  margin-right: 2rem;
`;
