import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { Button, Margin, ResumeDownloadModal } from 'components';

import { locale } from '../../../helpers/locale';
import { color } from '../../../styles';

const { green, red, yellow } = color;

const ToolTip = styled(ReactTooltip)`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border: 2px solid ${props => props.color};
  opacity: 1 !important;

  /* this makes the little arrow part of the tooltip the same as the border */
  ::after {
    border-top-color: ${props => props.color} !important;
  }
`;

const SmallButton = styled(Button)`
  position: relative;
  margin-left: 0.7rem;
  padding: 0.3rem 1rem !important;
  border-radius: 5px;
  letter-spacing: 0.15rem;
  font-size: 1.25rem;
  font-variant: small-caps;
  font-weight: 600;
`;

const StyledBubble = styled.span`
  position: absolute;
  top: -4px;
  left: -6px;
  border: 2px solid white;
  padding: 5px;
  background: ${props => props.color};
  border-radius: 50%;
  background-clip: padding-box;
`;

const ProfileResumeModalButton = ({
  modals,
  modalShow,
  nation,
  profile,
  requiresResumeApproval,
  resumes
}) => {
  const { name, profileId, resumeApprovalStatus } = profile;

  const status = {
    pending: {
      color: yellow,
      message: 'awaiting review'
    },
    approved: {
      color: green,
      message: 'approved'
    },
    denied: {
      color: red,
      message: 'changes requested'
    },
    edited: {
      color: red,
      message: 'changes requested'
    }
  };

  return (
    <Fragment>
      {resumes.filter(resume => resume.profileId === profile.profileId).length > 0 ? (
        <SmallButton
          aria-label={
            (requiresResumeApproval && profile.resumeApprovalStatus
              ? `${locale.resume[nation]} ${status[profile.resumeApprovalStatus].message}, `
              : '') + `view ${locale.resume[nation]}s for ${profile.name}`
          }
          onClick={() => modalShow(`resume${profile.profileId}`)}
          /* the data-tip boolean prop is required for the tooltip */
          data-tip
          data-for={profileId}
          data-event="mouseover focusin"
          data-event-off="mouseout focusout"
        >
          {requiresResumeApproval && resumeApprovalStatus && (
            <Fragment>
              <StyledBubble color={status[resumeApprovalStatus].color} />
              <ToolTip
                id={profileId}
                place="top"
                effect="solid"
                type="light"
                color={status[resumeApprovalStatus].color}
              >
                {status[resumeApprovalStatus].message}
              </ToolTip>
            </Fragment>
          )}
          view
        </SmallButton>
      ) : (
        <Margin value="0 0 0 2.3rem">---</Margin>
      )}
      {modals[`resume${profileId}`] && (
        <ResumeDownloadModal
          userName={name}
          modalName={`resume${profileId}`}
          resumes={resumes.filter(res => res.profileId === profileId)}
        />
      )}
    </Fragment>
  );
};

ProfileResumeModalButton.propTypes = {
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    name: PropTypes.string,
    profileId: PropTypes.string,
    resumeApprovalStatus: PropTypes.string
  }).isRequired,
  requiresResumeApproval: PropTypes.bool,
  resumeEnabled: PropTypes.bool,
  resumes: PropTypes.array
};

export default ProfileResumeModalButton;
