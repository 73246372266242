function getUserAgentPayload() {
  return {
    name: 'keen:ua_parser',
    input: {
      ua_string: 'user_agent'
    },
    output: 'parsed_user_agent'
  };
}

function getReferrerPayload() {
  return {
    name: 'keen:referrer_parser',
    input: {
      referrer_url: 'referrer_url',
      page_url: 'page_url'
    },
    output: 'referer.info'
  };
}

function getGeoIPPayload() {
  return {
    name: 'keen:ip_to_geo',
    input: {
      ip: 'ip_address'
    },
    output: 'ip_geo_info'
  };
}

export default function getGlobals(state) {
  const keen = {
    keen: {
      addons: [...getUserAgentPayload(), ...getReferrerPayload(), ...getGeoIPPayload()]
    },
    // remove all other personally identifiable information
    user: {
      customData: {
        subdomain: state.profile.customData.subdomain,
        userRole: state.profile.customData.userRole
      },
      user_id: state.profile.user_id
    },
    user_agent: window.navigator.userAgent,
    ip_address: '${keen.ip}',
    referrer_url: document.referrer,
    page_url: window.location.href
  };

  return keen;
}
