import React, { Fragment } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { color } from '../../../styles';
import ChevronDown from '../../../images/chevron-down.svg';
import PencilIcon from '../../../images/pencil.svg';
import TrashIcon from '../../../images/trash-red.svg';

import { locale } from '../../../helpers/locale';
import { deleteProgram } from '../../../services';
import { updateProgram } from 'services';
import { Button, ConfirmationModal, EditProgramModal, TextButton } from 'components';

const { blue, red } = color;

const IconButton = styled.button`
  width: 2rem !important;
  height: 2rem;
  padding: 0.2rem;
  border: none;
  border-radius: 2px;
  background: ${blue};

  :hover {
    background: ${darken(0.05, blue)};
  }
`;

const IconWrapper = styled.div`
  padding-top: 0.1rem;
  transition: 0.33s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const renderCareerMappingColumns = ({
  hasManyInstitutions,
  nation,
  openDrawer,
  rowDrawers
}) => {
  const columns = [
    {
      id: 'editDrawer',
      header: '',
      hasSort: false,
      width: 5,
      value: rowData => (
        <IconButton color={blue} onClick={() => openDrawer(rowData)}>
          <IconWrapper isOpen={!!rowDrawers[rowData.code] && rowDrawers[rowData.code].isOpen}>
            <ChevronDown />
          </IconWrapper>
        </IconButton>
      )
    },
    {
      id: 'institutionName',
      header: 'Institution',
      isHidden: !hasManyInstitutions,
      width: 20,
      tooltip: `This identifies the institution(s) that a ${locale.edTrack.lower[nation]} is tied to.`
    },
    {
      id: 'name',
      header: `${locale.edTrack[nation]} Name`,
      hasSort: true,
      width: 40
    },
    {
      id: 'credential',
      header: 'Credential',
      hasSort: true,
      width: 20
    },
    {
      id: 'categories',
      hasColumnFilter: true,
      header: 'Category',
      width: 30,
      value: rowData => rowData.categories.map(program => program.name).join()
    },
    {
      id: 'code',
      header: 'Code',
      hasSort: true,
      width: 15
    },
    {
      id: 'addCareers',
      header: '',
      width: 18,
      value: rowData => (
        <TextButton color={blue} fontWeight="600" onClick={() => openDrawer(rowData)}>
          + Add Careers
        </TextButton>
      )
    }
  ];

  return columns;
};

export const renderLogColumns = ({ hasManyInstitutions, isDeveloper, nation }) => {
  const columns = [
    {
      id: 'createdAt',
      header: 'Date',
      hasSort: true,
      width: 18
    },
    {
      id: 'institutionName',
      header: 'Institution',
      isHidden: !hasManyInstitutions,
      width: 20
    },
    {
      id: 'name',
      header: `${locale.edTrack[nation]} Name`,
      hasSort: true,
      width: 30
    },
    {
      id: 'credential',
      header: 'Credential',
      hasSort: true,
      width: 20
    },
    {
      id: 'email',
      header: 'Email Address',
      hasSort: true,
      width: 34
    },
    {
      id: 'activity',
      header: 'Activity',
      width: 30
    },
    {
      id: 'stringifiedDiff',
      header: 'Diff',
      isHidden: !isDeveloper,
      width: 30
    }
  ];

  return columns;
};

export const renderProgramsColumns = ({
  hasManyInstitutions,
  modalHide,
  modalShow,
  modals,
  categoryOptions,
  fetchProgramData,
  institutionsById,
  setIsLoading,
  nation
}) => {
  const columns = [
    {
      id: 'edit',
      header: 'Edit',
      isSticky: true,
      width: 5,
      value: rowData => (
        <Fragment>
          <StyledButton
            aria-label={`Edit ${rowData.name} ${rowData.credential}`}
            data-cy={`open-edit-program-modal-button-${rowData.name}-${rowData.credential}`}
            onClick={() => modalShow(`program-modal-${rowData.code}`)}
          >
            <PencilIcon />
          </StyledButton>
          {modals[`program-modal-${rowData.code}`] && (
            <EditProgramModal
              isEdit
              programData={rowData}
              categoryOptions={categoryOptions}
              closeModal={() => modalHide(`program-modal-${rowData.code}`)}
              fetchProgramData={fetchProgramData}
              hasManyInstitutions={hasManyInstitutions}
              institutionsById={institutionsById}
              name={`program-modal-${rowData.code}`}
              setIsLoading={setIsLoading}
              updateProgram={updateProgram}
            />
          )}
        </Fragment>
      )
    },
    {
      id: 'institutionName',
      header: 'Institution',
      isHidden: !hasManyInstitutions,
      isSticky: true,
      width: 20,
      tooltip: 'This identifies the institution(s) that a program is tied to.'
    },
    {
      id: 'name',
      header: `${locale.edTrack[nation]} Name`,
      hasSort: true,
      isSticky: true,
      width: 40
    },
    {
      id: 'credential',
      header: 'Credential',
      hasSort: true,
      isSticky: true,
      hasBorderRight: true,
      width: 15,
      tooltip: 'Defines the award level or credential'
    },
    {
      id: 'url',
      header: 'URL',
      hasSort: true,
      width: 40,
      tooltip: 'The link to the program on your institution’s website.'
    },
    {
      id: 'categories',
      header: 'Category',
      width: 35,
      hasColumnFilter: true,
      value: rowData => rowData.categories.map(program => program.name).join(', '),
      tooltip: 'The program’s category or categories'
    },
    {
      id: 'code',
      header: 'Code',
      hasSort: true,
      width: 13,
      tooltip: 'This is a unique identifier for a program. Once added, it may not be changed.'
    },
    {
      id: 'locations',
      header: 'Location',
      isHidden: !hasManyInstitutions,
      width: 15,
      value: rowData => rowData.locations.join(),
      tooltip: 'Shows the location(s) a program is available'
    },
    {
      id: 'requestInfoEmail',
      header: 'Info Email',
      hasSort: true,
      width: 30,
      tooltip:
        'Designates the email address the request for information form will be sent for the program.'
    },
    {
      id: 'applicationLink',
      header: 'Application URL',
      width: 30,
      tooltip: "A custom link to a program's application page"
    },
    {
      id: 'tuitionLink',
      header: 'Tuition URL',
      hasSort: true,
      width: 30,
      tooltip: "a custom link to a program's tuition page"
    },
    {
      id: 'delete',
      header: 'Delete',
      width: 8,
      value: rowData => (
        <Fragment>
          <StyledButton
            aria-label={`Delete ${rowData.name}, ${rowData.credential}`}
            data-cy={`program-table-delete-button-${rowData.slug}`}
            onClick={() => modalShow(`delete-program-modal-${rowData.slug}`)}
          >
            <TrashIcon />
          </StyledButton>
          {modals[`delete-program-modal-${rowData.slug}`] && (
            <ConfirmationModal
              name={`delete-program-modal-${rowData.slug}`}
              heading={`Delete ${locale.edTrack[nation]}?`}
              modalHide={modalHide}
              text={`Delete ${rowData.name}, ${rowData.credential}?`}
              confirmationButton={
                <Button
                  color="#ffffff"
                  background={red}
                  data-cy={`delete-program-confirm-button-${rowData.slug}`}
                  onClick={async () => {
                    await deleteProgram({
                      ...rowData
                    });
                    fetchProgramData();
                    modalHide(`delete-program-modal-${rowData.slug}`);
                  }}
                >
                  Yes
                </Button>
              }
            />
          )}
        </Fragment>
      )
    }
  ];

  return columns;
};
