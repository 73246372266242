import styled from 'styled-components';
import RightArrow from '../../../images/arrow-right-white.svg';
import LeftArrow from '../../../images/arrow-left-black.svg';
import { Button, SearchInput } from 'components';

export const ButtonWrapper = styled.div`
  height: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 7rem 5rem 2rem 5rem;
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-weight: 600;
`;

export const LeftArrowIcon = styled(LeftArrow)`
  margin-right: 2rem;
`;

export const MultiSelect = styled.select`
  width: 100%;
  height: 30rem;
  padding: 1.5rem;
  border: 1px solid #d8dbe1;
  border-top: none;
  border-radius: 0 0 4px 4px;
  overflow-x: scroll;
`;

export const MultiSelectWrapper = styled.div`
  width: 50.5rem;
  height: 38rem;
`;

export const Option = styled.option`
  font-size: 1.4rem;
  padding: 0.25rem 2rem;
`;

export const RightArrowIcon = styled(RightArrow)`
  margin-right: 2rem;
`;

export const SaveButton = styled(Button)`
  align-self: flex-end;
  margin-top: 5rem;
`;

export const StyledButton = styled(Button)`
  width: 13rem;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  border-radius: 4px 4px 0 0;
`;

export const UnsavedWarning = styled.div`
  height: 10rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
`;
