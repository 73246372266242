import styled from 'styled-components';
import { color } from '../../../styles';

const Line = styled.hr`
  width: 100%;
  height: 0.2rem;
  background-color: ${color.lightGray};
  border: hidden;
`;

export default Line;
