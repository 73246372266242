import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Margin, BoldLabel, Toggle, Hint } from 'components';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FormRadio = ({ input: { onChange, value }, label, options, hintText, disabled, color }) => {
  return (
    <Margin value="0 0 3rem 0">
      <fieldset>
        <Flex>
          <BoldLabel label={label} component="legend" />
          {hintText && <Hint id={label}>{hintText}</Hint>}
        </Flex>
        {options.map((option, index) => {
          return (
            <Toggle
              ariaLabel={option.ariaLabel}
              color={color}
              key={`option-${index}`}
              type="radio"
              label={option.label}
              onChange={() => !disabled && onChange(option.value)}
              checked={option.value === value}
              disabled={disabled}
            />
          );
        })}
      </fieldset>
    </Margin>
  );
};

FormRadio.defaultProps = {
  hintText: '',
  options: [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ]
};

FormRadio.propTypes = {
  color: PropTypes.string,
  hintText: PropTypes.string,
  options: PropTypes.array,
  // redux-form
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default FormRadio;
