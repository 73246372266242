import React from 'react';
import PropTypes from 'prop-types';

import { color } from '../../../styles/index';
import { locale } from '../../../helpers/locale';
import { HelpModal } from 'components';

const { lightGreen, lightYellow } = color;

const CareerMappingHelpModal = ({ nation }) => {
  const modalData = [
    {
      subtitle: 'View Mapped Careers',
      description: `To view the current careers mapped to your ${locale.edTrack.lower[nation]}, click the down arrow or Add Careers.`
    },
    {
      subtitle: 'Add Careers',
      description:
        'After opening the mapped careers section, use the Available Careers table to search for new careers to add. You can select one or multiple careers at once by using the command (Mac) or ctrl (PC) while clicking career names. Click the Add button. Make sure to save your changes.'
    },
    {
      subtitle: 'Remove Careers',
      description:
        'After opening the mapped careers section, use the Mapped Careers table to search for the career you wish to remove. Click to highlight the careers you want to remove from the list and click the Remove button. Make sure to save your changes.'
    }
  ];
  const legendData = [
    {
      legendText: 'No Careers Mapped',
      background: `${lightYellow}`
    },
    {
      legendText: `New ${locale.edTrack[nation]}`,
      background: `${lightGreen}`
    }
  ];

  return <HelpModal legendData={legendData} modalData={modalData} />;
};

CareerMappingHelpModal.propTypes = {
  nation: PropTypes.string.isRequired
};

export default CareerMappingHelpModal;
