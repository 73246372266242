import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const IsDeveloper = ({ children, isDeveloper }) => {
  return isDeveloper ? children : null;
};

const mapStateToProps = ({ profile: { isDeveloper } }) => ({
  isDeveloper
});

IsDeveloper.propTypes = {
  userRole: PropTypes.string
};

export default connect(mapStateToProps)(IsDeveloper);
