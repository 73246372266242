import { flashError } from 'helpers/flashmessage';

/**
 * takes the errors object from the API catch method
 * @method handleErrors
 * @param  {object}     e the error object
 * @return {object}       the errors object
 */
export const handleErrors = (subdomain, e) => {
  if (e.errors) {
    // loop through the errors array and output each error
    e.errors.forEach(error => {
      flashError(`${error.title} for ${subdomain}`, error.detail.slice(48));
    });
    return e.errors;
  }
  return e;
};
