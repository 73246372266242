import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Float, Truncate } from 'components';

const Li = styled.li`
  padding-bottom: 1rem;
`;

const StyledFloat = styled(Float)`
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
`;

const Value = styled.span`
  margin-left: 3rem;
  width: 8.5rem;
  display: inline-block;
  text-align: right;
`;

const AssessmentsBySchoolStatItem = ({ completions, starts, schoolName }) => (
  <Li>
    <Truncate>{schoolName}</Truncate>
    <StyledFloat component="span">
      <Value>{starts}</Value>
      <Value>{completions}</Value>
    </StyledFloat>
  </Li>
);

AssessmentsBySchoolStatItem.propTypes = {
  completions: PropTypes.number.isRequired,
  schoolName: PropTypes.string.isRequired,
  starts: PropTypes.number.isRequired
};

export default AssessmentsBySchoolStatItem;
