import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { getDateFromISO } from '../../../helpers/get-date-from-iso';
import { Loading } from 'components';

import { drawChart } from './functions';
import {
  CanvasWrapper,
  Card,
  HiddenA11yTableWrapper,
  Tooltip,
  TooltipTail,
  TooltipValue,
  Total,
  TotalLabel,
  TotalRow,
  TotalWrapper
} from './components';

const PercentageChart = ({
  className,
  color,
  chartId,
  data,
  a11yHeader,
  loaded,
  nation,
  percentageLabel
}) => {
  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [totals, setTotals] = useState([]);
  const [tooltipData, setTooltipData] = useState();

  useEffect(() => {
    if (loaded) {
      const newTotals = [];
      for (let index = 0; index < data.length; index++) {
        const currentData = data[index];
        const newTotal = currentData.results
          .map(({ value }) => value)
          .reduce((alpha, bravo) => alpha + bravo);
        newTotals.push(newTotal);
      }
      setTotals(newTotals);
    }
  }, [loaded]);

  useEffect(() => {
    if (totals.length > 0) {
      drawChart({
        canvasRef,
        color,
        data,
        chartId,
        nation,
        setIsTooltipVisible,
        setMousePosition,
        setTooltipData
      });
    }
  }, [totals]);

  useEffect(() => {
    addEventListener('resize', () => {
      if (totals.length > 0) {
        drawChart({
          canvasRef,
          color,
          data,
          chartId,
          nation,
          setIsTooltipVisible,
          setMousePosition,
          setTooltipData
        });
      }
    });
  });

  return (
    <Card className={className}>
      <Loading loaded={loaded}>
        {loaded && (
          <Fragment>
            <TotalRow>
              {totals.map((currentTotal, index) => (
                <TotalWrapper key={`${chartId}-total=${index}`}>
                  <Total color={data[index].color}>{currentTotal}</Total>
                  <TotalLabel>{`Total ${data[index].label}`}</TotalLabel>
                </TotalWrapper>
              ))}
              {totals[0] > 0 && (
                <TotalWrapper>
                  <Total key={`${chartId}-total=percentage`}>
                    {`${Math.round((totals[1] / totals[0]) * 100)}%`}
                  </Total>
                  <TotalLabel>{percentageLabel}</TotalLabel>
                </TotalWrapper>
              )}
            </TotalRow>

            {totals.length > 0 && (
              <CanvasWrapper id={`${chartId}-wrapper`}>
                <canvas ref={canvasRef} id={chartId} />
                {isTooltipVisible && (
                  <Tooltip mousePosition={mousePosition}>
                    <div>{tooltipData.date}</div>
                    <TooltipValue>{`${tooltipData.value} ${tooltipData.label}`}</TooltipValue>
                    <TooltipTail />
                  </Tooltip>
                )}
                <HiddenA11yTableWrapper>
                  <table>
                    <caption>{a11yHeader}</caption>
                    <thead>
                      <tr>
                        <th />
                        {data[0].results.map(({ timeframe }) => (
                          <th key={`${chartId}-${timeframe.start}`}>
                            {getDateFromISO(timeframe.start, nation)}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {data.map(({ label, results }) => (
                        <tr key={`${chartId}-${label}`}>
                          <td>{label}</td>
                          {results.map(({ value }, index) => (
                            <td key={`${chartId}-${index}`}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </HiddenA11yTableWrapper>
              </CanvasWrapper>
            )}
          </Fragment>
        )}
      </Loading>
    </Card>
  );
};

PercentageChart.propTypes = {
  a11yHeader: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.array,
  chartId: PropTypes.string,
  heading: PropTypes.string,
  loaded: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  percentageLabel: PropTypes.string
};

export default PercentageChart;
