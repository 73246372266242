import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Strong } from 'components';

const Name = styled.div`
  color: #fff;
  font-size: 1.8rem;
`;

const Subdomain = styled.p`
  color: #fff;
  opacity: 0.6;
  margin: 0;
`;

const Wrapper = styled.div`
  display: inline-block;
  line-height: 1.1;
  vertical-align: middle;
`;

const SiteName = ({ name }) => {
  return (
    <Wrapper>
      <Strong><Name>{name}</Name></Strong>
      <Subdomain>lightcastcc.com</Subdomain>
    </Wrapper>
  );
};

SiteName.propTypes = {
  name: PropTypes.string.isRequired
};

export default SiteName;
