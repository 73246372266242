import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Loading, Message, StatItems, TabButton } from 'components';

const StyledCard = styled(Card)`
  width: 100%;
  min-height: 33rem;

  @media (min-width: 1024px) {
    width: 49.5%;
  }
`;

const Header = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 1.8rem;

  @media (min-width: 560px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TabButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;

  @media (min-width: 560px) {
    width: auto;
    margin-top: 0;
  }
`;

const StatTabCard = ({
  firstTab,
  secondTab,
  isLoaded,
  subdomain,
  title,
  modals,
  modalShow,
  nation
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const isNoData =
    (selectedTabIndex === 0 && isLoaded && firstTab.data && firstTab.data.length === 0) ||
    (selectedTabIndex === 1 && isLoaded && secondTab.data && secondTab.data.length === 0);

  return (
    <StyledCard>
      <Header>
        {title}
        <TabButtonWrapper>
          <TabButton
            isFirst
            selected={selectedTabIndex === 0}
            onClick={() => setSelectedTabIndex(0)}
          >
            {firstTab.buttonLabel}
          </TabButton>
          <TabButton
            isLast
            selected={selectedTabIndex === 1}
            onClick={() => setSelectedTabIndex(1)}
          >
            {secondTab.buttonLabel}
          </TabButton>
        </TabButtonWrapper>
      </Header>
      <Loading loaded={isLoaded}>
        {isLoaded && (
          <Fragment>
            {selectedTabIndex === 0 && !isNoData && (
              <StatItems
                data={firstTab.data}
                loaded
                subdomain={subdomain}
                resultKey={firstTab.resultKey}
              />
            )}
            {selectedTabIndex === 1 && !isNoData && (
              <StatItems
                data={secondTab.data}
                loaded
                subdomain={subdomain}
                resultKey={secondTab.resultKey}
                modals={modals}
                modalShow={modalShow}
                nation={nation}
              />
            )}
            {isNoData && <Message>No Data Available</Message>}
          </Fragment>
        )}
      </Loading>
    </StyledCard>
  );
};

StatTabCard.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  subdomain: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  firstTab: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    resultKey: PropTypes.string.isRequired,
    byApplicants: PropTypes.arrayOf(
      PropTypes.shape({
        result: PropTypes.number.isRequired
      })
    )
  }),
  secondTab: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    resultKey: PropTypes.string.isRequired,
    byApplicants: PropTypes.arrayOf(
      PropTypes.shape({
        result: PropTypes.number.isRequired
      })
    )
  }),

  modals: PropTypes.object,
  modalShow: PropTypes.func,
  nation: PropTypes.string
};

export default StatTabCard;
