import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import QuestionMark from '../../../images/question-mark.svg';
import styled from 'styled-components';

const ToolTip = styled(ReactTooltip)`
  max-width: 300px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
`;

const StyledQuestionMark = styled(QuestionMark)`
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  top: 3px;

  @media (max-width: 539px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  margin: 0 5px;
  padding: 0;
`;

const Hint = ({ children, id, icon, place, ...props }) => {
  return (
    <div {...props}>
      <StyledButton data-tip data-for={id} data-event="click" role="tooltip" type="button">
        {icon || <StyledQuestionMark />}
      </StyledButton>
      <ToolTip
        id={id}
        place={place || 'right'}
        effect="solid"
        type="light"
        globalEventOff="focusout"
      >
        {children}
      </ToolTip>
    </div>
  );
};

Hint.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element,
  place: PropTypes.string
};

export default Hint;
