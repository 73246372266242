import axios from 'axios';
import FileSaver from 'file-saver';
import fetch from 'isomorphic-fetch';
import dasherize from 'dasherize';

import { getCognitoToken } from '../';
import { flashError } from '../../helpers/flashmessage';
import { get } from '../utils';

/**
 * downloadResume
 * @return null The resume will be directly saved locally.
 */
const downloadResume = async (resume, name, nation) => {
  const data = {
    data: {
      nation,
      type: 'resume',
      attributes: {
        ...dasherize(resume)
      }
    }
  };
  // an uploaded resume
  if (resume.externalLink) {
    const signedResume = await fetch(`/api/cc-api/manage-s3/resume/sign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        data: {
          type: 'file-read',
          attributes: {
            filename: `${resume.subdomain}/${resume.externalLink.split('/').pop()}`,
            filetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          }
        }
      })
    }).then(response => response.json());

    const { url } = signedResume.data.attributes;

    return axios
      .get(url, {
        responseType: 'blob'
      })
      .then(blob => FileSaver.saveAs(blob.data, `${name}-${resume.title}`));
  }

  return fetch('/api/cc-api/resume-exporter', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  })
    .then(response => response.blob())
    .then(blob => FileSaver.saveAs(blob, `${name}-${resume.title}.docx`));
};

/**
 * Fetch resumes by subdomain
 */
export const fetchResumes = async subdomain => {
  const headers = new Headers({
    Authorization: `Bearer ${await getCognitoToken()}`
  });
  return get(`/api/cc-api/resumes?subdomain=${subdomain}&isDeactivated=false`, null, headers)
    .then(resumes => {
      return resumes;
    })
    .catch(() => {
      flashError('Sorry, we are unable to get resumes at this point.');
      return false;
    });
};

/**
 * Attempt to fetch and download the individual's resume, using the previous two sagas. Catch any error by displaying a flash error message.
 */
export const fetchAndDownloadResume = async ({ id, name, nation }) => {
  const headers = new Headers({
    Authorization: `Bearer ${await getCognitoToken()}`
  });
  // fetch the resume
  return (
    get(`/api/cc-api/resumes/${id}`, null, headers)
      // post resume to resume-exporter and download it
      .then(resume => downloadResume(resume, name, nation))
      .catch(() => {
        flashError(`Sorry, we are unable to download ${name}'s resume at this point.`);
      })
  );
};
