import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { locale } from '../../../helpers/locale';
import { Loading, StickyColumnTable } from 'components';
import { fetchData } from './functions';
import { usePagination } from '../../../hooks/usePagination';

const Wrapper = styled.div`
  max-width: 108rem;
`;

const Header = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const ProgramWidgetTopActivityTable = ({ nation, subdomain, timeframe }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    handlePageNumberChange,
    pageNumber,
    paginatedData: paginatedTableData,
    rowsPerPage
  } = usePagination(tableData);

  const fetchTableData = async () => {
    setIsLoading(true);
    const newTableData = await fetchData({ subdomain, timeframe });
    setTableData(newTableData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [timeframe]);

  const columns = [
    {
      id: 'title',
      header: `${locale.edTrack[nation]}`,
      hasSort: false,
      width: 70
    },
    {
      id: 'views',
      header: 'Views',
      hasSort: false,
      textAlign: 'center',
      width: 50,
      value: rowData => rowData.views ? rowData.views.toLocaleString() : '0'
    },
    {
      id: 'clicks',
      header: 'Clicks in Widget',
      hasSort: false,
      textAlign: 'center',
      width: 50,
      value: rowData => rowData.clicks ? rowData.clicks.toLocaleString() : '0'
    },
    {
      id: 'visit-button-clicks',
      header: 'Career Coach Clicks',
      hasSort: false,
      textAlign: 'center',
      width: 50,
      value: rowData => rowData['visit-button-clicks'] ? rowData['visit-button-clicks'].toLocaleString() : '0'
    }
  ];

  const isNoData = !!paginatedTableData && paginatedTableData.length === 0;
  if (isNoData) {
    return null;
  }

  return (
    <Wrapper>
      <Loading loaded={!isLoading}>
        <Header>{`Top Activity by ${locale.edTrack[nation]} Widget`}</Header>
        <StickyColumnTable
          columns={columns}
          dataCy="program-widget-activity-by-program-table"
          isLoading={!paginatedTableData}
          shouldAlternateRowColor
          tableData={paginatedTableData || []}
          totalRows={tableData.length}
          rowsPerPage={rowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={handlePageNumberChange}
        />
      </Loading>
    </Wrapper>
  );
};

ProgramWidgetTopActivityTable.propTypes = {
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

export default ProgramWidgetTopActivityTable;
