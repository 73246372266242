import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ResumeApproval } from 'components';
import { modalShow, modalHide } from '../../reducers/modal';
import { selectSubdomain } from '../../selectors/subdomain';
import { fetchResumes, getProfiles } from '../../services';

const ResumeApprovalContainer = props => {
  const [resumes, setResumes] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [hasLoadingError, setHasLoadingError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (props.requiresResumeApproval) {
      loadResumes(props.subdomain);
    }
  }, []);

  const loadResumes = async subdomain => {
    let resumesResponse, profilesResponse;
    try {
      resumesResponse = await fetchResumes(subdomain);
      profilesResponse = await getProfiles(subdomain);
      setIsLoaded(true);
      setProfiles(profilesResponse.profiles);
      setResumes(Object.values(resumesResponse));
    } catch (e) {
      setIsLoaded(true);
      setHasLoadingError(true);
    }
  };

  return props.requiresResumeApproval ? (
    <ResumeApproval
      {...props}
      resumes={resumes}
      allProfiles={profiles}
      hasLoadingError={hasLoadingError}
      isLoaded={isLoaded}
      reloadProfiles={loadResumes}
    />
  ) : null;
};

ResumeApprovalContainer.propTypes = {
  subdomain: PropTypes.string.isRequired,
  requiresResumeApproval: PropTypes.bool.isRequired
};

export const mapStateToProps = ({ profile, sites }) => {
  const currentSite = selectSubdomain(sites, profile);
  return {
    adminEmail: sites.items[currentSite].employerNotificationEmail,
    nation: sites.items[currentSite].nation,
    requiresResumeApproval: sites.items[currentSite].requiresResumeApproval,
    subdomain: currentSite,
    whiteLabel: sites.items[currentSite].whiteLabel
  };
};

export default connect(mapStateToProps, {
  modalShow,
  modalHide
})(ResumeApprovalContainer);
