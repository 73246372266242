import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { getProfiles, updateCognitoUser, updateProfile } from '../../../services/profiles';
import { Button, ConfirmationModal, Strong } from 'components';
import { flashError } from '../../../helpers/flashmessage';

const { gray, red } = color;

const Row = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 2px solid ${gray};
`;

const SuperAdminList = ({ modalHide, modalShow }) => {
  const [isSuperAdmins, setSuperAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProfiles = async () => {
    setIsLoading(true);
    const profilesResponse = await getProfiles('universal', '?limit=20000');
    const { profiles } = profilesResponse;
    setSuperAdmins(profiles);
    setIsLoading(false);
  };

  const makeUserNotSuper = async (email, profileId) => {
    const newSubdomain = 'demo';
    const authResponse = await updateCognitoUser({
      email,
      userRole: '',
      subdomain: newSubdomain
    });

    if (!authResponse.ok) {
      return flashError('Sorry, we cannot change that user at the moment');
    }

    await updateProfile('universal', profileId, {
      userRole: '',
      subdomain: newSubdomain
    });
    fetchProfiles();
    modalHide(`remove-super-admin-${profileId}`);
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  return (
    <ul>
      {!isLoading &&
        isSuperAdmins.map(({ email, profileId }) => (
          <Fragment>
            <Row key={`super-admin-list-${profileId}`}>
              <span>{email}</span>
              <Button background="red" onClick={() => modalShow(`remove-super-admin-${profileId}`)}>
                Remove
              </Button>
            </Row>
            <ConfirmationModal
              name={`remove-super-admin-${profileId}`}
              heading="Remove Super Admin"
              modalHide={modalHide}
              text={
                <Fragment>
                  Remove Super Admin Access for
                  <Strong>{` ${email}?`}</Strong>
                </Fragment>
              }
              confirmationButton={
                <Button
                  color="#ffffff"
                  background={red}
                  onClick={() => makeUserNotSuper(email, profileId)}
                >
                  Yes
                </Button>
              }
            />
          </Fragment>
        ))}
    </ul>
  );
};

SuperAdminList.propTypes = {
  modalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.func.isRequired
};

export default SuperAdminList;
