import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormInput, FormLayout, Margin, FormRadio } from 'components';

const HelpText = ({ children }) => <Margin value="-3rem 0 2rem">{children}</Margin>;

const HomeForm = ({
  handleFormSubmit,
  submitting,
  pristine,
  reset,
  handleSubmit // this is getting passed in by redux-form
}) => {
  return (
    <FormLayout
      title="Home Settings"
      submitForm={handleSubmit(handleFormSubmit)}
      submitting={submitting}
      pristine={pristine}
      reset={reset}
    >
      <Field label="Headline Text" name="headlineText" component={FormInput} type="text" />

      <Field label="Intro Text" name="introText" component={FormInput} type="text" />

      <Field
        label="Video URL"
        name="videoUrl"
        placeholder="https://www.youtube.com/embed/xdiKtOb2AQI"
        component={FormInput}
        type="text"
      />
      <HelpText>
        The video URL must be in the form of a YouTube embed link (if left empty, it defaults to
        https://www.youtube.com/embed/xdiKtOb2AQI)
      </HelpText>

      <Field label="Military Search" name="militarySearch" component={FormRadio} type="text" />

      <Field
        label="Equal Opportunity Employer"
        name="hasEoeEnabled"
        component={FormRadio}
        type="text"
      />

      <Field
        label='"About the College" Headline Text'
        name="aboutHeadline"
        component={FormInput}
        type="text"
      />

      <Field
        label='"About the College" Intro Text'
        name="aboutText"
        component={FormInput}
        type="text"
      />

      <Field
        label="Facebook URL"
        name="socialMedia.facebook"
        placeholder="http://facebook.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="Instagram URL"
        name="socialMedia.instagram"
        placeholder="http://instagram.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="LinkedIn URL"
        name="socialMedia.linkedin"
        placeholder="http://linkedin.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="Twitter URL"
        name="socialMedia.twitter"
        placeholder="http://twitter.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="Snapchat URL"
        name="socialMedia.snapchat"
        placeholder="http://snapchat.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="YouTube URL"
        name="socialMedia.youtube"
        placeholder="http://youtube.com/lightcast"
        component={FormInput}
        type="text"
      />

      <Field
        label="College URL"
        name="collegeUrl"
        placeholder="http://college.edu"
        component={FormInput}
        type="text"
      />
    </FormLayout>
  );
};

HomeForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleFormSubmit: PropTypes.func.isRequired
};

const HomeSettingsForm = reduxForm({
  form: 'HomeSettingsForm',
  enableReinitialize: true
})(HomeForm);

export default HomeSettingsForm;
