import styled from 'styled-components';
import { color } from '../../../styles/index';

const { lightGray, mediumDarkGray } = color;

export const Cell = styled.td`
  ${({ isSticky }) => isSticky && ' position: sticky;'}
  left: ${({ offset }) => (!!offset ? `${offset}rem` : '0')};
  width: ${({ width }) => `${width}`};
  padding: 1rem;
  padding-left: 2rem;
  border-bottom: 1px solid ${mediumDarkGray};
  ${({ hasBorderRight }) => hasBorderRight && `border-right: 3px solid ${mediumDarkGray};`}
  ${({ rowIndex, shouldAlternateRowColor }) =>
    shouldAlternateRowColor && `background: ${rowIndex % 2 === 0 ? 'white' : lightGray};`}
  ${({ rowColor }) => !!rowColor && `background: ${rowColor};`}
  word-wrap: break-word;
  text-align: ${({ textAlign }) => (!!textAlign ? textAlign : 'left')};
`;

export const FooterTotal = styled.div`
  margin-top: 1.5rem;
`;

export const HeaderAndToolTip = styled.div`
  width: 100%
  display: flex;
  justify-content: ${({ justifyContent }) => (!!justifyContent ? justifyContent : 'flex-start')};
`;

export const HeaderCell = styled.th`
  ${({ isSticky }) => isSticky && 'position: sticky;'}
  left: ${({ offset }) => (!!offset ? `${offset}rem` : '0')};
  width: ${({ width }) => `${width}rem`};
  padding: 1rem;
  padding-left: 2rem;
  border-bottom: 3px solid ${mediumDarkGray};
  ${({ hasBorderRight }) => hasBorderRight && `border-right: 3px solid ${mediumDarkGray};`}
  background: ${lightGray};

  font-weight: 600;
  z-index: ${({ zIndex }) => zIndex};
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;
export const PaginateWrapper = styled.div`
  width: 24rem;
`;

export const Row = styled.tr`
  ${({ rowIndex, shouldAlternateRowColor }) =>
    shouldAlternateRowColor && `background: ${rowIndex % 2 === 0 ? 'white' : lightGray};`}
  ${({ rowColor }) => !!rowColor && `background: ${rowColor};`}
`;

export const RowDrawer = styled.div`
  width: ${({ width }) => `${width}`};
  ${({ height, isOpen }) => (isOpen ? `height: ${height}rem` : 'height: 0; overflow: hidden;')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  border-bottom: 1px solid ${mediumDarkGray};
  transition: height 0.5s ease;
`;

export const RowDrawerContents = styled.div`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: opacity 0.5s ease;
`;

export const SortButton = styled.button`
  float: right;
  margin-top: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
`;

export const Table = styled.table`
  width: ${({ width }) => width};
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  border: 1px solid ${mediumDarkGray};
  overflow-x: scroll;
`;

export const TableFooter = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
  display: flex;
  justify-content: space-between;
`;
