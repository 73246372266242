import axios from 'axios';
import config from '../../config';
import Raven from 'raven-js';
import { formatRequest } from '../utils';
import { getCognitoToken } from '../auth';

const date = new Date();

const KeenQuery = ({
  project = 'cc',
  subdomain,
  queryType = 'count',
  event,
  ignoreReferrers = [],
  groupBy = null,
  timeframe,
  targetProperty = null,
  interval = null,
  extraFilters = []
}) => {
  const ignoreReferrerFilters = ignoreReferrers.map(urlToIgnore => ({
    operator: 'not_contains',
    property_name: 'referrer_url',
    property_value: urlToIgnore
  }));

  const careerCoachFilters = [
    {
      operator: 'eq',
      property_name: 'subdomain',
      property_value: subdomain
    },
    // ignore bot traffic
    {
      operator: 'not_contains',
      property_name: 'user_agent',
      property_value: 'bot'
    },
    {
      operator: 'not_contains',
      property_name: 'parsed_user_agent.browser.family',
      property_value: 'Bytespider'
    }
  ];

  const widgetFilters = [
    {
      operator: 'eq',
      property_name: 'clientId',
      property_value: subdomain
    },
    {
      operator: 'ne',
      property_name: 'isPreview',
      property_value: 'true'
    }
  ];

  // this filter removes Lightcast's ip addresses from stat counts
  const lightcastIpFilters = config.internalIPs.map(ip => {
    return {
      operator: 'ne',
      property_name: 'ip_address',
      property_value: ip
    };
  });

  // combine base filters with extra filters passed in as an argument
  !!ignoreReferrers && careerCoachFilters.push(...ignoreReferrerFilters);
  const filter = project === 'widget' ? widgetFilters : careerCoachFilters;
  const filters = filter.concat(extraFilters).concat(lightcastIpFilters);
  return {
    project,
    analysis_type: queryType,
    event_collection: event,
    filters,
    group_by: groupBy,
    timeframe,
    target_property: targetProperty,
    // timezone value equal to the number of seconds to offset the time.
    // the offset value is in minutes * 60min gives us the pacific timezone in our case
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    timezone: -date.getTimezoneOffset() * 60,
    interval
  };
};

export const fetchKeenData = async data => {
  const query = KeenQuery(data);
  try {
    const token = await getCognitoToken();
    const response = await axios.post('/api/cc-api/keen', formatRequest({ query }, 'keen-query'), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.data.attributes.result;
  } catch (error) {
    Raven.captureException(error);
    console.log(error);
    return [];
  }
};
