import {
  keenAdmissionClicks,
  keenAssessmentCompletions,
  keenAssessmentStarts,
  keenAssessmentStartsBySchool,
  keenAssessmentCompletionsBySchool,
  keenProgramClicks,
  keenReferrerUrls,
  keenResumeDownloads,
  keenResumeStarts,
  keenTopCareerFavorites,
  keenTopCareers,
  keenTopCareersByJobPosting,
  keenTopCities,
  keenTopInfoRequests,
  keenTopProgramFavorites,
  keenTopPrograms,
  keenTopCareerSearches,
  keenTopProgramSearches,
  keenTopZips,
  keenTotalVisitors,
  keenTotalVisits,
  keenTuitionClicks,
  keenVisitors,
  keenVisits,
  keenWidgetClicks,
  keenWidgetTotalLoads,
  keenWidgetTotalLoadsByPrograms,
  keenWidgetLoads,
  keenWidgetTotalClicks,
  keenWidgetTotalClicksByPrograms,
  keenWidgetTotalVisitButtonClicks,
  keenWidgetVisitButtonClicksByCareerCoachUrl,
  keenWidgetTotalVisitButtonClicksByPrograms,
  keenWorkOpportunityViewsByPosting,
  keenAssessmentWidgetStarts,
  keenAssessmentWidgetTotalStarts,
  keenAssessmentWidgetCompletions,
  keenAssessmentWidgetTotalCompletions,
  keenAssessmentWidgetTotalProgramLinkClicks,
  keenAssessmentWidgetProgramLinkClicks,
  keenAssessmentWidgetProgramLinkClicksByProgram,
  keenAssessmentWidgetCareerLinkClicks,
  keenAssessmentWidgetCareerLinkClicksByCareer,
  keenAssessmentWidgetDownloads,
  keenWidgetVisitButtonClicks
} from './queries';

export const statisticsReportQueries = [
  keenAdmissionClicks,
  keenAssessmentCompletions,
  keenAssessmentStarts,
  keenProgramClicks,
  keenReferrerUrls,
  keenResumeDownloads,
  keenResumeStarts,
  keenTopCareerFavorites,
  keenTopCareers,
  keenTopCareersByJobPosting,
  keenTopCities,
  keenTopInfoRequests,
  keenTopProgramFavorites,
  keenTopPrograms,
  keenTopCareerSearches,
  keenTopProgramSearches,
  keenTopZips,
  keenTotalVisitors,
  keenTotalVisits,
  keenTuitionClicks,
  keenVisitors,
  keenVisits,
  keenWorkOpportunityViewsByPosting
];

const multiSchoolQueries = [
  keenTopCareers,
  keenTopPrograms,
  keenTopCareerSearches,
  keenTopProgramSearches,
  keenVisitors,
  keenVisits,
  keenAssessmentStartsBySchool,
  keenAssessmentCompletionsBySchool
];

const widgetQueries = [
  keenWidgetClicks,
  keenWidgetTotalLoads,
  keenWidgetTotalLoadsByPrograms,
  keenWidgetLoads,
  keenWidgetTotalClicks,
  keenWidgetTotalClicksByPrograms,
  keenWidgetTotalVisitButtonClicks,
  keenWidgetVisitButtonClicksByCareerCoachUrl,
  keenWidgetTotalVisitButtonClicksByPrograms,
  keenWidgetVisitButtonClicks,
  keenAssessmentWidgetStarts,
  keenAssessmentWidgetTotalStarts,
  keenAssessmentWidgetCompletions,
  keenAssessmentWidgetTotalCompletions,
  keenAssessmentWidgetTotalProgramLinkClicks,
  keenAssessmentWidgetProgramLinkClicks,
  keenAssessmentWidgetProgramLinkClicksByProgram,
  keenAssessmentWidgetCareerLinkClicks,
  keenAssessmentWidgetCareerLinkClicksByCareer,
  keenAssessmentWidgetDownloads
];

const addGroupByToQueries = queries => {
  return queries.map(query =>
    query.groupBy
      ? { ...query, groupBy: [...query.groupBy, 'schoolName'] }
      : { ...query, groupBy: ['schoolName'] }
  );
};

export const multiSchoolStatisticsReportQueries = addGroupByToQueries(multiSchoolQueries);

export const widgetStatisticsReportQueries = addGroupByToQueries(widgetQueries);
