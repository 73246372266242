import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ExcelProgramUploadErrorWorkbook = ({ errors, subdomain }) => (
  <ExcelFile filename={`${subdomain}-upload-errors`} hideElement>
    <ExcelSheet data={errors} name="errors">
      <ExcelColumn label="sheet" value="sheet" />
      <ExcelColumn label="row" value="row" />
      <ExcelColumn label="column" value="column" />
      <ExcelColumn label="error" value="title" />
      <ExcelColumn label="detail" value="detail" />
    </ExcelSheet>
  </ExcelFile>
);

ExcelProgramUploadErrorWorkbook.propTypes = {
  errors: PropTypes.array.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default ExcelProgramUploadErrorWorkbook;
