import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { SiteFields, FormLayout } from 'components';
import { validateSubdomainAliases } from '../../../helpers/state-validation';

const GeneralSettings = ({
  change,
  employerPortal,
  employerNotificationEmail,
  handleSubmit,
  isAdvancedAssessmentExportEnabled,
  nation,
  organizations,
  pristine,
  reset,
  saveGeneralSettings,
  studentIdEnabled,
  submitting
}) => {
  return (
    <FormLayout
      title="General Settings"
      submitForm={handleSubmit(saveGeneralSettings)}
      submitting={submitting}
      pristine={pristine}
      reset={reset}
    >
      <SiteFields
        change={change}
        employerPortal={employerPortal}
        employerNotificationEmail={employerNotificationEmail}
        disabled
        selectOptions={organizations}
        isStudentIdEnabled={studentIdEnabled}
        isAdvancedAssessmentExportEnabled={isAdvancedAssessmentExportEnabled}
        nation={nation}
      />
    </FormLayout>
  );
};

GeneralSettings.propTypes = {
  change: PropTypes.func.isRequired, // this prop comes directly from reduxForm
  employerPortal: PropTypes.bool,
  employerNotificationEmail: PropTypes.string,
  handleSubmit: PropTypes.func,
  isAdvancedAssessmentExportEnabled: PropTypes.bool,
  nation: PropTypes.string,
  organizations: PropTypes.object,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  saveGeneralSettings: PropTypes.func,
  studentIdEnabled: PropTypes.bool,
  sites: PropTypes.object,
  submitting: PropTypes.bool
};

let GeneralSettingsForm = reduxForm({
  form: 'GeneralSettingsForm',
  enableReinitialize: true,
  validate: (values, state) => {
    const errors = validateSubdomainAliases(values, state);
    
    return errors;
  }
})(GeneralSettings);

const selector = formValueSelector('GeneralSettingsForm'); // <-- same as form name
GeneralSettingsForm = connect(state => {
  // can select values individually
  return {
    employerPortal: selector(state, 'employerPortal'),
    employerNotificationEmail: selector(state, 'employerNotificationEmail'),
    isAdvancedAssessmentExportEnabled: selector(
      state,
      'advancedAssessmentExportSettings.isEnabled'
    ),
    studentIdEnabled: selector(state, 'studentIdEnabled'),
    nation: selector(state, 'nation'),
    sites: state.sites,
    subdomainAliases: selector(state, 'subdomainAliases')
  };
})(GeneralSettingsForm);

export default GeneralSettingsForm;
