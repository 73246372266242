import { connect } from 'react-redux';
import { WidgetBuilderSettingsForm } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

export const mapStateToProps = ({ form, sites }) => ({
  currentSite: sites.currentSite,
  initialValues: sites.items[sites.currentSite].widgetBuilderSettings,
  currentValues: form && form.WidgetBuilderSettingsForm && form.WidgetBuilderSettingsForm.values
});

export const mapDispatchToProps = dispatch => ({
  saveWidgetBuilderSettings: ({ currentSite, settings }) =>
    dispatch(
      patchSiteRequest({
        subdomain: currentSite,
        widgetBuilderSettings: {
          ...settings
        }
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBuilderSettingsForm);
