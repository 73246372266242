import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgramIcon from '../../../images/program-icon.svg';
import AddProgramsIcon from '../../../images/circle-plus.svg';
import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import { TextButton } from 'components';

const { sapphireD20 } = color;

const Wrapper = styled.div`
  text-align: center;
`;

const AddProgramsButton = styled(TextButton)`
  width: 13rem;
  margin-top: 1rem;
  color: ${sapphireD20} !important;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
`;

const StyledAddProgramsIcon = styled(AddProgramsIcon)`
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 0.1rem;
`;

const WidgetBuilderProgramsEmptyState = ({ isDisabled = false, nation, toggleIsModalOpen }) => {
  return (
    <Wrapper>
      <ProgramIcon />
      <br />
      You haven't added any {locale.edTrack[nation]}s yet.
      <br />
      <AddProgramsButton
        disabled={isDisabled}
        onClick={toggleIsModalOpen}
        aria-label={`Open the Add ${locale.edTrack[nation]}s modal to add ${locale.edTrack[nation]}s to the widget.`}
      >
        <ButtonContentWrapper>
          <StyledAddProgramsIcon />
          <div>&nbsp;Add {locale.edTrack[nation]}s</div>
        </ButtonContentWrapper>
      </AddProgramsButton>
    </Wrapper>
  );
};

WidgetBuilderProgramsEmptyState.propTypes = {
  isDisabled: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  toggleIsModalOpen: PropTypes.func.isRequired
};

export default WidgetBuilderProgramsEmptyState;
