import algoliasearch from 'algoliasearch';
import camelCase from 'camelcase-keys-deep';
import config from '../../config';

const algoliaClient = algoliasearch(config.algoliaProjectId, config.algoliaKey);

/**
 * searchGeos
 */
export function searchGeos(query, level, nation = 'us') {
  const gisDataRun = {
    uk: config.ukGisDatarun,
    ca: config.caGisDatarun,
    us: config.usGisDatarun
  };

  const index = algoliaClient.initIndex(`${nation}_geos_${gisDataRun[nation]}`);
  let areaLevel = level;
  if (level === 'pr') {
    areaLevel = 'Provterr';
  }
  if (level === 'fips') {
    areaLevel = 'County';
  }
  return index
    .search({
      query,
      filters: `child.level:${areaLevel}`
    })
    .then(response =>
      response.hits.map(hit => {
        // remove these fields from the object, aren't needed
        const { objectID, highlightResult, ...rest } = hit; // eslint-disable-line
        return {
          ...camelCase(rest)
        };
      })
    );
}
