import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'components';
import { color } from '../../../styles';

const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const Remove = styled(Icon)`
  margin-left: 1rem;
`;

const CareerListItem = ({ children, onClick }) => {
  return (
    <Item>
      {children}
      <Remove
        type="close"
        tabIndex="0"
        aria-label={`Remove ${children}`}
        onKeyDown={e => e.keyCode === 13 && onClick()}
        onClick={onClick}
        size="3"
        color={color.black}
      />
    </Item>
  );
};

CareerListItem.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CareerListItem;
