import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    flex-flow: column;
  }
`;

export default Row;
