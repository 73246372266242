import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Toggle } from 'components';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-weight: 600;
`;

const ToggleWrapper = styled.div`
  display: inline-block;
  margin-right: 1.2rem;
  font-weight: 400;
  label {
    text-transform: capitalize !important;
  }
`;

const ApplicationTypeRadioGroup = ({ handleChange, input: { onChange, value } }) => {
  const applicationTypes = [
    { label: 'Apply through portal', value: 'standard' },
    { label: 'Apply on company site (provide URL)', value: 'employer-link' },
    { label: 'Special instructions only', value: 'instructions-only' }
  ];
  return (
    <StyledLabel>
      Application Instructions
      <div>
        {applicationTypes.map(applicationType => (
          <ToggleWrapper key={`radio-button-filter-${applicationType.value}`}>
            <Toggle
              type="radio"
              value={applicationType.value}
              label={applicationType.label}
              onChange={() => {
                onChange(applicationType.value);
                handleChange(applicationType.value);
              }}
              checked={value === applicationType.value}
            />
          </ToggleWrapper>
        ))}
      </div>
    </StyledLabel>
  );
};

ApplicationTypeRadioGroup.propTypes = {
  handleChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default ApplicationTypeRadioGroup;
