import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color as colors } from '../../../styles';
import { getDateFromISO } from '../../../helpers/get-date-from-iso';
import { Loading } from 'components';
import { drawMiniChart } from './functions';

const Card = styled.div`
  width: 35rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  background: ${colors.lightGray};
`;

const Heading = styled.h2`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const Total = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: 600;
  color: ${({ color }) => color};
`;

const CanvasWrapper = styled.div`
  position: relative;
`;

const HiddenA11yTableWrapper = styled.div`
  position: absolute;
  opacity: 0;
`;

const Tooltip = styled.div`
  position: absolute;
  ${({ mousePosition }) => `left: ${mousePosition.x - 55}px; top: ${mousePosition.y - 70}px;`}
  width: 12rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
`;

const TooltipValue = styled.div`
  font-weight: 600;
`;

const TooltipTail = styled.div`
  width: 10px;
  height: 10px;
  overflow: hidden;
  position: absolute;
bottom: -10px;
box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

:after {
  content: '';
  background: white;
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-71%,0);
`;

const MiniChart = ({ className, color, dataLabel, data, heading, loaded, nation, tooltip }) => {
  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [total, setTotal] = useState();
  const [tooltipData, setTooltipData] = useState();
  const formattedTotal = total ? total.toLocaleString() : '0';

  useEffect(() => {
    if (loaded) {
      const newTotal = data.map(({ value }) => value).reduce((alpha, bravo) => alpha + bravo);
      setTotal(newTotal);
    }
  }, [loaded]);

  useEffect(() => {
    if (total > 0) {
      drawMiniChart({
        canvasRef,
        color,
        data,
        nation,
        setIsTooltipVisible,
        setMousePosition,
        setTooltipData
      });
    }
  }, [total]);


  return (
    <Card className={className}>
      <Loading loaded={loaded}>
        {loaded && (
          <Fragment>
            <Heading>
              {heading}
              {tooltip}
            </Heading>
            <Total color={color}>{formattedTotal}</Total>

            {total > 0 && (
              <CanvasWrapper>
                <canvas width="320" height="180" ref={canvasRef} />
                {isTooltipVisible && (
                  <Tooltip mousePosition={mousePosition}>
                    <div>{tooltipData.date}</div>
                    <TooltipValue>{`${tooltipData.value} ${dataLabel}`}</TooltipValue>
                    <TooltipTail />
                  </Tooltip>
                )}
                <HiddenA11yTableWrapper>
                  <table>
                    <thead>
                      <tr>
                        <th>{dataLabel}</th>
                        {data.map(({ timeframe }) => (
                          <th key={`${dataLabel}-${timeframe.start}`}>
                            {getDateFromISO(timeframe.start, nation)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td />
                        {data.map(({ value }, index) => (
                          <td key={`${dataLabel}-${index}`}>{value}</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </HiddenA11yTableWrapper>
              </CanvasWrapper>
            )}
          </Fragment>
        )}
      </Loading>
    </Card>
  );
};

MiniChart.propTypes = {
  color: PropTypes.string.isRequired,
  data: PropTypes.array,
  dataLabel: PropTypes.string,
  heading: PropTypes.string,
  loaded: PropTypes.bool,
  nation: PropTypes.string.isRequired,
  tooltip: PropTypes.element
};

export default MiniChart;
