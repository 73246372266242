import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react';

import MediumScreenSizeIcon from '../../../images/medium-screen-size.svg';
import SmallScreenSizeIcon from '../../../images/small-screen-size.svg';
import WideScreenSizeIcon from '../../../images/wide-screen-size.svg';
import { generateAssessmentWidgetSrcUrl } from '../../../helpers/widget-embed-code-utils';
import { ScreenSizeButton } from 'components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const ButtonRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 3rem 2rem 0;
`;

const StyledInput = styled.input`
  width: 8rem;
  height: 4.5rem;
  margin-left: 0.3rem;
  padding: 0.25rem 0.25rem 0.25rem 2rem;
  border: 1px solid #d8dbe1;
  border-radius: 3px;
  fontsize: 1.5rem;
`;

const UnitLabel = styled.span`
  position: absolute;
  top: 9px
  right: 20px;
`;

const Preview = styled.div`
  width: ${({ width }) => width};
  height: 80rem;
  ${({ isHidden }) => isHidden && 'visibility: hidden;'}
`;

const presetScreenSizes = [
  { icon: WideScreenSizeIcon, size: 720 },
  { icon: MediumScreenSizeIcon, size: 540 },
  { icon: SmallScreenSizeIcon, size: 320 }
];

const AssessmentWidgetBuilderPreview = ({ configurations, isHidden }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [previewSize, setPreviewSize] = useState(presetScreenSizes[0].size);
  const [sizeInputValue, setSizeInputValue] = useState(previewSize);
  const widgetSrc = generateAssessmentWidgetSrcUrl({ configurations });

  const preventFlickering = () => {
    setTimeout(() => {
      setIsResizing(false);
    }, 0);
  };

  const resetPreviewSize = newWidth => {
    setIsResizing(true);
    setPreviewSize(newWidth);
    setSizeInputValue(newWidth);
    preventFlickering();
  };

  return (
    <Wrapper>
      {!isHidden && (
        <ButtonRow>
          {presetScreenSizes.map(({ icon, size }) => (
            <ScreenSizeButton
              icon={icon}
              key={`widget-builder-screen-size-button-${size}`}
              previewSize={previewSize}
              handleClick={resetPreviewSize}
              size={size}
            />
          ))}
          <StyledInput
            type="number"
            value={sizeInputValue}
            aria-label="set widget preview size"
            onChange={({ target }) => {
              const { value } = target;
              setSizeInputValue(value);
              const newSize = Number(value);

              const maximumSize = 768;
              const minimumSize = 320;
              if (newSize >= maximumSize) {
                setPreviewSize(maximumSize);
              } else if (newSize <= minimumSize) {
                setPreviewSize(minimumSize);
              } else {
                setPreviewSize(newSize);
              }
            }}
          />
          <UnitLabel>px</UnitLabel>
        </ButtonRow>
      )}
      {!isResizing && (
        <Preview width={`${previewSize}px`} isHidden={isHidden}>
          <IframeResizer
            title="Widget Builder Preview"
            heightCalculationMethod="taggedElement"
            checkOrigin="false"
            src={`${widgetSrc}&isPreview=true`}
            style={{ width: '1px', minWidth: '100%' }}
          />
        </Preview>
      )}
    </Wrapper>
  );
};

AssessmentWidgetBuilderPreview.propTypes = {
  configurations: PropTypes.object.isRequired,
  isHidden: PropTypes.bool
};

export default AssessmentWidgetBuilderPreview;
