import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Margin, FormHeader, DesignFields, SiteFields, FormLayout, GeoForm } from 'components';

const AddSiteForm = ({
  createSite,
  employerPortal,
  employerNotificationEmail,
  handleSubmit,
  hasJobPostings,
  initialValues,
  nation,
  pristine,
  reset,
  selectLevels,
  studentIdEnabled,
  submitting
}) => {
  return (
    <FormLayout
      title="Site Settings"
      submitForm={handleSubmit(createSite)}
      submitting={submitting}
      pristine={pristine}
      reset={reset}
    >
      <SiteFields
        employerPortal={employerPortal}
        employerNotificationEmail={employerNotificationEmail}
        isStudentIdEnabled={studentIdEnabled}
        nation={nation}
      />
      <Margin value="2rem 0 0 0">
        <FormHeader>Location Settings</FormHeader>
      </Margin>
      <FieldArray
        name="areas"
        component={GeoForm}
        {...initialValues}
        hasJobPostings={hasJobPostings}
        selectLevels={selectLevels}
        formId="addSiteForm"
      />
      <Margin value="2rem 0">
        <FormHeader>Design Settings</FormHeader>
      </Margin>
      <DesignFields />
    </FormLayout>
  );
};

AddSiteForm.propTypes = {
  createSite: PropTypes.func.isRequired,
  employerPortal: PropTypes.bool,
  employerNotificationEmail: PropTypes.string,
  handleSubmit: PropTypes.func,
  hasJobPostings: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  nation: PropTypes.string,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  selectLevels: PropTypes.object.isRequired,
  studentIdEnabled: PropTypes.bool,
  submitting: PropTypes.bool
};

export default AddSiteForm;
