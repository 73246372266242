import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import { fetchInstitutions, fetchProgramCategories, fetchPrograms } from '../../../services';
import { ProgramTable } from 'containers';
import {
  Button,
  HideContentOnMobile,
  Layout,
  CareerMappingTable,
  CareerMappingHelpModal,
  ProgramTableHelpModal,
  SearchInput
} from 'components';
import ProgramLogTable from '../../organisms/ProgramLogTable';

import { renderCareerMappingColumns, renderLogColumns, renderProgramsColumns } from './columns';

const { mediumGray, lightGray } = color;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: -11rem;
  padding: 5rem;
  background: white;
`;

const Text = styled.div`
  max-width: 120rem;
  font-size: 1.8rem;
`;

const HeaderWrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const TableHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  font-size: 2.8rem;
`;

const TopPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: ${lightGray};
  border: 1px solid ${mediumGray};
  border-radius: 4px;
`;

const Programs = ({ modalShow, ...props }) => {
  const { hasManyInstitutions, isDeveloper, nation, subdomain } = props;
  const [categoryIds, setCategoryIds] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [programData, setProgramData] = useState([]);
  const [programTotal, setProgramTotal] = useState(0);
  const [institutionsById, setInstitutionsById] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [sortColumn, setSortColumn] = useState('-createdAt');

  const rowsPerPage = 10;

  const fetchInstitutionData = async () => {
    try {
      const institutionsResponse = await fetchInstitutions(subdomain);
      setInstitutionsById(institutionsResponse);
    } catch (error) {
      setIsError(true);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const categoriesResponse = await fetchProgramCategories(subdomain);
      const newCategoryOptions = Object.values(categoriesResponse.categories).map(
        ({ name, slug }) => ({
          name,
          id: slug
        })
      );
      setCategoryOptions(newCategoryOptions);
      return true;
    } catch (error) {
      setIsError(true);
    }
  };

  const combineInstitutionAndProgramData = programs => {
    return programs.map(program => {
      const { institutionId } = program;
      const institutionKey = `${subdomain}-${institutionId}`;
      const institutionName = institutionsById[institutionKey].displayName;
      return {
        ...program,
        institutionName
      };
    });
  };

  const fetchProgramData = async () => {
    setIsLoading(true);

    if (!categoryOptions) {
      await fetchCategoryData();
    }
    const offset = (pageNumber - 1) * rowsPerPage;

    try {
      const programsResponse = await fetchPrograms({
        categoryIds,
        limit: rowsPerPage,
        nation,
        offset,
        searchTerm,
        sortBy: sortColumn,
        subdomain
      });
      const { programs, total } = programsResponse;
      setProgramTotal(total);
      const newProgramData = hasManyInstitutions
        ? combineInstitutionAndProgramData(programs)
        : programs;

      setProgramData(newProgramData);
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInstitutionData();
  }, []);

  useEffect(() => {
    if (institutionsById) {
      fetchProgramData();
    }
  }, [institutionsById, pageNumber, searchTerm, sortColumn, categoryIds]);

  const navigation = [
    {
      title: `${locale.edTrack[nation]}s`,
      path: '/programs/general',
      component: ProgramTable,
      renderColumns: renderProgramsColumns
    },
    {
      title: 'Career Mappings',
      path: '/programs/career-mappings',
      component: CareerMappingTable,
      renderColumns: renderCareerMappingColumns
    },
    {
      title: 'Activity Log',
      path: '/programs/log',
      exact: true,
      component: ProgramLogTable,
      renderColumns: renderLogColumns
    }
  ].filter(Boolean);

  const calculateTableMaxWidth = columns =>
    `${columns
      .map(({ isHidden, width }) => (isHidden ? 0 : width))
      .reduce((alpha, beta) => alpha + beta, 0) + 0.2}rem`;

  const routeKey = location.pathname.split('/')[2];

  const tableData = {
    general: {
      pageTitle: `${locale.edTrack[nation]} Information`,
      tableTitle: `${locale.edTrack[nation]}s`,
      tableDescription: `Below is a listing of your institution’s ${locale.edTrack.lower[nation]}s currently contained within Career
      Coach and/or the Widget Builder. To edit a current ${locale.edTrack.lower[nation]}’s information, click the
      pencil icon and edit the ${locale.edTrack.lower[nation]} form. To add a new ${locale.edTrack.lower[nation]}, click the Add ${locale.edTrack[nation]}
      button and complete the entry form.`,
      tableMaxWidth: calculateTableMaxWidth(
        renderProgramsColumns({
          hasManyInstitutions
        })
      )
    },
    'career-mappings': {
      pageTitle: 'Career Mappings',
      tableTitle: 'Map Careers',
      tableDescription: `To view the current careers mapped to your ${locale.edTrack.lower[nation]}, click the down arrow or Add Careers. Make the desired changes and save changes.`,
      tableMaxWidth: calculateTableMaxWidth(
        renderCareerMappingColumns({
          hasManyInstitutions
        })
      )
    },
    log: {
      pageTitle: `${locale.edTrack[nation]} Information`,
      tableTitle: 'Activity Log',
      tableMaxWidth: calculateTableMaxWidth(
        renderLogColumns({ hasManyInstitutions, isDeveloper, nation })
      )
    }
  };

  const { tableDescription = '', tableMaxWidth, pageTitle, tableTitle } = tableData[routeKey];

  return (
    <Layout title={pageTitle} navigation={navigation}>
      <Wrapper>
        <HideContentOnMobile
          breakpoint="400px"
          message={`The ${tableTitle} Table is only available on desktop!`}
        >
          <Text>{tableDescription}</Text>
          <HeaderWrapper maxWidth={tableMaxWidth}>
            <TableHeader>
              <span>{tableTitle}</span>
              {routeKey === 'general' && <ProgramTableHelpModal nation={nation} />}
              {routeKey === 'career-mappings' && <CareerMappingHelpModal nation={nation} />}
            </TableHeader>
            {routeKey !== 'log' && (
              <TopPanel>
                <SearchInput
                  placeholder={`${locale.edTrack[nation]} Search`}
                  onChange={({ target: { value } }) => {
                    setSearchTerm(value);
                  }}
                  aria-label={`Search ${locale.edTrack.lower[nation]}`}
                  defaultValue={searchTerm}
                />
                {routeKey === 'general' && (
                  <Button
                    onClick={() => modalShow('add-program-modal')}
                    data-cy="open-add-program-modal-button"
                  >{`+ Add ${locale.edTrack[nation]}`}</Button>
                )}
              </TopPanel>
            )}
          </HeaderWrapper>
          <Switch>
            {navigation.map((nav, index) => (
              <Route
                key={`route-${index}}`}
                path={nav.path}
                exact={nav.exact}
                component={() => {
                  return (
                    <nav.component
                      categoryOptions={categoryOptions}
                      categoryIds={categoryIds}
                      fetchProgramData={fetchProgramData}
                      institutionsById={institutionsById}
                      isError={isError}
                      isChecked={isChecked}
                      isLoading={isLoading}
                      pageNumber={pageNumber}
                      programData={programData}
                      programTotal={programTotal}
                      renderColumns={nav.renderColumns}
                      rowsPerPage={rowsPerPage}
                      setCategoryIds={setCategoryIds}
                      setIsChecked={setIsChecked}
                      setIsLoading={setIsLoading}
                      searchTerm={searchTerm}
                      setPageNumber={setPageNumber}
                      setSortColumn={setSortColumn}
                      sortColumn={sortColumn}
                      tableMaxWidth={tableMaxWidth}
                      {...props}
                    />
                  );
                }}
              />
            ))}
            <Redirect to="/programs/" />
          </Switch>
        </HideContentOnMobile>
      </Wrapper>
    </Layout>
  );
};

Programs.propTypes = {
  hasLot: PropTypes.bool,
  hasManyInstitutions: PropTypes.bool,
  isDeveloper: PropTypes.bool,
  modalShow: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default Programs;
