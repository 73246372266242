import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UploadSettings } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

const UploadSettingsContainer = props => {
  const updateLogo = logoFilename => {
    props.patchSite({
      subdomain: props.subdomain,
      styles: {
        ...props.styles,
        logoFilename: logoFilename
      }
    });
  };
  const updateDisclaimer = (type, disclaimerFilename) => {
    const patchBody =
      type === 'prosecution-warning'
        ? {
            subdomain: props.subdomain,
            disclaimerFilenameEmployer: disclaimerFilename
          }
        : {
            subdomain: props.subdomain,
            disclaimerFilenameStudent: disclaimerFilename
          };
    props.patchSite(patchBody);
  };

  return <UploadSettings {...props} updateLogo={updateLogo} updateDisclaimer={updateDisclaimer} />;
};

UploadSettingsContainer.propTypes = {
  employerDisclaimer: PropTypes.string.isRequired,
  patchSite: PropTypes.func.isRequired,
  studentDisclaimer: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
  subdomain: PropTypes.string.isRequired
};

export const mapStateToProps = ({ sites }) => ({
  employerDisclaimer: sites.items[sites.currentSite].disclaimerFilenameEmployer,
  nation: sites.items[sites.currentSite].nation,
  studentDisclaimer: sites.items[sites.currentSite].disclaimerFilenameStudent,
  styles: sites.items[sites.currentSite].styles,
  subdomain: sites.currentSite
});

export default connect(mapStateToProps, { patchSite: patchSiteRequest })(UploadSettingsContainer);
