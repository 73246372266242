import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BoldLabel, Text } from 'components';
import { color } from '../../../styles';

// These colors are not added to the styles helper because they
// are only used to keep consistency with emsi-ui input colors
const DISABLED_INPUT_BORDER_GREY = '#e5e5e5';
const DISABLED_INPUT_TEXT_GREY = '#7f7f7f';

const TextAreaWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  height: unset;
  padding: 1rem;
  resize: none;
  border-radius: 0.3rem;
  border: 1px solid ${color.mediumGray};
  font-size: 1.5rem;
  :disabled {
    background: ${color.lightGray};
    border: 1px solid ${DISABLED_INPUT_BORDER_GREY};
    color: ${DISABLED_INPUT_TEXT_GREY};
  }
`;

const SubText = styled.div`
  margin-bottom: 0.8rem;
`;

const CharacterLimit = styled.div`
  position: absolute;
  top: calc(100% + 0.3rem);
  right: 0.3rem;
`;

const FormTextArea = ({ input, label, maxLength, meta: { active, error }, subtext, ...props }) => (
  <div>
    <BoldLabel name={input.name} label={label} />
    {subtext && <SubText>{subtext}</SubText>}
    <TextAreaWrapper>
      <StyledTextArea id={input.name} maxLength={maxLength} {...input} {...props} />
      {maxLength && active && (
        <CharacterLimit>
          {input.value.length}/{maxLength}
        </CharacterLimit>
      )}
      <span>
        <Text error={!!error}>{error}</Text>
      </span>
    </TextAreaWrapper>
  </div>
);

FormTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string
  }),
  subtext: PropTypes.string,
  input: PropTypes.object
};

export default FormTextArea;
