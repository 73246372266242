import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { Field, reduxForm } from 'redux-form';

import { resetPasswordRequest } from '../../../services/auth';
import { isRequired, isEmail } from '../../../helpers/validation';
import { FormInput } from 'components';
import { color } from '../../../styles';

const { sapphireD20 } = color;

const Text = styled.p`
  margin-block: 2rem;
`;

const Button = styled.button`
  position: relative;
  display: block;
  margin-top: 2rem;
  background: ${sapphireD20};
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  padding: 1.2rem 2rem;
  border-radius: 3px;

  @media (max-width: 549px) {
    margin-top: 3rem;
    width: 100%;
  }

  :hover {
    background: ${darken(0.05, sapphireD20)};
  }
`;

const SendResetPasswordEmailForm = ({
  handleSubmit,
  setIsResettingPassword,
  setResetPasswordEmail
}) => {
  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(({ email }) =>
          resetPasswordRequest(email)
            .then(() => {
              setResetPasswordEmail(email);
              setIsResettingPassword(true);
            })
            .catch(() => setIsResettingPassword(true))
        )}
      >
        <Text>
          To receive a verification code to reset your password, enter your email below. <br /> If
          an account exists, you will receive a validation code.
        </Text>
        <Field
          type="text"
          name="email"
          label="Email"
          component={FormInput}
          validate={[isRequired, isEmail]}
        />
        <Button type="submit">Send Email</Button>
      </form>
    </Fragment>
  );
};

SendResetPasswordEmailForm.propTypes = {
  // handleSubmit is from redux form
  handleSubmit: PropTypes.func.isRequired,
  setIsResettingPassword: PropTypes.func.isRequired,
  setResetPasswordEmail: PropTypes.func.isRequired
};

export default reduxForm({ form: 'sendResetPasswordEmail' })(SendResetPasswordEmailForm);
