import React from 'react';
import PropTypes from 'prop-types';
import { Hint, Strong, Chart } from 'components';
import styled from 'styled-components';

const Section = styled.p`
  margin-bottom: 1rem;
`;

const VisitsChart = props => {
  return (
    <Chart
      {...props}
      subheading="Total visits"
      a11yCaption="Chart of visit count by month. Series 1 indicates Total Visits, Series 2 indicates Unique Visitors"
      a11ySummary="Overall total visits and unique visitors"
      a11yHeader="Dates"
      legendNames={['Total Visits', 'Unique Visitors']}
      tooltip={
        <Hint id="visits_chart">
          <Section>
            <Strong>Total Visits</Strong>: The total number of visits from both new and returning
            users within the specified date range.
          </Section>
          <Section>
            <Strong>Unique Visitors</Strong>: Individual users that are counted once per day within
            the specified date range.
          </Section>
        </Hint>
      }
    />
  );
};

VisitsChart.propTypes = {
  data: PropTypes.object,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  loaded: PropTypes.bool
};

export default VisitsChart;
