import { connect } from 'react-redux';
import SuperAdminList from 'components/molecules/SuperAdminList';
import { modalShow, modalHide } from '../../reducers/modal';

export const mapStateToProps = ({ modal, profile }) => ({
  isDeveloper: profile.isDeveloper,
  modals: modal
});

export default connect(mapStateToProps, { modalShow, modalHide })(SuperAdminList);
