import styled from 'styled-components';

// used mainly for handling the date selector select boxes
// but it could be used for other things
// figures out the width of the children nodes based on 1, 2, or 3 child nodes
const ItemsWrapper = styled.div`
  position: relative;

  /* one item */
  [data-item]:nth-child(1):nth-last-child(1) {
    width: 100%;
  }

  /* two items */
  [data-item]:nth-child(1):nth-last-child(2) {
    width: 67%;
    margin-right: 3%;
  }
  [data-item]:nth-child(2):nth-last-child(1) {
    width: 30%;
  }

  /* three items */
  [data-item]:nth-child(1):nth-last-child(3) {
    width: 50%;
    margin-right: 3%;
  }
  [data-item]:nth-child(2):nth-last-child(2) {
    width: 22%;
    margin-right: 3%;
  }
  [data-item]:nth-child(3):nth-last-child(1) {
    width: 22%;
  }
`;

export default ItemsWrapper;
