import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { objectifyArray } from '../../../helpers/objectify-array';
import { keenWidgetVisitButtonClicksByCareerCoachUrl } from '../../../services/keen/queries';
import { fetchAllCareers, fetchKeenData } from '../../../services';
import { ExternalLink, Loading, StickyColumnTable } from 'components';
import { usePagination } from '../../../hooks/usePagination';

const Wrapper = styled.div`
  max-width: 108rem;
`;

const Header = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const ProgramWidgetCareerCoachVisitsTable = ({ hasLot, nation, subdomain, timeframe }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    handlePageNumberChange,
    pageNumber,
    paginatedData: paginatedTableData,
    rowsPerPage
  } = usePagination(tableData);

  const fetchCareerData = async slugs => {
    let careerDataBySlug;
    try {
      const careerData = await fetchAllCareers({ hasLot, nation });
      careerDataBySlug = objectifyArray(Object.values(careerData), 'titleSlug');
    } catch (error) {
      careerDataBySlug = {};
    }

    let careerDataByCode = {};
    for (let index = 0; index < slugs.length; index++) {
      const currentSlug = slugs[index];
      try {
        careerDataByCode[currentSlug] = careerDataBySlug[currentSlug].title;
      } catch ({ errors }) {
        careerDataByCode[currentSlug] = currentSlug;
      }
    }

    return careerDataByCode;
  };

  const fetchTableData = async () => {
    setIsLoading(true);
    const visitButtonClicksData = await fetchKeenData({
      subdomain,
      timeframe,
      ...keenWidgetVisitButtonClicksByCareerCoachUrl
    });

    const visitButtonClicksDataBySlug = visitButtonClicksData.map(({ careerCoachUrl, result }) => ({
      link: careerCoachUrl,
      slug: careerCoachUrl.split('/')[4],
      result
    }));
    const visitButtonClicksCareerSlugs = visitButtonClicksDataBySlug.map(({ slug }) => slug);
    const slugs = Array.from(new Set([...visitButtonClicksCareerSlugs.flat()]));
    const careerTitlesBySlug = await fetchCareerData(slugs);

    const newTableData = visitButtonClicksDataBySlug
      .map(({ link, result, slug }) => ({
        link,
        title: careerTitlesBySlug[slug] || slug,
        'visit-button-clicks': result
      }))
      .sort((alpha, bravo) => bravo['visit-button-clicks'] - alpha['visit-button-clicks']);

    setTableData(newTableData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [timeframe]);

  const columns = [
    {
      id: 'title',
      header: 'Career Name',
      hasSort: false,
      width: 160,
      value: rowData => <ExternalLink url={rowData.link}>{rowData.title}</ExternalLink>
    },
    {
      id: 'visit-button-clicks',
      header: 'Career Coach Clicks',
      hasSort: false,
      width: 70,
      textAlign: 'center'
    }
  ];

  const isNoData = !!paginatedTableData && paginatedTableData.length === 0;
  if (isNoData) {
    return null;
  }

  return (
    <Wrapper>
      <Header>Career Pages Visited from Widgets</Header>
      <Loading loaded={!isLoading}>
        <StickyColumnTable
          columns={columns}
          dataCy="widget-careers-pages-activity-table"
          isLoading={!paginatedTableData}
          shouldAlternateRowColor
          tableData={paginatedTableData || []}
          totalRows={tableData.length}
          rowsPerPage={rowsPerPage}
          pageNumber={pageNumber}
          setPageNumber={handlePageNumberChange}
        />
      </Loading>
    </Wrapper>
  );
};

ProgramWidgetCareerCoachVisitsTable.propTypes = {
  hasLot: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  timeframe: PropTypes.object.isRequired
};

export default ProgramWidgetCareerCoachVisitsTable;
