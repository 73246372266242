import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentWrap, EmployersCard, PostingsCard, Loading } from 'components';

const Padding = styled.div`
  padding-top: ${({ loaded }) => !loaded && '5rem'};
`;

const Employers = props => {
  return (
    <Padding loaded={props.loaded}>
      <Loading loaded={props.loaded}>
        <ContentWrap>
          <EmployersCard {...props} />
          <PostingsCard {...props} />
        </ContentWrap>
      </Loading>
    </Padding>
  );
};
Employers.propTypes = {
  loaded: PropTypes.bool.isRequired
};

export default Employers;
