import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { color } from 'styles';

const { red } = color;

const StyledError = styled.p`
  color: ${red};
`;

const Text = ({ children, error, ...other }) => {
  if (error === true) {
    return <StyledError {...other}>{children}</StyledError>;
  } else {
    return <p {...other}>{children}</p>;
  }
};

Text.propTypes = {
  bold: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  header: PropTypes.bool,
  error: PropTypes.bool
};

export default Text;
