import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { locale } from '../../../helpers/locale';
import { ExternalLink, Loading, StickyColumnTable } from 'components';
import { usePagination } from '../../../hooks/usePagination';

const Wrapper = styled.div`
  max-width: 108rem;
`;

const Header = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
`;

const AssessmentWidgetProgramVisitsTable = ({ data, nation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    handlePageNumberChange,
    pageNumber,
    paginatedData: paginatedTableData,
    rowsPerPage
  } = usePagination(data);

  useEffect(() => {
    setIsLoading(true);
    if (data && data.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  const columns = [
    {
      id: 'programNameCredential',
      header: 'Programs',
      width: 70,
      value: rowData => {
        const programTitle = rowData.programCredential
          ? `${rowData.programName} - ${rowData.programCredential}`
          : rowData.programName;

        return <ExternalLink url={rowData.programUrl}>{programTitle}</ExternalLink>;
      }
    },
    {
      id: 'result',
      header: 'Program Clicks',
      width: 50,
      textAlign: 'center'
    }
  ];

  const isNoData = !!paginatedTableData && paginatedTableData.length === 0;
  if (isNoData) {
    return null;
  }

  return (
    <Wrapper>
      <Loading loaded={!isLoading}>
        <Header>{`${locale.edTrack[nation]} Pages Visited from Widget`}</Header>
        <StickyColumnTable
          columns={columns}
          dataCy="assessment-widget-activity-by-program"
          isLoading={!paginatedTableData}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          setPageNumber={handlePageNumberChange}
          shouldAlternateRowColor
          tableData={paginatedTableData || []}
          totalRows={data.length}
        />
      </Loading>
    </Wrapper>
  );
};

AssessmentWidgetProgramVisitsTable.propTypes = {
  data: PropTypes.array.isRequired,
  nation: PropTypes.string.isRequired
};

export default AssessmentWidgetProgramVisitsTable;
