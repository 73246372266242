import { toastr } from 'react-redux-toastr';
import { capital } from 'case';

export const flashError = (title, message) =>
  toastr.error(capital(title), capital(message), { showCloseButton: false });

export const flashSuccess = message =>
  toastr.success('Success', capital(message), { showCloseButton: false });

export const flashWarning = message =>
  toastr.warning('Warning', capital(message), { showCloseButton: false });

export const flashInfo = message =>
  toastr.info(capital(message), { showCloseButton: false });
