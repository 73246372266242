import { flashError, flashSuccess } from '../../helpers/flashmessage';
import { getCognitoToken } from '../';

export const uploadPrograms = async ({ uploadFile, subdomain }) => {
  let formData = new FormData();
  formData.append('spreadsheet', uploadFile);

  const res = await fetch(`/api/cc-api/programs/${subdomain}/upload`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    }),
    body: formData
  });

  if (res.status === 204) {
    flashSuccess(`${subdomain} programs successfully uploaded!`);
    return res;
  } else {
    flashError('Something went wrong');
    return res;
  }
};

export const uploadLogo = (file, uploadFilename) => {
  return fetch('/api/cc-api/manage-s3/logo/sign', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'file-upload',
        attributes: {
          filename: uploadFilename,
          filetype: file.type
        }
      }
    })
  })
    .then(res => res.json())
    .then(res => {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
        },
        body: file
      };
      return fetch(res.data.attributes.url, options);
    })
    .then(() => {
      const location =
        process.env.NODE_ENV === 'production'
          ? 'https://career-coach-uploads'
          : 'https://career-coach-uploads-qa';
      return {
        name: file.name,
        url: `${location}.s3-us-west-2.amazonaws.com/logos/${uploadFilename}`
      };
    });
};

export const uploadDisclaimer = (file, uploadFilename, disclaimerType) => {
  return fetch('/api/cc-api/manage-s3/disclaimer/sign', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'file-upload',
        attributes: {
          filename: `${disclaimerType}/${uploadFilename}`,
          filetype: 'application/pdf'
        }
      }
    })
  })
    .then(res => res.json())
    .then(res => {
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/pdf'
        },
        body: file
      };
      return fetch(res.data.attributes.url, options);
    })
    .then(() => {
      const location =
        process.env.NODE_ENV === 'production'
          ? 'https://cc-resume-submission-disclaimers'
          : 'https://cc-resume-submission-disclaimers-qa';
      return {
        name: file.name,
        url: `${location}.s3-us-west-2.amazonaws.com/${disclaimerType}/${uploadFilename}`
      };
    });
};

export const deleteDisclaimer = (disclaimerType, subdomain) => {
  return fetch('/api/cc-api/manage-s3/disclaimer', {
    method: 'DELETE',
    body: JSON.stringify({
      data: {
        attributes: {
          filename: `${disclaimerType}/${subdomain}.pdf`
        }
      }
    })
  });
};
