import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading, Message, StatItems, Hint } from 'components';

const Content = styled.div`
  display: flex;
`;

const StyledMessage = styled(Message)`
  margin-bottom: 5rem;
`;

const TabBody = ({ data, loaded, hintMessage = '', ...rest }) => {
  return (
    <div>
      <Content>
        {hintMessage.length > 0 && (
          <Hint id={rest.resultKey}>{hintMessage}</Hint>
        )}
      </Content>
      <Loading loaded={loaded}>
        {data.length > 0 ? (
          <StatItems data={data} {...rest} />
        ) : (
          <StyledMessage>No Data Available</StyledMessage>
        )}
      </Loading>
    </div>
  );
};

TabBody.propTypes = {
  data: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  hintMessage: PropTypes.string
};

export default TabBody;
