import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Header, Hint, Loading, Message, Select, StatItems } from 'components';

const StyledCard = styled(Card)`
  min-height: 33rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 0;
`;

const StyledHeader = styled(Header)`
  flex-grow: 1;
  margin-bottom: 1rem !important;
  text-align: center;

  @media (min-width: 560px) {
    text-align: left;
  }

  transition: width 2s, height 2s, background-color 2s, transform 2s;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 540px) {
    justify-content: flex-start;
    flex-direction: row;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > :first-child {
    margin: 0;
  }

  select {
    padding: 1rem 2rem 1rem 1rem;
  }

  @media (min-width: 560px) {
    > :first-child {
      flex-grow: 0;
      margin: 0;
    }
  }
`;

const StyledMessage = styled(Message)`
  margin-bottom: 5rem;
`;

const StatCard = ({
  data,
  heading,
  loaded,
  hintMessage = '',
  hasSelect,
  selectOnChange,
  selectOptions,
  selectedInstitution,
  ...rest
}) => {
  return (
    <StyledCard>
      <Content>
        <StyledHeader component="h2" type="subtitle" bold>
          {heading}
        </StyledHeader>
        {hintMessage.length > 0 && <Hint id={rest.resultKey}>{hintMessage}</Hint>}
        <SelectWrapper>
          {hasSelect && (
            <Select
              onChange={selectOnChange}
              options={selectOptions}
              selection={selectedInstitution}
            />
          )}
        </SelectWrapper>
      </Content>
      <Loading isAbsolute loaded={loaded}>
        {data.length > 0 ? (
          <StatItems data={data} {...rest} />
        ) : (
          <StyledMessage>No Data Available</StyledMessage>
        )}
      </Loading>
    </StyledCard>
  );
};

StatCard.propTypes = {
  data: PropTypes.array.isRequired,
  hasSelect: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  hintMessage: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  selectedInstitution: PropTypes.string,
  selectOnChange: PropTypes.func,
  selectOptions: PropTypes.array
};

export default StatCard;
