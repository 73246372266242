import React from 'react';
import { GlobalSelect } from 'containers';
import { ContentWrap, Layout, Margin } from 'components';

// On mobile screens, Change Institution occupies a separate page.
const ChangeSite = () => {
  return (
    <Layout title="Change Institution">
      <ContentWrap>
        <Margin value="5rem 0 0 0">
          <GlobalSelect />
        </Margin>
      </ContentWrap>
    </Layout>
  );
};

export default ChangeSite;
