import * as types from './constants';

export const fetchSitesRequest = () => ({
  type: types.FETCH_SITES_REQUEST
});

export const fetchSiteRequest = subdomain => ({
  type: types.FETCH_SITE_REQUEST,
  payload: subdomain
});

export const fetchSitesSuccess = sites => ({
  type: types.FETCH_SITES_SUCCESS,
  payload: sites
});

export const fetchSiteSuccess = site => ({
  type: types.FETCH_SITE_SUCCESS,
  payload: site
});

export const updateCurrentSite = site => ({
  type: types.UPDATE_CURRENT_SITE,
  payload: {
    site
  }
});

export const createSiteRequest = data => ({
  type: types.CREATE_SITE_REQUEST,
  payload: { data }
});

export const createSiteSuccess = site => ({
  type: types.CREATE_SITE_SUCCESS,
  payload: { ...site }
});

export const patchSiteRequest = data => ({
  type: types.PATCH_SITE_REQUEST,
  payload: { data }
});

export const patchSiteSuccess = site => ({
  type: types.PATCH_SITE_SUCCESS,
  payload: {
    ...site
  }
});

export const fetchFailure = (type, payload) => ({
  type: type,
  error: true,
  payload
});

export const clearProfileErrorMessage = () => ({
  type: types.CLEAR_PROFILE_ERROR_MESSAGE
});

export const login = (email, password, ssoConnection) => ({
  type: types.USER_LOGIN,
  email,
  password,
  ssoConnection
});

export const logout = () => ({
  type: types.USER_LOGOUT
});

export const loginFailure = error => ({
  type: types.USER_LOGIN_FAILURE,
  error
});

export const loginSuccess = payload => ({
  type: types.USER_LOGIN_SUCCESS,
  payload,
  meta: {
    analytics: {
      collection: 'Logins',
      // remove all other personally identifiable information
      event: payload
    }
  }
});
export const signUp = payload => ({
  type: types.USER_SIGNUP,
  payload
});

export const updateStatsDates = payload => ({
  type: types.UPDATE_STATS_DATES,
  payload
});

export const sendKeenStats = (collection, event) => ({
  type: types.SEND_ANALYTICS,
  meta: {
    analytics: {
      collection,
      event
    }
  }
});

export const fetchProfilesRequest = subdomain => {
  return {
    type: types.FETCH_PROFILES_REQUEST,
    payload: subdomain
  };
};

export const fetchProfilesSuccess = payload => ({
  type: types.FETCH_PROFILES_SUCCESS,
  payload
});

export const downloadResume = payload => ({
  type: types.DOWNLOAD_RESUME,
  payload
});

export const fetchEmployersRequest = subdomain => {
  return {
    type: types.FETCH_EMPLOYERS_REQUEST,
    payload: subdomain
  };
};

export const fetchEmployersSuccess = payload => {
  return {
    type: types.FETCH_EMPLOYERS_SUCCESS,
    payload
  };
};

export const updateEmployerRequest = data => ({
  type: types.UPDATE_EMPLOYER_REQUEST,
  payload: data
});

export const updateEmployerSuccess = payload => ({
  type: types.UPDATE_EMPLOYER_SUCCESS,
  payload
});

export const whitelistEmployer = payload => ({
  type: types.WHITELIST_EMPLOYER,
  payload
});

export const createPostingRequest = (subdomain, data) => ({
  type: types.CREATE_POSTING_REQUEST,
  subdomain,
  payload: data
});

export const createPostingSuccess = payload => ({
  type: types.CREATE_POSTING_SUCCESS,
  payload,
  meta: {
    analytics: {
      collection: 'Admin Created Posting',
      event: { posting: payload }
    }
  }
});

export const fetchPostingsRequest = payload => {
  return {
    type: types.FETCH_POSTINGS_REQUEST,
    payload
  };
};

export const fetchPostingsSuccess = payload => {
  return {
    type: types.FETCH_POSTINGS_SUCCESS,
    payload
  };
};

export const fetchPostingAnalyticsRequest = payload => {
  return {
    type: types.FETCH_POSTING_ANALYTICS_REQUEST,
    payload
  };
};

export const fetchPostingAnalyticsSuccess = payload => {
  return {
    type: types.FETCH_POSTING_ANALYTICS_SUCCESS,
    payload
  };
};

export const updatePostingRequest = data => ({
  type: types.UPDATE_POSTING_REQUEST,
  payload: data
});

export const updatePostingSuccess = payload => ({
  type: types.UPDATE_POSTING_SUCCESS,
  payload
});

export const updatePostingsSuccess = payload => ({
  type: types.UPDATE_POSTINGS_SUCCESS,
  payload
});
