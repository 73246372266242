import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../styles/theme';

// For desktop screens, where all the tabButtons lay horizontally
const getHorizontalBorderRadius = ({ isFirst, isLast }) => {
  if (isFirst) {
    return '3px 0 0 3px';
  }
  if (isLast) {
    return '0 3px 3px 0';
  }

  return '0 0 0 0';
};
// For mobile screens, where all the tabButtons stack vertically
const getVerticalBorderRadius = ({ isFirst, isLast }) => {
  if (isFirst) {
    return '3px 3px 0 0';
  }
  if (isLast) {
    return '0 0 3px 3px';
  }

  return '0 0 0 0';
};

const Button = styled.button`
  border: 0;
  text-transform: uppercase;
  height: 3rem;
  background: ${props =>
    props.selected ? theme.accentColor.darken(0.3) : theme.accentColor};
  padding: inherit;
  letter-spacing: 0.15rem;
  font-weight: 500;
  color: ${props => (props.color ? props.color : '#ffffff')};
  text-align: center;
  overflow: visible;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0;
  padding: 0.3rem 2rem;
  position: relative;

  &:hover {
    box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.1);
  }
  &:focus {
    outline-color: rgb(77, 144, 254) !important;
    outline-offset: -3px !important;
    outline-style: auto !important;
    outline-width: 8px !important;
    box-shadow: none !important;
  }
  @media (min-width: 560px) {
    border-radius: ${getHorizontalBorderRadius};
    margin-right: ${props => !props.isLast && '1px'};
  }
  @media (max-width: 559px) {
    width: 100%;
    border-radius: ${getVerticalBorderRadius};
    margin-bottom: ${props => !props.isLast && '1px'};
  }
`;

const TabButton = ({ to = '', children, ...rest }) => {
  // If a 'to' url exists, the TabButton will be rendered as a Link (used for Employers)
  if (to) {
    return (
      <Link to={to}>
        <Button tabIndex="1" {...rest}>
          {children}
        </Button>
      </Link>
    );
  }
  return <Button {...rest}>{children}</Button>;
};

TabButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
};

export default TabButton;
