import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { reverse } from 'ramda';

import config from '../../../config';
import { locale } from '../../../helpers/locale';
import { searchCareers } from '../../../services';
import { Header, SelectInput, CareerListItem } from 'components';

const ItemWrapper = styled.div`
  padding: 2rem;
  border: 0;
  display: flex;
`;

const CareerImage = styled.img`
  border-radius: 3px;
  width: 100%;
  height: auto;
`;

const LeftColumn = styled.div`
  width: 20%;
`;

const RightColumn = styled.div`
  width: 80%;
  margin-left: 2rem;

  /* styles for the career title */
  div {
    margin-bottom: 0 !important;
  }
`;

const InputWrapper = styled.div`
  /* modify the wrapper for the SelectInput to adjust the margin bottom */
  > div {
    margin-bottom: 0;
  }
`;

class CareerSearch extends React.Component {
  static propTypes = {
    // redux form prop
    fields: PropTypes.object.isRequired,
    hasLot: PropTypes.bool,
    // redux form prop
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    nation: PropTypes.string
  };
  static defaultProps = {
    label: 'This posting will appear on these Career Coach pages',
    nation: 'us'
  };
  state = {
    careers: [],
    careerSlugs: [],
    value: ''
  };

  render() {
    const { label, fields, meta, nation } = this.props;
    const { careers, value, careerSlugs } = this.state;

    return (
      <Wrapper>
        <Autosuggest
          id="select-career"
          inputProps={{
            id: 'select career',
            name: 'select career',
            placeholder: locale.postings.placeholder[nation],
            value,
            onChange: (e, { newValue }) => this.setState({ value: newValue }),
            label,
            meta
          }}
          focusInputOnSuggestionClick={false}
          onSuggestionSelected={(e, { suggestion }) => {
            // don't submit the form
            e.preventDefault();
            // reset the field after selecting a career
            this.setState({ value: '' , careerSlugs: [...this.state.careerSlugs, suggestion.titleSlug]});
            fields.push(suggestion);
          }}
          getSuggestionValue={({ title }) => title}
          onSuggestionsClearRequested={() => this.setState({ careers: [] })}
          onSuggestionsFetchRequested={search => this.getResults(search)}
          renderSuggestion={this.showResults}
          renderInputComponent={inputProps => (
            <InputWrapper>
              <SelectInput {...inputProps} isSearch />
            </InputWrapper>
          )}
          suggestions={careers}
        />
        <ul>
          {fields.length > 0 &&
            reverse(
              fields.map((name, index, values) => {
                const career = values.get(index);
                return (
                  <CareerListItem
                    key={`${name}-${index}`}
                    onClick={() => {
                      // remove career from fields array
                      values.remove(index);
                      // filter removed career slug out of the careerSlugs array
                      this.setState(prevState => ({
                        careerSlugs: prevState.careerSlugs.filter(slug => slug !== career.titleSlug)
                      }));
                    }}
                  >
                    {career.humanizedTitle}
                  </CareerListItem>
                );
              })
            )}
        </ul>
      </Wrapper>
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { fields } = props;
    // if you edit a posting, populate state with the career slugs
    if (fields !== state.fields && fields.length) {
      return {
        careerSlugs: fields.getAll().map(career => career.titleSlug),
        fields
      };
    }
    return null;
  }

  showResults = career => {
    const { title, description, id: careerId } = career;
    const { hasLot, nation } = this.props;
    const taxonomyVersion = config.taxonomyVersion[nation];
    return (
      <ItemWrapper>
        <LeftColumn>
          <CareerImage
            alt=""
            src={`https://cc.emsiservices.com/images/${nation}/${taxonomyVersion}/careers/${
              hasLot ? career.standardId : careerId
            }.jpg?width=105&height=105`}
          />
        </LeftColumn>
        <RightColumn>
          <Header component="div" type="body" bold>
            {title}
          </Header>
          <Header component="p" type="caption">
            {description}
          </Header>
        </RightColumn>
      </ItemWrapper>
    );
  };

  /**
   * @method getResults
   * @param  {string}   value the search value
   * @param  {array}    careerSlugs an array of slugs to construct a filter query
   * @return {function}         sets the state with the results from the API call
   */
  getResults = ({ value: query }) => {
    const { hasLot, nation } = this.props;
    this.setState({ query });
    if (query.length > 2) {
      searchCareers({ nation, query, hasLot }).then(careers => {
        const filteredCareers = careers.filter(career => !this.state.careerSlugs.includes(career.titleSlug));
        // We no longer use humanizedTitle from US careers in postings
        // The Postings API still requires it so the humanizedTitle attribute is created from title
        this.setState({
          careers: filteredCareers.map(career => {
            return {
              ...career,
              humanizedTitle: career.title
            };
          })
        });
      });
    }
  };
}

// put this styled component down here because it's super long and handles the child autosuggest styles
const Wrapper = styled.div`
  margin-bottom: 3rem;
  width: 60%;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    background-color: #fff;
    max-height: 40rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 2;
    overflow-y: scroll;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #efefef;
  }
`;

export default CareerSearch;
