import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import config from '../config';
import createKeenMiddleware from 'redux-keen';
import createSagaMiddleware from 'redux-saga';
import getGlobals from '../utils';
import Keen from 'keen-js';
import Raven from 'raven-js';
import reduxCatch from 'redux-catch';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

Raven.config(config.sentryDSN).install();

let enhancer;

function errorHandler(error, getState, action) {
  Raven.captureException(error, {
    extra: {
      state: getState(),
      action
    }
  });
}
const sagaMiddleware = createSagaMiddleware();

const keenClient = new Keen({
  projectId: config.adminKeen.projectId,
  writeKey: config.adminKeen.writeKey
});

// If the user declines cookie usage, we cannot send requests to keen. We must not include the keen middleware.
if (
  document.cookie.replace(/(?:(?:^|.*;\s*)cookieconsent_status\s*\=\s*([^;]*).*$)|^.*$/, '$1') !=
  'deny'
) {
  const keenMiddleware = createKeenMiddleware(keenClient, getGlobals);
  // Include keen middleware.
  enhancer = composeWithDevTools(
    applyMiddleware(reduxCatch(errorHandler), sagaMiddleware, keenMiddleware)
  );
} else {
  // Do not include keen middleware.
  enhancer = composeWithDevTools(applyMiddleware(reduxCatch(errorHandler), sagaMiddleware));
}

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  sagaMiddleware.run(rootSaga);

  return store;
}
