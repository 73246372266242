import { color as colors } from '../../../styles';
import { getDateFromISO } from '../../../helpers/get-date-from-iso';
import {
  drawBackground,
  drawCircle,
  drawDottedLines,
  drawLine,
  drawText,
  drawPolygon
} from '../../../helpers/canvas-draw';

const { darkGray, mediumGray } = colors;

const sortDataSeries = data => [...data].sort((alpha, bravo) => bravo.value - alpha.value);

const findGreatestValue = data => {
  const sortedDataSeries = sortDataSeries(data);
  const greatestValue = sortedDataSeries[0].value;
  return greatestValue;
};

const generateGreatestLineValue = ({ greatestValue, numberOfLines }) => {
  let roundingDenominator = 10;
  for (let index = 0; index < greatestValue.toString().length - 2; index++) {
    roundingDenominator = roundingDenominator * numberOfLines;
  }
  const greatestLineValue = Math.ceil(greatestValue / roundingDenominator) * roundingDenominator;
  return greatestLineValue;
};

const getDistanceBetween = (pointA, pointB) => {
  let y = pointB.x - pointA.x;
  let x = pointB.y - pointA.y;
  return Math.sqrt(x * x + y * y);
};

const drawAxisLines = ({
  context,
  lineColor,
  margin,
  numberOfLines,
  textOffset,
  verticalOffset
}) => {
  const offset = margin + textOffset;
  for (let index = 0; index < numberOfLines; index++) {
    const lineHeight =
      index * ((context.canvas.height - verticalOffset - margin) / numberOfLines) + verticalOffset;
    drawDottedLines({ context, color: lineColor, offset, lineHeight });
  }
};

const drawLabels = ({
  labelColor,
  context,
  fontSize,
  margin,
  numberOfLines,
  textOffset,
  verticalIncrement,
  verticalOffset
}) => {
  const xPoint = margin + textOffset - 10;

  for (let index = 0; index < numberOfLines; index++) {
    const text = verticalIncrement * (numberOfLines - index);
    const yPoint =
      index * ((context.canvas.height - verticalOffset - margin) / numberOfLines) +
      verticalOffset +
      fontSize / 3;
    drawText({ context, color: labelColor, fontSize, text, xPoint, yPoint });
  }
};

const drawDataPoints = ({
  context,
  color,
  data,
  greatestLineValue,
  margin,
  spaceBetweenPoints,
  textOffset,
  verticalOffset
}) => {
  let points = [
    {
      x: context.canvas.width - margin,
      y: context.canvas.height - margin + 2
    },
    {
      x: margin + textOffset,
      y: context.canvas.height - margin + 2
    }
  ];

  for (let index = 0; index < data.length; index++) {
    const currentValue = data[index].value;

    const actualGraphHeight = context.canvas.height - verticalOffset - margin;
    const scaleMultiplier = actualGraphHeight / greatestLineValue;
    const scaledCurrentValue = currentValue * scaleMultiplier;
    const xPoint = margin + textOffset + index * spaceBetweenPoints;
    const yPoint = context.canvas.height - scaledCurrentValue - margin;

    const nextdex = index + 1;
    if (!!data[nextdex]) {
      const nextValue = data[nextdex].value;
      const scaledNextValue = nextValue * scaleMultiplier;
      const xPointB = margin + textOffset + nextdex * spaceBetweenPoints;
      const yPointB = context.canvas.height - scaledNextValue - margin;
      drawLine({ context, color, xPointA: xPoint, xPointB, yPointA: yPoint, yPointB });
    }

    points.push({ x: xPoint, y: yPoint });
    drawCircle({ context, color, xPoint, yPoint });
  }

  drawPolygon({ context, color, points });

  return points.splice(2, points.length);
};

const handleHover = ({
  canvas,
  data,
  dataPoints,
  nation,
  setIsTooltipVisible,
  setMousePosition,
  setTooltipData
}) => {
  canvas.onmousemove = function(event) {
    let rect = this.getBoundingClientRect();
    const { clientX, clientY } = event;
    const mousePoint = { x: clientX - rect.left, y: clientY - rect.top };

    let isTooltipVisible = false;
    for (let index = 0; index < dataPoints.length; index++) {
      const currentDataPoint = dataPoints[index];
      const distanceBetween = getDistanceBetween(mousePoint, currentDataPoint);
      const threshold = 4;
      if (distanceBetween <= threshold) {
        isTooltipVisible = true;
        const dayLabel = getDateFromISO(data[index].timeframe.start, nation);

        setTooltipData({ date: dayLabel, value: data[index].value });
        break;
      }
    }
    setMousePosition(mousePoint);
    setIsTooltipVisible(isTooltipVisible);
  };

  canvas.onmouseout = function() {
    setIsTooltipVisible(false);
  };
};

export const drawMiniChart = ({
  canvasRef,
  color,
  data,
  fontSize = 14,
  margin = 10,
  nation,
  numberOfLines = 5,
  setIsTooltipVisible,
  setMousePosition,
  setTooltipData
}) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext('2d');

  const greatestValue = findGreatestValue(data);
  const greatestLineValue = generateGreatestLineValue({ greatestValue, numberOfLines });

  const props = {
    context,
    color,
    data,
    greatestValue,
    greatestLineValue,
    fontSize,
    labelColor: darkGray,
    lineColor: mediumGray,
    nation,
    margin,
    numberOfLines,
    textOffset: 35,
    verticalIncrement: greatestLineValue / numberOfLines,
    verticalOffset: fontSize * 1.5
  };

  drawBackground(props);

  const numberOfSpaces = data.length - 1;

  props.spaceBetweenPoints =
    (context.canvas.width - margin * 2 - props.textOffset - 5) / numberOfSpaces;

  drawAxisLines(props);
  drawLabels(props);
  const dataPoints = drawDataPoints(props);

  handleHover({
    canvas,
    data,
    dataPoints,
    setIsTooltipVisible,
    setMousePosition,
    setTooltipData
  });
};
