import baseConfig from './base';

const config = {
  ...baseConfig,
  cloudfrontUrl: 'https://d24eua068y5lt4.cloudfront.net',
  defaultSite: 'demo',
  awsCognitoAuth: {
    region: 'us-west-2',
    userPoolId: process.env.PROD_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.PROD_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      ...baseConfig.awsCognitoAuth.oauth,
      domain: 'career-coach-prod.auth.us-west-2.amazoncognito.com', // URL of Career Coach "App Client" configured in AWS Cognito. Used to access Cognito's Oauth API.
      redirectSignIn: 'https://admin.lightcastcc.com', // Default Career Coach Frontend URL to redirect to after a SAML signin request
      redirectSignOut: 'https://admin.lightcastcc.com' // Default Career Coach Frontend URL to redirect to after a SAML signout reques
    }
  },
  adminKeen: {
    projectId: process.env.ADMIN_KEEN_PROD_PROJECT_KEY,
    writeKey: process.env.ADMIN_KEEN_PROD_WRITE_KEY
  },
  sentryDSN: process.env.SENTRY_DSN,
  widgetUrl: 'https://widget.lightcastcc.com'
};

export default config;
