import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { reduxForm, Field, SubmissionError } from 'redux-form';

import { isRequired, isPassword, isMatching } from '../../../helpers/validation';
import { resetPasswordRequest, resetPasswordSubmit } from '../../../services/auth';
import { FormInput, Header, Message } from 'components';
import { color } from '../../../styles';

const { sapphireD20 } = color;

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    width: 48%;
    margin-bottom: 1.5rem;

    @media (max-width: 549px) {
      width: 100%;
    }
  }
`;

const Submit = styled.button`
  display: block;
  background: ${sapphireD20};
  margin-top: 2rem;
  padding: 1.2rem 2rem;
  border: 0;
  border-radius: 3px;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 549px) {
    margin-top: 3rem;
    width: 100%;
  }

  :hover {
    background: ${darken(0.05, sapphireD20)};
  }
`;

class ResetPasswordForm extends React.Component {
  componentDidMount() {
    this.props.clearProfileErrorMessage();

    if (!this.props.hasForgottenPassword) {
      /* send the verification email */
      resetPasswordRequest(this.props.email);
    }
  }
  render() {
    const { email, error, errorMessage, login, handleSubmit, hasForgottenPassword } = this.props;

    return (
      <form
        onSubmit={handleSubmit(({ verificationCode, ...data }) =>
          resetPasswordSubmit({ ...data, email, verificationCode: verificationCode.trim() })
            .then(() => login({ email, password: data.password }))
            .catch(e => {
              throw new SubmissionError({
                _error: e.message
              });
            })
        )}
      >
        <Header component="h2" type="title" bold>
          Reset Password
        </Header>
        {!hasForgottenPassword && (
          <p>
            Due to a recent update, you are required to update your password. We have sent a
            verification code to your email address.
          </p>
        )}
        <FieldsWrapper>
          <Field
            type="text"
            name="verificationCode"
            label="Verification Code"
            validate={isRequired}
            component={FormInput}
          />
        </FieldsWrapper>
        <FieldsWrapper>
          <Field
            type="password"
            validate={[isRequired, isPassword]}
            name="password"
            label="New Password"
            component={FormInput}
          />
          <Field
            type="password"
            validate={[isRequired, isPassword, isMatching]}
            name="confirmPassword"
            label="Re-enter New Password"
            component={FormInput}
          />
        </FieldsWrapper>
        {(error || errorMessage) && <Message type="error">{error || errorMessage}</Message>}
        <Submit type="submit">Submit</Submit>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  email: PropTypes.string.isRequired,
  // this error is from this local redux form, from the cognito password submit process
  error: PropTypes.string,
  // this error is from global state, during login
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hasForgottenPassword: PropTypes.bool,
  login: PropTypes.func.isRequired
};

export default reduxForm({ form: 'resetPassword' })(ResetPasswordForm);
