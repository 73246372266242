import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WorkOpportunityViewsChart } from 'components';
import { fetchKeenData } from '../../services';
import {
  keenWorkOpportunityUniqueViews,
  keenWorkOpportunityViews
} from '../../services/keen/queries';
import { reduceTimeLabels } from '../../helpers/timeLabels';
import { selectSubdomain } from '../../selectors/subdomain';

const WorkOpportunityViewsChartContainer = ({ subdomain, timeframe }) => {
  const [keenData, setKeenData] = useState({});
  const [heading, setHeading] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const getData = async () => {
    const userParams = {
      subdomain,
      timeframe
    };

    const pageViewsData = await fetchKeenData({
      ...userParams,
      ...keenWorkOpportunityViews
    });

    const uniqueViewsData = await fetchKeenData({
      ...userParams,
      ...keenWorkOpportunityUniqueViews
    });

    const total = pageViewsData.reduce((sum, current) => {
      sum += current.value;
      return sum;
    }, 0);

    const labels = pageViewsData.map(item => {
      return item.timeframe.start;
    });

    const combinedData = {
      data: {
        series: [pageViewsData, uniqueViewsData],
        labels
      },
      heading: new Intl.NumberFormat().format(total)
    };

    const { data } = reduceTimeLabels(combinedData);
    setKeenData(data);
    setHeading(combinedData.heading);
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    getData();
  }, [subdomain, timeframe]);

  return (
    <WorkOpportunityViewsChart
      data={keenData}
      timeframe={timeframe}
      loaded={isLoaded}
      subdomain={subdomain}
      heading={heading}
    />
  );
};

WorkOpportunityViewsChartContainer.propTypes = {
  subdomain: PropTypes.string,
  timeframe: PropTypes.shape({
    end: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired
};

export const mapStateToProps = ({ profile, sites, stats }) => {
  return {
    subdomain: selectSubdomain(sites, profile),
    timeframe: stats
  };
};

export default connect(mapStateToProps)(WorkOpportunityViewsChartContainer);
