import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../../styles/theme';
import { flashError } from '../../../helpers/flashmessage';
import DownloadIcon from '../../../images/download-icon.svg';
import LoadingImage from '../../../images/loading-gray.gif';
import {
  fetchInstitutions,
  fetchProgramCategories,
  fetchProgramsBySubdomain
} from '../../../services';
import ExcelProgramsWorkbook from './ExcelProgramsWorkbook';

const Button = styled.button`
  width: 3rem;
  height: 3rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  fill: ${theme.linkColor};
`;

const Image = styled.img`
  width: 3rem;
  height: 3rem;
  display: block;
`;

const ProgramDownloadButton = ({ nation, subdomain }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [institutionData, setInstitutionData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [programData, setProgramData] = useState();
  const [shouldExport, setShouldExport] = useState(false);

  useEffect(() => {
    if (shouldExport) {
      setShouldExport(false);
    }
  }, [shouldExport]);

  const fetchInstitutionData = async () => {
    const institutionsResponse = await fetchInstitutions(subdomain);
    return Object.values(institutionsResponse).map(({ regions, ...rest }) => {
      const formattedInstitutionData = {
        ...rest
      };
      const geoLevels = Object.keys(regions);
      for (let index = 0; index < geoLevels.length; index++) {
        const currentKey = geoLevels[index];
        formattedInstitutionData[currentKey] = regions[currentKey].join('|');
      }
      return formattedInstitutionData;
    });
  };

  const fetchCategoryData = async () => {
    const categoriesResponse = await fetchProgramCategories(subdomain);
    return Object.values(categoriesResponse.categories);
  };

  const fetchProgramData = async () => {
    const programsResponse = await fetchProgramsBySubdomain({ nation, subdomain });

    const formattedProgramData = programsResponse.map(
      ({ careers, categories, locations, transfers, tuitionRange, ...rest }) => {
        let filteredCareers = careers;
        if (nation === 'ca') {
          filteredCareers = careers.filter(career => career.length === 5);
        }
        return {
          ...rest,
          locations: locations.join('|'),
          careers: filteredCareers.join('|'),
          categories: categories.map(({ name }) => name).join('|'),
          tuitionRange: tuitionRange.join('|'),
          transferNames: transfers.map(({ name }) => name).join('|'),
          transferUrls: transfers.map(({ url }) => url).join('|')
        };
      }
    );

    return formattedProgramData;
  };

  const handleClick = async () => {
    setIsDownloading(true);
    try {
      const newInstitutionData = await fetchInstitutionData();
      setInstitutionData(newInstitutionData);

      const newCategoryData = await fetchCategoryData();
      setCategoryData(newCategoryData);

      const newProgramData = await fetchProgramData();
      setProgramData(newProgramData);
      setShouldExport(true);
      setIsDownloading(false);
    } catch (error) {
      flashError('There was a problem downloading program data');
    }
  };

  return (
    <Fragment>
      <Button onClick={handleClick}>
        {isDownloading ? <Image alt="Loading" src={LoadingImage} /> : <StyledDownloadIcon />}
      </Button>
      {shouldExport && (
        <ExcelProgramsWorkbook
          categoryData={categoryData}
          nation={nation}
          programData={programData}
          institutionData={institutionData}
          subdomain={subdomain}
        />
      )}
    </Fragment>
  );
};

ProgramDownloadButton.propTypes = {
  nation: PropTypes.string,
  subdomain: PropTypes.string.isRequired
};

export default ProgramDownloadButton;
