import React from 'react';
import { Col, Row, StatCard } from 'components';
import { FetchKeen, OutboundReferralStats } from 'containers';
import { keenReferrerUrls } from '../../../services/keen/queries';

const Referrals = () => {
  return (
    <div>
      <Row>
        <Col>
          <FetchKeen heading="Top Referrals" {...keenReferrerUrls}>
            {props => <StatCard {...props} />}
          </FetchKeen>
        </Col>
      </Row>
      <Row>
        <Col>
          <OutboundReferralStats />
        </Col>
      </Row>
    </div>
  );
};

export default Referrals;
