import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SliderToggle, Strong } from 'components';

const Wrapper = styled.label`
  display: flex;
`;

const WidgetBuilderImagesToggle = ({ configurations, setConfigurations }) => {
  const { hasImages } = configurations;
  return (
    <Wrapper>
      <SliderToggle
        ariaLabel={`${hasImages ? 'disable' : 'enable'} Career Images`}
        handleChange={() => setConfigurations({ ...configurations, hasImages: !hasImages })}
        isChecked={hasImages}
      />
      <Strong>Career Images</Strong>
    </Wrapper>
  );
};

WidgetBuilderImagesToggle.propTypes = {
  configurations: PropTypes.shape({
    hasImages: PropTypes.bool.isRequired
  }).isRequired,
  setConfigurations: PropTypes.func.isRequired
};

export default WidgetBuilderImagesToggle;
