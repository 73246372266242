import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { locale } from '../../../helpers/locale';
import {
  EmptySelectionPrompt,
  HideContentOnMobile,
  Layout,
  ProgramWidgetBuilderEmbed,
  ProgramWidgetBuilderPreview,
  ProgramWidgetBuilderSidebar,
  WidgetBuilderTabHeader
} from 'components';

const Wrapper = styled.div`
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 2.5rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 0 2.5rem 2rem;
  border-radius: 6px;
`;

const WidgetBuilderOutput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EmbedWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ProgramWidgetBuilder = ({ siteSettings }) => {
  const { accentColor, currentSite, nation, areas, hasCcEnabled } = siteSettings;

  const defaultLocation = areas[0];
  const [configurations, setConfigurations] = useState({
    hasCareerList: true,
    hasEmployment: true,
    hasAllLocations: false,
    hasHeading: true,
    hasImages: true,
    hasJobOpenings: nation !== 'ca',
    hasNationalData: true,
    hasOutlook: true,
    hasSkills: true,
    hasVisitButton: !!hasCcEnabled,
    hasWages: true,
    isBatch: false,
    isHourlyWage: true,
    labelSet: 'default',
    locationName: defaultLocation.name,
    primaryColor: accentColor,
    stylesheetHref: '',
    clientId: currentSite,
    visitButtonColor: accentColor
  });

  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [isAlternativeEmbedCode, setIsAlternativeEmbedCode] = useState(false);
  const [isPreview, setIsPreview] = useState(true);

  return (
    <Layout title={`${locale.edTrack[nation]} Widget Builder`}>
      <Wrapper>
        <HideContentOnMobile message="The Widget Builder is only available on desktop!">
          <Card>
            <ProgramWidgetBuilderSidebar
              configurations={configurations}
              siteSettings={siteSettings}
              setConfigurations={setConfigurations}
              selectedPrograms={selectedPrograms}
              setSelectedPrograms={setSelectedPrograms}
            />
            <WidgetBuilderOutput>
              <WidgetBuilderTabHeader
                configurations={configurations}
                isAlternativeEmbedCode={isAlternativeEmbedCode}
                isPreview={isPreview}
                selectedPrograms={selectedPrograms}
                setIsPreview={setIsPreview}
                siteSettings={siteSettings}
              />
              {isPreview && !selectedPrograms.length && <EmptySelectionPrompt />}
              {!isPreview && (
                <EmbedWrapper>
                  <ProgramWidgetBuilderEmbed
                    configurations={configurations}
                    isAlternativeEmbedCode={isAlternativeEmbedCode}
                    siteSettings={siteSettings}
                    setIsAlternativeEmbedCode={setIsAlternativeEmbedCode}
                    selectedPrograms={selectedPrograms}
                  />
                </EmbedWrapper>
              )}
              <ProgramWidgetBuilderPreview
                isHidden={!isPreview}
                configurations={configurations}
                siteSettings={siteSettings}
                selectedPrograms={selectedPrograms}
              />
            </WidgetBuilderOutput>
          </Card>
        </HideContentOnMobile>
      </Wrapper>
    </Layout>
  );
};

ProgramWidgetBuilder.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  siteSettings: PropTypes.shape({
    hasCcEnabled: PropTypes.bool.isRequired,
    nation: PropTypes.string.isRequired,
    orderCareersBy: PropTypes.oneOf(['Alphabetical', 'Salary', 'None']),
    schoolName: PropTypes.string.isRequired
  })
};

export default ProgramWidgetBuilder;
