import moment from 'moment';

/**
 * takes the result from keen response and
 * Depending how many data points there are, only display a fraction of the time labels to fit in the chart
 * @method reduceTimeLabels
 * @param  {object}         data the data from the keen response
 * @return {object}              the new object returned with the data labels mapped corretly
 */
export const reduceTimeLabels = ({ data }) => {
  const count = data.labels.length;
  if (count === 0) return {};
  const labels = data.labels.reduce((result, item, index) => {
    // Display a label daily when there are fewer than 18 days
    if (count < 18) {
      result.push(moment(item).format('MMM D'));
      return result;
    }
    // Display a label semi-monthly when there are fewer than 200 days (~6.5 months)
    if (count < 200) {
      if (
        // Only display a initial label, in case where only one label is displaying
        (index === 0 && moment(item).date() % 16 < 13 && count < 50) ||
        moment(item).format('D') === '1' ||
        moment(item).format('D') === '15'
      )
        result.push(moment(item).format('MMM D'));
      else result.push('');
      return result;
    }
    // Display a label monthly when there are fewer than 450 days (~15 months)
    if (count < 450) {
      if (moment(item).format('D') === '1')
        result.push(moment(item).format('MMM YYYY'));
      else result.push('');
      return result;
    }
    // Display a label yearly when there are more than 450 days (>1 year)
    if (index === 0 || moment(item).format('MM-DD') === '01-01')
      result.push(moment(item).format('MMM YYYY'));
    else
      // Leave an empty label for the skipped data points (necessary for Chartist)
      result.push('');
    return result;
  }, []);
  data.labels = labels;
  return { data };
};
