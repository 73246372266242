import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { CareerSettingsFormFields, FormLayout } from 'components';

const CareerForm = ({ handleSubmit, submitting, reset, pristine, handleFormSubmit }) => {
  return (
    <FormLayout
      title="Career Settings"
      submitForm={handleSubmit(handleFormSubmit)}
      submitting={submitting}
      pristine={pristine}
      reset={reset}
    >
      <CareerSettingsFormFields />
    </FormLayout>
  );
};

CareerForm.propTypes = {
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleFormSubmit: PropTypes.func
};

const CareerSettingsForm = reduxForm({
  form: 'careerSettingsForm',
  enableReinitialize: true
})(CareerForm);

export default CareerSettingsForm;
