// Sites
export const FETCH_SITES_REQUEST = 'FETCH_SITES_REQUEST';
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAILURE = 'FETCH_SITES_FAILURE';
export const FETCH_SITE_REQUEST = 'FETCH_SITE_REQUEST';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAILURE = 'FETCH_SITE_FAILURE';
export const UPDATE_CURRENT_SITE = 'UPDATE_CURRENT_SITE';
export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE';
export const PATCH_SITE_REQUEST = 'PATCH_SITE_REQUEST';
export const PATCH_SITE_SUCCESS = 'PATCH_SITE_SUCCESS';
export const PATCH_SITE_FAILURE = 'PATCH_SITE_FAILURE';

// flash message
export const FLASH_MESSAGE_SHOW = 'FLASH_MESSAGE_SHOW';
export const FLASH_MESSAGE_HIDE = 'FLASH_MESSAGE_HIDE';

// Authentication
export const CLEAR_PROFILE_ERROR_MESSAGE = 'CLEAR_PROFILE_ERROR_MESSAGE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_SIGNUP = 'USER_SIGNUP';

// Batch Profile Management
export const FETCH_PROFILES_REQUEST = 'FETCH_PROFILES_REQUEST';
export const FETCH_PROFILES_SUCCESS = 'FETCH_PROFILES_SUCCESS';
export const FETCH_PROFILES_FAILURE = 'FETCH_PROFILES_FAILURE';

// Resumes
export const DOWNLOAD_RESUME = 'DOWNLOAD_RESUME';

// STATS
export const UPDATE_STATS_DATES = 'UPDATE_STATS_DATES';
export const SEND_ANALYTICS = 'SEND_ANALYTICS';

// Employers
export const FETCH_EMPLOYERS_REQUEST = 'FETCH_EMPLOYERS_REQUEST';
export const FETCH_EMPLOYERS_SUCCESS = 'FETCH_EMPLOYERS_SUCCESS';
export const UPDATE_EMPLOYER_REQUEST = 'UPDATE_EMPLOYER_REQUEST';
export const UPDATE_EMPLOYER_SUCCESS = 'UPDATE_EMPLOYER_SUCCESS';
export const WHITELIST_EMPLOYER = 'WHITELIST_EMPLOYER';

// Postings
export const CREATE_POSTING_REQUEST = 'CREATE_POSTING_REQUEST';
export const CREATE_POSTING_SUCCESS = 'CREATE_POSTING_SUCCESS';
export const FETCH_POSTINGS_REQUEST = 'FETCH_POSTINGS_REQUEST';
export const FETCH_POSTINGS_SUCCESS = 'FETCH_POSTINGS_SUCCESS';
export const FETCH_POSTING_ANALYTICS_REQUEST = 'FETCH_POSTING_ANALYTICS_REQUEST';
export const FETCH_POSTING_ANALYTICS_SUCCESS = 'FETCH_POSTING_ANALYTICS_SUCCESS';
export const UPDATE_POSTING_REQUEST = 'UPDATE_POSTING_REQUEST';
export const UPDATE_POSTING_SUCCESS = 'UPDATE_POSTING_SUCCESS';
export const UPDATE_POSTINGS_SUCCESS = 'UPDATE_POSTINGS_SUCCESS';
