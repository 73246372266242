export function validateSubdomainAliases(values, state) {
  const errors = {};

  const existingSubdomainAliases = Object.values(state.sites.items)
    .filter(({ subdomain }) => subdomain !== values.subdomain)
    .map(({ subdomainAliases }) => subdomainAliases)
    .flat();

  if (existingSubdomainAliases.includes(values.subdomain)) {
    errors.subdomain = `${values.subdomain} is already in use as a subdomain alias. Please try something else.`;
  }

  for (let index = 0; index < values.subdomainAliases.length; index++) {
    const current = values.subdomainAliases[index];

    if (state.sites.items[current]) {
      errors.subdomainAliases = `${current} is already in use as a subdomain. Please try something else.`;
    }
    if (existingSubdomainAliases.includes(current)) {
      errors.subdomainAliases = `${current} is already in use as a subdomain alias. Please try something else.`;
    }
  }

  return errors;
}
