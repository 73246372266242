import styled from 'styled-components';
import { color } from '../../../styles';
import { Button, Icon, Input } from 'components';
import CirclePlus from '../../../images/circle-plus.svg';
import CircleX from '../../../images/circle-x.svg';

const { darkGray, sapphireD20 } = color;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Card = styled.div`
  background: white;
  padding: 4rem 2rem 2rem;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

export const DeselectAllIcon = styled(CircleX)`
  position: relative;
  top: 3px;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  fill: ${sapphireD20};
`;

export const Header = styled.div`
  margin: 4rem 2rem 0;
`;

export const SearchIcon = styled(Icon)`
  position: relative;
  left: 1rem;
  top: 5rem;
  color: ${darkGray};
  pointer-events: none;
  z-index: 1;
`;

export const SelectAllButton = styled.button`
  border: none;
  background: none;
  color: ${sapphireD20};
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 2rem;
  padding: 0;
`;

export const SelectAllButtonsWrapper = styled.div`
  display: flex;
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
`;

export const SelectAllIcon = styled(CirclePlus)`
  position: relative;
  top: 3px;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  fill: ${sapphireD20};
`;

export const StyledButton = styled(Button)`
  margin-left: 2rem;
`;

export const StyledInput = styled(Input)`
  margin-top: 2rem;
  background: #f0f1f3;
  border: none;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 3.5rem !important;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;
