import {
  createAPI as createSite,
  fetchAllAPI as fetchSites,
  patchAPI as patchSite,
  fetchAPI as fetchSite
} from './sites';
import {
  watchSignUp as signUp,
  watchLogin as login,
  watchLogout as logout,
  watchFetchProfiles as fetchProfiles,
  watchDownloadResume as downloadResumes
} from './profile';
import { fetchEmployersAPI, updateEmployerAPI, whitelistEmployer } from './employers';
import {
  createPostingAPI,
  updatePostingAPI,
  watchFetchPostings,
  watchFetchPostingAnalytics
} from './postings';

export default function* rootSaga() {
  yield [
    createSite(),
    createPostingAPI(),
    fetchSite(),
    fetchSites(),
    patchSite(),
    signUp(),
    login(),
    logout(),
    fetchProfiles(),
    downloadResumes(),
    fetchEmployersAPI(),
    updateEmployerAPI(),
    whitelistEmployer(),
    updatePostingAPI(),
    watchFetchPostings(),
    watchFetchPostingAnalytics()
  ];
}
