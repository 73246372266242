import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  color: #61737a;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  ${props => (props.type === 'back' ? 'left: calc(50% - 115px);' : 'right: calc(50% - 115px);')}
  border: none; 
  font-size: 16px;
  background: none;
  padding: 0;
  bottom: 25px;
`;

const Arrow = (props) => {
  return <Button className={`ion-android-arrow-${props.type}`} {...props} />;
};

Arrow.propTypes = {
  type: PropTypes.string.isRequired
};

export default Arrow;
