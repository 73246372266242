import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { FormLayout, FormRadio, Loading } from 'components';
import { withNationHOC } from '../../../hocs/withNation';

const SkillsSettings = withNationHOC(
  ({
    currentSite,
    currentValues,
    handleSubmit,
    pristine,
    reset,
    saveSkillsSettings,
    submitting
  }) => {
    if (!currentValues) {
      return <Loading />;
    }

    return (
      <FormLayout
        title="Skills Settings"
        submitForm={handleSubmit(settings => saveSkillsSettings({ currentSite, settings }))}
        submitting={submitting}
        pristine={pristine}
        reset={reset}
      >
        <Field
          aria-label="Skills Assessment Enabled"
          label="Skills Assessment Enabled"
          name="hasSkillsAssessment"
          component={FormRadio}
        />
      </FormLayout>
    );
  }
);

SkillsSettings.propTypes = {
  currentSite: PropTypes.string,
  currentValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  saveSkillsSettings: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

const SkillsSettingsForm = reduxForm({
  form: 'SkillsSettingsForm',
  enableReinitialize: true
})(SkillsSettings);

export default SkillsSettingsForm;
