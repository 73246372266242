import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components';
import Loading from '../../../images/loading-gray.gif';

const BackgroundButton = styled(Button)`
  background-image: url("${Loading}");
  background-size: 3.5rem;
  background-repeat: no-repeat;
  background-position: center;
`;

const HiddenText = styled.div`
  visibility: hidden;
`;

const LoadingButton = ({ children, isLoading, ...rest }) => {
  return isLoading ? (
    <BackgroundButton role="alert" aria-live="assertive" aria-label="Loading" {...rest}>
      <HiddenText>{children}</HiddenText>
    </BackgroundButton>
  ) : (
    <Button type="submit" {...rest}>
      {children}
    </Button>
  );
};

LoadingButton.defaultProps = {
  isLoading: false
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool
};

export default LoadingButton;
