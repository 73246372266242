import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactQuill, { Quill } from 'react-quill';

import { BoldLabel, Margin } from 'components';
import { color } from '../../../styles';
import { flashError } from '../../../helpers/flashmessage';
import 'react-quill/dist/quill.snow.css';

const InputWrapper = styled(Margin)`
  position: relative;
`;

const StyledRichTextEditor = styled(ReactQuill)`
  border: 1px solid ${props => (props.error ? color.red : color.mediumGray)};
`;

const Subtitle = styled.span`
  position: absolute;
  right: 8px;
  top: 35px;
`;

const Error = styled.span`
  color: ${color.red};
  position: absolute;
  right: 0px;
  top: 100%;
`;

let Inline = Quill.import('blots/inline');
class LinkBlot extends Inline {
  static create(value) {
    let node = super.create();
    if (value) {
      const prefix = /^https?:\/\//.test(value);
      if (!prefix) {
        value = `https://${value}`;
      }
      if (/^https?:\/\/(\S+)\.(\S+)$/.test(value)) {
        node.setAttribute('href', value);
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
        return node;
      } else {
        flashError('Link was invalid. Please add an extension.');
        return node;
      }
    }
  }

  static formats(node) {
    return node.getAttribute('href');
  }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

Quill.register(LinkBlot);

const RichTextEditor = ({
  className,
  meta: { error, touched },
  postfixText,
  input,
  label,
  placeholder = '',
  ...props
}) => {
  const [bubbleState, setBubbleState] = useState(false);
  const [value, setValue] = useState(input.value);

  useEffect(() => {
    document.querySelector('input[data-link]').setAttribute('data-link', 'https://example.com');
    const query = document.getElementsByClassName('ql-toolbar');
    const buttons = query[0].getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      const buttonTitle =
        buttons[i].className.charAt(3).toUpperCase() + buttons[i].className.slice(4);
      buttons[i].setAttribute('aria-label', `Toggle ${buttonTitle + i} `);
    }
  }, []);

  useEffect(() => {
    if (bubbleState) {
      const output =
        value === '<p><br></p>' || value === "<span class='ql-cc'></span>" ? '' : value;
      input.onChange(output);
    }
    return () => {
      setBubbleState(false);
    };
  }, [bubbleState]);

  const toolbar = [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link'],
    ['clean']
  ];

  const formats = [
    'align',
    'center',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link'
  ];

  const hasError = error && touched;

  return (
    <InputWrapper value="0 0 3rem 0" className={className}>
      <BoldLabel name={input.name} label={label} />
      <StyledRichTextEditor
        theme="snow"
        {...input}
        {...props}
        error={hasError}
        formats={formats}
        placeholder={placeholder}
        modules={{
          toolbar,
          clipboard: {
            matchVisual: false
          }
        }}
        defaultValue={input.value}
        value={value}
        onChange={(newValue, delta, source) => {
          if (source === 'user' || source === 'api') {
            setValue(newValue);
            setTimeout(() => {
              setBubbleState(true);
            }, 350);
          }
        }}
        onBlur={(range, source, quill) => {
          input.onBlur(quill.getHTML());
        }}
      />
      {postfixText && <Subtitle>{postfixText}</Subtitle>}
      {hasError && <Error>{error}</Error>}
    </InputWrapper>
  );
};

RichTextEditor.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  postfixText: PropTypes.string
};

export default RichTextEditor;
