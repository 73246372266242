import { useEffect, useState } from 'react';

export const usePagination = tableData => {
  const rowsPerPage = 10;
  const [paginatedData, setPaginatedData] = useState(tableData.slice(0, rowsPerPage));
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setPaginatedData(tableData.slice(0, rowsPerPage));
    setPageNumber(1);
  }, [tableData]);

  const handlePageNumberChange = newPageNumber => {
    const newPaginatedData = tableData.slice(
      (newPageNumber - 1) * rowsPerPage,
      (newPageNumber - 1) * rowsPerPage + rowsPerPage
    );
    setPaginatedData(newPaginatedData);
    setPageNumber(newPageNumber);
  };

  return {
    paginatedData,
    pageNumber,
    handlePageNumberChange,
    rowsPerPage
  };
};
