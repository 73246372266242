import config from '../../config';

export const fetchAssessmentDownload = async ({
  clientId,
  nation,
  results,
  questionSet,
  userName
}) => {
  const datarun = config[`${nation}DataRun`].replace('.', '_');
  return fetch(`/api/cc-api/assessments/download?datarun=${datarun}`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          'client-id': clientId,
          'question-set': questionSet,
          traits: results,
          userName
        }
      }
    })
  });
};
