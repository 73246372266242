import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { color } from '../../../styles';

const ColumnHeader = styled(TableHeaderColumn)`
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

const StyledTable = styled(BootstrapTable)`
  ${props => props.isHiddenFromScreenReader && 'display:none'};
  padding-top: 3rem;
  thead {
    border-top: 1px solid ${color.lightGray};
  }
  td {
    border-top: 1px solid ${color.lightGray};
    border-bottom: 1px solid ${color.lightGray};
  }
  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
  td,
  th {
    padding: 8px 0;
    position: relative;
    outline: 0;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;

    div {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  .react-bs-container-body {
    padding-bottom: 2rem;
  }
  .order {
    /* selected arrow color */
    .caret {
      display: inline-block;
      cursor: pointer;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
  .dropdown,
  .dropup {
    position: relative;
    /* sort down arrow */
    .caret {
      display: inline-block;
      cursor: pointer;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }
  .dropup {
    position: relative;
    /* sort up arrow */
    .caret {
      content: '';
      border-top: 0;
      border-bottom: 4px solid;
    }
  }
`;

const Table = ({ data, columns, options = {}, ...props }) => {
  return (
    <StyledTable bordered={false} data={data} options={options} {...props}>
      {/* remove any empty columns */}
      {columns
        .filter(column => column)
        .map((column, i) => (
          <ColumnHeader
            isKey={column.isKey}
            key={`column-${i}`}
            dataSort={column.dataSort === undefined || column.dataSort}
            dataField={column.dataField}
            dataFormat={column.dataFormat}
            sortFunc={column.sortFunc}
            dataAlign={column.dataAlign}
            hidden={column.hidden}
          >
            {column.text}
          </ColumnHeader>
        ))}
    </StyledTable>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object
};

export default Table;
