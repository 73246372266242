import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { Modal } from 'containers';
import { ProgramForm, Card } from 'components';

const { mediumGray } = color;

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  overflow: visible;

  @media (min-width: 1042px) {
    border-radius: 3px;
    width: 90rem;
  }
`;

const Header = styled.h2`
  margin-bottom: 2.2rem;
  font-size: 2rem;
  font-weight: 600;
`;

const HorizontalRule = styled.div`
  position: relative;
  left: -3rem;
  width: calc(100% + 6rem);
  border-top: 1px solid ${mediumGray};
`;

const EditProgramModal = ({
  categoryOptions,
  closeModal,
  fetchProgramData,
  hasManyInstitutions,
  institutionsById,
  isEdit,
  name,
  openAddedProgramModal,
  programData,
  setIsLoading,
  updateProgram
}) => {
  const { institutionName, tuitionRange, ...initialValues } = programData;

  return (
    <Modal name={name} width={90} shouldCloseOnOverlayClick={false}>
      <Wrapper>
        <Header>{`${isEdit ? 'Edit' : 'Add'} Program`}</Header>
        <HorizontalRule />
        <ProgramForm
          categoryOptions={categoryOptions}
          closeModal={closeModal}
          fetchProgramData={fetchProgramData}
          hasManyInstitutions={hasManyInstitutions}
          initialValues={initialValues}
          institutionsById={institutionsById}
          isEdit={isEdit}
          openAddedProgramModal={openAddedProgramModal}
          setIsLoading={setIsLoading}
          updateProgram={updateProgram}
        />
      </Wrapper>
    </Modal>
  );
};

EditProgramModal.propTypes = {
  isEdit: PropTypes.bool,
  categoryOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  closeModal: PropTypes.func.isRequired,
  hasManyInstitutions: PropTypes.bool,
  institutionsById: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  fetchProgramData: PropTypes.func.isRequired,
  openAddedProgramModal: PropTypes.func,
  programData: PropTypes.shape({
    careers: PropTypes.array,
    categories: PropTypes.array,
    cip: PropTypes.string,
    code: PropTypes.string,
    credential: PropTypes.string,
    institutionId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    locations: PropTypes.array,
    name: PropTypes.string,
    slug: PropTypes.string,
    subdomain: PropTypes.string,
    transfers: PropTypes.array,
    tuitionRange: PropTypes.array,
    url: PropTypes.string
  }),
  name: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  updateProgram: PropTypes.func.isRequired
};

export default EditProgramModal;
