import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchKeenData, fetchCareerTitles } from '../../services';
import { processKeenResult } from '../../helpers/process-keen-result';
import { selectSubdomain } from '../../selectors/subdomain';

class FetchKeen extends React.Component {
  static propTypes = {
    subdomain: PropTypes.string.isRequired,
    resultKey: PropTypes.string,
    event: PropTypes.string,
    extraFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    nation: PropTypes.string
  };

  static defaultProps = {
    resultKey: ''
  };

  state = {
    data: [],
    loaded: false
  };
  // on inital load, load the `testsite`
  componentDidMount() {
    this.loadKeenData(this.props.subdomain);
    this.mounted = true;
  }

  // called when the site changes or the timeframe for analytics changes
  componentDidUpdate(prevProps) {
    // set the loaded state to false and then call the new data
    if (prevProps !== this.props) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ loaded: false }, () => this.loadKeenData(this.props.subdomain));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    // renders the children and pass along the data
    return this.props.children({ ...this.state, ...this.props });
  }
  /**
   * queries the keen api for the data
   * @method loadKeenData
   * @param  {string}     subdomain the subdomain
   * @return {object}     sets the local state with the data retrieved and loaded
   */
  async loadKeenData(subdomain) {
    const { resultKey, event, extraFilters, nation } = this.props;
    const res = await fetchKeenData({ subdomain, ...this.props });
    if (Array.isArray(res)) {
      const data = processKeenResult(res, resultKey);
      let careerIdsToSlug = {};
      if (event === 'Favorites' && extraFilters[0].property_value === 'careers') {
        careerIdsToSlug = await fetchCareerTitles('all', nation);
      }
      if (this.mounted) {
        return this.setState({
          data,
          loaded: true,
          careerIdsToSlug
        });
      }
    }
    if (this.mounted) {
      return this.setState({ data: res, loaded: true });
    }
  }
  mounted = false;
}

export const mapStateToProps = ({ profile, sites, stats }) => ({
  // figure out which default site to query the keen data for the client's subdomain or the default `testsite` one
  subdomain: selectSubdomain(sites, profile),
  timeframe: stats,
  nation: sites.items[sites.currentSite].nation
});

export { FetchKeen as PureFetchKeen }; // used in tests

export default connect(mapStateToProps)(FetchKeen);
