import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, EmployerSettingsForm } from 'components';

const StyledCard = styled(Card)`
  overflow: visible;
`;

const EmployerSettingsCard = ({
  employerWhitelist,
  jobTypes,
  patchSiteRequest,
  subdomain,
  whitelistEmployer
}) => {
  const defaultJobTypes = ['Full-Time', 'Part-Time', 'Internship', 'Apprenticeship', 'Work-Study'];

  return (
    <StyledCard>
      <EmployerSettingsForm
        defaultJobTypes={defaultJobTypes}
        initialValues={{ jobTypes, employerWhitelist }}
        patchSiteRequest={patchSiteRequest}
        subdomain={subdomain}
        whitelistEmployer={whitelistEmployer}
      />
    </StyledCard>
  );
};

EmployerSettingsCard.propTypes = {
  employerWhitelist: PropTypes.array,
  jobTypes: PropTypes.array,
  patchSiteRequest: PropTypes.func.isRequired,
  subdomain: PropTypes.string.isRequired,
  whitelistEmployer: PropTypes.func.isRequired
};

export default EmployerSettingsCard;
