import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const sizeStyles = ({ size }) => {
  switch (size) {
    case '1':
      return `
        height: 3rem;
        width: 3rem;
      `;
    case '2':
      return `
        height: 2rem;
        width: 2rem;
      `;
    case '3':
      return `
        height: 1.5rem;
        width: 1.5rem;
      `;
    default:
  }
  return null;
};

const Svg = styled(({ type, hasHover, color, ...rest }) => <svg {...rest} />)`
  vertical-align: middle;
  color: ${props => props.color};
  cursor: ${props => (props.type !== 'search' ? 'pointer' : 'default')};
  ${sizeStyles};
  :hover {
    opacity: ${props => (props.hasHover ? '0.8' : '1')};
  }
`;
// these are taken from `https://github.com/gorangajic/react-icons/tree/master/io`
// instead using the whole package.
const ioIcons = {
  radioChecked:
    'm19.8 11.9c4.4 0 8.1 3.6 8.1 8.1s-3.7 8.1-8.1 8.1-8.2-3.6-8.2-8.1 3.7-8.1 8.2-8.1z m0-8.1c8.9 0 16.2 7.3 16.2 16.2s-7.3 16.3-16.2 16.3-16.3-7.4-16.3-16.3 7.3-16.2 16.3-16.2z m0 29.2c7.1 0 12.9-5.8 12.9-13s-5.8-13-12.9-13-13 5.8-13 13 5.8 13 13 13z',
  radio:
    'm19.8 3.8c8.9 0 16.2 7.3 16.2 16.2s-7.3 16.3-16.2 16.3-16.3-7.4-16.3-16.3 7.3-16.2 16.3-16.2z m0 29.2c7.1 0 12.9-5.8 12.9-13s-5.8-13-12.9-13-13 5.8-13 13 5.8 13 13 13z',
  checkboxChecked:
    'm31.6 5c1.8 0 3.4 1.6 3.4 3.4v23.2c0 1.8-1.6 3.4-3.4 3.4h-23.2c-1.8 0-3.4-1.6-3.4-3.4v-23.2c0-1.8 1.6-3.4 3.4-3.4h23.2z m-15 23.4l15-15-2.3-2.4-12.7 12.7-5.9-6-2.3 2.3z',
  checkbox:
    'm31.6 8.4h-23.2v23.2h23.2v-23.2z m0-3.4c1.8 0 3.4 1.6 3.4 3.4v23.2c0 1.8-1.6 3.4-3.4 3.4h-23.2c-1.8 0-3.4-1.6-3.4-3.4v-23.2c0-1.8 1.6-3.4 3.4-3.4h23.2z',
  checkmark:
    'm35.8 8.6c0.1 0.2 0.2 0.3 0.2 0.5s-0.1 0.3-0.2 0.4l-19.4 25c-0.2 0.2-0.4 0.5-0.7 0.5s-0.6-0.2-0.8-0.4-11-10.6-11-10.6l-0.2-0.2c-0.1-0.2-0.2-0.4-0.2-0.5s0.1-0.3 0.2-0.4l0.1-0.2c1.1-1.1 3.3-3.4 3.5-3.6s0.3-0.4 0.6-0.4 0.6 0.3 0.8 0.4 6.2 6.1 6.2 6.1l15.6-20c0.2-0.2 0.3-0.2 0.5-0.2s0.4 0.1 0.5 0.2z',
  dropdown: 'm10 15h20l-10 10z',
  close:
    'm31.8 10.7l-9.3 9.3 9.3 9.3-2.4 2.3-9.3-9.3-9.3 9.3-2.3-2.3 9.3-9.3-9.3-9.3 2.3-2.3 9.3 9.3 9.3-9.3z',
  down:
    'm17.5 23.3l13.6-13.1c0.3-0.3 0.9-0.3 1.2 0l2.5 2.4c0.3 0.3 0.3 0.9 0 1.2l-16.7 16c-0.1 0.1-0.4 0.2-0.6 0.2s-0.5-0.1-0.6-0.2l-16.7-16c-0.3-0.3-0.3-0.9 0-1.2l2.5-2.4c0.3-0.3 0.9-0.3 1.2 0z',
  pencil:
    'm5 28.8l18.4-18.5 6.3 6.3-18.4 18.4h-6.3v-6.2z m29.5-17.1l-3.1 3.1-6.2-6.2 3.1-3.1c0.7-0.7 1.5-0.7 2.2 0l4 4c0.7 0.7 0.7 1.5 0 2.2z',
  plus:
    'm19.8 3.8c8.9 0 16.2 7.2 16.2 16.2s-7.3 16.3-16.2 16.3-16.3-7.3-16.3-16.3 7.3-16.2 16.3-16.2z m8.3 17.8v-3.2h-6.7v-6.8h-3.3v6.8h-6.7v3.2h6.7v6.8h3.3v-6.8h6.7z',
  search:
    'm26.9 23.3l-0.3 0.5 8.4 8.5-2.7 2.7-8.4-8.5-0.5 0.4c-1.9 1.1-4.2 1.9-6.5 1.9-6.6 0-11.9-5.4-11.9-11.9s5.3-11.9 11.9-11.9 11.9 5.3 11.9 11.9c0 2.3-0.8 4.5-1.9 6.4z m-3.4-13.1c-1.8-1.8-4.1-2.7-6.6-2.7s-4.9 0.9-6.7 2.7-2.7 4.2-2.7 6.7 0.9 4.8 2.7 6.6 4.2 2.8 6.7 2.8 4.8-1 6.6-2.8 2.8-4.1 2.8-6.6-1-4.9-2.8-6.7z',
  up:
    'm17.5 16.7l-13.6 13.1c-0.3 0.3-0.9 0.3-1.2 0l-2.5-2.4c-0.3-0.3-0.3-0.9 0-1.2l16.7-16c0.1-0.1 0.4-0.2 0.6-0.2s0.5 0.1 0.6 0.2l16.7 16c0.3 0.3 0.3 0.9 0 1.2l-2.5 2.4c-0.3 0.3-0.9 0.3-1.2 0l-13.6-13.1z'
};

const Icon = ({ type, size = '1', hasHover = true, ...other }) => {
  return (
    <Svg
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 40 40"
      size={size}
      hasHover={hasHover}
      type={type}
      {...other}
    >
      <g>
        <path d={ioIcons[type]} />
      </g>
    </Svg>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  hasHover: PropTypes.bool
};

export default Icon;
