import camelCase from 'camelcase-keys-deep';
import { getCognitoToken } from '../';

export const fetchProgramLogs = async ({ limit, offset, search, subdomain, sort }) => {
  try {
    const response = await fetch(
      `/api/cc-api/program-log/${subdomain}?limit=${limit}&offset=${offset}${
        !!search ? `&search=${encodeURIComponent(search)}` : ''
      }${!!sort ? `&sort=${sort}` : ''}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${await getCognitoToken()}`
        })
      }
    );

    if (response.status === 200) {
      const json = await response.json();
      const {
        data,
        meta: { count }
      } = json;
      const logs = data.map(({ attributes }) => camelCase(attributes));

      return {
        count,
        logs
      };
    } else if (response.status === 404) {
      return {
        logs: []
      };
    }
  } catch (error) {
    return error;
  }
};
