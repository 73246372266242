import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AddAdminSubdomain, Modal } from 'containers';
import { CreateSuper, InfoBody } from 'components';

const Title = styled.h2`
  margin-bottom: 1rem !important;
  padding: 3rem 5rem;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.5rem;
`;

const TabsWrapper = styled.div`
  background: white;
  margin-left: 5rem;
`;

const TabButton = styled.button`
  width: ${({ width }) => width};
  height: 3rem;
  background: white;
  margin-right: 4rem;
  font-size: 1.8rem;

  /* remove default button styles */
  border: none;
  cursor: pointer;
  padding: 0;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  letter-spacing: ${({ isSelected }) => (!isSelected ? '.25px' : 'inherit')};
  border-bottom: ${({ isSelected }) => (isSelected ? '4px solid black' : '4px solid white')};

  :hover {
    border-bottom: 4px solid black;
  }
`;

const ProfileInfoModal = ({
  isDeveloper,
  isStudentIdEnabled,
  isSuperUser,
  modalHide,
  name,
  nation,
  reloadProfiles,
  title,
  ...rest
}) => {
  const isUSA = nation === 'us';
  const initialTabIndex = isUSA ? 0 : 1;
  const [currentTab, setCurrentTab] = useState(initialTabIndex);
  const { email, subdomain, profileId, userRole } = rest.profile;
  const isAdminProfile = userRole === 'admin';

  const entryKeys = ['email', 'currentSchool', 'currentMajor', 'phoneNumber', 'address'];
  if (isStudentIdEnabled) {
    entryKeys.splice(2, 0, 'studentId');
  }

  return (
    <Modal name={name}>
      <Fragment>
        <Title type="headline" component="h2">
          {title}
        </Title>
        {isSuperUser && isAdminProfile && (
          <TabsWrapper>
            {isUSA && (
              <TabButton
                isSelected={currentTab === 0}
                onClick={() => setCurrentTab(0)}
                width="6rem"
              >
                Profile
              </TabButton>
            )}
            <TabButton isSelected={currentTab === 1} onClick={() => setCurrentTab(1)} width="17rem">
              Admin Permissions
            </TabButton>
          </TabsWrapper>
        )}
        {currentTab === 0 && isUSA && <InfoBody row={rest.profile} entryKeys={entryKeys} />}
        {currentTab === 1 && (
          <Fragment>
            <AddAdminSubdomain profileId={profileId} closeModal={() => modalHide(name)} />
            {isDeveloper && (
              <CreateSuper
                closeModal={() => modalHide(name)}
                email={email}
                subdomain={subdomain}
                profileId={profileId}
                reloadProfiles={reloadProfiles}
                userRole={userRole}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </Modal>
  );
};

ProfileInfoModal.propTypes = {
  isDeveloper: PropTypes.bool,
  isStudentIdEnabled: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  modalHide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  nation: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  reloadProfiles: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default ProfileInfoModal;
