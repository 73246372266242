import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Header, PostingForm } from 'components';
import { Modal } from 'containers';

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  overflow: visible;

  @media (min-width: 1042px) {
    border-radius: 3px;
    width: 90rem;
  }
`;

const PostingModal = ({ initialValues, title, ...rest }) => {
  const [selectedApplicationType, setSelectedApplicationType] = useState(
    initialValues.application.type
  );
  return (
    <Modal name={rest.name} width={90}>
      <Wrapper>
        <Header type="headline" component="h2">
          {title}
        </Header>
        <PostingForm
          initialValues={initialValues}
          selectedApplicationType={selectedApplicationType}
          setSelectedApplicationType={setSelectedApplicationType}
          {...rest}
        />
      </Wrapper>
    </Modal>
  );
};

PostingModal.propTypes = {
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  title: PropTypes.string
};

export default PostingModal;
