import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { capital } from 'case';
import { AssessmentsBySchoolStatItem, Paginate, StatItem } from 'components';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const List = styled.ul`
  min-height: 18.5rem;
`;

const StatItems = ({
  resultKey,
  data,
  modals,
  modalShow,
  nation,
  subdomain,
  searchType,
  event,
  careerIdsToSlug,
  extraFilters
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * slices the data based on current page and how many we want to show per page
   * @method renderCurrentItems
   * @return {array} sliced data
   */
  const renderCurrentItems = () => {
    const lastIndexItem = currentPage * 5;
    const firstIndexItem = lastIndexItem - 5;

    const response = data ? data.slice(firstIndexItem, lastIndexItem) : [];
    return response;
  };

  /**
   * calculates the url for each of the stat cards
   * @method renderUrl
   * @param  {string}  key   the key to grab the correct data point
   * @param  {object}  field the object inside the array of data that comes from the keen response, grab the value from the object
   * @return {string}        returns the correct url
   */
  const renderUrl = (key, field) => {
    const domain = `https://${subdomain}.lightcastcc.com`;
    let url = {
      'career.title': `${domain}/careers/${field['career.titleSlug']}`,
      'program.name': `${domain}/programs/${field['program.slug']}/${field['program.ipeds']}`,
      referrer_url: field['long_url'],
      // filter out military search urls atm
      searchTerm: `${domain}/search/${searchType && searchType.toLowerCase()}?q=${
        field.searchTerm
      }`,
      // check to make sure that there is data on the page before we call the function to replace the url
      page_url: field['page_url'] && field['page_url'].replace('/jobs', '')
    };

    if (field['posting.title']) {
      const currentDate = new Date()
        .toISOString()
        .substring(0, 10)
        .replaceAll('-', '');
      const [endMonth, endDay, endYear] = field['posting.endOn'].split('-');
      const endedOn = [endYear, endMonth, endDay].join('');
      if (endedOn >= currentDate) {
        url['posting.title'] = `${domain}/work-opportunities?posting=${field['posting.postingId']}`;
      }
    }

    if (event === 'Favorites') {
      const type = extraFilters[0].property_value;
      if (type === 'careers' && careerIdsToSlug[field.id]) {
        url['name'] = `${domain}/careers/${careerIdsToSlug[field.id].titleSlug}`;
      } else if (type === 'programs') {
        url['name'] = `${domain}/programs/${field['id']}`;
      }
    }

    return url[key];
  };

  /**
   * renders the title for each item in the stat cards
   * @method renderTitle
   * @param  {string}    key   the key of the main value from the keen response
   * @param  {object}    field the keen response object inside the array of dataRun
   * @return {string}          returns a string of the title
   */
  const renderTitle = (key, field) => {
    let region = '';
    // append the province/state or country to the title
    if ([field['ip_geo_info.country']] && field['ip_geo_info.country'] !== 'United States') {
      // append the country
      region = `, ${field['ip_geo_info.country']}`;
    } else if (field['ip_geo_info.province']) {
      // append the state
      region = `, ${field['ip_geo_info.province']}`;
    }
    const title = {
      'ip_geo_info.city': `${field[key]}${region}`,
      // used on the top careers by job posting clicks
      // grab the career title slug from the url and title case it
      page_url: capital(field[key].split('/')[4]),
      'program.name': `${field[key]} - ${field['program.credential']}`,
      default: field[key]
    };

    return title[key] || title['default'];
  };

  const currentItems = renderCurrentItems();

  /**
   * takes the updated page and sets the internal state with currentPage
   * @method goToPage
   * @param  {Number} value the next page
   */
  const goToPage = value => {
    setCurrentPage(value);
  };

  return (
    <Wrapper>
      <List>
        {currentItems.map((field, index) => {
          const isAssessmentBySchool = !!field.starts;
          if (!isAssessmentBySchool) {
            return (
              <StatItem
                postingId={field['posting-id']}
                selectedApplicant={field['selected-applicant']}
                applicants={field['applicants']}
                modals={modals}
                modalShow={modalShow}
                nation={nation}
                key={`stat-${index}`}
                title={renderTitle(resultKey, field)}
                url={renderUrl(resultKey, field)}
                value={field.result}
                additionalValues={field.additionalValues}
              />
            );
          } else {
            return <AssessmentsBySchoolStatItem key={`stat-${index}`} {...field} />;
          }
        })}
      </List>
      {data && <Paginate total={data.length} limit={5} page={currentPage} goToPage={goToPage} />}
    </Wrapper>
  );
};

StatItems.propTypes = {
  careerIdsToSlug: PropTypes.object,
  data: PropTypes.array.isRequired,
  event: PropTypes.string,
  extraFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  groupBy: PropTypes.array,
  modals: PropTypes.object,
  modalShow: PropTypes.func,
  nation: PropTypes.string,
  resultKey: PropTypes.string,
  searchType: PropTypes.string,
  subdomain: PropTypes.string.isRequired
};

export default StatItems;
