import React from 'react';
import styled from 'styled-components';
import { ExternalLink } from 'components';
import image from '../../../images/board.png';

const Flex = styled.div`
  margin: 10rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  font-weight: bold;
  margin: 2rem 0 1rem;
`;

const Maintenance = () => (
  <Flex>
    <img src={image} />
    <Title>Sorry, we&apos;re currently under maintenance</Title>
    <div>
      Please check back in again shortly! You may check for updates on our{' '}
      <ExternalLink url="https://status.emsidata.com/">status page</ExternalLink>.
    </div>
  </Flex>
);

export default Maintenance;
