import { createSelector } from 'reselect';
import config from '../config';

const getSubdomain = (sites, profile) => {
  const { subdomain, adminSubdomains } = profile.customData;
  const isMultiAdmin = adminSubdomains && adminSubdomains.length > 1;

  if (isMultiAdmin) {
    return sites.currentSite || adminSubdomains[0];
  } else if (profile.isSuperAdmin) {
    return sites.currentSite || config.defaultSite;
  } else {
    return subdomain;
  }
};

export const selectSubdomain = createSelector(getSubdomain, subdomain => subdomain);
