import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { color } from '../../../styles';
import { isRequired, isExpiredDate, isUrl } from '../../../helpers/validation';
import {
  ApplicationTypeRadioGroup,
  Button,
  CareerSearch,
  DateSelect,
  DaySelect,
  FormInput,
  ItemsWrapper,
  JobTypeRadioGroup,
  LoadingButton,
  Margin,
  MonthSelect,
  RichTextEditor,
  SelectAutoComplete,
  YearSelect
} from 'components';

const { lightGray, darkerGray } = color;

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`;

const Column = styled.div`
  width: 30rem;
  margin-right: 2rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button:first-child {
    margin-right: 1rem;
  }
`;

const StyledItemsWrapper = styled(ItemsWrapper)`
  margin: 2rem 0 -2rem;
`;

class PostingForm extends React.Component {
  state = {
    isLoading: false
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  componentDidUpdate() {
    const { isCompleted, modalHide, name, history } = this.props;
    if (isCompleted) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ isLoading: false });
      history.push('/analytics/employers?employers=approved&postings=live');
      // close the modal after submit
      modalHide(name);
    }
  }

  render() {
    const {
      createPosting,
      employers,
      enabledJobTypes,
      handleSubmit,
      hasLot,
      modalHide,
      nation,
      subdomain,
      submitText,
      updatePosting
    } = this.props;
    const { isLoading } = this.state;
    const getValues = ({ endOn, careers, employerName, ...values }) => {
      const [{ name, location, id }] = employers.filter(employer => employer.name === employerName);
      const { description, ...rest } = values;
      return {
        ...rest,
        description: `<span class='ql-cc'>${description}</span>`,
        // The date selector component uses the format YYYY-MM-DD which is native to moment.js
        // and it seemed like too much work to change that
        endOn: moment(endOn).format('MM-DD-YYYY'),
        // send only the titleSlug and title
        careers: careers.map(({ titleSlug, humanizedTitle }) => ({
          titleSlug,
          humanizedTitle
        })),
        subdomain,
        employer: {
          name,
          location,
          id
        }
      };
    };

    return (
      <Wrapper>
        <form
          onSubmit={handleSubmit(values => {
            createPosting && createPosting(subdomain, getValues(values));
            updatePosting && updatePosting({ noEmail: true, ...getValues(values) });
            this.setState({ isLoading: true });
          })}
        >
          {createPosting && (
            <Margin value="0 0 3rem 0">
              <Field
                name="employerName"
                validate={[isRequired]}
                component={({ input: { onChange, value }, ...props }) => {
                  return (
                    <SelectAutoComplete
                      {...props}
                      items={employers
                        .filter(employer => employer.status === 'approved')
                        .map(employer => employer.name)
                        // the sort function makes sure that the sort is case-insensitive.  Otherwise it sorts all capitalized names first.
                        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))}
                      selectedValue={value}
                      ariaLabel="Select Employer"
                      placeholder="Select Employer"
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Margin>
          )}
          <Field name="title" label="Job Title" component={FormInput} validate={[isRequired]} />
          <Field
            name="description"
            label="Description"
            props={{ placeholder: 'Add a job description' }}
            validate={[isRequired]}
            component={RichTextEditor}
          />

          <Field name="jobType" component={JobTypeRadioGroup} enabledJobTypes={enabledJobTypes} />
          <Field
            name="application.type"
            aria-label="Application Instructions"
            component={props => (
              <ApplicationTypeRadioGroup
                handleChange={val => this.props.setSelectedApplicationType(val)}
                {...props}
              />
            )}
          />
          <Row>
            {this.props.selectedApplicationType !== 'instructions-only' && (
              <Column>
                <Field
                  name="application.buttonText"
                  label="Button Text"
                  aria-label="Button Text"
                  component={FormInput}
                  validate={
                    this.props.selectedApplicationType !== 'instructions-only'
                      ? [isRequired]
                      : () => false
                  }
                />
              </Column>
            )}
            {this.props.selectedApplicationType === 'employer-link' && (
              <Column>
                <Field
                  name="application.link"
                  label="Job Posting URL"
                  aria-label="Job Posting URL"
                  component={FormInput}
                  validate={
                    this.props.selectedApplicationType === 'employer-link' && [isUrl, isRequired]
                  }
                />
              </Column>
            )}
          </Row>
          <Field
            name="application.instructions"
            label={`Special Instructions ${
              this.props.selectedApplicationType !== 'instructions-only' ? '(optional)' : ''
            }`}
            aria-label={`Special Instructions ${
              this.props.selectedApplicationType !== 'instructions-only' ? '(optional)' : ''
            }`}
            props={{ component: 'textarea', placeholder: 'Add special instructions' }}
            validate={
              this.props.selectedApplicationType === 'instructions-only'
                ? [isRequired]
                : () => false
            }
            component={RichTextEditor}
          />
          <Field
            name="endOn"
            validate={[isExpiredDate]}
            component={({ input: { name, onChange, value }, meta }) => {
              return (
                <StyledItemsWrapper>
                  <DateSelect
                    label="This posting will expire after"
                    width="53%"
                    field={name}
                    handleDate={onChange}
                    initialDate={value}
                    error={meta.error}
                  >
                    <MonthSelect />
                    <DaySelect />
                    <YearSelect allowFuture numOfFutureYears={1} startYear={moment().year()} />
                  </DateSelect>
                </StyledItemsWrapper>
              );
            }}
          />
          <FieldArray
            name="careers"
            validate={[isRequired]}
            component={CareerSearch}
            props={{ hasLot, nation }}
          />

          <ButtonsWrapper>
            <Button
              type="button"
              color={darkerGray}
              background={lightGray}
              onClick={() => {
                modalHide(name);
              }}
            >
              Cancel
            </Button>
            <LoadingButton isLoading={isLoading} isDisabled={isLoading}>
              {submitText}
            </LoadingButton>
          </ButtonsWrapper>
        </form>
      </Wrapper>
    );
  }
}

PostingForm.propTypes = {
  createPosting: PropTypes.func,
  employers: PropTypes.array.isRequired,
  enabledJobTypes: PropTypes.array.isRequired,
  // handleSubmit is passed in from reduxForm
  handleSubmit: PropTypes.func.isRequired,
  hasLot: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      buttonText: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.string
    })
  }),
  isCompleted: PropTypes.bool,
  modalHide: PropTypes.func.isRequired,
  name: PropTypes.string,
  nation: PropTypes.string.isRequired,
  postingId: PropTypes.string,
  selectedApplicationType: PropTypes.string.isRequired,
  setSelectedApplicationType: PropTypes.func.isRequired,
  subdomain: PropTypes.string,
  submitText: PropTypes.string.isRequired,
  updatePosting: PropTypes.func
};

export default reduxForm({
  form: 'postingForm'
})(withRouter(PostingForm));
