import moment from 'moment';
import { capital } from 'case';
import { locale } from './locale';
import { sortDatesAndNotDates } from './sort';

const flatten = arr =>
  arr.reduce(
    (flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );

const initializeOrAddTo = (obj, key, value) => {
  if (obj[key]) {
    obj[key] += value;
  } else {
    obj[key] = value;
  }
};

const formatDailyVisitData = ({ data, nation }) =>
  flatten(
    data.map(item => {
      const { timeframe, value } = item;
      const readableDate = moment(timeframe.start).format(locale.date[nation]);
      const dailyItem = value.map(({ result, schoolName }) => ({
        result,
        schoolName,
        rowLabel: readableDate
      }));
      return dailyItem;
    })
  );

const calculateVisitTotals = data => {
  const totals = {};
  data.map(item => {
    const { value } = item;
    value.map(({ result, schoolName }) => {
      if (!schoolName || schoolName === '') {
        initializeOrAddTo(totals, 'Unknown', result);
      } else {
        initializeOrAddTo(totals, schoolName, result);
      }
    });
  });
  const totalWithRowLabel = Object.keys(totals).map(schoolName => ({
    result: totals[schoolName],
    schoolName,
    rowLabel: 'Total'
  }));
  return totalWithRowLabel;
};

export const normalizeVisitData = (data, nation) => {
  const totals = calculateVisitTotals(data);
  const resultsByDay = formatDailyVisitData({ data, nation });
  return [...totals, ...resultsByDay];
};

const assimilateDatums = (array, callback) =>
  array.map(item => {
    const { result, schoolName } = item;
    const rowLabel = callback(item);
    return {
      result,
      schoolName,
      rowLabel
    };
  });

// This function formats our data arrays so they can be
// handled generically afterwards.
// Each resulting object in the returned data array will have three properties:
// "result", "schoolName", and "rowLabel"
export const normalizeData = ({ data, label, nation }) => {
  if (data.length === 0) {
    return [];
  }
  if (label === 'Top Career Searches' || label === `Top ${locale.edTrack[nation]} Searches`) {
    return assimilateDatums(data, item => item.searchTerm);
  } else if (label === 'Top Viewed Careers') {
    return assimilateDatums(data, item => capital(item.page_url.split('/')[4]));
  } else if (label === `Top Viewed ${locale.edTrack[nation]}s`) {
    return assimilateDatums(data, item =>
      capital(`${item['program.name']}, ${item['program.credential']}`)
    );
  } else if (label === 'Visits' || label === 'Visitors') {
    return normalizeVisitData(data, nation);
  }
};

export const removeDuplicatesAndSort = (array, nation) =>
  array
    .filter((item, index) => array.indexOf(item) === index)
    .sort((a, b) => sortDatesAndNotDates(a, b, nation));

const createHeaders = ({ normalizedData, nation }) => {
  const rawColumnHeaders = normalizedData
    .map(datum => datum.schoolName)
    .filter(item => item && item !== 'Other' && item !== 'Unknown' && item != '');
  let columnHeaders = removeDuplicatesAndSort(rawColumnHeaders, nation);
  columnHeaders.push('Other', 'Unknown');
  return columnHeaders;
};

const addLabelColumn = ({ columnHeaders, label }) => {
  let columns = [];
  // The A1 cell in the "Visits" and "Visitors" worksheets should be empty
  if (label === 'Visits' || label === 'Visitors') {
    columns = ['', ...columnHeaders];
  } else {
    columns = [label, ...columnHeaders];
  }
  return columns;
};

const createColumns = ({ normalizedData, nation, label }) => {
  const columnHeaders = createHeaders({ normalizedData, nation, label });
  const columns = addLabelColumn({ columnHeaders, label });
  return columns;
};

const createLeaders = ({ normalizedData, nation }) => {
  const rawRowLeaders = normalizedData.map(datum => datum.rowLabel);
  return removeDuplicatesAndSort(rawRowLeaders, nation);
};

const initializeRows = ({ rowLeaders, columns }) =>
  rowLeaders.map(leader => [leader, ...new Array(columns.length - 1).fill(0)]);

const createRows = ({ columns, normalizedData, nation }) => {
  const rowLeaders = createLeaders({ normalizedData, nation });
  let rows = initializeRows({ rowLeaders, columns });

  normalizedData.map(normalizedDatum => {
    let schoolName = normalizedDatum.schoolName;

    if (!schoolName || schoolName === '' || schoolName === 'Unknown') {
      schoolName = 'Unknown';
    }

    const rowLabel = normalizedDatum.rowLabel;
    const columnIndex = columns.indexOf(schoolName);
    const rowIndex = rowLeaders.indexOf(rowLabel);
    rows[rowIndex][columnIndex] += normalizedDatum.result;
  });
  return rows;
};

const createColumnsAndRows = ({ data, label, nation }) => {
  const normalizedData = normalizeData({ data, label, nation });
  const columns = createColumns({ normalizedData, nation, label });
  const rows = createRows({ columns, normalizedData, nation });
  return { columns, rows };
};

export const formatData = (keenData, nation) => {
  const { columns, rows } = createColumnsAndRows({ ...keenData, nation });
  return [
    {
      columns,
      data: rows
    }
  ];
};
