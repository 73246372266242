import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Element } from 'components';

/**
 * Margin component
 * @type {[type]}
 */
const Margin = styled(Element)`
  margin: ${({ value }) => `${value}`};
`;

Margin.defaultProps = {
  component: 'div'
};

Margin.propTypes = {
  value: PropTypes.string.isRequired
};

export default Margin;
