import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Badge, Header, Table, Truncate } from 'components';
import { Modal } from 'containers';

const Wrapper = styled(Card)`
  border-radius: 0;
  margin-bottom: 0;
  @media (min-width: 1042px) {
    border-radius: 3px;
    width: 90rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Title = styled(Header)`
  margin-bottom: 5rem !important;
`;

const HiresModal = ({ hires, name, employerName }) => {
  return (
    <Modal name={name}>
      <Wrapper>
        <Title type="headline" component="h2">
          {employerName} Hires
        </Title>
        <Table
          data={hires}
          options={{
            defaultSortName: 'hireName',
            defaultSortOrder: 'asc'
          }}
          columns={[
            {
              isKey: true,
              dataField: 'hireName',
              text: 'Name',
              dataFormat: cell => (
                <NameWrapper>
                  <Truncate>{cell}</Truncate>
                </NameWrapper>
              )
            },
            {
              dataField: 'title',
              text: 'Job Title',
              dataFormat: cell => <Truncate>{cell}</Truncate>
            },
            {
              dataField: 'jobType',
              text: 'Type',
              dataFormat: cell => <Badge type={cell}>{cell}</Badge>
            }
          ]}
        />
      </Wrapper>
    </Modal>
  );
};

HiresModal.propTypes = {
  hires: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  employerName: PropTypes.string.isRequired
};

export default HiresModal;
