import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { withSiteHOC } from '../../../hocs/withSite';

const StyledButton = styled.button`
  background: ${({ background }) => background};
  border: ${({ border }) => (!!border ? border : 0)};
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  padding: 1.2rem 2rem;
  border-radius: 3px;

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
    :hover {
      box-shadow: none;
    }
  }

  :hover {
    background: ${({ background }) => darken(0.05, background)};
  }
`;

const Button = withSiteHOC()(({ styles, background, color, children, isInverted, ...props }) => {
  const { accentColor, buttonColor } = styles;

  const backgroundColor = background || (isInverted ? buttonColor : accentColor);
  const textColor = color || (isInverted ? accentColor : buttonColor);

  return (
    <StyledButton background={backgroundColor} color={textColor} {...props}>
      {children}
    </StyledButton>
  );
});

Button.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  isInverted: PropTypes.bool,
  styles: PropTypes.object
};

export default Button;
