import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormInput, Margin } from 'components';
import { withNationHOC } from '../../../hocs/withNation';
import { locale } from '../../../helpers/locale';
import { correlatingFieldCheck } from '../../../helpers/validation';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled.div`
  width: 48%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  width: 48%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ResponsiveMargin = styled(Margin)`
  @media (max-width: 800px) {
    margin-top: 6rem;
  }
`;

const maxButtonTextLength = 25;

export const CareerSettingsFormFields = withNationHOC(({ nation }) => {
  return (
    <div>
      <ResponsiveMargin value="0 0 1.5rem 0">
        No {locale.edTrack[nation]}s Found Options
      </ResponsiveMargin>
      <Field
        id="settings-description-text"
        // This text will be shown on a career page when that career has no programs associated with it.
        aria-label="Description text"
        label="Description text"
        placeholder="Enter text..."
        name="careerSettings.noProgramsText"
        component={FormInput}
        type="text"
      />
      <Container>
        <ButtonGroup>
          <LeftColumn>
            <Field
              id="button-1-text"
              aria-label="Button 1 text"
              label="Button 1 text"
              placeholder="Enter text..."
              name="careerSettings.noProgramsButtons.button1.text"
              component={FormInput}
              type="text"
              maxLength={maxButtonTextLength}
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button1.url')}
            />
          </LeftColumn>
          <RightColumn>
            <Field
              id="button-1-link"
              aria-label="Button 1 link"
              label="Button 1 link"
              placeholder="Enter URL..."
              name="careerSettings.noProgramsButtons.button1.url"
              component={FormInput}
              type="text"
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button1.text')}
            />
          </RightColumn>
        </ButtonGroup>
        <ButtonGroup>
          <LeftColumn>
            <Field
              id="button-2-text"
              aria-label="Button 2 text"
              label="Button 2 text"
              placeholder="Enter text..."
              name="careerSettings.noProgramsButtons.button2.text"
              component={FormInput}
              type="text"
              maxLength={maxButtonTextLength}
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button2.url')}
            />
          </LeftColumn>
          <RightColumn>
            <Field
              id="button-2-link"
              aria-label="Button 2 link"
              label="Button 2 link"
              placeholder="Enter URL..."
              name="careerSettings.noProgramsButtons.button2.url"
              component={FormInput}
              type="text"
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button2.text')}
            />
          </RightColumn>
        </ButtonGroup>
        <ButtonGroup>
          <LeftColumn>
            <Field
              id="button-3-text"
              aria-label="Button 3 text"
              label="Button 3 text"
              placeholder="Enter text..."
              name="careerSettings.noProgramsButtons.button3.text"
              component={FormInput}
              type="text"
              maxLength={maxButtonTextLength}
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button3.url')}
            />
          </LeftColumn>
          <RightColumn>
            <Field
              id="button-3-link"
              aria-label="Button 3 link"
              label="Button 3 link"
              placeholder="Enter URL..."
              name="careerSettings.noProgramsButtons.button3.url"
              component={FormInput}
              type="text"
              validate={correlatingFieldCheck('careerSettings.noProgramsButtons.button3.text')}
            />
          </RightColumn>
        </ButtonGroup>
      </Container>
    </div>
  );
});

export default CareerSettingsFormFields;
