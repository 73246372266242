import React from 'react';
import { connect } from 'react-redux';
import { WidgetStatisticsDownload } from 'components';
import { fetchAllCareers, fetchKeenData, fetchProgramByCode } from '../../services';
import { widgetStatisticsReportQueries as queries } from '../../services/keen/excel-export-queries';
import { processKeenResult } from '../../helpers/process-keen-result';

class WidgetStatisticsDownloadContainer extends React.Component {
  state = {
    data: {}
  };
  render() {
    return (
      <WidgetStatisticsDownload
        {...this.props}
        getData={params => this.loadManyKeenData(params)}
        {...this.state}
      />
    );
  }

  async loadManyKeenData({ hasLot, hasProgramWidget, nation, subdomain, timeframe }) {
    this.setState({ isLoaded: false, isLoading: true });
    const allResults = {};
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];
      const { resultKey } = query;
      const res = await fetchKeenData({
        subdomain,
        event_collection: query.event,
        timeframe,
        ...query
      });
      let newResult = res;
      if (query.key === 'widget-loads') {
        newResult = res;
      } else if (Array.isArray(res)) {
        newResult = processKeenResult(res, resultKey);
      }
      allResults[query.key] = newResult;
    }

    let programData;
    let careerData;

    if (hasProgramWidget) {
      const programCodes = allResults['widget-total-loads-by-programs'].map(
        event => event.programs
      );
      try {
        programData = await fetchProgramByCode({ code: programCodes, subdomain });
      } catch (error) {
        programData = {};
      }

      try {
        careerData = await fetchAllCareers({ hasLot, nation });
      } catch (error) {
        careerData = {};
      }
    }

    return this.setState({
      data: allResults,
      isLoaded: true,
      isLoading: false,
      careerData,
      programData
    });
  }
}

export const mapStateToProps = ({ sites, stats }) => ({
  hasAssessmentWidget:
    sites.items[sites.currentSite].widgetBuilderSettings.assessmentWidget.isEnabled,
  hasLot: sites.items[sites.currentSite].hasLot,
  hasProgramWidget: sites.items[sites.currentSite].widgetBuilderSettings.programWidget.isEnabled,
  nation: sites.items[sites.currentSite].nation,
  subdomain: sites.currentSite,
  timeframe: stats
});

export default connect(mapStateToProps)(WidgetStatisticsDownloadContainer);
