import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';

import { fetchInstitutions } from 'services';
import {
  HorizontalRule,
  WidgetBuilderColorPicker,
  WidgetBuilderGeography,
  WidgetBuilderToggle
} from 'components';

const Sidebar = styled.div`
  min-width: 35rem;
  height: 96.5rem;
  padding: 3rem;
  padding-left: 1rem;
  border-right: 2px solid #f0f1f3;
  overflow-y: auto;
`;

const Header = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 700;
`;

const Label = styled.label`
  margin: 0 0 2rem 0;
  font-weight: 600;
`;

const Select = styled(ReactSelect)`
  font-weight: 400;
`;

const AssessmentWidgetBuilderSidebar = props => {
  const { configurations, siteSettings, setConfigurations } = props;
  const { accentColor, hasCcLink, primaryColor } = configurations;
  const { currentSite, hasCcEnabled, hasManyInstitutions } = siteSettings;

  const [institutionSelectOptions, setInstitutionSelectOptions] = useState();

  const fetchInstitutionData = async () => {
    const institutionsResponse = await fetchInstitutions(currentSite);

    const newInstitutionSelectOptions = Object.values(institutionsResponse).map(
      ({ displayName, ipeds }) => ({
        label: displayName,
        value: ipeds
      })
    );

    setInstitutionSelectOptions([{ label: 'All', value: '' }, ...newInstitutionSelectOptions]);
  };

  useEffect(() => {
    if (hasManyInstitutions) {
      fetchInstitutionData();
    }
  }, []);

  return (
    <Sidebar>
      <Header>Widget Parameters</Header>
      <WidgetBuilderGeography
        {...props}
        label="Geography for Salary"
        subLabel="Select Location"
        hasHasAllLocations={false}
        hasHasNationalData={false}
      />
      {hasCcEnabled && (
        <Fragment>
          <HorizontalRule />
          <WidgetBuilderToggle
            label="Link To Career Coach"
            handleChange={() => setConfigurations({ ...configurations, hasCcLink: !hasCcLink })}
            isChecked={hasCcLink}
          />
          <HorizontalRule />
        </Fragment>
      )}
      {hasManyInstitutions && (
        <Fragment>
          <Label htmlFor="selected-institution">
            Select Institution
            <Select
              clearable={false}
              id="selected-institution"
              value={configurations.institutionId}
              onChange={option =>
                setConfigurations({
                  ...configurations,
                  institutionId: option.value
                })
              }
              options={institutionSelectOptions}
            />
          </Label>
          <HorizontalRule />
        </Fragment>
      )}
      <WidgetBuilderColorPicker
        label="Primary Color"
        defaultColor={primaryColor}
        handleChange={hex => setConfigurations({ ...configurations, primaryColor: hex })}
      />
      <HorizontalRule />
      <WidgetBuilderColorPicker
        label="Accent Color"
        defaultColor={accentColor}
        handleChange={hex => setConfigurations({ ...configurations, accentColor: hex })}
      />
    </Sidebar>
  );
};

AssessmentWidgetBuilderSidebar.propTypes = {
  configurations: PropTypes.shape({
    accentColor: PropTypes.string,
    hasCcLink: PropTypes.bool,
    primaryColor: PropTypes.string
  }).isRequired,
  currentSite: PropTypes.string,
  hasManyInstitutions: PropTypes.bool,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({
    hasCcEnabled: PropTypes.bool.isRequired
  }).isRequired
};

export default AssessmentWidgetBuilderSidebar;
