import { connect } from 'react-redux';
import { AssessmentStartsCompletionsGraph } from 'components';

export const mapStateToProps = ({ sites, stats }) => ({
  nation: sites.items[sites.currentSite].nation,
  subdomain: sites.currentSite,
  timeframe: stats
});

export default connect(mapStateToProps, null)(AssessmentStartsCompletionsGraph);
