import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { Button, ConfirmationModal, EmployersTable, Icon, Strong, Truncate } from 'components';
import { color } from '../../../styles';

const { green, red } = color;

const Table = styled(EmployersTable)`
  table {
    table-layout: auto;
  }
  /* first column heading and data */
  th:first-child,
  td:first-child {
    width: 20rem;
  }
  /* third column heading and data */
  th:nth-of-type(3),
  td:nth-of-type(3),
  th:nth-of-type(4),
  td:nth-of-type(4) {
    text-align: center !important;
    width: 7rem;
  }
  /* fourth column heading and data */
  th:nth-of-type(4) {
    text-align: right !important;
  }
  td:nth-of-type(4) {
    padding-right: 8px;
    text-align: right !important;
  }
`;

const PendingEmployers = ({ data, modalHide, modals, modalShow, updateEmployer }) => {
  return (
    <Table
      data={data}
      modals={modals}
      modalShow={modalShow}
      options={{
        defaultSortName: 'name',
        defaultSortOrder: 'asc'
      }}
      statusType="pending"
      tableColumns={[
        {
          dataField: 'location',
          text: 'Location',
          dataFormat: cell => <Truncate>{cell || '---'}</Truncate>
        },
        {
          dataField: null,
          text: 'Approve',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="checkmark"
                size="2"
                tabIndex="0"
                role="button"
                aria-label={`Approve Employer: ${row.name}`}
                color={green}
                onKeyDown={e => e.keyCode === 13 && modalShow(`approve${row.employerId}`)}
                onClick={() => modalShow(`approve${row.employerId}`)}
              />
              {modals[`approve${row.employerId}`] && (
                <ConfirmationModal
                  name={`approve${row.employerId}`}
                  heading="Approve Employer?"
                  modalHide={modalHide}
                  text={
                    <Fragment>
                      Do you wish to approve <Strong>{row.name}</Strong> to publish job postings to
                      your Career Coach site? <br /> All postings will require your approval before
                      being published.
                    </Fragment>
                  }
                  confirmationButton={
                    <Button
                      onClick={() => {
                        updateEmployer({
                          employerId: row.employerId,
                          email: row.email,
                          subdomain: row.subdomain,
                          status: 'approved'
                        });
                        modalHide(`approve${row.employerId}`);
                      }}
                    >
                      Approve
                    </Button>
                  }
                />
              )}
            </Fragment>
          )
        },
        {
          dataField: null,
          text: 'Deny',
          dataSort: false,
          dataFormat: (_, row) => (
            <Fragment>
              <Icon
                type="close"
                tabIndex="0"
                size="2"
                role="button"
                aria-label={`Deny Employer: ${row.name}`}
                color={red}
                onKeyDown={e => e.keyCode === 13 && modalShow(`deny${row.employerId}`)}
                onClick={() => modalShow(`deny${row.employerId}`)}
              />
              <ConfirmationModal
                name={`deny${row.employerId}`}
                heading="Deny Employer?"
                modalHide={() => modalHide(`deny${row.employerId}`)}
                deny
                text={
                  <Fragment>
                    Do you wish to deny <Strong>{row.name}</Strong> and block them from posting on
                    your Career Coach site? <br /> The employer may edit their information and
                    request approval again.
                  </Fragment>
                }
                confirmationButton={
                  <Button
                    color="#ffffff"
                    background={red}
                    onClick={message => {
                      updateEmployer({
                        employerId: row.employerId,
                        email: row.email,
                        subdomain: row.subdomain,
                        status: 'denied',
                        message
                      });
                      modalHide(`deny${row.employerId}`);
                    }}
                  >
                    Deny
                  </Button>
                }
              />
            </Fragment>
          )
        }
      ]}
    />
  );
};

PendingEmployers.propTypes = {
  data: PropTypes.object.isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  updateEmployer: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(PendingEmployers);
