import { connect } from 'react-redux';
import { updateStatsDates } from '../../actions/actionCreators';
import DateRangePicker from '../../components/organisms/DateRangePicker';

export const mapStateToProps = ({ profile, stats }) => ({ 
  isSuperAdmin: profile.isSuperAdmin,
  startDate: stats.start,
  endDate: stats.end
});

export default connect(mapStateToProps, {
  updateStatsDates
})(DateRangePicker);