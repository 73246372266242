import React from 'react';
import { connect } from 'react-redux';
import { DownloadButton } from 'components';
import { downloadResume } from '../../actions/actionCreators';
import { withNationHOC } from '../../hocs/withNation';

const ResumeDownloadContainer = withNationHOC(props => (
  <DownloadButton {...props} />
));

export const mapDispatchToProps = dispatch => ({
  download: payload => dispatch(downloadResume(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(ResumeDownloadContainer);
