import { filter, map } from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';

const getPostings = state => state.postings.items;

export const selectPostings = createSelector(getPostings, postings =>
  map(
    posting => ({
      ...posting,
      employerName: posting.employer.name
    }),
    postings
  )
);

export const selectPostingsByStatusAndDate = (state, type, momentType) =>
  filter(
    posting =>
      posting.status === type && moment()[momentType](moment(posting.endOn, 'MM-DD-YYYY'), 'day'),
    selectPostings(state)
  );

export const selectEndedPostings = state =>
  filter(
    posting =>
      posting.status !== 'denied' && moment().isAfter(moment(posting.endOn, 'MM-DD-YYYY'), 'day'),
    selectPostings(state)
  );
