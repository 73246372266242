import { connect } from 'react-redux';
import { ProgramWidgetTopActivityTable } from 'components';

const mapStateToProps = ({ sites, stats }) => {
  const { nation } = sites.items[sites.currentSite];

  return {
    nation,
    subdomain: sites.currentSite,
    timeframe: stats
  };
};

export default connect(mapStateToProps)(ProgramWidgetTopActivityTable);
