import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Hint } from 'components';
import { color } from 'styles';

const { gray, sapphireD40 } = color;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const TopActivityCard = styled(Card)`
  background-color: ${gray};
`;

const Wrapper = styled.div`
  text-align: center;
  min-height: 10rem;
`;

const Text = styled.div`
  ${({ fontSize }) => !!fontSize && `font-size: ${fontSize};`};
  font-weight: 700;
  line-height: 2.7rem;
  ${({ textColor }) => !!textColor && `color: ${textColor};`};
  ${({ margin }) => !!margin && `margin: ${margin};`};
`;

const WidgetMetric = ({ data, heading, hintId, hintMessage, loaded }) => {
  return (
    <TopActivityCard>
      <Wrapper>
        <Content>
          <Text fontSize={"1.6rem"} margin={"0 0 1.25rem 0"} >
            {heading}
          </Text>
            {hintMessage && <Hint id={hintId}>{hintMessage}</Hint>}
        </Content>
        {!loaded && <Text>Loading...</Text>}
        {loaded && <Text textColor={sapphireD40} fontSize={"3rem"} >{data}</Text>}
      </Wrapper>
    </TopActivityCard>
  );
};

WidgetMetric.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  heading: PropTypes.string.isRequired,
  hintId: PropTypes.string,
  hintMessage: PropTypes.string,
  loaded: PropTypes.bool
};

export default WidgetMetric;