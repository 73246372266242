import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../../styles';

const { darkGray, sapphireD20 } = color;

const StyledIcon = styled(({ icon: Icon, isSelected, ...props }) => <Icon {...props} />)`
  position: relative;
  top: 1px;
  fill: ${({ isSelected }) => (isSelected ? 'white' : darkGray)};
`;

const StyledButton = styled.button`
  width: 4.5rem;
  height: 4.5rem;
  margin-left: 1rem;
  border: none;
  border-radius: 3px;
  background: ${({ isSelected }) => (isSelected ? sapphireD20 : 'white')};

  :hover {
    background: ${sapphireD20};
  }

  &:hover ${StyledIcon} {
    fill: white;
  }
`;

const ScreenSizeButton = ({ icon, handleClick, previewSize, size }) => {
  return (
    <StyledButton
      aria-label={`change widget preview size to ${size} pixels`}
      isSelected={previewSize === size}
      onClick={() => handleClick(size)}
    >
      <StyledIcon isSelected={previewSize === size} icon={icon} />
    </StyledButton>
  );
};

ScreenSizeButton.propTypes = {
  icon: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  previewSize: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired
};

export default ScreenSizeButton;
