import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { lifecycle } from 'recompose';
import { BoldLabel, Margin, MultiSelectOption, Message } from 'components';
import { color } from '../../../styles';

const Wrapper = styled(Margin)`
  position: relative;

  .Select-control {
    .Select-multi-value-wrapper {
      max-height: 25rem;
      overflow-y: auto;
      width: 100%;

      .Select-value {
        color: ${color.black};
      }
    }
  }
  .Select-input {
    margin-left: 4px;
  }
  .Select-input > input {
    padding: 8px 0;
    cursor: none;
  }
  .Select-placeholder {
    color: ${color.darkGray};
  }
`;

const ErrorMessage = styled(Message)`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  position: absolute;
  right: 0;
`;

const FormSelect = lifecycle({
  componentDidUpdate(prevProps) {
    // if the items change, reset the selected value
    // deep equal check since we are dealing with objects with the same keys
    if (JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items)) {
      this.props.input.onChange(null);
    }
  }
})(
  ({
    items,
    multi = false,
    loadOptions = () => {},
    label,
    disabled,
    meta: { error, touched },
    input: { value, onFocus, onBlur, onChange, name },
    className,
    placeholder = '',
    isSearchable = true
  }) => {
    let options;
    let Select;
    if (items) {
      options = Object.values(items).map(item => ({
        value: item.id,
        label: item.name
      }));
      Select = ReactSelect;
    } else {
      Select = ReactSelect.Async;
    }
    const hasError = error && touched;
    return (
      <Wrapper value="0 0 3rem 0" className={className}>
        <BoldLabel name={name} label={label} />
        <Select
          style={hasError ? { borderColor: 'red' } : null}
          tabIndex="0"
          placeholder={placeholder}
          multi={multi}
          loadOptions={loadOptions}
          disabled={disabled}
          options={options}
          ignoreCase
          searchable={isSearchable}
          inputProps={{ id: name }}
          autoload={false}
          cache={false}
          arrowRenderer={() => false}
          clearable={false}
          value={value}
          valueComponent={({ children, onRemove, value: selectValue }) => {
            if (onRemove) {
              return (
                <MultiSelectOption onRemove={() => onRemove(selectValue)}>
                  {children}
                </MultiSelectOption>
              );
            }
            return <div className="Select-value">{children}</div>;
          }}
          onFocus={onFocus}
          onBlur={() => onBlur(value.value ? value : value.label)}
          onChange={newValue => onChange(newValue)}
        />
        {hasError && <ErrorMessage type="error">{error}</ErrorMessage>}
      </Wrapper>
    );
  }
);

FormSelect.propTypes = {
  loadOptions: PropTypes.func,
  multi: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  items: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool
};

export default FormSelect;
