import { get, post, patch, formatRequest } from '../utils';
import { getCognitoToken } from '../';
/**
 * fetchSites makes a call to the sites API for all available sites
 * @return {function}
 */
export const fetchSites = () =>
  get('/api/cc-api/sites?fields=name,subdomain,subdomain-aliases,widget-builder-settings');

export const fetchSite = async subdomain =>
  get(
    `/api/cc-api/sites/${subdomain}`,
    null,
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );

/**
 * POST call to create the site
 * @method createSite
 * @param  {object}   data the payload
 * @return {function}       post call with the endpoint and payload
 */
export const createSite = async data => {
  const site = formatRequest(data, 'site', data.subdomain);
  return post(
    '/api/cc-api/sites',
    site,
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );
};

/**
 * patchSite makes a call to the sites API
 * @param {string} siteId
 * @return {object} response
 */
export const patchSite = async data => {
  const site = formatRequest(data, 'site', data.subdomain);
  return patch(
    `/api/cc-api/sites/${data.subdomain}`,
    site,
    new Headers({
      Authorization: `Bearer ${await getCognitoToken()}`
    })
  );
};
