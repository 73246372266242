import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { color } from '../../../styles';
const { sapphireD20 } = color;

const ColorButton = styled.button`
  background: none;
  border: none;
  color: ${sapphireD20} !important;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  height: 2.5rem;
  line-height: 26.25px;
  margin-top: 1rem;
`;

const ColorDiv = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 3px;
  height: 2.5rem;
  margin-right: 1rem;
  width: 7.5rem;
`;

const PickerDiv = styled.div`
  height: 34rem;
  margin-top: 0.5rem;
`;

const ColorPicker = ({ handleChange, id, pickedColor }) => {
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  return (
    <Fragment>
      <ColorButton
        onClick={() => {
          setIsColorPickerVisible(!isColorPickerVisible);

          if (!isColorPickerVisible) {
            setTimeout(
              () =>
                document.getElementById(id).scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start'
                }),
              10
            );
          }
        }}
      >
        <ColorDiv backgroundColor={pickedColor} />{' '}
        {isColorPickerVisible ? 'Close color picker' : 'Click to edit color'}
      </ColorButton>
      {isColorPickerVisible && (
        <PickerDiv id={id}>
          <SketchPicker
            color={pickedColor}
            onChangeComplete={({ hex }) => {
              handleChange(hex);
            }}
          />
        </PickerDiv>
      )}
    </Fragment>
  );
};

ColorPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  pickedColor: PropTypes.string.isRequired
};

export default ColorPicker;
