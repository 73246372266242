import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchProfilesById, toggleUserRole } from '../../../services';
import { Button, ConfirmationModal, Strong } from 'components';

const LocalAdminConfirmationModal = ({
  cell,
  modalHide,
  nation,
  red,
  reloadProfiles,
  row,
  subdomain
}) => {
  const [isMultiAdmin, setIsMultiAdmin] = useState('loading');

  const checkForMultiAdmin = async () => {
    const profiles = await fetchProfilesById(row.profileId);
    const isMulti = profiles.filter(({ userRole }) => userRole === 'admin').length > 1;
    setIsMultiAdmin(isMulti);
  };

  useEffect(() => {
    checkForMultiAdmin();
  }, []);

  if (isMultiAdmin === 'loading') {
    return null;
  }

  const changeUserRole = async (checked, id, email, name) => {
    const userRole = checked ? '' : 'admin';
    const isSuccessful = await toggleUserRole({ subdomain, id, email, userRole });
    if (isSuccessful) {
      reloadProfiles();
      modalHide(name);
    }
  };

  if (isMultiAdmin) {
    return (
      <ConfirmationModal
        name={`admin${row.profileId}`}
        heading={`Cannot ${cell ? 'Revoke' : 'Grant'} Admin Access`}
        modalHide={modalHide}
        text={
          <Fragment>
            {`The user ${
              nation === 'us' ? row.name : row.email
            } has admin permissions on multiple subdomains. 
            Please remove additional subdomain permissions before removing
            admin permission for this subdomain.`}
          </Fragment>
        }
      />
    );
  }

  return (
    <Fragment>
      {cell && (
        <ConfirmationModal
          name={`admin${row.profileId}`}
          heading={`${cell ? 'Revoke' : 'Grant'} Admin Access?`}
          modalHide={modalHide}
          text={
            <Fragment>
              Admin access will be revoked for{' '}
              <Strong>{nation === 'us' ? row.name : row.email}</Strong>
            </Fragment>
          }
          confirmationButton={
            <Button
              color="#ffffff"
              background={red}
              onClick={() =>
                changeUserRole(cell, row.profileId, row.email, `admin${row.profileId}`)
              }
            >
              Yes
            </Button>
          }
        />
      )}
      {!cell && (
        <ConfirmationModal
          name={`admin${row.profileId}`}
          heading={`${cell ? 'Revoke' : 'Grant'} Admin Access?`}
          modalHide={modalHide}
          text={
            <Fragment>
              Admin access will be granted to{' '}
              <Strong>{nation === 'us' ? row.name : row.email}</Strong>
            </Fragment>
          }
          confirmationButton={
            <Button
              onClick={() =>
                changeUserRole(cell, row.profileId, row.email, `admin${row.profileId}`)
              }
            >
              Yes
            </Button>
          }
        />
      )}
    </Fragment>
  );
};

LocalAdminConfirmationModal.propTypes = {
  cell: PropTypes.string,
  modalHide: PropTypes.func.isRequired,
  nation: PropTypes.string.isRequired,
  red: PropTypes.string.isRequired,
  reloadProfiles: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default LocalAdminConfirmationModal;
