import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StatisticsDownload } from 'components';
import { fetchKeenData } from '../../services';
import { statisticsReportQueries as queries } from '../../services/keen/excel-export-queries';
import { processKeenResult } from '../../helpers/process-keen-result';
import {
  selectHasJobPostings,
  selectHasPrograms,
  selectRequestEmail,
  selectResumeBuilder
} from '../../selectors/sites';

class StatisticsDownloadContainer extends React.Component {
  state = {
    data: {}
  };
  render() {
    StatisticsDownloadContainer.propTypes = {
      fetchPostingAnalytics: PropTypes.func
    };

    return (
      <StatisticsDownload
        {...this.props}
        getData={params => {
          this.loadManyKeenData(params);
        }}
        {...this.state}
      />
    );
  }

  async loadManyKeenData({ subdomain, timeframe }) {
    this.setState({ isLoaded: false, isLoading: true });

    const allResults = {};
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];
      const { resultKey } = query;
      const res = await fetchKeenData({
        subdomain,
        event_collection: query.event,
        timeframe,
        ...query
      });
      let newResult = res;
      if (query.key === 'visits' || query.key === 'visitors') {
        newResult = res;
      } else if (Array.isArray(res)) {
        newResult = processKeenResult(res, resultKey);
      }
      allResults[query.key] = newResult;
    }
    return this.setState({
      data: allResults,
      isLoaded: true,
      isLoading: false
    });
  }
}

export const mapStateToProps = ({ sites, stats }) => ({
  employerPortal: sites.items[sites.currentSite].employerPortal,
  hasJobPostings: selectHasJobPostings(sites, sites.currentSite),
  hasPrograms: selectHasPrograms(sites, sites.currentSite),
  requestEmail: selectRequestEmail(sites, sites.currentSite),
  resumeBuilder: selectResumeBuilder(sites, sites.currentSite),
  subdomain: sites.currentSite,
  timeframe: stats
});

export { StatisticsDownloadContainer as PureStatisticsDownloadContainer }; // used in tests

export default connect(mapStateToProps, null)(StatisticsDownloadContainer);
