import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Card, Float, Margin } from 'components';

const StyledCard = styled(Card)`
  overflow: visible;
`;

const Header = styled.h2`
  font-size: 2.1rem;
`;

const HeaderRow = styled(Margin)`
  @media (min-width: 420px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const FormLayout = ({ title, submitForm, pristine, submitting, reset, disabled, children }) => {
  return (
    <Margin value="3rem 0 0 0">
      <StyledCard>
        <form onSubmit={submitForm}>
          <HeaderRow value="0 0 3rem 0">
            <Header component="span" type="title">
              {title}
            </Header>
            <Float component="span" value="right">
              <Margin component="span" value="0 1rem 0 0">
                <Button type="button" disabled={pristine || submitting} onClick={reset}>
                  Reset
                </Button>
              </Margin>
              <Button type="submit" disabled={disabled} id="save-button">
                Save
              </Button>
            </Float>
          </HeaderRow>
          {children}
        </form>
      </StyledCard>
    </Margin>
  );
};

FormLayout.propTypes = {
  title: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default FormLayout;
