import React from 'react';
import PropTypes from 'prop-types';
import createHistory from 'history/createBrowserHistory';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { sendKeenStats } from './actions/actionCreators';

import {
  App,
  AssessmentWidgetBuilder,
  Login,
  Programs,
  ProgramWidgetBuilder,
  SignUp,
  WidgetBuilderDashboard
} from 'containers';
import { AddSite, Analytics, ChangeSite, Maintenance, ONET, Settings } from 'components';

// Redirect to the login page if the user is not logged in
const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.profile.loggedIn,
  wrapperDisplayName: 'IsAuthenticated'
});

// Redirect to whichever page the user was trying to access previously, after the user logs in
// The redirect url will be in the format of "/login?redirect=%2Fsettings%2Fgeneral"
// If no redirect url exists, redirect to default home page ("/analytics/settings")
const locationHelper = locationHelperBuilder({});
const notAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/analytics/statistics',
  allowRedirectBack: false,
  authenticatedSelector: state => !state.profile.loggedIn,
  wrapperDisplayName: 'NotAuthenticated'
});

// widget builder determiners
const determineIsProgramWidgetEnabled = state => {
  const { sites } = state;
  const { currentSite } = sites;
  const isProgramWidgetEnabled =
    state.sites.items[currentSite].widgetBuilderSettings.programWidget.isEnabled;
  return isProgramWidgetEnabled;
};
const determineIsAssessmentWidgetEnabled = state => {
  const { sites } = state;
  const { currentSite } = sites;
  const isAssessmentWidgetEnabled =
    state.sites.items[currentSite].widgetBuilderSettings.assessmentWidget.isEnabled;
  return isAssessmentWidgetEnabled;
};

const hasAssessmentWidgetBuilder = connectedRouterRedirect({
  redirectPath: '/widget-builder',
  authenticatedSelector: state => determineIsAssessmentWidgetEnabled(state),
  wrapperDisplayName: 'hasAssessmentWidgetBuilder'
});
const hasProgramWidgetBuilder = connectedRouterRedirect({
  redirectPath: '/widget-builder',
  authenticatedSelector: state => determineIsProgramWidgetEnabled(state),
  wrapperDisplayName: 'hasProgramWidgetBuilder'
});

// if the subdomain has only the Assessment Widget,
// redirect them to the Assessment Widget Builder
const hasNotOnlyAssessmentWidgetBuilder = connectedRouterRedirect({
  redirectPath: '/widget-builder/assessment',
  authenticatedSelector: state => {
    const isProgramWidgetEnabled = determineIsProgramWidgetEnabled(state);
    const isAssessmentWidgetEnabled = determineIsAssessmentWidgetEnabled(state);
    return !(isAssessmentWidgetEnabled & !isProgramWidgetEnabled);
  },
  wrapperDisplayName: 'hasNotOnlyAssessmentWidgetBuilder'
});

// if the subdomain has only the Program Widget,
// redirect them to the Program Widget Builder
const hasNotOnlyProgramWidgetBuilder = connectedRouterRedirect({
  redirectPath: '/widget-builder/program',
  authenticatedSelector: state => {
    const isAssessmentWidgetEnabled = determineIsAssessmentWidgetEnabled(state);
    const isProgramWidgetEnabled = determineIsProgramWidgetEnabled(state);

    return !(isProgramWidgetEnabled & !isAssessmentWidgetEnabled);
  },
  wrapperDisplayName: 'hasNotOnlyProgramWidgetBuilder'
});

const isSuperAdmin = connectedRouterRedirect({
  redirectPath: '/analytics/statistics',
  authenticatedSelector: state => state.profile.isSuperAdmin,
  wrapperDisplayName: 'IsSuperAdmin'
});

const history = createHistory();

class RoutesLayout extends React.Component {
  componentDidMount() {
    const { store } = this.props;
    store.dispatch(sendKeenStats('Page Views'));
    history.listen(() => {
      store.dispatch(sendKeenStats('Page Views'));
    });
  }
  render() {
    // maintenance mode
    if (false) {
      return <Maintenance />;
    }
    return (
      <Router history={history}>
        <App>
          <Switch>
            <Route path="/add-site" component={isAuthenticated(isSuperAdmin(AddSite))} />
            <Route path="/change-site" component={isAuthenticated(isSuperAdmin(ChangeSite))} />
            <Redirect exact from="/analytics" to="/analytics/statistics" />

            <Route
              path="/widget-builder/assessment"
              component={isAuthenticated(hasAssessmentWidgetBuilder(AssessmentWidgetBuilder))}
            />
            <Route
              path="/widget-builder/program"
              component={isAuthenticated(hasProgramWidgetBuilder(ProgramWidgetBuilder))}
            />
            <Route
              path="/widget-builder"
              component={isAuthenticated(
                hasNotOnlyProgramWidgetBuilder(
                  hasNotOnlyAssessmentWidgetBuilder(WidgetBuilderDashboard)
                )
              )}
            />

            <Route path="/analytics" component={isAuthenticated(Analytics)} />
            <Redirect exact from="/programs" to="/programs/general" />
            <Route path="/programs" component={isAuthenticated(Programs)} />
            <Redirect exact from="/settings" to="/settings/general" />
            <Route path="/settings" component={isAuthenticated(isSuperAdmin(Settings))} />
            <Route path="/login" component={notAuthenticated(Login)} />
            <Route path="/sign-up" component={notAuthenticated(SignUp)} />
            <Route path="/onet" component={ONET} />
            <Redirect to="/analytics/statistics" />
          </Switch>
        </App>
      </Router>
    );
  }
}

RoutesLayout.propTypes = {
  store: PropTypes.any
};

export default RoutesLayout;
