import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Subtitle = styled.span`
  font-style: italic;
  color: #666;
`;

class SelectOption extends React.Component {
  render() {
    const { option, onFocus, isFocused, className, children } = this.props;
    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={e => onFocus(option, e)}
        onMouseMove={e => !isFocused && onFocus(option, e)}
        title={option.title}
      >
        {children} <br />
        <Subtitle>
          {option.value}
          .lightcastcc.com
        </Subtitle>
      </div>
    );
  }
  handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelect(this.props.option, e);
  };
}

SelectOption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired
};

export default SelectOption;
