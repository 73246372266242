import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentWrap, SubNav, PageHeader, BackGround } from 'components';
import theme from '../../../styles/theme';

const StyledLayout = styled.div`
  background-color: ${theme.menuBackgroundColor};
`;

const Layout = ({ title, children, navigation }) => {
  return (
    <StyledLayout>
      <ContentWrap styleName="menu-font-color">
        <PageHeader>{title}</PageHeader>
      </ContentWrap>
      {navigation && <SubNav navigation={navigation} hasPadding />}
      <BackGround>{children}</BackGround>
    </StyledLayout>
  );
};

Layout.propTypes = {
  navigation: PropTypes.array,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Layout;
