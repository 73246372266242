import * as types from '../actions/constants';

const defaultState = {
  fetching: false,
  loaded: false,
  error: false,
  items: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_EMPLOYERS_REQUEST:
      return {
        ...state,
        fetching: true,
        loaded: false,
        error: false
      };

    case types.FETCH_EMPLOYERS_SUCCESS:
      return {
        ...state,
        items: {
          ...action.payload
        },
        fetching: false,
        loaded: true,
        error: false
      };
    case types.UPDATE_EMPLOYER_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.employerId]: {
            ...action.payload
          }
        },
        fetching: false,
        loaded: true,
        error: false
      };

    default:
      return state;
  }
}
