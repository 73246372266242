import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  createProfile,
  deleteProfile,
  fetchProfilesById,
  updateCognitoMultiAdminSubdomains
} from '../../../services';
import { Button } from 'components';

const Wrapper = styled.div`
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  padding: 5rem;
  border-radius: 0 0 3px 3px;
`;

const AdditionalSubdomains = styled.div`
  min-height: 6.5rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const PillsWrapper = styled.div`
  margin-top: 0.5rem;
`;

const Pill = styled.button`
  height: 2.5rem;
  background: white;
  padding: 0.1rem 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border: 2px solid white;
  border-radius: 2px;
  font-size: 1.4rem;
  cursor: pointer;
`;

const CloseIcon = styled.span`
  margin-right: 1rem;
`;

const AddSubdomainsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
`;
const SubdomainsTextArea = styled.textarea`
  min-height: 20rem;
  margin-top: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 3px 3px 0 0;
  padding: 2rem;
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 3px 3px;
`;

const AddButton = styled.button`
  height: 3.5rem;
  margin-left: 1.5rem;
  border: none;
  background: none;
  color: #2576c1;
  font-size: 1.4rem;
  cursor: pointer;
`;

const PlusIcon = styled.span`
  position: relative;
  top: 2px;
  margin-right: 1rem;
  font-size: 2rem;
`;

const ErrorMessage = styled.div`
  color: #d62424;
`;

const CloseButton = styled(Button)`
  margin-top: 2rem;
  align-self: flex-end;
`;

const AddAdminSubdomain = ({ closeModal, currentSite, profileId, validSubdomains }) => {
  const [additionalSubdomains, setAdditionalSubdomains] = useState([]);
  const [baseProfileAttributes, setBaseProfileAttributes] = useState();
  const [validationErrors, setValidationErrors] = useState([]);
  const [apiErrors, setApiErrors] = useState([]);

  const setBaseProfile = profiles => {
    const { createdAt, email, familyName, givenName, lastLogin } = profiles.filter(
      ({ subdomain }) => subdomain === currentSite
    )[0];
    setBaseProfileAttributes({
      'created-at': createdAt,
      email,
      'family-name': familyName,
      'given-name': givenName,
      'last-login': lastLogin,
      'profile-id': profileId
    });
  };

  const getListOfAdminSubdomains = async id => {
    const profiles = await fetchProfilesById(id);
    setBaseProfile(profiles);
    const adminSubdomains = profiles
      .filter(({ userRole }) => userRole === 'admin')
      .map(({ subdomain }) => subdomain)
      .filter(subdomain => {
        return subdomain !== currentSite;
      });
    setAdditionalSubdomains(adminSubdomains);
    return adminSubdomains;
  };

  const deleteAdminProfile = async subdomain => {
    await deleteProfile({ profileId, subdomain });
    const listOfAdminSubdomains = await getListOfAdminSubdomains(profileId);

    const { email } = baseProfileAttributes;
    const multiAdminSubdomainsString = listOfAdminSubdomains.join();
    const updateAdditionalSubdomainsResponse = await updateCognitoMultiAdminSubdomains(
      email,
      multiAdminSubdomainsString
    );
    let newApiErrors = [];
    if (updateAdditionalSubdomainsResponse.status !== 204) {
      newApiErrors.push(
        `Network Error: attempt to remove ${subdomain} from user additional subdomains failed`
      );
    }
    setApiErrors(newApiErrors);
  };

  const addSubdomains = async () => {
    setValidationErrors([]);
    setApiErrors([]);
    const textAreaValue = document.getElementById('add-subdomains').value;
    const submittedSubdomains = textAreaValue.split(/\s/).filter(value => !!value);
    const invalidSubdomains = submittedSubdomains.filter(
      subdomain => !validSubdomains.includes(subdomain)
    );

    if (invalidSubdomains.length) {
      const newValidationErrors = invalidSubdomains.map(
        invalidSubdomain => `${invalidSubdomain} is not a valid subdomain`
      );
      setValidationErrors(newValidationErrors);
    } else {
      const filteredSubdomains = submittedSubdomains.filter(subdomain => {
        const isNotAlreadyAdmin = !additionalSubdomains.includes(subdomain);
        return isNotAlreadyAdmin;
      });
      const subdomainsToAdd = filteredSubdomains.filter((item, index) => {
        const isNotDuplicateItem = filteredSubdomains.indexOf(item) === index;
        return isNotDuplicateItem;
      });

      const newApiErrors = [];
      for (let i = 0; i < subdomainsToAdd.length; i++) {
        const subdomain = subdomainsToAdd[i];
        const data = {
          data: {
            id: profileId,
            type: 'profile',
            attributes: {
              ...baseProfileAttributes,
              subdomain,
              'user-role': 'admin'
            }
          }
        };
        const createProfileResponse = await createProfile({ subdomain, data });
        if (createProfileResponse === 'error') {
          newApiErrors.push(
            `Network Error: attempt to add ${subdomain} to admin permissions failed`
          );
        }
      }

      const { email, subdomain } = baseProfileAttributes;

      const multiAdminSubdomainsString = additionalSubdomains.concat(subdomainsToAdd).join();
      const updateAdditionalSubdomainsResponse = await updateCognitoMultiAdminSubdomains(
        email,
        multiAdminSubdomainsString
      );
      if (updateAdditionalSubdomainsResponse.status !== 204) {
        newApiErrors.push(
          `Network Error: attempt to add ${subdomain} to user additional subdomains failed`
        );
      }

      setApiErrors(newApiErrors);
      getListOfAdminSubdomains(profileId);

      if (!newApiErrors.length) {
        document.getElementById('add-subdomains').value = '';
      }
    }
  };

  useEffect(() => {
    getListOfAdminSubdomains(profileId);
  }, []);

  return (
    <Wrapper>
      {!!additionalSubdomains.length && (
        <AdditionalSubdomains>
          Additional Subdomains
          <PillsWrapper>
            {additionalSubdomains.map(subdomain => (
              <Pill
                aria-label={`remove admin permission for ${subdomain}`}
                onClick={() => deleteAdminProfile(subdomain)}
                key={`admin-list-${subdomain}`}
              >
                <CloseIcon className="ion-android-close" />
                {subdomain}
              </Pill>
            ))}
          </PillsWrapper>
        </AdditionalSubdomains>
      )}

      <AddSubdomainsWrapper>
        Add Subdomains (separated by line)
        <SubdomainsTextArea
          id="add-subdomains"
          aria-label="enter subdomains to add to admin permissions"
        />
        <AddButtonWrapper>
          <AddButton onClick={addSubdomains} aria-label="add admin permissions">
            <PlusIcon className="ion-android-add" />
            Add Subdomains
          </AddButton>
        </AddButtonWrapper>
      </AddSubdomainsWrapper>

      {validationErrors.map((message, i) => (
        <ErrorMessage key={`validation-error-${i}`}>{message}</ErrorMessage>
      ))}
      {apiErrors.map((message, i) => (
        <ErrorMessage key={`api-error-${i}`}>{message}</ErrorMessage>
      ))}
      <CloseButton aria-label="close profile info modal" onClick={closeModal}>
        Close
      </CloseButton>
    </Wrapper>
  );
};

AddAdminSubdomain.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentSite: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  validSubdomains: PropTypes.array
};

export default AddAdminSubdomain;
