import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExternalLink, Loading, StickyColumnTable } from 'components';
import { fetchCareerSlugsByPost } from '../../../services';
import { usePagination } from '../../../hooks/usePagination';

const Wrapper = styled.div`
  max-width: 108rem;
`;

const Header = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
`;

const AssessmentWidgetCareerCoachVisitsTable = ({ data, subdomain }) => {
  const [careerSlugs, setCareerSlugs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const {
    handlePageNumberChange,
    pageNumber,
    paginatedData: paginatedTableData,
    rowsPerPage
  } = usePagination(data);

  useEffect(() => {
    setIsLoading(true);
    const fetchSlugs = async () => {
      const careerCodes = data.map(item => item.careerCode);
      const response = await fetchCareerSlugsByPost(careerCodes);
      const extractedSlugs = Object.entries(response).reduce((acc, [key, value]) => {
        acc[key] = value.titleSlug;
        return acc;
      }, {});

      setCareerSlugs(extractedSlugs);
      setIsLoading(false);
    };

    fetchSlugs();
  }, [data]);

  const columns = [
    {
      id: 'careerTitle',
      header: 'Career',
      width: 70,
      value: rowData => {
        const careerSlug = careerSlugs[rowData.careerCode] || null;
        const careerLink = careerSlug
          ? `https://${subdomain}.lightcastcc.com/careers/${careerSlug}`
          : `https://${subdomain}.lightcastcc.com/browse-careers`;
        return <ExternalLink url={careerLink}>{rowData.careerTitle}</ExternalLink>;
      }
    },
    {
      id: 'result',
      header: 'Career Coach Clicks',
      width: 50,
      textAlign: 'center'
    }
  ];

  const isNoData = !!paginatedTableData && paginatedTableData.length === 0;
  if (isNoData) {
    return null;
  }

  return (
    <Wrapper>
      <Loading loaded={!isLoading}>
        <Header>{'Career Pages Visited from Widget'}</Header>
        <StickyColumnTable
          columns={columns}
          dataCy="assessment-widget-activity-by-career"
          isLoading={!paginatedTableData}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          setPageNumber={handlePageNumberChange}
          shouldAlternateRowColor
          tableData={paginatedTableData || []}
          totalRows={data.length}
        />
      </Loading>
    </Wrapper>
  );
};

AssessmentWidgetCareerCoachVisitsTable.propTypes = {
  data: PropTypes.array.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default AssessmentWidgetCareerCoachVisitsTable;
