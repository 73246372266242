import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import X from '../../../images/x.svg';
import { color } from '../../../styles';
import { fetchToken } from '../../../services/inviteToken';
import { sendAdminInvitation } from '../../../services/mandrill';
import { deleteProfile, updateProfile } from '../../../services/profiles';
import {
  Button,
  ConfirmationModal,
  HideContentOnMobile,
  Loading,
  LoadingButton,
  LocalAdminConfirmationModal,
  Strong
} from 'components';

const { gray, red, sapphireD20 } = color;

const SectionHeader = styled.h3`
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-weight: 700;
  font-size: 1.5rem;
`;

const LinkButton = styled.button`
  background: none !important;
  border: none;
  color: ${sapphireD20};
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0 !important;
  text-decoration: underline;
`;

const RemoveIcon = styled(X)`
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  top: 3px;
  fill: ${red};
`;

const RemoveAccessButton = styled.button`
  align-self: center;
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  width: 12rem;
`;

const Row = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${gray};
`;

const Cell = styled.span`
  width: 15rem;
  height: 4rem;
  display: flex;
  align-items: center;
`;

const EmailCell = styled(Cell)`
  width: 35rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const EmailHeaderCell = styled(EmailCell)`
  font-weight: 700;
`;

const HeaderCell = styled(Cell)`
  font-weight: 700;
`;

const ModalsWrapper = styled.span`
  position: absolute;
`;

const determineStatus = ({ invitationDate, isInvited }) => {
  if (!isInvited) {
    return 'Activated';
  }

  const now = moment().format();
  if (
    moment(invitationDate)
      .add(7, 'days')
      .format() < now
  ) {
    return 'Expired';
  }

  return 'Pending';
};

const AdminInviteTable = ({
  adminProfiles,
  fetchProfiles,
  modals,
  modalShow,
  modalHide,
  nation,
  setIsLoading,
  institutionName,
  isLoading
}) => {
  const [invitationError, setInvitationError] = useState();

  const resendInvitation = async profile => {
    const { email, profileId, subdomain } = profile;

    const token = await fetchToken({ nation, profileId, subdomain, email });
    if (!token) {
      return setInvitationError('invitation token could not be generated');
    }

    const sendInviteEmailResponse = await sendAdminInvitation({
      institutionName,
      token,
      email
    });
    if (sendInviteEmailResponse.status !== 200) {
      return setInvitationError('invitation email could not be resent');
    }

    const data = {
      invitationDate: moment().format()
    };
    await updateProfile(subdomain, profileId, data);

    setIsLoading(true);
    fetchProfiles();
    modalHide(`resend${profile.profileId}`);
  };

  const removeAccess = profile => {
    if (profile.isInvited) {
      return modalShow(`invite${profile.profileId}`);
    } else {
      return modalShow(`admin${profile.profileId}`);
    }
  };

  const sortedProfiles = adminProfiles.sort((a, b) => {
    if (a.isInvited === b.isInvited) {
      return 0;
    } else {
      return a.isInvited ? -1 : 1;
    }
  });

  return (
    <HideContentOnMobile message="Please view the Administrators Table on desktop!">
      <SectionHeader>List of Admins</SectionHeader>
      <ul>
        <Row>
          <EmailHeaderCell>Email</EmailHeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Resend Invitation</HeaderCell>
          <HeaderCell>Remove Access</HeaderCell>
        </Row>
        <Loading loaded={!isLoading}>
          {sortedProfiles.map(profile => {
            const { email, invitationDate, isInvited } = profile;
            return (
              <Row key={`admin-invite-table-${profile.profileId}`}>
                <EmailCell>{email}</EmailCell>
                <Cell>{determineStatus({ invitationDate, isInvited })}</Cell>
                <Cell>
                  {isInvited ? (
                    <LinkButton
                      onClick={() => {
                        setInvitationError();
                        modalShow(`resend${profile.profileId}`);
                      }}
                    >
                      Resend
                    </LinkButton>
                  ) : (
                    '-'
                  )}
                </Cell>
                <Cell>
                  <RemoveAccessButton
                    aria-label={`remove access for ${profile.email}`}
                    onClick={() => removeAccess(profile)}
                  >
                    <RemoveIcon />
                  </RemoveAccessButton>
                </Cell>
                <ModalsWrapper>
                  {modals[`admin${profile.profileId}`] && (
                    <LocalAdminConfirmationModal
                      row={{ ...profile, name: `${profile.givenName} ${profile.familyName}` }}
                      cell={profile.userRole}
                      modalHide={modalHide}
                      nation={nation}
                      red={red}
                      subdomain={profile.subdomain}
                      reloadProfiles={() => {
                        setIsLoading(true);
                        fetchProfiles();
                      }}
                    />
                  )}
                  {modals[`invite${profile.profileId}`] && (
                    <ConfirmationModal
                      name={`invite${profile.profileId}`}
                      heading="Revoke Admin Invitation?"
                      modalHide={modalHide}
                      text={
                        <Fragment>
                          Admin invitation will be revoked for <Strong>{profile.email}</Strong>
                        </Fragment>
                      }
                      confirmationButton={
                        <Button
                          color="#ffffff"
                          background={red}
                          onClick={() => {
                            deleteProfile(profile);
                            modalHide(`invite-${profile.profileId}`);
                            setIsLoading(true);
                            fetchProfiles();
                          }}
                        >
                          Yes
                        </Button>
                      }
                    />
                  )}
                  {modals[`resend${profile.profileId}`] && (
                    <ConfirmationModal
                      error={invitationError}
                      name={`resend${profile.profileId}`}
                      heading="Resend Invitation?"
                      modalHide={modalHide}
                      text={
                        <Fragment>
                          Admin invitation shall once again be sent to{' '}
                          <Strong>{profile.email}</Strong>
                        </Fragment>
                      }
                      confirmationButton={
                        <LoadingButton
                          background={sapphireD20}
                          color="#ffffff"
                          onClick={() => resendInvitation(profile)}
                        >
                          Yes
                        </LoadingButton>
                      }
                    />
                  )}
                </ModalsWrapper>
              </Row>
            );
          })}
        </Loading>
      </ul>
    </HideContentOnMobile>
  );
};

AdminInviteTable.propTypes = {
  adminProfiles: PropTypes.array.isRequired,
  fetchProfiles: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  modalHide: PropTypes.func.isRequired,
  nation: PropTypes.string,
  setIsLoading: PropTypes.func.isRequired,
  institutionName: PropTypes.string,
  isLoading: PropTypes.bool
};

export default AdminInviteTable;
