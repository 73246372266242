import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import moment from 'moment';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

const ExcelEmployersWorkbook = ({ employers, postings }) => {
  let employerData = Object.values(employers)
    .filter(employer => employer.status === 'approved')
    .sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB);
    });

  let postingData = Object.values(postings)
    .filter(posting => posting.status !== 'denied')
    .map(posting => {
      let status = posting.status;
      const isEnded = ({ endOn }) => moment().isAfter(moment(endOn, 'MM-DD-YYYY'), 'day');
      if (isEnded(posting)) {
        status = 'ended';
      } else if (status === 'approved') {
        status = 'live';
      }
      return {
        ...posting,
        employerName: posting.employer.name,
        status
      };
    })
    .sort((a, b) => {
      let nameA = a.employerName.toUpperCase();
      let nameB = b.employerName.toUpperCase();
      return nameA.localeCompare(nameB);
    });

  const introduction = [
    {
      text:
        "The Career Coach Employers workbook consists of a two worksheets. The first contains the contact information for all approved employers in your institution's employer portal, and the second contains job postings that were not denied.",
      value: null
    }
  ];

  return (
    <ExcelFile filename="Career_Coach_Employer_Information" hideElement>
      <ExcelSheet data={employerData} name="Employer Contact Information">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Contact Name" value="contactName" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="phoneNumber" />
        <ExcelColumn label="Website" value="website" />
      </ExcelSheet>
      <ExcelSheet data={postingData} name="Employer Postings">
        <ExcelColumn label="Employer Name" value="employerName" />
        <ExcelColumn label="Job Title" value="title" />
        <ExcelColumn label="Job Type" value="jobType" />
        <ExcelColumn label="Description" value="description" />
        <ExcelColumn label="Posting Status" value="status" />
        <ExcelColumn label="Posted Date" value="createdOn" />
        <ExcelColumn label="End Date" value="endOn" />
      </ExcelSheet>
      <ExcelSheet data={introduction} name="Introduction">
        <ExcelColumn label={`${introduction[0].text}`} value="value" />
      </ExcelSheet>
    </ExcelFile>
  );
};

ExcelEmployersWorkbook.propTypes = {
  employers: PropTypes.object.isRequired,
  postings: PropTypes.object.isRequired
};

export default ExcelEmployersWorkbook;
