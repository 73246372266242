import { takeLatest } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchPostingsSuccess,
  fetchPostingAnalyticsSuccess,
  updatePostingSuccess,
  createPostingSuccess
} from '../actions/actionCreators';
import * as t from '../actions/constants';
import {
  fetchPostings,
  fetchPostingAnalytics,
  updatePosting,
  createPosting,
  sendApprovedPosting,
  sendDeniedPosting,
  sendEndedPosting
} from '../services';

export function* fetchPostingsSaga(action) {
  const postings = yield call(fetchPostings, action.payload);
  postings && (yield put(fetchPostingsSuccess(postings)));
}

export function* fetchPostingAnalyticsSaga(action) {
  const postings = yield call(fetchPostingAnalytics, action.payload);
  postings && (yield put(fetchPostingAnalyticsSuccess(postings)));
}

export function* createPostingSaga({ subdomain, payload: { ...data } }) {
  const posting = yield call(createPosting, subdomain, data);
  posting && (yield put(createPostingSuccess(posting)));
}

export function* updatePostingSaga({
  // remove `selectedApplicant` and`id` from the payload, we don't want to patch them in this instance
  payload: { postingId, subdomain, noEmail, message, selectedApplicant, id, ...data }
}) {
  const site = yield select(state => state.sites.items[state.sites.currentSite]);
  const posting = yield call(updatePosting, subdomain, postingId, data);
  if (!noEmail) {
    if (data.status === 'approved') {
      sendApprovedPosting(data, site.employerNotificationEmail, subdomain);
    } else if (data.status === 'denied') {
      sendDeniedPosting(data, site.employerNotificationEmail, subdomain, message, site.whiteLabel);
    } else {
      sendEndedPosting(data, site.employerNotificationEmail, subdomain, message, site.whiteLabel);
    }
  }

  posting && (yield put(updatePostingSuccess(posting)));
}

export function* watchFetchPostings() {
  yield call(takeLatest, t.FETCH_POSTINGS_REQUEST, fetchPostingsSaga);
}

export function* watchFetchPostingAnalytics() {
  yield call(takeLatest, t.FETCH_POSTING_ANALYTICS_REQUEST, fetchPostingAnalyticsSaga);
}

export function* createPostingAPI() {
  yield call(takeLatest, t.CREATE_POSTING_REQUEST, createPostingSaga);
}

export function* updatePostingAPI() {
  yield call(takeLatest, t.UPDATE_POSTING_REQUEST, updatePostingSaga);
}
