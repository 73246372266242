import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProgramSettingsForm } from 'components';
import { patchSiteRequest } from '../../actions/actionCreators';

class ProgramSettingsFormContainer extends React.Component {
  render() {
    return (
      <ProgramSettingsForm {...this.props} saveProgramSettings={this.saveProgramSettings} />
    );
  }

  saveProgramSettings = ({ ...settings }) => {
    this.props.patchSite({
      subdomain: this.props.currentSite,
      programSettings: {
        ...settings
      }
    });
  };
}

ProgramSettingsFormContainer.propTypes = {
  currentSite: PropTypes.string.isRequired,
  patchSite: PropTypes.func.isRequired
};

export const mapStateToProps = ({ sites }, ownProps) => ({
  currentSite: sites.currentSite,
  initialValues: sites.items[sites.currentSite].programSettings
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  patchSite: data => dispatch(patchSiteRequest(data))
});

export { ProgramSettingsFormContainer as PureProgramSettingsFormContainer }; // used in tests

export default connect(mapStateToProps, mapDispatchToProps)(
  ProgramSettingsFormContainer
);
