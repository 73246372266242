import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  ConfirmationModal,
  EmployersTable,
  Icon,
  HiresModal,
  Strong,
  TextButton,
  Truncate
} from 'components';
import { color } from '../../../styles';
import { sortStrings } from '../../../helpers/sort';
import { withNationHOC } from '../../../hocs/withNation';

const Table = styled(EmployersTable)`
  /* last (remove icon) column heading and data */
  td:last-child {
    padding-left: 1.8rem;
  }
`;

const ApprovedEmployers = withNationHOC(
  ({ data, modals, modalShow, modalHide, nation, updateEmployer }) => {
    return (
      <Table
        data={data}
        modals={modals}
        modalShow={modalShow}
        options={{
          defaultSortName: 'name',
          defaultSortOrder: 'asc'
        }}
        statusType="approved"
        tableColumns={[
          {
            dataField: 'location',
            text: 'Location',
            sortFunc: sortStrings,
            dataFormat: cell => <Truncate>{cell || '---'}</Truncate>
          },
          {
            dataField: 'livePostings',
            text: 'Live Postings',
            dataFormat: (cell = []) => cell.length
          },
          {
            dataField: 'endedPostings',
            text: 'Ended Postings',
            dataFormat: (cell = []) => cell.length
          },
          {
            dataField: 'hires',
            text: 'Hires',
            dataFormat: (cell, row) =>
              cell.length > 0 && nation === 'us' ? (
                <Fragment>
                  <TextButton onClick={() => modalShow(`hires${row.employerId}`)}>
                    {cell.length}
                  </TextButton>
                  {modals[`hires${row.employerId}`] && (
                    <HiresModal
                      name={`hires${row.employerId}`}
                      hires={cell}
                      employerName={row.name}
                    />
                  )}
                </Fragment>
              ) : (
                cell.length
              )
          },
          {
            dataField: null,
            text: 'Remove',
            dataSort: false,
            dataFormat: (_, row) => (
              <Fragment>
                <Icon
                  type="close"
                  tabIndex="0"
                  size="2"
                  role="button"
                  aria-label={`Deny Employer: ${row.name}`}
                  color={color.red}
                  onKeyDown={e => e.keyCode === 13 && modalShow(`deny${row.employerId}`)}
                  onClick={() => modalShow(`deny${row.employerId}`)}
                />
                {modals[`deny${row.employerId}`] && (
                  <ConfirmationModal
                    name={`deny${row.employerId}`}
                    heading="Remove Employer?"
                    modalHide={modalHide}
                    deny
                    text={
                      <Fragment>
                        <Strong>{row.name}</Strong> will no longer be able to publish postings, and
                        all of their postings will be ended. <br />
                        You will no longer have access to their posting statistics. The employer can
                        request approval again in the future.
                      </Fragment>
                    }
                    confirmationButton={
                      <Button
                        background={color.red}
                        onClick={message => {
                          updateEmployer({
                            subdomain: row.subdomain,
                            employerId: row.employerId,
                            status: 'denied',
                            email: row.email,
                            message
                          });
                          modalHide(`deny${row.employerId}`);
                        }}
                      >
                        Remove
                      </Button>
                    }
                  />
                )}
              </Fragment>
            )
          }
        ]}
      />
    );
  }
);

ApprovedEmployers.propTypes = {
  data: PropTypes.object.isRequired,
  modalHide: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  modalShow: PropTypes.func.isRequired,
  updateEmployer: PropTypes.func.isRequired
};

export default ApprovedEmployers;
