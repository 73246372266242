import styled from 'styled-components';
import { darken } from 'polished';

import XIcon from '../../../images/x.svg';
import { color } from '../../../styles';
import { Button, Select } from 'components';

const { black, blue, darkGray, lightGray, red } = color;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.h3`
  margin-top: 2.8rem;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
`;

export const Label = styled.label`
  width: 48%;
  font-weight: 600;
  height: ${({ height }) => (!!height ? height : '7.5rem')};
`;

export const TransferInputWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const TransferLabel = styled(Label)`
  width 100%;
  ${({ isHidden }) =>
    isHidden
      ? ' position:absolute !important; height: 1px; width: 1px; overflow: hidden; clip: rect(1px, 1px, 1px, 1px);'
      : ''};
`;

export const TransferButtonWrapper = styled.div`
  height: 9rem;
  display: flex;
  align-items: center;
`;

export const AddTransferButton = styled(Button)`
  visibility: ${({ isVisible = true }) => (isVisible ? 'initial' : 'hidden')};
  width: 7rem;
  height: 4rem;
  background: white;
  padding: 1rem 0;

  border: 1px solid ${blue};
  color: ${blue};
  font-weight: 600;

  :hover {
    color: ${darken(0.05, blue)}
    background: white;
    border: 1px solid ${darken(0.05, blue)}
  }
`;

export const DeleteTransferButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.5rem 0 0 1.5rem;
  padding: 0 1px 0 1px;
  border-radius: 50%;
  background: ${red};
  color: white;

  :hover {
    background: ${darken(0.05, red)};
  }
`;

export const WhiteXIcon = styled(XIcon)`
  fill: white;
`;

export const ButtonRow = styled(Row)`
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  margin: 2rem 2rem 0 0;
  color: ${black};
  background: white;

  :hover {
    background: ${lightGray};
  }
`;

export const SaveButton = styled(Button)`
  background: ${blue}
  margin-top: 2rem;

  :hover {
    background: ${darken(0.05, blue)};
  }
`;

export const PillCloseIconWrapper = styled.span`
  position: relative;
`;

export const PillCloseIcon = styled(XIcon)`
  position: absolute;
  top: -1.6rem;
  right: -1.5rem;
  width: 0.7rem;
  height: 0.7rem;
  cursor: pointer;
  fill: ${darkGray};
`;

export const StyledSelect = styled(Select)`
  .select {
    background: white;
  }
`;
