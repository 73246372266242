import { connect } from 'react-redux';
import { StatTabCard } from 'components';
import { modalShow } from '../../reducers/modal';
import { selectSubdomain } from '../../selectors/subdomain';

const mapStateToProps = ({ modal, sites, profile }) => {
  const currentSite = selectSubdomain(sites, profile);

  return {
    modals: modal,
    nation: sites.items[currentSite].nation
  };
};

export default connect(mapStateToProps, { modalShow })(StatTabCard);
