import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const A = styled.a`
  color: ${theme.linkColor};
  text-decoration: none;
`;

const ExternalLink = ({ url, children, ...other }) => {
  return (
    <A {...other} href={url} rel="noopener noreferrer" target="_blank">
      {children}
    </A>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string
};

export default ExternalLink;
