import React from 'react';
import { Chart } from 'components';

const WorkOpportunityViewsChart = props => {
  return (
    <Chart
      {...props}
      subheading="Total Page Views"
      a11yCaption="Chart of visit count by month. Series 1 indicates Total Page Views, Series 2 indicates Unique Page Views"
      a11ySummary="Overall total Page Views and unique Page Views"
      a11yHeader="Dates"
      legendNames={['Total Page Views', 'Unique Page Views']}
    />
  );
};

export default WorkOpportunityViewsChart;
