import React from 'react';
import { connect } from 'react-redux';
import { selectSubdomain } from '../selectors/subdomain';

export const WithSite = connect(({ profile, sites }) => ({
  ...sites.items[selectSubdomain(sites, profile)]
}))(({ children, ...props }) => children(props));

export const withSiteHOC = () => WrappedComponent =>
  connect(({ profile, sites }) => ({
    ...sites.items[selectSubdomain(sites, profile)]
  }))(props => <WrappedComponent {...props} />);
