import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from '../../../styles';
import { uploadPrograms } from '../../../services/uploads';
import { Card, ProgramDownloadButton, Upload } from 'components';
import ExcelProgramUploadErrorWorkbook from './ExcelProgramUploadErrorWorkbook.js';

const H2 = styled.h2`
  margin-bottom: 2rem;
  font-size: 2.1rem;
`;

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const HalfWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1.5rem 1rem;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0.5rem 0 1rem;
  }
`;

const H3 = styled.h3`
  margin: 0.6rem 0;
  font-weight: 600;
`;

const ProgramDownloadWrapper = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${color.mediumGray};
`;

const ProgramUpload = props => {
  const { nation, subdomain } = props;

  const [shouldExportErrors, setShouldExportErrors] = useState();
  const [programUploadErrors, setProgramUploadErrors] = useState();

  useEffect(() => {
    if (shouldExportErrors) {
      setShouldExportErrors(false);
    }
  }, [shouldExportErrors]);

  const maxFileSize = 1000000;
  const tooBigMessage = `Please limit spreadsheets to less than ${maxFileSize /
    1000000}MB in size.`;

  return (
    <Card>
      <H2>Programs</H2>
      <Wrapper>
        <HalfWrapper>
          <H3>Download Programs</H3>
          <ProgramDownloadWrapper>
            <ProgramDownloadButton nation={nation} subdomain={subdomain} />
          </ProgramDownloadWrapper>
        </HalfWrapper>
        <HalfWrapper>
          <H3>Upload Programs</H3>
          <Upload
            {...props}
            ariaLabel={'Program Uploader'}
            caveat={tooBigMessage}
            mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            maxSize={maxFileSize}
            handleUpload={async ({ acceptedFile, setUploading }) => {
              const programsResponse = await uploadPrograms({
                subdomain,
                uploadFile: acceptedFile
              });

              if (programsResponse.status === 400) {
                const json = await programsResponse.json();
                setProgramUploadErrors(json.errors);
                setShouldExportErrors(true);
                return {
                  errorMessage: 'Program upload validation failed. check the error report download'
                };
              }

              setUploading(false);
            }}
          />
          {shouldExportErrors && (
            <ExcelProgramUploadErrorWorkbook subdomain={subdomain} errors={programUploadErrors} />
          )}
        </HalfWrapper>
      </Wrapper>
    </Card>
  );
};

ProgramUpload.propTypes = {
  nation: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired
};

export default ProgramUpload;
