import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { capital } from 'case';
import { truthy } from 'is_js';

import { borderRadius } from '../../../styles';
import { ExternalLink, InfoEntry } from 'components';

const Content = styled.div`
  background: #f2f2f2;
  padding: 2rem 5rem;
  border-radius: 0 0 ${borderRadius} ${borderRadius};
`;

const InfoBody = ({ entryKeys, row }) => {
  const { careers, city = '', state = '', postalCode = '', address = '', website, jobType } = row;
  const entryType = {
    address:
      // if any of these values are true, continue on to formatting, otherwise N/A
      (city || state || postalCode || address) && (
        <Fragment>
          {address}
          {city && state ? (
            <p>
              {city}, {state} {postalCode}
            </p>
          ) : (
            <p>
              {city} {state} {postalCode}
            </p>
          )}
        </Fragment>
      ),
    jobType: jobType,
    website: website && (
      <ExternalLink
        url={
          // Checks if given link starts with `http(s)://`, and if not prepends it.
          /^https?:\/\//i.test(website) ? website : `http://${website}`
        }
      >
        {website}
      </ExternalLink>
    ),
    careers:
      truthy(careers) &&
      careers.map((career, i) => (
        <Fragment key={`career-${i}`}>
          {capital(career.humanizedTitle)}
          <br />
        </Fragment>
      )),
    studentId: row.institutionUserId || ''
  };

  // this allows us to pass in nested "entryKeys" like `application.type`
  // stole from https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
  const resolvePath = (object, path, defaultValue) =>
    path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);

  return (
    <Fragment>
      <Content>
        {entryKeys.map((key, index) => {
          let entryKey = key;
          let label = key;
          if (typeof key === 'object') {
            entryKey = key.value;
            label = key.label;
          }

          const applicationTypes = {
            standard: 'Apply through portal',
            'employer-link': 'Apply on company site (provide URL)',
            'instructions-only': 'Special instructions only'
          };
          const displayValue =
            label === 'application.type'
              ? applicationTypes[resolvePath(row, entryKey)]
              : entryType[entryKey] || resolvePath(row, entryKey);
          return (
            <InfoEntry key={`entry${index}`} label={label}>
              {displayValue}
            </InfoEntry>
          );
        })}
      </Content>
    </Fragment>
  );
};

InfoBody.propTypes = {
  entryKeys: PropTypes.array.isRequired,
  row: PropTypes.shape({
    address: PropTypes.string,
    careers: PropTypes.array,
    city: PropTypes.string,
    jobType: PropTypes.string,
    postalCode: PropTypes.string,
    website: PropTypes.string
  }).isRequired
};

export default InfoBody;
