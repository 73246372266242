import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

class DaySelect extends React.Component {
  render() {
    const { days: numDays, ...props } = this.props;
    const max = numDays || 31;
    let days = [];
    for (let i = 1; i <= max; ++i) {
      days.push({
        value: i,
        label: i
      });
    }
    return <Select options={days} {...props} />;
  }

  static get dateType() {
    // In JS, "days" is "date"
    return 'date';
  }
}

DaySelect.propTypes = {
  days: PropTypes.number
};

export default DaySelect;
