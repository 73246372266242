import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import { Button, GlobalNav, Link, Logo, MobileMenu, SiteName } from 'components';

import { Authenticated, GlobalSelect } from 'containers';
import config from '../../../config';
const { cloudfrontUrl } = config;

/* GlobalSelect and New Site button only show up on desktop screens.
They are accessed through the mobile nav menu on mobile screens. */
const SelectWrapper = styled.div`
  align-self: center;
  flex-basis: 45%;
  margin-right: 3rem;
  display: flex;

  & > :first-child {
    width: 20rem;
  }

  & > button {
    height: 44px;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 100px;
  }

  @media (max-width: 1060px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  display: inline-flex;
  @media (min-width: 1060px) {
    display: flex;
  }

  justify-content: space-between;

  & > :first-child {
    align-self: center;
  }
`;

const SiteLink = styled.a`
  width: 30%;
  flex-grow: 3;
  align-self: center;

  @media (max-width: 1060px) {
    display: none;
  }
  div > p,
  div > span > div {
    color: ${theme.menuColor};
  }
`;

const SuperContentWrapper = styled.div`
  align-self: center;
`;

const HeaderBackGround = styled.div`
  background-color: ${theme.menuBackgroundColor};
  border: none;
`;

export const GlobalHeader = ({ adminSubdomains, sites, history, currentSite }) => {
  const { name: siteName, subdomain, styles } = sites.items[currentSite];
  const isMultiAdmin = adminSubdomains && adminSubdomains.length > 1;
  return (
    <HeaderBackGround>
      <Wrapper>
        <MobileMenu subdomain={subdomain} />

        <Link to="/">
          {
            // The image source includes a timestamp on it so that the browser doesn't cache an old image
            // This is useful for updating to the correct logo when we add/change logos
          }

          <Logo image={styles && `${cloudfrontUrl}/logos/${styles.logoFilename}?${Date.now()}`} />
        </Link>

        <SuperContentWrapper>
          <Authenticated requiresRole="super">
            <SelectWrapper>
              <GlobalSelect />
              <Button id="new-site-button" onClick={() => history.push('/add-site')}>
                New Site
              </Button>
            </SelectWrapper>
          </Authenticated>

          <Authenticated requiresRole="admin">
            {isMultiAdmin ? (
              <SelectWrapper>
                <GlobalSelect adminSubdomains={adminSubdomains} />
              </SelectWrapper>
            ) : (
              <SiteLink id="global-site-name" href={`https://${subdomain}.lightcastcc.com`}>
                <SiteName name={siteName} />
              </SiteLink>
            )}
          </Authenticated>
        </SuperContentWrapper>

        <GlobalNav />
      </Wrapper>
    </HeaderBackGround>
  );
};

GlobalHeader.propTypes = {
  adminSubdomains: PropTypes.array,
  currentSite: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  sites: PropTypes.object
};

export default GlobalHeader;
