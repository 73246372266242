import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withState } from 'recompose';
import { Button, Header, HiddenLabel, Input } from 'components';
import { Modal } from 'containers';
import { color } from '../../../styles';

const { darkerGray, lightGray, red } = color;

const Text = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  padding: 3rem;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  button:first-child {
    margin-right: 1rem;
  }
`;

const Error = styled.div`
  color: ${red};
  margin-top: 2rem;
`;

const ConfirmationModal = withState(
  'message',
  'handleMessage',
  ''
)(
  ({
    confirmationButton,
    deny,
    error,
    handleMessage,
    heading,
    inputPlaceholder,
    message,
    modalHide,
    name,
    text
  }) => {
    const button =
      !!confirmationButton &&
      React.cloneElement(confirmationButton, {
        onClick: () => confirmationButton.props.onClick(message)
      });
    const onClose = () => handleMessage('');
    return (
      <Modal name={name} onClose={onClose}>
        <Wrapper>
          <Header component="h1" type="headline">
            {heading}
          </Header>
          <Text>{text}</Text>
          {deny && (
            <Fragment>
              <HiddenLabel htmlFor="deny-modal">Optional message to employer</HiddenLabel>
              <Input
                onChange={e => handleMessage(e.target.value)}
                id="deny-modal"
                type="text"
                value={message}
                placeholder={inputPlaceholder || 'Message to employer (optional)'}
                name="deny modal"
                component="textarea"
              />
            </Fragment>
          )}
          <ButtonWrapper>
            <Button
              background={lightGray}
              color={darkerGray}
              onClick={() => {
                modalHide(name);
                onClose();
              }}
            >
              Cancel
            </Button>
            {!!confirmationButton && button}
          </ButtonWrapper>
          {error && (
            <Error role="alert" type="error">
              {error}
            </Error>
          )}
        </Wrapper>
      </Modal>
    );
  }
);

ConfirmationModal.propTypes = {
  confirmationButton: PropTypes.node,
  deny: PropTypes.bool,
  error: PropTypes.string,
  handleMessage: PropTypes.func,
  heading: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  modalHide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default ConfirmationModal;
